import React from "react";
import { useIntl } from "react-intl";

import { Tag } from "@trace-one/design-system";
import { Space } from "antd";
import useCompanyStatuses from "pages/Companies/hooks/useCompanyStatuses";

import { CompanyForTraceoneAdminData } from "models";

import Card, { CardColumn } from "components/Card";

import useCompanyActivityLabels from "../../../../../shared/hooks/useCompanyActivityLabels";

interface CompanyCardProps {
  company: CompanyForTraceoneAdminData;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
  const { formatMessage } = useIntl();

  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();
  const { companyActivityLabels } = useCompanyActivityLabels();

  const columns: CardColumn[] = [
    {
      title: formatMessage({ id: "companyList.companyDisplayName" }),
      content: company?.companyDisplayName,
    },
    {
      title: formatMessage({ id: "general.companyType" }),
      content: companyActivityLabels[company?.companyActivityId],
    },
    {
      title: formatMessage({ id: "general.status" }),
      content: (
        <Space>
          <Tag
            label={companyStatusLabels[company?.companyStatus]}
            color={companyStatusTagColors[company?.companyStatus]}
            size="medium"
            mode="light"
          />
        </Space>
      ) as any,
    },
  ];

  return (
    <Card
      columns={columns}
      columnProps={{
        xs: 24,
        md: 8,
        xl: 8,
      }}
    />
  );
};

export default CompanyCard;
