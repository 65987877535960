import { useIntl } from "react-intl";

import { ApplicationType, ModernizedApplicationType } from "shared/constants";

export interface AppTranslationsArgs {
  modernizedOnly?: boolean;
}

export default function useAppTranslations({
  modernizedOnly,
}: AppTranslationsArgs = {}) {
  const { formatMessage } = useIntl();

  const appLabels = {
    [ApplicationType.PROJECT]: "Project",
    [ApplicationType.QMS]: "QMS",
    [ApplicationType.SPEC]: "Specification",
    [ApplicationType.RFX]: "RFX",
    [ApplicationType.MASTERDATA]: "Master Data",
    [ApplicationType.ADMINISTRATION]: "Administration",
    [ApplicationType.INSIGHT]: "Insight",
    [ApplicationType.QUALITY_PORTAL]: formatMessage({
      id: "app.qualityPortal",
    }),
    [ApplicationType.PACK_PORTAL]: formatMessage({
      id: "app.packPortal",
    }),
    [ApplicationType.PDM]: "PDM",
    [ApplicationType.PDM_NA]: "PLM NA",
    [ApplicationType.PDM_FIND]: "M&S FIND",
    [ApplicationType.BUSINESS_INTELLIGENCE]: "Business Intelligence",
    [ApplicationType.TON]: "Network",
  };

  if (modernizedOnly) {
    const modernizedApps = Object.entries(appLabels).filter(
      ([key, value]) => !!ModernizedApplicationType[key]
    );

    const appLabelMap: {
      [key: number]: string;
    } = Object.fromEntries(modernizedApps);

    return {
      appLabelMap,
      appLabelOptions: Object.entries(appLabelMap).map(([value, label]) => ({
        value,
        label,
      })),
    };
  }

  return {
    appLabelMap: appLabels,
    appLabelOptions: Object.entries(appLabels).map(([value, label]) => ({
      value,
      label,
    })),
  };
}
