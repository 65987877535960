import React from "react";
import { useIntl } from "react-intl";

import { Modal } from "@trace-one/design-system";

import { RelationData } from "../../../../../../models";
import styles from "../../CompanyRelationList.module.less";

const ReactivateModal = ({
  visible,
  setVisible,
  selectedRelation,
  reactivateRelation,
}: {
  visible: boolean;
  setVisible: (boolean) => void;
  selectedRelation: RelationData;
  reactivateRelation: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Simple
      title={formatMessage({
        id: "general.reactivate",
      })}
      illustrationName="warning"
      open={visible}
      primaryButtonText={formatMessage({ id: "general.confirm" })}
      secondaryButtonText={formatMessage({ id: "general.cancel" })}
      onPrimaryButtonClick={() => {
        reactivateRelation();

        setVisible(false);
      }}
      onSecondaryButtonClick={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <section className={styles.relationModal}>
        <div className={styles.description}>
          {formatMessage(
            {
              id: "companyRelationList.actions.reactivateRelation.description",
            },
            {
              ownerCompanyName: selectedRelation.ownerCompanyName,
              associatedCompanyName: selectedRelation.associatedCompanyName,
            }
          )}
          <p>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </p>
        </div>
      </section>
    </Modal.Simple>
  );
};

export default ReactivateModal;
