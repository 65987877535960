import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Card } from "@trace-one/react-components";

import { RoleForCompanyData, UserForUIAdminData } from "models";

import useAppTranslations from "shared/hooks/useAppTranslations";

import AppIcon from "../../../../../../../components/AppIcon";
import usePermissions from "../../../../../../../core/oidc/usePermissions";

import AppSwitch from "./AppSwitch";
import styles from "./UserRolesApp.module.less";

interface ActivityCardProps {
  value?: string[];
  onChange?: (roles: string[]) => void;
  app?: RoleForCompanyData;
  selectedApp?: number;
  setSelectedApp?: Dispatch<SetStateAction<number>>;
  appTypeId?: number;
  user: UserForUIAdminData;
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  value,
  onChange,
  app,
  selectedApp,
  setSelectedApp,
  appTypeId,
  user,
}) => {
  const { hasRoleSuperAdmin } = usePermissions();

  const { appLabelMap } = useAppTranslations();
  const [isAppActivated, setIsAppActivated] = useState<boolean>(false);

  useEffect(() => {
    setIsAppActivated(Array.isArray(value));
  }, []);

  return (
    <div
      className={
        app.applicationTypeId === selectedApp
          ? styles.modernCardActivated
          : styles.modernCard
      }
    >
      <Card
        title={
          <>
            <div>
              <AppIcon type={appTypeId} />
            </div>
            <div className={styles.appName}>{appLabelMap[appTypeId]}</div>
          </>
        }
        onClick={() => {
          if (isAppActivated) {
            setSelectedApp(app.applicationTypeId);
          }
        }}
        data-test-id={`user-manage-app-card-${appLabelMap[appTypeId]}`}
      >
        <AppSwitch
          checked={Array.isArray(value)}
          onChange={value => {
            setIsAppActivated(value);

            if (value) {
              setSelectedApp(app.applicationTypeId);
              onChange([]);
            } else {
              setSelectedApp(null);
              onChange(null);
            }
          }}
          applicationTypeId={app.applicationTypeId}
          disabled={
            app.applicationTypeId === 90 &&
            hasRoleSuperAdmin === false &&
            user.isTraceOneCompany === true
          }
        />
      </Card>
    </div>
  );
};

export default ActivityCard;
