import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Modal, DatePicker, Tooltip } from "@trace-one/design-system";
import { Radio, RadioChangeEvent } from "antd";
import moment from "moment";

import { RelationData } from "../../../../../../models";
import {
  RelationCloseNow,
  RelationCloseSchedule,
} from "../../../CompanyList/models";
import styles from "../../CompanyRelationList.module.less";

const CloseModal = ({
  visible,
  setVisible,
  selectedRelation,
  disableRelation,
  scheduleClosure,
}: {
  visible: boolean;
  setVisible: (boolean) => void;
  selectedRelation: RelationData;
  disableRelation: () => void;
  scheduleClosure: ({ relationIds, targetSuspendDate, action }) => void;
}) => {
  const { formatMessage } = useIntl();
  const [action, setAction] = useState<string>(null);
  const [targetSuspendDate, setTargetSuspendDate] = useState<string>(null);

  const updateDisabled =
    action === null ||
    (action === RelationCloseSchedule && targetSuspendDate === null);

  const closeModal = () => {
    setVisible(false);
    setAction(null);
    setTargetSuspendDate(null);
  };

  return (
    <Modal.Simple
      title={formatMessage({
        id: "companyRelationList.actions.manageClosure.title",
      })}
      illustrationName="close-paper"
      open={visible}
      primaryButtonText={formatMessage({ id: "general.confirm" })}
      secondaryButtonText={formatMessage({ id: "general.cancel" })}
      onPrimaryButtonClick={() => {
        switch (action) {
          case RelationCloseNow:
            disableRelation();
            break;
          case RelationCloseSchedule:
            scheduleClosure({
              relationIds: [selectedRelation.id],
              targetSuspendDate: targetSuspendDate,
              action: RelationCloseSchedule,
            });
            break;
        }

        closeModal();
      }}
      onSecondaryButtonClick={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
      primaryButtonProps={{
        disabled: updateDisabled,
        extra: updateDisabled && (
          <Tooltip>{formatMessage({ id: "general.chooseOption" })}</Tooltip>
        ),
      }}
    >
      <section className={styles.relationModal}>
        <div className={styles.description}>
          <p>
            {formatMessage(
              {
                id: "companyRelationList.actions.closeRelation.description",
              },
              {
                b: text => <b style={{ fontWeight: "bold" }}>{text}</b>,
                ownerCompanyName: selectedRelation.ownerCompanyName,
                associatedCompanyName: selectedRelation.associatedCompanyName,
              }
            )}
          </p>
          <br />
          <p>
            {formatMessage(
              {
                id: "companyRelationList.actions.closeRelation.description.secondLine",
              },
              {
                b: text => <b style={{ fontWeight: "bold" }}>{text}</b>,
                associatedCompanyName: selectedRelation.associatedCompanyName,
                ownerCompanyName: selectedRelation.ownerCompanyName,
              }
            )}
          </p>
          <br />
          <p>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </p>
        </div>

        <div className={styles.radiosContainer}>
          <Radio.Group
            value={action}
            onChange={(e: RadioChangeEvent) => {
              setAction(e.target.value);
            }}
          >
            <Radio value={RelationCloseNow}>
              {formatMessage({
                id: "companyRelationList.actions.closeRelation.radioBtn.close.now",
              })}
            </Radio>
            <Radio value={RelationCloseSchedule}>
              {formatMessage({
                id: "companyRelationList.actions.closeRelation.radioBtn.close.schedule",
              })}
            </Radio>
            {action === RelationCloseSchedule && (
              <div className={styles.calendarInnerContainer}>
                <DatePicker
                  value={targetSuspendDate}
                  onChange={setTargetSuspendDate}
                  showToday={false}
                  disabledDate={current => {
                    return current && current <= moment().endOf("day");
                  }}
                />
              </div>
            )}
          </Radio.Group>
        </div>
      </section>
    </Modal.Simple>
  );
};

export default CloseModal;
