import React, { useEffect } from "react";
import { useIntl } from "react-intl";

import { Container, Illustration, Button } from "@trace-one/design-system";
import moment from "moment";

import { CumdAPI, PmdAPI, RlmdAPI } from "apis";

import ErrorPage from "../../components/ErrorPage";
import useAsyncFileDownload from "../../shared/hooks/useAsyncFileDownload";
import useRouteSearchParams from "../../shared/hooks/useRouteSearchParams";

import styles from "./Download.module.less";

interface QueryData {
  type?: string;
  fileId?: string;
}

const AllowedTypes = {
  PMD: "pmd",
  RLMD: "rlmd",
  CUMD: "cumd",
};

const Download: React.FC = () => {
  const { formatMessage } = useIntl();

  const { type, fileId } = useRouteSearchParams<QueryData>();

  const isQueryCorrect =
    !!type &&
    !!fileId &&
    Object.values(AllowedTypes).includes(type.toLowerCase());

  const filename = `ExportFile_${moment().format("DDMMYYYYhhmm")}.csv`;

  useEffect(() => {
    if (isQueryCorrect) handleFileExport();
  }, []);

  const getFileEndpoint = (type: string, fileId: string) => {
    type = type.toLowerCase();
    if (type === AllowedTypes.PMD) return PmdAPI.getFile(fileId);
    if (type === AllowedTypes.RLMD) return RlmdAPI.getFile(fileId);
    if (type === AllowedTypes.CUMD) return CumdAPI.getFile(fileId);
  };

  const [handleFileExport] = useAsyncFileDownload(
    () => getFileEndpoint(type, fileId),
    {
      displayLoadingMessage: true,
      loadingMessage: formatMessage({ id: "general.exportInProgress" }),
      filename,
    }
  );

  if (!isQueryCorrect) return <ErrorPage status={404} />;
  return (
    <Container isPageWrapper>
      <div className={styles.downloadFile}>
        <Illustration name="download-in-progress" color="currentColor" />
        <div className={styles.downloadTitle}>
          {formatMessage(
            { id: "downloadPage.title" },
            {
              filename,
            }
          )}
        </div>
        <div className={styles.downloadSubTitle}>
          {formatMessage({ id: "downloadPage.subTitle" })}
        </div>
        <Button type="link" onClick={handleFileExport}>
          {formatMessage({
            id: "general.clickHere",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default Download;
