import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUserId } from "reduxStore/user/selectors";

import { StorageType } from "shared/webStorage/storage";

const isObject = obj => typeof obj === "object" && obj !== null;

type PaginationInput = {
  skip: number;
  take: number;
};

const defaultInitialValue: PaginationInput = {
  skip: 0,
  take: 10,
};

type TablePaginationOptions = {
  webStorage?: StorageType;
  skipAndTakeQueryStats: { totalCount?: number };
  initialValue?: PaginationInput;
};

export default function useTablePagination({
  webStorage,
  skipAndTakeQueryStats: { totalCount },
  initialValue,
}: TablePaginationOptions) {
  const userId = useSelector(selectUserId);

  const initialPaginationQuery = useMemo<PaginationInput>(() => {
    const result = webStorage?.get();

    const initialPaginationQuery = initialValue ?? defaultInitialValue;
    if (!isObject(result)) {
      return initialPaginationQuery;
    }

    const isValid =
      result.userId === userId &&
      Number.isInteger(result.take) &&
      Number.isInteger(result.skip) &&
      result.skip >= 0 &&
      result.take >= 0;

    if (isValid) {
      return { skip: result.skip, take: result.take };
    } else {
      return initialPaginationQuery;
    }
  }, []);

  const [paginationQuery, setPaginationQuery] = useState(
    initialPaginationQuery
  );

  useEffect(() => {
    webStorage?.set({ ...paginationQuery, userId });
  }, [paginationQuery]);

  useEffect(() => {
    const isSkipGreaterOrEqualExistedListLength =
      Number.isInteger(totalCount) &&
      totalCount >= 0 &&
      paginationQuery.skip >= totalCount;

    if (isSkipGreaterOrEqualExistedListLength) {
      resetPageNumber();
    }
  }, [totalCount]);

  const resetPageNumber = useCallback(() => {
    setPaginationQuery(prev => ({ ...prev, skip: 0 }));
  }, [setPaginationQuery]);

  return {
    paginationQuery,
    setPaginationQuery,
    resetPageNumber,
  };
}
