import React from "react";

import { Icon } from "@trace-one/react-components";
import { Radio } from "antd";
import PropTypes from "prop-types";

import styles from "./styles.module.less";

const Switcher = ({ options, ...rest }) => {
  return (
    <Radio.Group className={styles.wrapper} buttonStyle="solid" {...rest}>
      {options.map(({ value, iconName, name, dataTestId, disabled }, index) => (
        <Radio.Button
          value={value}
          key={index}
          data-test-id={dataTestId}
          disabled={disabled}
        >
          {name ? (
            <div className={styles.name}>
              {iconName && <Icon name={iconName} size={20} />}
              {name}
            </div>
          ) : (
            <div className={styles.icon}>
              {iconName && <Icon name={iconName} size={20} />}
            </div>
          )}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

Switcher.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      iconName: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default Switcher;
