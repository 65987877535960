import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { Container, Button } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import usePermissions from "../../../../core/oidc/usePermissions";
import PendingCompanyList from "../../containers/PendingCompanyList";

import styles from "./PendingCompanyListPage.module.less";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());

export const PendingCompanyListPage: React.FC<BaseHocProps> = () => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <Container isPageWrapper>
      <div className={styles.titleWrapper}>
        <Title
          value={formatMessage({
            id: "general.pendingCompanies",
          }).toUpperCase()}
          level={1}
        />
        {(hasRoleSuperAdmin || hasRoleAdministrator) && (
          <Button
            onClick={() => {
              history.push(`/companies/create-company`);
            }}
          >
            {formatMessage({ id: "general.createCompany" })}
          </Button>
        )}
      </div>
      <PendingCompanyList />
    </Container>
  );
};

export default enhance(PendingCompanyListPage);
