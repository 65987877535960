import { useIntl } from "react-intl";

import { CompanyStatus } from "shared/constants";

export default function useCompanyStatuses() {
  const { formatMessage } = useIntl();

  const companyStatusLabels = {
    [CompanyStatus.ENABLED]: formatMessage({ id: "general.active" }),
    [CompanyStatus.DISABLED]: formatMessage({ id: "general.inactive" }),
    [CompanyStatus.SUSPENDED]: formatMessage({ id: "general.suspended" }),
    [CompanyStatus.PENDING]: formatMessage({ id: "general.pending" }),
    [CompanyStatus.REJECTED]: formatMessage({ id: "general.rejected" }),
    [CompanyStatus.VALIDATED]: formatMessage({ id: "general.validated" }),
  };

  const companyStatusTagColors = {
    [CompanyStatus.ENABLED]: "green",
    [CompanyStatus.DISABLED]: "red",
    [CompanyStatus.SUSPENDED]: "orange",
    [CompanyStatus.PENDING]: "blue",
    [CompanyStatus.REJECTED]: "red",
    [CompanyStatus.VALIDATED]: "green",
  };

  const getCompanyStatusOptions = (
    companyStatuses?: CompanyStatus[]
  ): { value: CompanyStatus; label: string }[] => {
    if (companyStatuses?.length > 0) {
      return companyStatuses.map(status => ({
        value: status,
        label: companyStatusLabels[status],
      }));
    }

    return Object.values(companyStatusLabels).map(([value, label]) => ({
      value: value as CompanyStatus,
      label,
    }));
  };

  return {
    companyStatusLabels,
    companyStatusTagColors,
    getCompanyStatusOptions,
  };
}
