import React from "react";
import { useIntl } from "react-intl";

import { Radio, RadioChangeEvent } from "antd";

import { BusinessModelNA, BusinessModelRFM, BusinessModelSFM } from "../models";

interface BusinessModelProps {
  onChange: (value: string) => void;
  value: string;
  disabled: boolean;
}

const BusinessModel: React.FC<BusinessModelProps> = ({
  onChange,
  value,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Radio.Group
      value={value}
      onChange={(e: RadioChangeEvent) => {
        onChange(e.target.value);
      }}
      disabled={disabled}
      style={{
        minHeight: 42,
        display: "flex",
        alignItems: "center",
      }}
      data-test-id="business-model-radio-group"
    >
      <Radio
        value={BusinessModelRFM}
        style={{
          marginRight: 40,
          fontSize: "16px",
        }}
        data-test-id="rfm-business-radio"
      >
        {formatMessage({
          id: "general.rfm",
        })}
      </Radio>
      <Radio
        value={BusinessModelSFM}
        style={{ fontSize: "16px", marginRight: 40 }}
        data-test-id="sfm-business-radio"
      >
        {formatMessage({
          id: "general.sfm",
        })}
      </Radio>
      <Radio
        value={BusinessModelNA}
        style={{ fontSize: "16px" }}
        data-test-id="na-business-radio"
      >
        {formatMessage({
          id: "general.not.applicable",
        })}
      </Radio>
    </Radio.Group>
  );
};

export default BusinessModel;
