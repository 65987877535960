import { StorageType } from "shared/webStorage/storage";

export default function saveFilters(
  webStorage: StorageType,
  {
    searchText,
    filterObj,
    userId,
  }: {
    searchText?: string;
    filterObj: any;
    userId: string;
  }
) {
  webStorage?.set({ searchText, filterObj, userId });
}
