import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchPendingUsersForTraceoneAdmin = createAsyncThunk(
  "userList/fetchUsersForTraceoneAdmin",
  async ({
    skip,
    take,
    registrationStatuses,
    SearchText,
    CompanyLegalName,
    createdAt,
    UserFirstName,
    UserLastName,
    UserEmail,
  }: {
    skip?: number;
    take?: number;
    registrationStatuses: number[];
    SearchText: string;
    CompanyLegalName: string;
    createdAt: string;
    UserFirstName: string;
    UserLastName: string;
    UserEmail: string;
  }) => {
    const { data } = await CumdAPI.getPendingUsers(
      { registrationStatuses },
      {
        SearchText,
        skip,
        take,
        CompanyLegalName,
        createdAt,
        UserFirstName,
        UserLastName,
        UserEmail,
      }
    );
    return data;
  }
);
