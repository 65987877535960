/* istanbul ignore file */
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCompanySubscriptionForm = (state: RootState) =>
  state.companySubscriptionForm;

export const selectCompanySubscriptionForm = createSelector(
  _selectCompanySubscriptionForm,
  state => state
);
