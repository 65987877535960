import { CountryData, ReferenceListItemData } from "models/index";
type SharedBuilderState<DataType, IncludeIsInitiated> =
  IncludeIsInitiated extends true
    ? {
        data: DataType;
        isInitiated: boolean;
        isLoading: boolean;
        hasError: boolean;
      }
    : {
        data: DataType;
        isLoading: boolean;
        hasError: boolean;
      };

type State = {
  companyActivitiesData: SharedBuilderState<ReferenceListItemData[], true>;
  teamMemberResponsibilitiesData: SharedBuilderState<
    ReferenceListItemData[],
    true
  >;
  jobTitlesData: SharedBuilderState<ReferenceListItemData[], true>;
  // countriesData: SharedBuilderState<ReferenceListItemData[], true>;
  countriesData: SharedBuilderState<CountryData[], true>;
  languagesData: SharedBuilderState<ReferenceListItemData[], true>;
};

export const initialState: State = {
  companyActivitiesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  teamMemberResponsibilitiesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  jobTitlesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  // countriesData: {
  //   data: [],
  //   isInitiated: false,
  //   isLoading: false,
  //   hasError: false,
  // },
  countriesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
  languagesData: {
    data: [],
    isInitiated: false,
    isLoading: false,
    hasError: false,
  },
};
