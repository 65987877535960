import React from "react";
import { useIntl } from "react-intl";

import { CompanyWithDuns } from "@trace-one/api-clients.cumd";
import { Col } from "antd";

import styles from "./FoundedInfo.module.less";

interface FoundedInfoProps {
  company: CompanyWithDuns;
}

const FoundedInfo: React.FC<FoundedInfoProps> = ({ company }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <p className={styles.title}>
        {formatMessage({ id: "companyCreateDuns.changeDuns.founded.title" })}
      </p>

      <Col className={styles.info} style={{ padding: "0" }}>
        <span className={styles.infoTitle}>
          {formatMessage({ id: "general.dunsName" })}
        </span>
        <div>
          {company?.companyName
            ? company?.companyName
            : formatMessage({ id: "general.empty" })}
        </div>
      </Col>

      <Col className={styles.info} style={{ padding: "0" }}>
        <span className={styles.infoTitle}>
          {formatMessage({ id: "general.dunsNumber" })}
        </span>
        <div>
          {company?.companyDuns
            ? company?.companyDuns
            : formatMessage({ id: "general.empty" })}
        </div>
      </Col>

      <Col className={styles.info} style={{ padding: "0" }}>
        <span className={styles.infoTitle}>
          {formatMessage({ id: "general.address" })}
        </span>
        <div>
          {company?.companyAddress}, {company?.companyPostalCode},
          {company?.companyCity}, {company?.companyCountry},
          {(company?.companyState === "CA" || "US") && company?.companyState}
        </div>
      </Col>

      <Col className={styles.info} style={{ padding: "0" }}>
        <span className={styles.infoTitle}>
          {formatMessage({ id: "companyGeneralInfo.companyIdentifierSIRET" })}
        </span>
        <div>
          {company?.companySiret
            ? company?.companySiret
            : formatMessage({ id: "general.empty" })}
        </div>
      </Col>

      <Col className={styles.info} style={{ padding: "0" }}>
        <span className={styles.infoTitle}>
          {formatMessage({ id: "companyGeneralInfo.companyIdentifierVAT" })}
        </span>
        <div>
          {company?.companyVat
            ? company?.companyVat
            : formatMessage({ id: "general.empty" })}
        </div>
      </Col>
    </div>
  );
};

export default FoundedInfo;
