import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Modal, DatePicker } from "@trace-one/design-system";
import { Radio, RadioChangeEvent } from "antd";
import moment from "moment";

import { CompanyForTraceoneAdminData } from "models";

import styles from "../CompanyListModel.module.less";
import {
  RelationCloseCancel,
  RelationCloseNow,
  RelationCloseSchedule,
} from "../models";

const ManageSuspendModal = ({
  visible,
  setVisible,
  selectedCompany,
  suspendCompany,
  scheduleSuspension,
  scheduledSuspension,
}: {
  visible: boolean;
  setVisible: (boolean) => void;
  selectedCompany: CompanyForTraceoneAdminData;
  suspendCompany: () => void;
  scheduleSuspension: ({ companyIds, targetSuspendDate, action }) => void;
  scheduledSuspension: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [action, setAction] = useState<string>(
    selectedCompany?.targetSuspendDate !== null ? RelationCloseSchedule : null
  );
  const [targetSuspendDate, setTargetSuspendDate] = useState(
    selectedCompany?.targetSuspendDate !== null
      ? moment(selectedCompany?.targetSuspendDate)
      : null
  );

  const closeModal = () => {
    setVisible(false);
    if (scheduledSuspension) {
      setAction(RelationCloseSchedule);
      setTargetSuspendDate(moment(selectedCompany?.targetSuspendDate));
    } else {
      setAction(null);
      setTargetSuspendDate(null);
    }
  };

  useEffect(() => {
    setAction(
      selectedCompany?.targetSuspendDate !== null ? RelationCloseSchedule : null
    );
    setTargetSuspendDate(
      selectedCompany?.targetSuspendDate !== null
        ? moment(selectedCompany?.targetSuspendDate)
        : null
    );
  }, [selectedCompany]);

  return (
    <Modal.Simple
      title={formatMessage({
        id: "companySubscriptionForm.suspend.title",
      })}
      illustrationName="locked"
      open={visible}
      primaryButtonText={formatMessage({ id: "general.confirm" })}
      secondaryButtonText={formatMessage({ id: "general.cancel" })}
      onPrimaryButtonClick={() => {
        switch (action) {
          case RelationCloseNow:
            suspendCompany();
            break;
          case RelationCloseSchedule:
            scheduleSuspension({
              companyIds: [selectedCompany?.companyId],
              targetSuspendDate: targetSuspendDate,
              action: RelationCloseSchedule,
            });
            break;
          case RelationCloseCancel:
            scheduleSuspension({
              companyIds: [selectedCompany?.companyId],
              targetSuspendDate: null,
              action: RelationCloseCancel,
            });
        }

        closeModal();
      }}
      onSecondaryButtonClick={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
      primaryButtonProps={{
        disabled:
          action === null ||
          (action === RelationCloseSchedule && targetSuspendDate === null),
      }}
    >
      <section className={styles.relationModal}>
        <div className={styles.description}>
          <p>
            {formatMessage(
              { id: "companySubscriptionForm.suspend.description" },

              {
                CompanyDisplayName: selectedCompany?.companyDisplayName,
              }
            )}
          </p>
          <br />
          <p>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </p>
        </div>

        <div className={styles.radiosContainer}>
          <Radio.Group
            value={action}
            onChange={(e: RadioChangeEvent) => {
              setAction(e.target.value);
            }}
          >
            <Radio value={RelationCloseNow}>
              {formatMessage({
                id: "companySubscriptionForm.suspend.radioBtn.close.now",
              })}
            </Radio>
            <Radio value={RelationCloseSchedule}>
              {formatMessage({
                id: "companySubscriptionForm.suspend.radioBtn.close.schedule",
              })}
            </Radio>
            {action === RelationCloseSchedule && (
              <div className={styles.calendarInnerContainer}>
                <DatePicker
                  value={targetSuspendDate}
                  defaultValue={targetSuspendDate}
                  onChange={setTargetSuspendDate}
                  showToday={false}
                  disabledDate={current => {
                    return current && current <= moment().endOf("day");
                  }}
                />
              </div>
            )}
            {scheduledSuspension && (
              <Radio value={RelationCloseCancel}>
                {formatMessage({
                  id: "companySubscriptionForm.suspend.radioBtn.close.cancel",
                })}
              </Radio>
            )}
          </Radio.Group>
        </div>
      </section>
    </Modal.Simple>
  );
};

export default ManageSuspendModal;
