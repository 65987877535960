import React, { memo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Header } from "@trace-one/business-components";
import cn from "classnames";

// import BackToAppButton from "./BackToAppButton";
import { useLegalMonster } from "core/legalMonster";
import useLogout from "core/oidc/useLogout";
import { Languages } from "translations";

import { selectOidcAccessToken } from "reduxStore/oidc/selectors";
import { selectUserId } from "reduxStore/user/selectors";

import FakeIntercom from "components/FakeIntercom";
import { APPLICATIONS } from "shared/constants";

import usePermissions from "../oidc/usePermissions";

import Footer from "./Footer";
import useHeaderRoutes from "./hooks/useHeaderRoutes";
import useScrollToTopOnRouteChange from "./hooks/useScrollTopOnRouteChange";
import styles from "./Layout.module.less";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  useLegalMonster();
  useScrollToTopOnRouteChange();

  const { isMasquerade } = usePermissions();
  const { homePath, headerRoutes } = useHeaderRoutes();
  const { search } = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
  const { from, appid } = queryParams ?? {};
  const intl = useIntl();
  const logout = useLogout();
  const userId = useSelector(selectUserId);
  const accessToken = useSelector(selectOidcAccessToken);
  const previousApp =
    from && appid ? APPLICATIONS.find(app => app.id === parseInt(appid)) : null;

  return (
    <>
      <Header
        appName="Administration"
        appLogo="administration"
        userId={userId}
        onLogout={logout}
        homeUrl={homePath}
        routes={headerRoutes}
        isMasquerade={isMasquerade}
        extraAboveAppLogo={<FakeIntercom />}
        appSwitcherProps={{
          accessToken,
          userId: userId,
          showDiscoverSolutions: true,
          excludeApps: [90],
        }}
        redirectBanner={{
          visible: !!previousApp,
          bannerTitle:
            intl.formatMessage({
              id: "general.backTo",
            }) +
            " " +
            previousApp?.name,
          appIconName: `app-logo-${previousApp?.graphic}-full`,
          onBackClick: () => {
            window.location.href = from;
          },
        }}
        helpLinks={{
          [Languages.ENGLISH]: `${window.env.TO_HELP_LINK}/en`,
          [Languages.FRENCH]: `${window.env.TO_HELP_LINK}/fr`,
        }}
      />
      <div
        className={cn(
          styles.appContent,
          previousApp && styles.withPreviousAppBanner
        )}
      >
        {children}
      </div>
      <Footer />
      <div
        className={cn(previousApp && "withPreviousAppOverlay")}
        id="notificationsRoot"
      />
      <div
        className={cn(previousApp && "withPreviousAppOverlay")}
        id="allFiltersModalRoot"
      />
    </>
  );
};

export default memo(Layout);
