import {
  UserForUIAdminData,
  RoleForCompanyData,
  ReferenceListItemData,
} from "models";

type State = {
  userData: {
    data: UserForUIAdminData;
    companyActivityGuid: string;
    isLoading: boolean;
    hasError: boolean;
    errorStatus: number;
  };
  rolesData: {
    data: RoleForCompanyData[];
    hasError: boolean;
    errorStatus: number;
  };
  responsibilitiesData: {
    data: ReferenceListItemData[];
    hasError: boolean;
    errorStatus: number;
  };
};

export const initialState: State = {
  userData: {
    data: null,
    companyActivityGuid: null,
    isLoading: false,
    hasError: false,
    errorStatus: null,
  },
  rolesData: {
    data: [],
    hasError: false,
    errorStatus: null,
  },
  responsibilitiesData: {
    data: [],
    hasError: false,
    errorStatus: null,
  },
};
