import { createAsyncThunk } from "@reduxjs/toolkit";
import { RelationParams } from "@trace-one/api-clients.cumd";

import { CumdAPI } from "apis";

interface PaginatedRelationParams extends RelationParams {
  skip: number;
  take: number;
}

export const fetchCompanyRelations = createAsyncThunk(
  "companyRelationList/fetchCompanyRelations",
  async (
    {
      relationId,
      companyId,
      isCompanyIdOwner,
      partnerCompanyId,
      isEnabled,
      fromTargetSuspendDate,
      toTargetSuspendDate,
      skip,
      take,
    }: PaginatedRelationParams,
    thunkAPI
  ) => {
    try {
      const { data } = await CumdAPI.getRelationsByFilters({
        relationId,
        companyId,
        isCompanyIdOwner,
        partnerCompanyId,
        isEnabled,
        fromTargetSuspendDate,
        toTargetSuspendDate,
        skip,
        take,
      });

      return {
        relations: data.relations,
        skipAndTakeQueryStats: data.skipAndTakeQueryStats,
      };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);

export const fetchRelatedCompanies = createAsyncThunk(
  "companyRelationList/fetchRelatedCompanies",
  async ({ companyId }: RelationParams, thunkAPI) => {
    try {
      const { data } = await CumdAPI.getRelationsByFilters({
        companyId,
      });

      const relatedCompaniesMap = data.relations.reduce<{
        [relatedCompanyId: string]: string;
      }>((acc, value) => {
        if (value.associatedWithCompanyId !== companyId) {
          acc = {
            ...acc,
            [value.associatedWithCompanyId]: value.associatedCompanyName,
          };
        }
        if (value.ownerCompanyId !== companyId) {
          acc = {
            ...acc,
            [value.ownerCompanyId]: value.ownerCompanyName,
          };
        }

        return acc;
      }, {});

      return {
        relatedCompaniesMap,
      };
    } catch (error) {}
  }
);
