import { useEffect, useState } from "react";

import { CumdAPI } from "apis";

import AsyncSearchSelect, {
  AsyncSearchSelectProps,
} from "components/AsyncSearchSelect";
import { SelectOption } from "shared/typings";

interface AsyncCompanySelectProps
  extends Omit<AsyncSearchSelectProps, "onAsyncSearch"> {
  label: string;
  onLabelChange: (label: string) => void;
}

const getCompaniesOptions = ({ searchValue }: { searchValue?: string }) => {
  return CumdAPI.getCompaniesByFiltersForToAdmin(
    {},
    {
      searchText: searchValue,
    }
  ).then(({ data }) => {
    return data.companies.map(({ companyId, ...rest }) => ({
      value: companyId,
      label: rest.companyDisplayName,
    }));
  });
};

const useDefaultOptionsCompany = () => {
  const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
  useEffect(() => {
    getCompaniesOptions({})
      .then(result => {
        setDefaultOptions(result);
      })
      .catch(() => {});
  }, []);

  return defaultOptions;
};

const AsyncCompanySelect: React.FC<AsyncCompanySelectProps> = ({
  value,
  onChange,
  label,
  onLabelChange,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState("");

  const defaultOptions = useDefaultOptionsCompany();

  useEffect(() => {
    if (value) {
      CumdAPI.getCompaniesByFiltersForToAdmin({ companyIds: [value] }).then(
        ({ data }) => {
          if (data.companies.length === 1) {
            const company = data.companies[0];
            onLabelChange(company?.companyDisplayName);
          }
        }
      );
    }
  }, []);

  return (
    <AsyncSearchSelect
      {...rest}
      allowClear
      showSearch
      defaultOptions={defaultOptions}
      value={value ? { value, label } : undefined}
      searchValue={searchValue}
      onChange={(value, option) => {
        onChange(value?.value, option);
        onLabelChange(value?.label);
      }}
      onAsyncSearch={getCompaniesOptions}
      minLengthToSearch={3}
      labelInValue
      onSearch={setSearchValue}
    />
  );
};

export default AsyncCompanySelect;
