import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { Picker, Typography } from "@trace-one/design-system";

import ShortMsg from "components/ShortMsg";
import Spinner from "components/Spinner";
import useDebounce from "shared/hooks/useDebounce";
import useToast from "shared/hooks/useToast";

import styles from "./AsyncPickerDropdown.module.less";

const AsyncPickerDropdown = ({
  defaultOptions,
  onAsyncSearch,
  onSearch,
  minLengthToSearch = 3,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);
  const optionsRef = useRef([]);
  const shouldDisplayDefaultOptionsNow =
    !searchValue && Array.isArray(defaultOptions);
  const hasEnoughTextLength = (text: string) => {
    return text?.trim()?.length >= minLengthToSearch;
  };

  useEffect(() => {
    if (!onAsyncSearch) {
      return;
    }

    let mount = true;
    if (hasEnoughTextLength(debouncedSearchValue)) {
      onAsyncSearch(debouncedSearchValue)
        .then(options => {
          const isOptionsListArray = Array.isArray(options);
          if (!isOptionsListArray) {
            console.warn(
              "[AsyncSearchSelect] onAsyncSearch type ({ searchValue }) => Promise<{ label, value }[]>"
            );
          }
          if (mount && isOptionsListArray) {
            setFilteredOptions(options);
            optionsRef.current = options;
          }
          setLoading(false);
        })
        .catch(error => {
          if (mount) {
            toast.fetchError({ error });
            setFilteredOptions([]);
            optionsRef.current = [];
          }
          setLoading(false);
        });
    }
    return () => {
      mount = false;
      optionsRef.current = [];
    };
  }, [debouncedSearchValue]);

  return (
    <Picker.Filter
      renderAlternateContent={
        !!searchValue && !hasEnoughTextLength(searchValue) ? (
          <div className={styles.alternateContent}>
            <Typography component="span">
              <ShortMsg.EnterAtLeast value={minLengthToSearch} />
            </Typography>
          </div>
        ) : loading ? (
          <div className={styles.alternateContent}>
            <Spinner className={styles.spinner} />
          </div>
        ) : !(shouldDisplayDefaultOptionsNow ? defaultOptions : filteredOptions)
            .length ? (
          <div className={styles.alternateContent}>
            <Typography className={styles.noData} component="h6">
              {formatMessage({
                id: `general.noData`,
              })}
            </Typography>
          </div>
        ) : undefined
      }
      onSearch={str => {
        setSearchValue(str);
        if (onAsyncSearch) {
          if (!str) {
            setLoading(false);
            return;
          }
          if (hasEnoughTextLength(str)) {
            setLoading(true);
          }

          // Need to memorize ref of options if user type in fast value that will not trigger useEffect
          // Clearing options in cleanup will create vanish effect
          if (str === debouncedSearchValue) {
            setFilteredOptions(optionsRef.current);
            setLoading(false);
          } else {
            setFilteredOptions([]);
          }
          return;
        }
        setFilteredOptions(onSearch(str));
      }}
      items={
        shouldDisplayDefaultOptionsNow
          ? defaultOptions ?? filteredOptions
          : filteredOptions
      }
      clearBtnText={formatMessage({ id: "general.clear" })}
      closeBtnText={formatMessage({ id: "general.close" })}
      {...rest}
    />
  );
};

export default AsyncPickerDropdown;
