import { CompanyForTraceoneAdminData } from "models";

type State = {
  data: CompanyForTraceoneAdminData[];
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isLoading: boolean;
  hasError: boolean;
};

export const initialState: State = {
  data: [],
  skipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isLoading: false,
  hasError: false,
};
