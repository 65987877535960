import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import { Tooltip, Heading } from "@trace-one/design-system";
import GeneralInformation from "pages/Groups/containers/GroupDetails/GeneralInformation";

import StickyTabContainer from "components/StickyTabContainer";

import Spinner from "../../../../components/Spinner";
import useGroupDetails from "../GroupDetailsPage/hooks/useGroupDetails";

export const GroupDetailsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { groupId } = useParams<{ groupId?: string }>();

  const { fetchGroupDetails, isInitiated, groupDetails } = useGroupDetails();

  useEffect(() => {
    fetchGroupDetails(groupId);
  }, [groupId]);

  const tabList = [
    {
      label: formatMessage({ id: "general.generalInformation" }),
      content: (
        <GeneralInformation groupDetails={groupDetails}></GeneralInformation>
      ),
    },
    {
      label: formatMessage({ id: "groupList.table.sharedSubscription" }),
      disabled: true,
    },
  ];

  if (!isInitiated) {
    return <Spinner underHeader />;
  }

  return (
    <StickyTabContainer
      title={
        <Tooltip title={groupDetails.groupName} placement="bottom">
          <Heading size="m">{groupDetails.groupName}</Heading>
        </Tooltip>
      }
      onBack={() => {
        history.push("/groups/list");
      }}
      tabs={tabList}
    />
  );
};

export default GroupDetailsPage;
