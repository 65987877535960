import React, { Dispatch, SetStateAction } from "react";
import { Control } from "react-hook-form";

import { UserForUIAdminData } from "models";

import { UserAccessManageValues } from "../models";

import styles from "./BottomPart.module.less";
import UserRolesApps from "./components/UserRolesApps";
import Permissions from "./components/UserRolesApps/Permissions";

interface BottomPartProps {
  user: UserForUIAdminData;
  control: Control<UserAccessManageValues>;
  selectedApp: number;
  setSelectedApp: Dispatch<SetStateAction<number>>;
}

const BottomPart: React.FC<BottomPartProps> = ({
  user,
  control,
  selectedApp,
  setSelectedApp,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.userAppsAndRoles}>
        <UserRolesApps
          user={user}
          control={control}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
        />
      </div>
      <div className={styles.userPermissions}>
        {selectedApp !== null && <Permissions selectedApp={selectedApp} />}
      </div>
    </div>
  );
};

export default BottomPart;
