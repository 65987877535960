import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PendingUser } from "@trace-one/api-clients.cumd/dist/models/user";
import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";

import ActionDropdown from "components/ActionDropdown";

import { CumdAPI } from "../../../../apis";
import usePermissions from "../../../../core/oidc/usePermissions";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { CompanyActivity, UserStatus } from "../../../../shared/constants";
import useToast from "../../../../shared/hooks/useToast";

import styles from "./TraceoneAdminPendingUserList.module.less";

interface UserPendingActionProps {
  status: string;
  data: PendingUser;
  refetchUsers: () => void;
}

const UserPendingAction: React.FC<UserPendingActionProps> = ({
  data,
  status,
  refetchUsers,
}) => {
  const { hasRoleAdministrator, hasRoleSuperAdmin } = usePermissions();

  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const toast = useToast();
  const history = useHistory();

  const userPendingParam = { pageName: "user-pending" };

  const handleTONRedirect = () => {
    const url = window.env.VALIDATE_COMPANY_URL.replace(
      "{global-company-id}",
      data.globalCompanyId
    );
    window.open(url, "_blank");
  };

  const handleToRedirectToPendingCompanyPage = () => {
    return history.push(
      `/companies/pending/${data.globalUserId}?pageName=${userPendingParam.pageName}`
    );
  };

  const handleToRedirectToPendingCompanyAddDunsPage = () => {
    return history.push(
      `/companies/pending/${data.globalUserId}/addDuns?pageName=${userPendingParam.pageName}`
    );
  };

  const onPendingUserDelist = async () => {
    try {
      await CumdAPI.delistPendingUser(data.globalUserId);
      refetchUsers();
    } catch (error) {
      toast.fetchError({ error });
    }
  };

  const handleDelistModal = () => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          icon={"users"}
          confirmationModalTitle={formatMessage({
            id: "userActionModel.delist.pending.title",
          })}
          onConfirm={onPendingUserDelist}
        >
          <section className={styles.userStatusModal}>
            <div className={styles.description}>
              <p>
                {formatMessage({
                  id: "pendingUserActionModal.delist.description",
                })}
              </p>
            </div>
            <p className={styles.confirmationQuestion}>
              {formatMessage({
                id: "general.action.confirmation.text",
              })}
            </p>
          </section>
        </ConfirmationModal>
      ),
    });
  };

  const isNewRetailer =
    data.companyDuns &&
    data.companyActivity === CompanyActivity.RETAILER &&
    data.isAttachedToExistingCompany === false;

  const hasNoDuns =
    (data.companyDuns === null || data.companyDuns === "") &&
    (data.companyLegalName !== null || data.companyLegalName === "");

  const oldDelist =
    hasNoDuns || isNewRetailer ? handleTONRedirect : handleDelistModal;

  return (
    <>
      <ActionDropdown
        disabled={status === UserStatus.REJECTED}
        data-test-id="ad-user-action"
        overlay={
          <Menu>
            {(hasRoleSuperAdmin || hasRoleAdministrator) && (
              <>
                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? handleTONRedirect
                      : handleToRedirectToPendingCompanyPage
                  }
                  key="company-validate"
                  disabled={
                    !(
                      data.companyDuns &&
                      data.companyActivity === CompanyActivity.RETAILER &&
                      data.isAttachedToExistingCompany === false
                    )
                  }
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.validate",
                  })}
                </Menu.Item>

                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? oldDelist
                      : handleDelistModal
                  }
                  key="company-delist"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.delist",
                  })}
                </Menu.Item>

                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? handleTONRedirect
                      : handleToRedirectToPendingCompanyAddDunsPage
                  }
                  key="add-company-duns"
                  disabled={
                    (data.companyDuns !== null && data.companyDuns !== "") ||
                    data.companyLegalName === null ||
                    data.companyLegalName === ""
                  }
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.addDuns",
                  })}
                </Menu.Item>
              </>
            )}
          </Menu>
        }
      />
    </>
  );
};

export default UserPendingAction;
