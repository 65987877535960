import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CumdAPI } from "../../../../apis";
import Table from "../../../../components/Table";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchGroups } from "../../../../reduxStore/companyGroup/asyncActions";
import {
  selectGroupListData,
  selectGroupSkipAndTakeQueryStats,
} from "../../../../reduxStore/companyGroup/selectors";
import { clearGroups } from "../../../../reduxStore/companyGroup/slice";
import useSearchFilters from "../../../../shared/hooks/useSearchFilters";
import useTablePagination from "../../../../shared/hooks/useTablePagination";
import useToast from "../../../../shared/hooks/useToast";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../../../shared/webStorage";
import { GroupFilter } from "../../models";

import GroupSearch from "./GroupSearch";
import GroupTableHeader from "./GroupTableHeader";
import useGroupsTable from "./hooks/useGroupsTable";

const GroupList = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const groups = useSelector(selectGroupListData);
  const skipAndTakeQueryStats = useSelector(selectGroupSkipAndTakeQueryStats);

  const [companies, setCompanies] = useState([]);
  const [groupData, setGroupData] = useState<any>();
  const companyIds = groups?.map(({ mainCompanyId }) => mainCompanyId);

  const fetchCompanies = async () => {
    try {
      let { data } = await CumdAPI.getCompaniesByFiltersForToAdmin({
        companyIds: companyIds.filter(id => id !== null),
      });

      setCompanies(data.companies);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    dispatch(fetchGroups({}));
  }, []);

  useEffect(() => {
    if (companyIds.length > 0) {
      fetchCompanies();
    }
  }, [groups]);

  useEffect(() => {
    const data = groups.map(group => {
      const findCompanyName = groupId => {
        if (groupId !== null) {
          const company = companies.find(
            company => company.companyId === groupId
          );
          return company?.companyLegalName;
        }
      };

      return {
        ...group,
        mainCompanyName: findCompanyName(group.mainCompanyId),
      };
    });

    setGroupData(data);
  }, [companies, groups]);

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: TablePaginationStorage.GROUP_LIST,
    });

  const { columns, data } = useGroupsTable(groupData);

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<GroupFilter>(
    {
      groupName: undefined,
      groupCode: undefined,
      companyId: undefined,
      fromTargetCreationDate: undefined,
      toTargetCreationDate: undefined,
    },
    {
      resetPageNumber,
      webStorage: SearchFiltersStorage.GROUP_LIST,
    }
  );

  const refetchGroups = useCallback(() => {
    const {
      groupName,
      groupCode,
      companyId,
      fromTargetCreationDate,
      toTargetCreationDate,
    } = filterObj;

    dispatch(
      fetchGroups({
        groupName: groupName,
        groupCode: groupCode,
        companyId: companyId,
        fromTargetCreationDate: fromTargetCreationDate,
        toTargetCreationDate: toTargetCreationDate,
        searchText: searchText,
        skip: paginationQuery.skip,
        take: paginationQuery.take,
      })
    );
  }, [filterObj, searchText, paginationQuery]);

  useEffect(() => {
    return () => {
      dispatch(clearGroups());
    };
  }, []);

  useEffect(() => {
    refetchGroups();
  }, [refetchGroups]);

  return (
    <div>
      <GroupSearch
        filterObj={filterObj}
        mergeFilters={mergeFilters}
        initialSearchValue={searchText}
        onSearch={setSearchText}
        onClearFiltersClick={removeAllFiltersAndResetPageNumber}
      />
      <GroupTableHeader
        totalCount={skipAndTakeQueryStats?.totalCount}
        currentNumber={skipAndTakeQueryStats?.currentCount}
      />

      <Table
        columns={columns}
        dataSource={data}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
        rowSelection={{
          type: "checkbox",
        }}
      />
    </div>
  );
};

export default GroupList;
