import { ApplicationType } from "shared/constants";

export const APPS_TO_DISPLAY = [
  ApplicationType.SPEC,
  ApplicationType.PROJECT,
  ApplicationType.QMS,
  ApplicationType.RFX,
  ApplicationType.MASTERDATA,
  ApplicationType.INSIGHT,
];
