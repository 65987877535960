import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { DateRangePicker } from "@trace-one/design-system";
import { DatePicker } from "@trace-one/react-components";
import moment from "moment";
import useCompanyStatuses from "pages/Companies/hooks/useCompanyStatuses";

import {
  selectCompanyActivitesMap,
  selectCompanyActivitiesData,
} from "reduxStore/shared/selectors";

import CheckableTags from "components/CheckableTags";
import SearchFilters, { SearchFiltersProps } from "components/SearchFilters";
import Select from "components/Select";
import { CompanyStatus } from "shared/constants";
import useAppTranslations from "shared/hooks/useAppTranslations";

import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";

import { CompanyFilter } from "./models";

interface CompanySearchProps {
  filterObj: CompanyFilter;
  mergeFilters: (
    value:
      | Partial<CompanyFilter>
      | ((prevState: CompanyFilter) => CompanyFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
  companyIdFromUrl?: string;
}

const CompanySearch: React.FC<CompanySearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const { appLabelOptions, appLabelMap } = useAppTranslations();
  const { companyStatusLabels, getCompanyStatusOptions } = useCompanyStatuses();

  const companiesActivitiesMap = useSelector(selectCompanyActivitesMap);
  const companiesActivitiesOptions = useSelector(
    selectCompanyActivitiesData
  ).data.map(({ itemCode, text }) => ({ value: itemCode, label: text }));

  const optionsToDisplay = appLabelOptions.filter(option => {
    return option["value"] !== "40";
  });

  const sortArray = (x, y) => x.label.localeCompare(y.label);
  const sortedOptionsToDisplay = optionsToDisplay.sort(sortArray);

  const getSelectedFilters = () => {
    const selectedFilters = [];
    if (filterObj.companyActivityId) {
      selectedFilters.push({
        id: filterObj.companyActivityId,
        value: companiesActivitiesMap[filterObj.companyActivityId]?.text,
        name: companiesActivitiesMap[filterObj.companyActivityId]?.text,
        onCloseClick: () => mergeFilters({ companyActivityId: undefined }),
      });
    }

    if (filterObj.applicationTypeIds) {
      filterObj.applicationTypeIds.forEach(option => {
        selectedFilters.push({
          id: option,
          value: appLabelMap[option],
          name: appLabelMap[option],
          onCloseClick: () =>
            mergeFilters(prev => ({
              ...prev,
              applicationTypeIds: prev.applicationTypeIds.filter(
                appId => appId !== option
              ),
            })),
        });
      });
    }

    if (filterObj.createdAt) {
      selectedFilters.push({
        value: filterObj.createdAt,
        name: filterObj.createdAt,
        onCloseClick: () => mergeFilters({ createdAt: undefined }),
      });
    }

    if (filterObj.fromStatusUpdatedDate) {
      selectedFilters.push({
        id: `fromStatusUpdatedDate:${filterObj.fromStatusUpdatedDate}`,
        value: moment(filterObj.fromStatusUpdatedDate).format("DD/MM/YYYY"),
        name: filterObj.fromStatusUpdatedDate,
        onCloseClick: () => mergeFilters({ fromStatusUpdatedDate: undefined }),
      });
    }

    if (filterObj.toStatusUpdatedDate) {
      selectedFilters.push({
        id: `toStatusUpdatedDate:${filterObj.toStatusUpdatedDate}`,
        value: moment(filterObj.toStatusUpdatedDate).format("DD/MM/YYYY"),
        name: filterObj.toStatusUpdatedDate,
        onCloseClick: () => mergeFilters({ toStatusUpdatedDate: undefined }),
      });
    }

    if (filterObj.fromTargetSuspendDate) {
      selectedFilters.push({
        id: `fromTargetSuspendDate:${filterObj.fromTargetSuspendDate}`,
        value: moment(filterObj.fromTargetSuspendDate).format("DD/MM/YYYY"),
        name: filterObj.fromTargetSuspendDate,
        onCloseClick: () => mergeFilters({ fromTargetSuspendDate: undefined }),
      });
    }

    if (filterObj.toTargetSuspendDate) {
      selectedFilters.push({
        id: `toTargetSuspendDate:${filterObj.toTargetSuspendDate}`,
        value: moment(filterObj.toTargetSuspendDate).format("DD/MM/YYYY"),
        name: filterObj.toTargetSuspendDate,
        onCloseClick: () => mergeFilters({ toTargetSuspendDate: undefined }),
      });
    }

    filterObj.companyStatuses?.forEach(option => {
      selectedFilters.push({
        id: option,
        value: companyStatusLabels[option],
        name: companyStatusLabels[option],
        onCloseClick: () =>
          mergeFilters(prev => ({
            ...prev,
            companyStatuses: prev.companyStatuses.filter(
              status => status !== option
            ),
          })),
      });
    });

    return selectedFilters;
  };

  return (
    <SearchFilters
      onSearch={onSearch}
      searchPlaceholder={formatMessage({
        id: "companyList.searchPlaceholder",
      })}
      onClearFiltersClick={onClearFiltersClick}
      initialSearchValue={initialSearchValue}
      selectedFilters={getSelectedFilters()}
      filters={[
        {
          label: formatMessage({ id: "general.companyType" }),
          content: (
            <Select
              value={filterObj.companyActivityId}
              onChange={companyActivityId =>
                mergeFilters({ companyActivityId })
              }
              options={companiesActivitiesOptions}
              allowClear
              showSearch
              data-test-id="ad-filter-companyType"
            />
          ),
        },
        {
          label: formatMessage({ id: "general.applications" }),
          content: (
            <Select
              mode="multiple"
              value={filterObj.applicationTypeIds}
              onChange={applicationTypeIds =>
                mergeFilters({ applicationTypeIds })
              }
              options={sortedOptionsToDisplay}
              allowClear
              showSearch
              data-test-id="ad-filter-applicationTypeId"
            />
          ),
        },
        {
          label: formatMessage({ id: "general.createdSince" }),
          content: (
            <DatePicker
              value={
                filterObj.createdAt
                  ? moment(filterObj.createdAt, "DD/MM/YYYY")
                  : undefined
              }
              onChange={createdAt => {
                mergeFilters({
                  createdAt: createdAt?.format("DD/MM/YYYY"),
                });
              }}
            />
          ),
        },

        {
          label: formatMessage({ id: "general.statusUpdatedOn" }),
          content: (
            <DateRangePicker
              allowEmpty={[true, true]}
              value={[
                filterObj.fromStatusUpdatedDate
                  ? moment(filterObj.fromStatusUpdatedDate)
                  : undefined,
                filterObj.toStatusUpdatedDate
                  ? moment(filterObj.toStatusUpdatedDate)
                  : undefined,
              ]}
              onChange={dateRange => {
                mergeFilters({
                  fromStatusUpdatedDate: dateRange?.[0]
                    ? moment(dateRange[0]).toString()
                    : undefined,
                  toStatusUpdatedDate: dateRange?.[1]
                    ? moment(dateRange[1]).toString()
                    : undefined,
                });
              }}
              locale={languageCode}
              disabledDate={current => {
                return current && current.valueOf() >= Date.now();
              }}
            />
          ),
        },

        {
          label: formatMessage({ id: "companyList.filters.suspensionPlan" }),
          content: (
            <DateRangePicker
              allowEmpty={[true, true]}
              value={[
                filterObj.fromTargetSuspendDate
                  ? moment(filterObj.fromTargetSuspendDate)
                  : undefined,
                filterObj.toTargetSuspendDate
                  ? moment(filterObj.toTargetSuspendDate)
                  : undefined,
              ]}
              onChange={dateRange => {
                mergeFilters({
                  fromTargetSuspendDate: dateRange?.[0]
                    ? moment(dateRange[0]).toString()
                    : undefined,
                  toTargetSuspendDate: dateRange?.[1]
                    ? moment(dateRange[1]).toString()
                    : undefined,
                });
              }}
              locale={languageCode}
              // disabledDate={current => {
              //   return current && current.valueOf() >= Date.now();
              // }}
            />
          ),
        },

        {
          label: formatMessage({
            id: "general.status",
          }),
          content: (
            <CheckableTags
              value={filterObj.companyStatuses}
              onChange={companyStatuses =>
                mergeFilters({
                  companyStatuses,
                })
              }
              options={getCompanyStatusOptions([
                CompanyStatus.ENABLED,
                CompanyStatus.DISABLED,
                CompanyStatus.SUSPENDED,
              ])}
            />
          ),
        },
      ]}
    />
  );
};

export default CompanySearch;
