import axios from "axios";

axios.defaults.headers.common["Cache-control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = 0;

const AXIOS_INSTANCES = {
  CUMD: axios.create({
    baseURL: window.env.CUMD_URL,
  }),
  PMD: axios.create({
    baseURL: window.env.PMD_URL,
  }),
  RLMD: axios.create({
    baseURL: window.env.RLDM_URL,
  }),
  SMD: axios.create({
    baseURL: window.env.SMD_URL,
  }),
  NOTIF: axios.create({
    baseURL: window.env.NOTIF_URL,
  }),
  TON: axios.create({
    baseURL: window.env.TON_URL,
  }),
  CATALOGUE: axios.create({
    baseURL: window.env.CATALOGUE_URL,
  }),
};

export default AXIOS_INSTANCES;
