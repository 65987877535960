import { createAsyncThunk } from "@reduxjs/toolkit";
import { GroupParams } from "@trace-one/api-clients.cumd";

import { CumdAPI } from "apis";

interface PaginatedGroupParams extends GroupParams {
  skip?: number;
  take?: number;
}

export const fetchGroups = createAsyncThunk(
  "groupList/fetchGroups",
  async (params: PaginatedGroupParams = {}, thunkAPI) => {
    try {
      const { data } = await CumdAPI.getGroupsByFilters(params);
      return {
        groups: data.companyGroups,
        skipAndTakeQueryStats: data.skipAndTakeQueryStats,
      };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
