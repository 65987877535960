import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";

import { Tooltip } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";

import { ReferenceListItemData } from "models";

import Select, { SelectProps } from "components/Select";

import styles from "./ResponsibilitiesSelect.module.less";

type ResponsibilityId = string;

interface ResponsibilitiesSelectProps extends SelectProps {
  value: ResponsibilityId[];
  teamMemberResponsibilities: ReferenceListItemData[];
}

const ResponsibilitiesSelect: React.FC<ResponsibilitiesSelectProps> = ({
  onChange,
  value,
  teamMemberResponsibilities,
  error,
  errorMessage,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const dataToConfirmRef = useRef<ResponsibilityId[]>([]);

  return (
    <div className={styles.root}>
      <Label
        title={
          <b>
            {formatMessage({
              id: "general.responsibilities",
            })}
          </b>
        }
        mode="horizontal"
      />
      <Tooltip
        placement="bottom"
        text={formatMessage(
          {
            id: "userAccessManage.responsibilities.confirm",
          },
          { br: <br /> }
        )}
        onVisibleChange={() => setConfirmationOpen(false)}
        actions={[
          {
            text: formatMessage({ id: "general.no" }),
            onClick: () => {
              setConfirmationOpen(false);
            },
          },
          {
            text: formatMessage({ id: "general.yes" }),
            onClick: () => {
              onChange(dataToConfirmRef.current);
              dataToConfirmRef.current = [];
              setConfirmationOpen(false);
            },
          },
        ]}
        visible={confirmationOpen}
      >
        <Select
          onChange={(newValue: ResponsibilityId[]) => {
            if (newValue.length === 0 && value.length > 1) {
              return;
            }
            onChange(newValue);
            setConfirmationOpen(false);
          }}
          value={value}
          mode="multiple"
          data-test-id="ad-responsibilities"
          options={teamMemberResponsibilities.map(({ id, text }) => ({
            value: id,
            label: text,
          }))}
          error={error}
          errorMessage={errorMessage}
          onClear={() => {
            if (value.length > 1) {
              setConfirmationOpen(true);
            }
          }}
          {...rest}
        />
      </Tooltip>
    </div>
  );
};

export default ResponsibilitiesSelect;
