import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Icon, Button } from "@trace-one/design-system";
import { Container } from "@trace-one/react-components";
import { Typography, Tabs } from "antd";
import cn from "classnames";
import { renderToStaticMarkup } from "react-dom/server";

import IconButton from "components/IconButton";

import { CompanyStatus } from "../../../shared/constants";

import HeaderBackgroundSvg from "./HeaderBackgroundSvg";
import styles from "./StickyTabHeader.module.less";

export interface StickyTabHeaderProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  onBack?: () => void;
  onTabChange: (selectedTab: string) => void;
  tabs: {
    label: React.ReactNode;
    content?: React.ReactNode;
    disabled?: boolean;
  }[];
  companyId?: string;
  companyStatus?: string;
}

const StickyTabHeader: React.FC<StickyTabHeaderProps> = ({
  title,
  description,
  onBack,
  onTabChange,
  tabs,
  companyId,
  companyStatus,
}) => {
  const encodedHeaderBackgroundSvg = encodeURIComponent(
    renderToStaticMarkup(<HeaderBackgroundSvg />)
  );
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <div
      className={styles.root}
      style={{
        backgroundImage: `url("data:image/svg+xml,${encodedHeaderBackgroundSvg}")`,
      }}
    >
      <Container component="header" className={styles.container}>
        <section className={styles.sectionWrapper}>
          <div className={styles.titleWrapper}>
            {onBack && (
              <IconButton
                data-test-id="ad-back-btn"
                onClick={onBack}
                className={styles.backBtn}
              >
                <Icon
                  name="long-arrow-left"
                  color="primary"
                  size="large"
                  useTOLibrary
                />
              </IconButton>
            )}
            {typeof title === "string" ? <h2>{title}</h2> : title}
          </div>

          {!!description && (
            <Typography.Paragraph className={styles.description}>
              {description}
            </Typography.Paragraph>
          )}

          <div className={styles.tabsWrapper}>
            <Tabs onChange={onTabChange}>
              {tabs.map((tab, key) => {
                return (
                  <Tabs.TabPane
                    disabled={tab.disabled}
                    tab={
                      <div
                        className={cn(
                          styles.tabLabel,
                          tab.disabled && styles.tabDisabled
                        )}
                      >
                        <span>{tab.label}</span>
                      </div>
                    }
                    key={key}
                  />
                );
              })}
            </Tabs>
          </div>
        </section>
        <section className={styles.sectionRight}>
          {companyId && companyStatus === CompanyStatus.ENABLED && (
            <Button
              onClick={() => {
                history.push(`/users/create-user/${companyId}`);
              }}
            >
              {formatMessage({ id: "general.createUser" }).toUpperCase()}
            </Button>
          )}
        </section>
      </Container>
    </div>
  );
};

export default StickyTabHeader;
