import { createSlice } from "@reduxjs/toolkit";

import { fetchCompanyRelations, fetchRelatedCompanies } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "companyRelationList",
  initialState,
  reducers: {
    clearCompanyRelations: state => {
      state.relations = initialState.relations;
      state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      state.isLoading = false;
      state.hasError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompanyRelations.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorStatus = null;
      })
      .addCase(fetchCompanyRelations.fulfilled, (state, action) => {
        state.relations = action.payload.relations;
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.isLoading = false;
      })
      .addCase(fetchCompanyRelations.rejected, (state, action) => {
        state.relations = [];
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      })

      .addCase(fetchRelatedCompanies.fulfilled, (state, action) => {
        state.relatedCompaniesMap = action.payload.relatedCompaniesMap;
      });
  },
});

export const { clearCompanyRelations } = slice.actions;
export default slice.reducer;
