import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";
import { CompanyData } from "models";

import { UserStatus } from "shared/constants";

export const fetchUsersForCompanyAdmin = createAsyncThunk(
  "userList/fetchUsersForCompanyAdmin",
  async ({
    searchText,
    userStatuses,
    userJobTitle,
    responsibilityId,
    applicationTypeIds,
    isAccountAdministrator,
    skip,
    take,
  }: {
    searchText?: string;
    userStatuses?: UserStatus[];
    userJobTitle?: number;
    responsibilityId?: string;
    applicationTypeIds?: number[];
    isAccountAdministrator?: boolean;
    skip: number;
    take: number;
  }) => {
    const { data } = await CumdAPI.getUsersByFiltersForUIAdmin(
      {
        userStatuses:
          userStatuses?.length > 0
            ? userStatuses
            : [UserStatus.ENABLED, UserStatus.DISABLED],
        applicationTypeIds,
      },
      {
        userSearch: searchText,
        userJobTitle,
        responsibilityId,
        isAccountAdministrator,
        skip,
        take,
      }
    );
    return data;
  }
);

export const fetchUsersForTraceoneAdmin = createAsyncThunk(
  "userList/fetchUsersForTraceoneAdmin",
  async ({
    searchText,
    owningCompanyId,
    userJobTitle,
    userStatuses,
    responsibilityId,
    applicationTypeIds,
    isAccountAdministrator,
    creationDate,
    lastSeenBefore,
    isLocked,
    isMainContact,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    userFirstName,
    userLastName,
    userLogin,
    skip,
    take,
  }: {
    searchText?: string;
    owningCompanyId?: string;
    userJobTitle?: number;
    userStatuses?: UserStatus[];
    responsibilityId?: string;
    applicationTypeIds?: number[];
    isAccountAdministrator?: boolean;
    creationDate?: string;
    lastSeenBefore?: string;
    isLocked: boolean;
    isMainContact: boolean;
    fromStatusUpdatedDate?: string;
    toStatusUpdatedDate?: string;
    userFirstName?: string;
    userLastName?: string;
    userLogin?: string;
    skip: number;
    take: number;
  }) => {
    const { data } = await CumdAPI.getUsersByFiltersForUIAdmin(
      {
        userStatuses,
        applicationTypeIds,
      },
      {
        userSearch: searchText,
        owningCompanyId,
        userJobTitle,
        responsibilityId,
        isAccountAdministrator,
        creationDate,
        lastSeenBefore,
        isLocked,
        isMainContact,
        fromStatusUpdatedDate,
        toStatusUpdatedDate,
        userFirstName,
        userLastName,
        userLogin,
        skip,
        take,
      }
    );

    let companiesMap: { [companyId: string]: CompanyData } = {};
    const companyIds = data.users?.map(
      ({ owningCompanyId }) => owningCompanyId
    );

    if (companyIds.length > 0) {
      const {
        data: { companies },
      } = await CumdAPI.getCompaniesByFilters({ companyIds });
      companiesMap = companies.reduce<{ [companyId: string]: CompanyData }>(
        (prev, current) => ({ ...prev, [current.companyId]: current }),
        {}
      );
    }

    return {
      users: data.users,
      skipAndTakeQueryStats: data.skipAndTakeQueryStats,
      companiesMap,
    };
  }
);

export const fetchCompaniesForSearch = createAsyncThunk(
  "siteList/fetchCompaniesForSearch",
  async ({ ownerCompanyId }: { ownerCompanyId?: string }) => {
    const { data: supplierCompanies } = await CumdAPI.getCompaniesByFilters(
      {},
      {}
    );
    return supplierCompanies.companies;
  }
);
