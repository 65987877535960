/* istanbul ignore file */
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchAdminCompany = createAsyncThunk(
  "company/fetchAdminCompany",
  async ({ companyId }: { companyId: string }, thunkAPI) => {
    try {
      const { data: company } = await CumdAPI.getAdminCompanyById(companyId);

      if (!company) {
        return thunkAPI.rejectWithValue({
          status: 404,
        });
      }

      return { company };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);
