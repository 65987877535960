import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Filter } from "@trace-one/business-components";
import {
  DatePicker,
  DateRangePicker,
  FilterProps,
  Picker,
  Input,
} from "@trace-one/design-system";
import moment from "moment";

import { SearchFiltersProps } from "components/SearchFilters";

import { CumdAPI } from "../../../../apis";
import AsyncPickerDropdown from "../../../../components/AsyncPickerDropdown";
import CheckableTags from "../../../../components/CheckableTags";
import FilterMultiSelection from "../../../../components/FilterMultiSelection";
import Select from "../../../../components/Select";
import { selectJobTitlesData } from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { UserStatus } from "../../../../shared/constants";
import useAppTranslations from "../../../../shared/hooks/useAppTranslations";
import { SelectOption } from "../../../../shared/typings";
import { adjustIntercomButton } from "../../../../shared/utils/toggleIntercom";

import AsyncCompanySelect from "./components/CompanyAsyncSelect";
import { TraceoneAdminUserFilter } from "./models";
import styles from "./UserSearch.module.less";

interface UserSearchProps {
  filterObj: TraceoneAdminUserFilter;
  mergeFilters: (
    value:
      | Partial<TraceoneAdminUserFilter>
      | ((prevState: TraceoneAdminUserFilter) => TraceoneAdminUserFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
  setFilterObj: React.Dispatch<React.SetStateAction<TraceoneAdminUserFilter>>;
  resetPageNumberAndRowKeys: () => void;
}

const UserSearch: React.FC<UserSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const showIntercom = useRef(true);
  const { search } = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
  const { companyId } = queryParams ?? {};
  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  const [applicationTypeIdsArr, setApplicationTypeIdsArr] = useState([]);

  const { appLabelOptions, appLabelMap } = useAppTranslations();

  const optionsToDisplay = appLabelOptions.filter(option => {
    return option["value"] !== "40";
  });

  const sortArray = (x, y) => x.label.localeCompare(y.label);
  const sortedOptionsToDisplay = optionsToDisplay.sort(sortArray);

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const jobTitlesMap = jobTitlesOptions.reduce<{
    [itemCode: string]: string;
  }>((prev, current) => ({ ...prev, [current.value]: current.label }), {});

  const [companyLabel, setCompanyLabel] = useState("");

  useEffect(() => {
    if (companyId) {
      onClearFiltersClick();
      mergeFilters({ owningCompanyId: companyId });
    }
  }, [companyId]);

  useEffect(() => {
    const {
      userStatuses,
      applicationTypeIds,
      userJobTitle,
      administratorFilterList,
      userLockedFilter,
      owningCompanyId,
      createdAt,
      isMainContactFilterList,
      fromStatusUpdatedDate,
      toStatusUpdatedDate,
      userFirstName,
      userLastName,
      userLogin,
    } = filterObj;

    const appsTypes = applicationTypeIds
      ? appLabelOptions
          .filter(type => applicationTypeIds?.includes(type.value))
          .map(type => type.label)
      : [];

    const applicationTypeIdsArr = [];

    const defaultSelectedFilters = [
      userFirstName && {
        filterId: "1",
        values: [userFirstName],
      },

      userLastName && {
        filterId: "2",
        values: [userLastName],
      },

      userLogin && {
        filterId: "3",
        values: [userLogin],
      },

      owningCompanyId && {
        filterId: "4",
        values: [owningCompanyId],
      },

      applicationTypeIds && {
        filterId: "5",
        values: appsTypes,
      },

      administratorFilterList && {
        filterId: "6",
        values: administratorFilterList.map(e => e.length),
      },

      isMainContactFilterList && {
        filterId: "7",
        values: isMainContactFilterList.map(e => e.length),
      },

      userJobTitle && {
        filterId: "8",
        values: [jobTitlesMap[filterObj.userJobTitle]],
      },

      createdAt && {
        filterId: "9",
        values: [createdAt],
      },

      (fromStatusUpdatedDate || toStatusUpdatedDate) && {
        filterId: "10",
        values: [fromStatusUpdatedDate + "-" + toStatusUpdatedDate],
      },

      userStatuses && {
        filterId: "11",
        values: userStatuses.map(e => e.length),
      },

      userLockedFilter && {
        filterId: "12",
        values: userLockedFilter.map(e => e.length),
      },
    ].filter(i => !!i);

    if (filterObj.applicationTypeIds) {
      filterObj.applicationTypeIds.forEach(option => {
        applicationTypeIdsArr.push({
          value: option,
          label: appLabelMap[option],
          onCloseClick: () =>
            mergeFilters(prev => ({
              ...prev,
              applicationTypeIds: prev.applicationTypeIds.filter(
                appId => appId !== option
              ),
            })),
        });
      });
    }

    setSelectedFilters(defaultSelectedFilters);
    setApplicationTypeIdsArr(applicationTypeIdsArr);
  }, [filterObj]);

  const getCompaniesOptions = ({ searchValue }: { searchValue?: string }) => {
    return CumdAPI.getCompaniesByFiltersForToAdmin(
      {},
      {
        searchText: searchValue,
      }
    ).then(({ data }) => {
      return data.companies.map(({ companyId, ...rest }) => ({
        value: companyId,
        label: rest.companyDisplayName,
      }));
    });
  };

  const useDefaultOptionsCompany = () => {
    const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
      getCompaniesOptions({})
        .then(result => {
          setDefaultOptions(result);
        })
        .catch(() => {});
    }, []);

    return defaultOptions;
  };

  const defaultOptions = useDefaultOptionsCompany();

  const fetchCompaniesOnSearch = searchText => {
    return getCompaniesOptions({ searchValue: searchText })
      .then(companies => {
        return companies;
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  };

  const filters = [
    {
      title: formatMessage({ id: "general.firstName" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userFirstName: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          value={filterObj.userFirstName}
          onChange={e => mergeFilters({ userFirstName: e.target.value })}
          allowClear
          showSearch
          data-test-id="ad-registered-users-filter-firstName"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "general.lastName" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userLastName: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          onChange={e => mergeFilters({ userLastName: e.target.value })}
          value={filterObj.userLastName}
          allowClear
          showSearch
          data-test-id="ad-registered-users-filter-lastName"
        />
      ),
      filterId: "2",
    },

    {
      title: formatMessage({ id: "general.user.login" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userLogin: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          onChange={e => mergeFilters({ userLogin: e.target.value })}
          value={filterObj.userLogin}
          allowClear
          showSearch
          data-test-id="ad-registered-users-filter-userLogin"
        />
      ),
      filterId: "3",
    },

    {
      title: formatMessage({ id: "general.company" }),
      controlType: "single_selection",
      clearSelection: () => mergeFilters({ owningCompanyId: undefined }),

      elementToRender: (
        <AsyncCompanySelect
          value={filterObj.owningCompanyId}
          label={companyLabel}
          onChange={owningCompanyId => {
            mergeFilters({ owningCompanyId });
          }}
          onLabelChange={label => {
            setCompanyLabel(label);
          }}
          id="ad-filter-owningCompanyId"
          data-test-id="ad-registered-users-filter-company"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="ad-registered-users-filter-picker-company"
          title={formatMessage({ id: "general.company" })}
          showBadgeCount
          defaultOptions={defaultOptions}
          value={filterObj.owningCompanyId ? [filterObj.owningCompanyId] : []}
          selectionType="single"
          onSearch={undefined}
          minLengthToSearch={3}
          onAsyncSearch={fetchCompaniesOnSearch}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem }) => {
            mergeFilters({
              owningCompanyId: selectedItem.value,
            });
            setCompanyLabel(selectedItem.label);
          }}
          clearSelection={() =>
            mergeFilters({
              owningCompanyId: undefined,
            })
          }
        />
      ),
      filterId: "4",
    },

    {
      title: formatMessage({ id: "general.applications" }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          applicationTypeIds: [],
        });
      },
      elementToRender: (
        <FilterMultiSelection
          dataTestId="ad-apps-selection"
          options={sortedOptionsToDisplay}
          values={applicationTypeIdsArr}
          onChange={selectedValues => {
            mergeFilters({
              applicationTypeIds: selectedValues.length
                ? selectedValues.map(e => e.value)
                : undefined,
            });
          }}
          data-test-id="ad-registered-users-filter-apps"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.applications" })}
          showBadgeCount
          showSearchInput
          selectionType="multi"
          value={filterObj.applicationTypeIds ?? []}
          items={sortedOptionsToDisplay}
          onChange={({ value }) => {
            mergeFilters({
              applicationTypeIds: value.length ? value : undefined,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              applicationTypeIds: undefined,
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-users-filter-picker-apps"
        />
      ),
      filterId: "5",
    },

    {
      title: formatMessage({
        id: "general.administrator",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          administratorFilterList: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.administratorFilterList}
          onChange={administratorFilterList =>
            mergeFilters({
              administratorFilterList,
            })
          }
          options={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.administrator",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.administratorFilterList ?? []}
          items={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
          onChange={({ value }) => {
            mergeFilters({
              administratorFilterList: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              administratorFilterList: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-users-filter-picker-administrator"
        />
      ),
      filterId: "6",
    },

    {
      title: formatMessage({
        id: "general.isMainContact",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          isMainContactFilterList: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.isMainContactFilterList}
          onChange={isMainContactFilterList =>
            mergeFilters({
              isMainContactFilterList,
            })
          }
          options={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
        />
      ),
      filterId: "7",
    },
    {
      title: formatMessage({ id: "general.jobTitle" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userJobTitle: undefined,
        });
      },
      elementToRender: (
        <Select
          value={filterObj.userJobTitle}
          onChange={userJobTitle => {
            mergeFilters({ userJobTitle });
          }}
          options={jobTitlesOptions}
          allowClear
          showSearch
          data-test-id="ad-registered-users-filter-jobTitle"
        />
      ),
      filterId: "8",
    },

    {
      title: formatMessage({ id: "general.createdSince" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          creationDate: undefined,
        });
      },
      elementToRender: (
        <DatePicker
          value={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          onChange={createdAt => {
            mergeFilters({
              createdAt: createdAt?.format("DD/MM/YYYY"),
            });
          }}
          showToday={false}
          style={{ width: "275px" }}
          data-test-id="ad-registered-users-filter-createdSince"
        />
      ),
      filterId: "9",
    },

    {
      title: formatMessage({ id: "general.statusUpdatedOn" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          fromStatusUpdatedDate: undefined,
          toStatusUpdatedDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          value={[
            filterObj.fromStatusUpdatedDate
              ? moment(filterObj.fromStatusUpdatedDate)
              : undefined,
            filterObj.toStatusUpdatedDate
              ? moment(filterObj.toStatusUpdatedDate)
              : undefined,
          ]}
          defaultValue={[
            filterObj.fromStatusUpdatedDate
              ? moment(filterObj.fromStatusUpdatedDate)
              : undefined,
            filterObj.toStatusUpdatedDate
              ? moment(filterObj.toStatusUpdatedDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              fromStatusUpdatedDate: dateRange?.[0]
                ? moment(dateRange[0]).toString()
                : undefined,
              toStatusUpdatedDate: dateRange?.[1]
                ? moment(dateRange[1]).toString()
                : undefined,
            });
          }}
          locale={languageCode}
          disabledDate={current => {
            return current && current.valueOf() >= Date.now();
          }}
          data-test-id="ad-registered-users-filter-updatedDate"
        />
      ),
      filterId: "10",
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          userStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.userStatuses}
          onChange={userStatuses =>
            mergeFilters({
              userStatuses,
            })
          }
          options={[
            {
              value: UserStatus.ENABLED,
              label: formatMessage({ id: "general.active" }),
            },
            {
              value: UserStatus.DEACTIVATED,
              label: formatMessage({ id: "general.inactive" }),
            },
            {
              value: "DisabledAndSuspended",
              label: formatMessage({ id: "general.suspended" }),
            },
          ]}
          data-test-id="ad-registered-users-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.userStatuses ?? []}
          items={[
            {
              value: UserStatus.ENABLED,
              label: formatMessage({ id: "general.active" }),
            },
            {
              value: UserStatus.DEACTIVATED,
              label: formatMessage({ id: "general.inactive" }),
            },
            {
              value: "DisabledAndSuspended",
              label: formatMessage({ id: "general.suspended" }),
            },
          ]}
          onChange={({ value }) => {
            mergeFilters({
              userStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              userStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-users-filter-picker-status"
        />
      ),
      filterId: "11",
    },

    {
      title: formatMessage({
        id: "general.userLocked",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          userLockedFilter: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.userLockedFilter}
          onChange={userLockedFilter =>
            mergeFilters({
              userLockedFilter,
            })
          }
          options={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
          data-test-id="ad-registered-users-filter-userLocked"
        />
      ),
      filterId: "12",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "userList.toAdmin.searchPlaceholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default UserSearch;
