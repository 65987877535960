import { createSlice } from "@reduxjs/toolkit";

import { fetchGroups } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "groupList",
  initialState,
  reducers: {
    clearGroups: state => {
      state.groups = initialState.groups;
      state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      state.isLoading = false;
      state.hasError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGroups.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorStatus = null;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups = action.payload.groups;
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.isLoading = false;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.groups = [];
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      });
  },
});

export const { clearGroups } = slice.actions;
export default slice.reducer;
