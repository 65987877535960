import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Row } from "antd";
import { Form } from "antd";

import { CumdAPI } from "../../../../apis";
import StickyContainer from "../../../../components/StickyContainer";
import { CompanyForTraceoneAdminData } from "../../../../models";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchCountries } from "../../../../reduxStore/shared/asyncActions";
import {
  selectCompanyActivitesMap,
  selectCountriesData,
} from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import useToast from "../../../../shared/hooks/useToast";
import generateRandomGUID from "../../../../shared/utils/generateRandomGUID";

import ChangeDunsSection from "./ChangeDuns";
import CurrentInfoSection from "./CurrentInfo";

interface CompanyChangeDunsFormProps {
  company: CompanyForTraceoneAdminData;
}

const CompanyChangeDunsForm: React.FC<CompanyChangeDunsFormProps> = ({
  company,
}) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const languageCode = useSelector(selectUserLanguageCode);
  const companyActivitiesMap = useSelector(selectCompanyActivitesMap);
  const countries = useSelector(selectCountriesData);

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [companyDuns, setCompanyDuns] = useState(null);

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  const countriesOptions = countries.data?.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const sortedCountriesOptions = countriesOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const handleSubmit = async () => {
    try {
      await CumdAPI.updateCompanyDuns(company.companyId, {
        newCompanyDuns: companyDuns.companyDuns,
        newCompanyLegalName: companyDuns.companyName,
        statefulProcessId: generateRandomGUID(),
      });
      history.push(`/companies/${company.companyId}`);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <Form name="basic" form={form} onFinish={handleSubmit}>
      <StickyContainer
        title={formatMessage({
          id: "companyCreateDuns.title",
        }).toUpperCase()}
        description={formatMessage({
          id: "general.updateDuns",
        })}
        layoutMode="edit"
        // isSubmitting={formStat.isSubmitting}
        onCancel={() => {
          history.push(`/companies/${company.companyId}`);
        }}
        onBack={() => {
          history.push(`/companies/${company.companyId}`);
        }}
        saveEnabled={!saveEnabled}
      >
        <Row gutter={32}>
          <Col className="gutter-row" span={14}>
            <ChangeDunsSection
              company={company}
              countryOptions={sortedCountriesOptions}
              setSaveEnabled={setSaveEnabled}
              setCompanyDuns={setCompanyDuns}
            />
          </Col>
          <Col className="gutter-row" span={10}>
            <CurrentInfoSection
              company={company}
              companyType={
                companyActivitiesMap[company.companyActivityId]?.text
              }
              countryOptions={sortedCountriesOptions}
            />
          </Col>
        </Row>
      </StickyContainer>
    </Form>
  );
};

export default CompanyChangeDunsForm;
