import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchPendingCompanies = createAsyncThunk(
  "companyList/fetchPendingCompanies",
  async ({
    registrationStatuses,
    skip,
    take,
    companyActivity,
    companyLegalName,
    companyDuns,
    companySearchText,
    globalCompanyId,
    createdAt,
  }: {
    registrationStatuses: number[];
    skip?: number;
    take?: number;
    companyActivity: number;
    companyLegalName: string;
    companyDuns: string;
    companySearchText: string;
    globalCompanyId: string;
    createdAt: string;
  }) => {
    const { data } = await CumdAPI.getPendingCompanies(
      { registrationStatuses },
      {
        skip,
        take,
        companyActivity,
        companyLegalName,
        companyDuns,
        companySearchText,
        globalCompanyId,
        createdAt,
      }
    );
    return data;
  }
);
