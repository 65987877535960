import React from "react";
import { useIntl } from "react-intl";

import { Button, Heading, Illustration } from "@trace-one/design-system";
import { Modal } from "antd";

import styles from "./Modal.module.less";
export interface ArchiveGroupModalProps {
  setIsModalVisible?: any;
  isModalVisible?: boolean;
  handleRemoveGroup?: any;
}

const ArchiveGroupModal: React.FC<ArchiveGroupModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  handleRemoveGroup,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Modal
        wrapClassName={styles.root}
        title={
          <div className={styles.header}>
            <Illustration name="delete" color="grey" />
            <Heading size="xs">
              {formatMessage({ id: "groupList.actions.archive" })}
            </Heading>
          </div>
        }
        visible={isModalVisible}
        footer={
          <>
            <Button
              type="secondary"
              htmlType="submit"
              onClick={() => setIsModalVisible(false)}
            >
              {formatMessage({ id: "general.cancel" }).toUpperCase()}
            </Button>
            <Button htmlType="submit" onClick={handleRemoveGroup}>
              {formatMessage({ id: "general.confirm" }).toUpperCase()}
            </Button>
          </>
        }
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
      >
        {formatMessage({ id: "groupList.actions.archive.modal.text" })}
      </Modal>
    </div>
  );
};

export default ArchiveGroupModal;
