import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Select, Input, Button, TagV1 as Tag } from "@trace-one/design-system";

import { RlmdAPI } from "../../../../../../apis";
import { selectUserLanguageCode } from "../../../../../../reduxStore/user/selectors";

import styles from "./IdentificationCode.module.less";

interface IdentificationCodeProps {
  identifiers?: { id: string; value: string }[];
  setIdentifiers?: any;
}
const IdentificationCode: React.FC<IdentificationCodeProps> = ({
  identifiers,
  setIdentifiers,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const [companyIdentifiers, setCompanyIdentifiers] = useState([]);
  const [identifierId, setIdentifierId] = useState();
  const [identifierValue, setIdentifierValue] = useState();

  const fetchCompanyIdentifiers = async () => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "company_identifiers",
      {
        languageCode,
      }
    );
    setCompanyIdentifiers(data);
  };

  useEffect(() => {
    fetchCompanyIdentifiers();
  }, []);

  const identifierOptions = companyIdentifiers?.map(identifier => ({
    name: identifier.text,
    value: identifier.id,
  }));

  const sortedIdentifierOptions = identifierOptions.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const handleIdentifierValue = () => {
    setIdentifiers([
      ...identifiers,
      {
        id: identifierId,
        value: identifierValue,
      },
    ]);
    setIdentifierId(undefined);
    setIdentifierValue(undefined);
  };

  return (
    <div className={styles.root}>
      <div className={styles.selectWrapper}>
        <Select
          placeholder={formatMessage({ id: "general.selectValue" })}
          showSearch
          allowClear
          options={sortedIdentifierOptions}
          optionFilterProp="children"
          value={identifierId}
          onChange={setIdentifierId}
        />
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          value={identifierValue}
          onChange={e => {
            setIdentifierValue(e.target.value);
          }}
        />
        <Button
          type="secondary"
          onClick={handleIdentifierValue}
          disabled={
            identifierValue === undefined ||
            identifierValue === "" ||
            identifierId === undefined
          }
        >
          {formatMessage({ id: "general.add" }).toUpperCase()}
        </Button>
      </div>

      {identifiers &&
        identifiers.map(item => (
          <div className={styles.resultsWrapper}>
            <div>
              <Tag
                label={
                  <div>
                    {
                      companyIdentifiers?.find(value => value.id === item.id)
                        ?.text
                    }
                    : {item.value}
                  </div>
                }
                color="default"
                size="medium"
                mode="full"
                closable
                onClose={() => {
                  setIdentifiers(
                    identifiers?.filter(value => value.id !== item.id)
                  );
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default IdentificationCode;
