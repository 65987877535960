import { useSelector } from "react-redux";

import { compose } from "@reduxjs/toolkit";

import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import { selectCompanyChangeDunsForm } from "../../../../reduxStore/companyChangeDunsForm/selectors";
import CreateCompany from "../../containers/CreateCompany";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());

export const CreateCompanyPage: React.FC<BaseHocProps> = () => {
  const { companyData } = useSelector(selectCompanyChangeDunsForm);

  return <CreateCompany company={companyData.data} />;
};

export default enhance(CreateCompanyPage);
