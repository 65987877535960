import React from "react";

import AddFirstUser from "./components/AddFirstUser";
import styles from "./UserInformation.module.less";

interface UserInformationProps {
  companyId?: string;
  companyValue?: any;
  jobTitlesOptions?: object[];
  languageOptions?: object[];
  setTitle?: (string) => void;
  title?: string;
  // eslint-disable-next-line no-empty-pattern
  setJobTitle?: ({}) => void;
  jobTitle?: number;
  setLastName?: (string) => void;
  lastName?: string;
  setFirstName?: (string) => void;
  firstName?: string;
  // eslint-disable-next-line no-empty-pattern
  setLanguage?: ({}) => void;
  language?: string;
  setPhoneNumber?: (string) => void;
  phoneNumber?: string;
  setEmail?: (string) => void;
  email?: string;
  setConfirmationEmail?: (string) => void;
  confirmationEmail?: string;
  emailIsValid?: boolean;
  emailErrorCode?: string;
  setIsValid?: (boolean) => void;
  handleCreateUserAndCompany?: any;
  checkIfEmailExists?: any;
}

const UserInformation: React.FC<UserInformationProps> = ({
  companyId,
  companyValue,
  jobTitlesOptions,
  languageOptions,
  setTitle,
  title,
  setJobTitle,
  jobTitle,
  setLastName,
  lastName,
  setFirstName,
  firstName,
  setLanguage,
  language,
  setPhoneNumber,
  phoneNumber,
  setEmail,
  email,
  setConfirmationEmail,
  confirmationEmail,
  emailIsValid,
  emailErrorCode,
  setIsValid,
  handleCreateUserAndCompany,
}) => {
  return (
    <div className={styles.userInfoSection}>
      <AddFirstUser
        companyId={companyId}
        companyValue={companyValue}
        jobTitlesOptions={jobTitlesOptions}
        languageOptions={languageOptions}
        setTitle={setTitle}
        title={title}
        setJobTitle={setJobTitle}
        jobTitle={jobTitle}
        setLastName={setLastName}
        lastName={lastName}
        setFirstName={setFirstName}
        firstName={firstName}
        setLanguage={setLanguage}
        language={language}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setEmail={setEmail}
        email={email}
        setConfirmationEmail={setConfirmationEmail}
        confirmationEmail={confirmationEmail}
        emailIsValid={emailIsValid}
        emailErrorCode={emailErrorCode}
        setIsValid={setIsValid}
        handleCreateUserAndCompany={handleCreateUserAndCompany}
      />
    </div>
  );
};

export default UserInformation;
