import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Input, toaster } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Modal, Form } from "antd";

import { CumdAPI } from "../../../../../../apis";
import { CompanyForTraceoneAdminData } from "../../../../../../models";
import { useAppDispatch } from "../../../../../../reduxStore";
import { fetchCompany } from "../../../../../../reduxStore/companyChangeDunsForm/asyncActions";
import useToast from "../../../../../../shared/hooks/useToast";

import styles from "./EditDunsName.module.less";

interface EditCompanyInfoProps {
  company: CompanyForTraceoneAdminData;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
}

const EditDunsName: React.FC<EditCompanyInfoProps> = ({
  company,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useAppDispatch();
  const [newName, setNewName] = useState<string>(null);
  const { formatMessage } = useIntl();
  const toast = useToast();
  const [form] = Form.useForm();

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (company) {
      setNewName(company.companyLegalName);
    }
  }, [company, isModalVisible]);

  useEffect(() => {
    form.resetFields(["dunsName"]);
  }, [isModalVisible]);

  const handleSubmit = async () => {
    if (newName !== "") {
      try {
        await CumdAPI.updateCompanyLegalName(company?.companyId, {
          companyLegalName: newName,
        });
        dispatch(fetchCompany({ companyId: company.companyId }));
      } catch (error) {
        toast.saveError({ error });
      }
      setIsModalVisible(false);
      toaster.open({
        message: formatMessage({ id: "toast.modificationSuccess" }),
        description: formatMessage({
          id: "toast.confirmation.dunsNameHasBeenUpdated",
        }),
        type: "confirmation",
      });
    } else {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({ id: "general.could.not.be.saved" }),
        type: "alert",
      });
    }
  };

  return (
    <Form name="basic" form={form} onFinish={handleSubmit}>
      <Modal
        wrapClassName={styles.root}
        title={
          <h1 className={styles.title}>
            {formatMessage({ id: "companyGeneralInfo.dunsName.modal.title" })}
          </h1>
        }
        visible={isModalVisible}
        footer={
          <Button
            htmlType="submit"
            onClick={handleSubmit}
            disabled={newName === ""}
          >
            {formatMessage({ id: "general.save" })}
          </Button>
        }
        onCancel={closeModal}
      >
        <div className={styles.wrapper}>
          <Label
            required
            title={formatMessage({
              id: "general.dunsName",
            })}
          >
            <Form.Item
              name="dunsName"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
              initialValue={newName}
            >
              <Input onChange={e => setNewName(e.target.value)} />
            </Form.Item>
          </Label>
        </div>
      </Modal>
    </Form>
  );
};

export default EditDunsName;
