import { Switch } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import { CompanyChangeDunsPage } from "./pages/CompanyChangeDunsPage";
import CompanyDetailsPage from "./pages/CompanyDetailsPage";
import CompanyListPage from "./pages/CompanyListPage";
import CompanyProductSettingsPage from "./pages/CompanyProductSettingsPage";
import CompanySubscriptionCreatePage from "./pages/CompanySubscriptionCreatePage";
import CompanySubscriptionEditPage from "./pages/CompanySubscriptionEditPage";
import { CreateCompanyPage } from "./pages/CreateCompanyPage";
import { PendingCompanyAddDunsPage } from "./pages/PendingCompanyAddDunsPage";
import PendingCompanyDetailsPage from "./pages/PendingCompanyDetailsPage";
import { PendingCompanyListPage } from "./pages/PendingCompanyListPage";

const CompaniesRouting = () => {
  const { isTraceoneAdmin, hasRoleSuperAdmin, hasRoleAdministrator } =
    usePermissions();

  return (
    <Switch>
      <AuthorizedRoute path="/companies/list" canActive={isTraceoneAdmin} exact>
        <CompanyListPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/pending/list"
        canActive={isTraceoneAdmin}
        exact
      >
        <PendingCompanyListPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/create-company"
        canActive={isTraceoneAdmin}
        exact
      >
        <CreateCompanyPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/:companyId"
        canActive={isTraceoneAdmin}
        exact
      >
        <CompanyDetailsPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/pending/:userId"
        canActive={isTraceoneAdmin}
        exact
      >
        <PendingCompanyDetailsPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/:companyId/subscriptions/create"
        canActive={isTraceoneAdmin}
        exact
      >
        <CompanySubscriptionCreatePage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/subscriptions/:subscriptionId/edit"
        canActive={isTraceoneAdmin}
        exact
      >
        <CompanySubscriptionEditPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/:companyId/changeDuns"
        canActive={isTraceoneAdmin}
        exact
      >
        <CompanyChangeDunsPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/pending/:userId/addDuns"
        canActive={isTraceoneAdmin}
        exact
      >
        <PendingCompanyAddDunsPage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/companies/:companyId/productSettings"
        canActive={
          (isTraceoneAdmin && hasRoleSuperAdmin) ||
          (isTraceoneAdmin && hasRoleAdministrator)
        }
        exact
      >
        <CompanyProductSettingsPage />
      </AuthorizedRoute>

      <RouteIfNotFound />
    </Switch>
  );
};

export default CompaniesRouting;
