import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectUserList = (state: RootState) => state.userList;

export const selectUsers = createSelector(_selectUserList, ({ data }) => data);
export const selectUsersSkipAndTakeQueryStats = createSelector(
  _selectUserList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
export const selectIsUsersLoading = createSelector(
  _selectUserList,
  ({ isLoading }) => isLoading
);
export const selectCompaniesMap = createSelector(
  _selectUserList,
  ({ companiesMap }) => companiesMap
);
