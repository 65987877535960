import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchCompanySubscriptions = createAsyncThunk(
  "companySubscriptionList/fetchCompanySubscriptions",
  async ({ companyId }: { companyId: string }, thunkAPI) => {
    try {
      const [company, companySubscriptions] = await Promise.all([
        CumdAPI.getCompaniesByFiltersForToAdmin({
          companyIds: [companyId],
        }).then(({ data: { companies } }) => companies[0]),
        CumdAPI.getSubscriptionsByCompanyId(companyId).then(({ data }) => data),
      ]);
      if (!company) {
        return thunkAPI.rejectWithValue({
          status: 404,
        });
      }

      return { company, companySubscriptions };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);
