/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";

import { fetchCompany } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "companyChangeDunsForm",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCompany.pending, (state, action) => {
        state.companyData.isLoading = true;
        state.companyData.hasError = false;
        state.companyData.errorStatus = null;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.companyData.data = action.payload.company;
        // state.companyData.disabledApps = action.payload.disabledApps;
        state.companyData.isLoading = false;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.companyData.data = null;
        state.companyData.disabledApps = [];
        state.companyData.isLoading = false;
        state.companyData.hasError = true;
        state.companyData.errorStatus = (action.payload as any).status;
      });
  },
});

export default slice.reducer;
