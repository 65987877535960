import { Switch } from "@trace-one/react-components";

interface AppSwitchProps {
  checked: boolean;
  onChange: (status: boolean) => void;
  applicationTypeId: number;
  disabled?: boolean;
}

const AppSwitch: React.FC<AppSwitchProps> = ({
  checked,
  onChange,
  applicationTypeId,
  disabled,
}) => {
  return (
    <div data-test-id={`ad-app-switch-for-${applicationTypeId}`}>
      <Switch
        defaultChecked={checked}
        onChange={(value, event) => {
          event.stopPropagation();
          onChange(value);
        }}
        size="small"
        disabled={disabled}
      />
    </div>
  );
};

export default AppSwitch;
