import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading } from "@trace-one/design-system";

import { useAppDispatch } from "../../../../../reduxStore";
import { fetchCountries } from "../../../../../reduxStore/shared/asyncActions";
import { selectCountriesData } from "../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../reduxStore/user/selectors";

import styles from "./CurrentInfoSection.module.less";

interface CurrentInfoSectionProps {
  userInfo: any;
  companyType: string;
}

const CurrentInfoSection: React.FC<CurrentInfoSectionProps> = ({
  userInfo,
  companyType,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const countries = useSelector(selectCountriesData);

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  const countryOptions = countries.data.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const country = countryOptions?.find(country => {
    return country.value === userInfo.companyCountry;
  });

  return (
    <div className={styles.root}>
      <Heading size="xxs">
        {formatMessage({
          id: "general.currentInfo",
        }).toUpperCase()}
      </Heading>

      <div className={styles.infos}>
        <div className={styles.info}>
          <span>
            {formatMessage({ id: "companyGeneralInfo.companyDisplayName" })}
          </span>
          <div>
            {userInfo.companyDisplayName === null
              ? userInfo.companyLegalName
              : userInfo.companyDisplayName}
          </div>
        </div>
        <div className={styles.info}>
          <span>{formatMessage({ id: "general.companyType" })}</span>
          <div>
            {userInfo.companyActivity
              ? companyType
              : formatMessage({ id: "general.empty" })}
          </div>
        </div>

        <div className={styles.info}>
          <span>{formatMessage({ id: "general.address" })}</span>
          <div>
            {`${
              userInfo?.companyAddress !== null
                ? userInfo?.companyAddress
                : formatMessage({ id: "general.empty" })
            }, ${
              userInfo?.companyPostalCode !== null
                ? userInfo?.companyPostalCode
                : formatMessage({ id: "general.empty" })
            }, ${
              userInfo?.companyCity !== null
                ? userInfo?.companyCity
                : formatMessage({ id: "general.empty" })
            }, ${
              userInfo?.companyState !== null
                ? userInfo?.companyState
                : formatMessage({ id: "general.empty" })
            }, ${
              userInfo?.companyCountry !== null
                ? country?.name
                : formatMessage({ id: "general.empty" })
            }`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentInfoSection;
