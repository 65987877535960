import React from "react";
import { useIntl } from "react-intl";

import { Col } from "antd";

import Card from "components/Card";

import AppIcons from "../../../../../components/AppIcons";
import { CompanyForTraceoneAdminData } from "../../../../../models";

import styles from "./ApplicationsCard.module.less";

interface ApplicationsCardProps {
  company: CompanyForTraceoneAdminData;
}

const ApplicationsCard: React.FC<ApplicationsCardProps> = ({ company }) => {
  const { formatMessage } = useIntl();

  const apps = company?.applicationTypeIds.map(app => {
    if (app !== 40) {
      return app;
    }
    return null;
  });

  return (
    <div className={styles.root}>
      <Card title={formatMessage({ id: "general.applications" }).toUpperCase()}>
        <Col className={styles.info} style={{ padding: "0" }}>
          <AppIcons applications={apps} />
        </Col>
      </Card>
    </div>
  );
};

export default ApplicationsCard;
