/* istanbul ignore file */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { OfferData } from "models/index";

import { CumdAPI } from "apis";

import { SubscriptionStatus } from "shared/constants";
import { ApplicationType } from "shared/constants";

export const fetchSubscription = createAsyncThunk(
  "companySubscriptionForm/fetchSubscription",
  async ({ subscriptionId }: { subscriptionId: string }, thunkAPI) => {
    try {
      const { data: subscription } = await CumdAPI.getSubscriptionById(
        subscriptionId
      );
      if (subscription.status !== SubscriptionStatus.ACTIVE) {
        return thunkAPI.rejectWithValue({
          status: 403,
        });
      }

      const commentAuthorIds = subscription.comments.map(
        comment => comment.authorId
      );

      if (commentAuthorIds.length !== 0) {
        const { data: users } = await CumdAPI.getUsersByUserIds({
          userIds: commentAuthorIds,
        });

        subscription.comments.forEach(comment => {
          const author = users.find(user => user.userId === comment.authorId);
          comment[
            "authorName"
          ] = `${author.userFirstName} ${author.userLastName}`;
        });
      }

      return { subscription };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);

export const fetchCompany = createAsyncThunk(
  "companySubscriptionForm/fetchCompany",
  async ({ companyId }: { companyId: string }, thunkAPI) => {
    try {
      const [company, disabledApps] = await Promise.all([
        CumdAPI.getCompaniesByFiltersForToAdmin({
          companyIds: [companyId],
        }).then(({ data: { companies } }) => companies[0]),
        CumdAPI.getSubscriptionsByCompanyId(companyId).then(({ data }) =>
          data
            .filter(({ status }) => status === SubscriptionStatus.ACTIVE)
            .map(({ applicationTypeId }) => applicationTypeId)
        ),
      ]);
      if (!company) {
        return thunkAPI.rejectWithValue({
          status: 404,
        });
      }

      return { company, disabledApps };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);

export const fetchOffers = createAsyncThunk(
  "companySubscriptionForm/fetchOffers",
  async (
    {
      applicationIds,
      companyActivityId,
    }: {
      applicationIds: ApplicationType[];
      companyActivityId: number;
    },
    thunkAPI
  ) => {
    try {
      const promises = applicationIds.map(applicationTypeId =>
        CumdAPI.getOffersByFilters({ applicationTypeId }).then(
          ({ data }) => data
        )
      );
      const dataRes = await Promise.all(promises);

      const result: { [applicationTypeId: string]: OfferData[] } = {};
      dataRes.forEach((offers, index) => {
        result[applicationIds[index]] = offers.filter(offer =>
          offer.companyActivityIds.includes(companyActivityId)
        );
      });

      return result;
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);
