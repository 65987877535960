import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  CategoryItem,
  CategoryList,
} from "@trace-one/api-clients.rlmd/dist/models";
import { Graphic } from "@trace-one/design-system";
import { TreeSelect } from "antd";

import { RlmdAPI } from "../../apis";
import { selectUserLanguageCode } from "../../reduxStore/user/selectors";
import { GlobalCategoryId } from "../../shared/constants";
import useToast from "../../shared/hooks/useToast";

import styles from "./ProductCategoryTreeSelect.module.less";

interface ProductCategoryTreeSelect {
  category?: { id: string; categoryListId: string; name: string }[];
  // eslint-disable-next-line no-empty-pattern
  setCategory?: ([]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ProductCategoryTreeSelect: React.FC<ProductCategoryTreeSelect> = ({
  category,
  setCategory,
}) => {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const languageCode = useSelector(selectUserLanguageCode);

  const [categoryTree, setCategoryTree] = useState([]);

  const fetchCategoryList = async () => {
    try {
      const { data } = await RlmdAPI.getCategoryListsByCompanyId({
        companyId: GlobalCategoryId,
      });

      return data;
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const buildCategoryListTree = (categoryList: CategoryList[]) => {
    return categoryList.map(category => ({
      id: category.categoryListId,
      categoryId: category.categoryListId,
      categoryItemId: null,
      value: category.categoryListId,
      title: category.categoryListName,
      categoryListId: category.categoryListId,
      selectable: false,
    }));
  };

  const fetchCategoryChildren = async (
    parentId: string,
    categoryListId: string
  ) => {
    try {
      const { data } = await RlmdAPI.getCategoryItemsByIdInDirectChildIds(
        categoryListId,
        {
          parentId: parentId,
          languageCode,
        }
      );

      return data;
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const buildCategoryChildrenTree = (categoryChildren: CategoryItem[]) => {
    return categoryChildren.map(category => ({
      id: category.categoryItemId,
      categoryId: category.categoryId,
      categoryItemId: category.categoryItemId,
      value: category.categoryItemId,
      title: category.categoryItemName,
      categoryListId: category.categoryId,
      selectable: true,
    }));
  };

  useEffect(() => {
    (async () => {
      setCategoryTree(buildCategoryListTree(await fetchCategoryList()));
    })();
  }, []);

  const findNodeInTree = (tree, id) => {
    for (let node of tree) {
      if (node.value === id) {
        return node;
      }

      if (node.children) {
        let foundNode = findNodeInTree(node.children, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  return (
    <div className={styles.root}>
      <TreeSelect
        labelInValue
        placeholder={formatMessage({
          id: "general.selectValues",
        })}
        showSearch
        allowClear
        multiple
        treeData={categoryTree}
        value={category.map(item => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={selectedValues => {
          const updatedCategories = selectedValues.map(valueObj => {
            const foundItem = findNodeInTree(categoryTree, valueObj.value);
            return {
              id: valueObj.value,
              name: foundItem?.title,
              categoryId: foundItem?.categoryId,
              categoryItemId: foundItem.categoryItemId,
            };
          });

          setCategory(updatedCategories);
        }}
        loadData={async ({ id, categoryListId }) => {
          const findNode = (tree, id) => {
            for (let node of tree) {
              if (node.id === id) {
                return node;
              }

              if (node?.children) {
                let found = findNode(node.children, id);

                if (found) {
                  return found;
                }
              }
            }

            return null;
          };

          const newCategoryTree = JSON.parse(JSON.stringify(categoryTree));
          const result = findNode(newCategoryTree, id);

          const parentId = id === categoryListId ? null : id;
          const children = await fetchCategoryChildren(
            parentId,
            categoryListId
          );
          result.children = buildCategoryChildrenTree(children);
          setCategoryTree(newCategoryTree);
        }}
        clearIcon={<Graphic name="close" size="small" />}
      />
    </div>
  );
};

export default ProductCategoryTreeSelect;
