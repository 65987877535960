import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button } from "@trace-one/design-system";
import { Col } from "antd";

import Card from "../../../../../components/Card";

import styles from "./DunsInformationCard.module.less";

interface DunsInformationCardProps {
  userInfo: any;
}

const DunsInformationCard: React.FC<DunsInformationCardProps> = ({
  userInfo,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const handleTONRedirect = () => {
    const url = window.env.VALIDATE_COMPANY_URL.replace(
      "{global-company-id}",
      userInfo.data?.owningCompanyId
    );
    window.open(url, "_blank");
  };

  const handleRedirectToPendingCompanyAddDunsPage = () => {
    history.push(`/companies/pending/${userInfo?.data.userId}/addDuns`);
  };

  const addDunsBeforeSignUp = () => {
    if (
      (userInfo.data?.companyLegalName === null ||
        userInfo.data?.companyLegalName === "") &&
      userInfo.data?.registrationStatus !== 2
    ) {
      return (
        <Button type="secondary" onClick={handleTONRedirect}>
          {formatMessage({ id: "general.addDuns" })}
        </Button>
      );
    } else if (
      (userInfo.data?.companyDuns === null ||
        userInfo.data?.companyDuns === "") &&
      userInfo.data?.registrationStatus !== 2
    ) {
      return (
        <Button
          type="secondary"
          // onClick={handleRedirectToPendingCompanyAddDunsPage}
          onClick={handleTONRedirect}
        >
          {formatMessage({ id: "general.addDuns" })}
        </Button>
      );
    } else if (userInfo.data?.registrationStatus !== 2) {
      return (
        <Button type="secondary" onClick={handleTONRedirect}>
          {formatMessage({ id: "general.validate" })}
        </Button>
      );
    }
  };

  const addDunsAfterSignUp = () => {
    if (
      (userInfo.data?.companyLegalName === null ||
        userInfo.data?.companyLegalName === "") &&
      userInfo.data?.registrationStatus !== 2
    ) {
      return (
        <Button type="secondary" onClick={handleTONRedirect}>
          {formatMessage({ id: "general.addDuns" })}
        </Button>
      );
    } else if (
      (userInfo.data?.companyDuns === null ||
        userInfo.data?.companyDuns === "") &&
      userInfo.data?.registrationStatus !== 2
    ) {
      return (
        <Button
          type="secondary"
          onClick={handleRedirectToPendingCompanyAddDunsPage}
          // onClick={handleTONRedirect}
        >
          {formatMessage({ id: "general.addDuns" })}
        </Button>
      );
    }

    // else if (userInfo.data?.registrationStatus !== 2) {
    //   return (
    //     <Button
    //       type="secondary"
    //       // onClick={handleTONRedirect}
    //     >
    //       {formatMessage({ id: "general.validate" })}
    //     </Button>
    //   );
    // }
  };

  const modify = () => {
    return (
      <Button
        type="secondary"
        onClick={handleRedirectToPendingCompanyAddDunsPage}
        // onClick={handleTONRedirect}
      >
        {formatMessage({ id: "general.addDuns" })}
      </Button>
    );
  };

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "companyGeneralInfo.dunsInfo.title" })}
        extra={
          window.env.DISABLE_SIGNUP_FEATURE === "true"
            ? userInfo.data.registrationStatus !== 1 && addDunsBeforeSignUp()
            : userInfo.data.registrationStatus === 0 &&
              (userInfo.data?.companyDuns !== "" ||
                userInfo.data?.companyDuns !== null)
            ? modify()
            : addDunsAfterSignUp()
        }
        // extra={userInfo.data.registrationStatus !== 1 && addDuns()}
        // extra={
        //   userInfo.data.registrationStatus === 0 &&
        //   (userInfo.data?.companyDuns !== "" ||
        //     userInfo.data?.companyDuns !== null)
        //     ? modify()
        //     : addDunsAfterSignUp()
        // }
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsName" })}
          </span>
          <div>
            {userInfo.data?.companyLegalName
              ? userInfo.data?.companyLegalName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsNumber" })}
          </span>
          <div>
            {userInfo.data?.companyDuns
              ? userInfo.data?.companyDuns
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default DunsInformationCard;
