import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Toggle, Tooltip } from "@trace-one/design-system";
import { Card, Label, Select } from "@trace-one/react-components";

import { selectUserAccessManage } from "reduxStore/userAccessManage/selectors";

import { ApplicationType, ModernizedApplicationType } from "shared/constants";
import useAppTranslations from "shared/hooks/useAppTranslations";

import AppIcon from "../../../../../../../components/AppIcon";
import usePermissions from "../../../../../../../core/oidc/usePermissions";
import { UserForUIAdminData } from "../../../../../../../models";
import { UserAccessManageValues } from "../../../models";

import ActivityCard from "./ActivityCard";
import PermissionsPlaceholder from "./Permissions/PermissionsPlaceholder";
import styles from "./UserRolesApp.module.less";

interface UserRolesAppsProps {
  user: UserForUIAdminData;
  control: Control<UserAccessManageValues>;
  selectedApp: number;
  setSelectedApp: Dispatch<SetStateAction<number>>;
}

const UserRolesApps: React.FC<UserRolesAppsProps> = ({
  user,
  control,
  selectedApp,
  setSelectedApp,
}) => {
  const { hasRoleSuperAdmin } = usePermissions();

  const apps = useSelector(selectUserAccessManage).rolesData.data;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const dataToConfirmRef = useRef<string[]>([]);

  const { appLabelMap } = useAppTranslations();
  const { formatMessage } = useIntl();

  const legacyAppsToDisplay = user.applicationTypeIds
    .filter(app => app !== ApplicationType.TON)
    .filter(app => !ModernizedApplicationType[app]);

  return (
    <>
      <div className={styles.apps} data-test-id="user-manage-applications">
        <h1>{formatMessage({ id: "general.applications" })}</h1>
        <p className={styles.description}>
          {formatMessage({ id: "userAccessManage.applications.description" })}
        </p>

        <div className={styles.cardsWrapper}>
          {apps?.map((app, index) => {
            return (
              <Controller
                name={
                  `applicationRoles.${app.applicationTypeId}` as `applicationRoles.applicationTypeId`
                }
                control={control}
                key={index}
                render={({ field: { value, onChange } }) => {
                  return (
                    <div>
                      <ActivityCard
                        value={value}
                        onChange={onChange}
                        appTypeId={app?.applicationTypeId}
                        app={app}
                        selectedApp={selectedApp}
                        setSelectedApp={setSelectedApp}
                        user={user}
                      />
                    </div>
                  );
                }}
              />
            );
          })}
          {legacyAppsToDisplay.map(app => (
            <div className={styles.legacyCard}>
              <Card
                title={
                  <>
                    <div>
                      <AppIcon type={app} />
                    </div>
                    <div className={styles.appName}>{appLabelMap[app]}</div>
                  </>
                }
              >
                <Toggle size="small" checked={true} disabled={true} />
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.roles}>
        <h1>
          {formatMessage({ id: "general.permissionsByRole" }).toUpperCase()}
        </h1>
        {selectedApp ? (
          <>
            <p className={styles.description}>
              {formatMessage({ id: "userAccessManage.roles.description" })}
            </p>
            <Label
              required
              title={formatMessage({ id: "general.roles" })}
              mode="horizontal"
            />
            <Controller
              name={
                `applicationRoles.${selectedApp}` as `applicationRoles.applicationTypeId`
              }
              control={control}
              key={selectedApp}
              rules={{
                validate: value => {
                  return Array.isArray(value) && value.length === 0
                    ? formatMessage({
                        id: "userAccessManage.applications.emptyRoles",
                      })
                    : true;
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <div className={styles.rolesTooltip}>
                    <Tooltip
                      placement="left"
                      text={formatMessage(
                        {
                          id: "userAccessManage.switchApp.confirm",
                        },
                        { br: <br /> }
                      )}
                      actions={[
                        {
                          text: formatMessage({ id: "general.no" }),
                          onClick: () => {
                            setConfirmationOpen(false);
                          },
                        },
                        {
                          text: formatMessage({ id: "general.yes" }),
                          onClick: () => {
                            onChange(dataToConfirmRef.current);
                            dataToConfirmRef.current = [];
                            setConfirmationOpen(false);
                          },
                        },
                      ]}
                      visible={confirmationOpen}
                    >
                      <Select
                        mode="multiple"
                        value={value}
                        onChange={(newValue: string[]) => {
                          if (newValue.length === 0 && value.length > 1) {
                            return;
                          }
                          onChange(newValue);
                        }}
                        options={apps
                          .find(app => app.applicationTypeId === selectedApp)
                          .roles.map(({ name, roleId }) => ({
                            name,
                            value: roleId,
                          }))}
                        allowClear
                        onClear={() => {
                          if (value.length > 1) {
                            setConfirmationOpen(true);
                          }
                        }}
                        error={error}
                        errorMessage={error?.message}
                        disabled={
                          selectedApp === ApplicationType.ADMINISTRATION &&
                          hasRoleSuperAdmin === false &&
                          user.isTraceOneCompany === true
                        }
                        data-test-id="user-manage-select-roles"
                      />
                    </Tooltip>
                  </div>
                );
              }}
            />
          </>
        ) : (
          <PermissionsPlaceholder />
        )}
      </div>
    </>
  );
};

export default UserRolesApps;
