import { createSelector } from "@reduxjs/toolkit";

import { RoleForCompanyData } from "models";

import { RootState } from "reduxStore";

/* istanbul ignore next */
export const _selectUserAccessManage = (state: RootState) =>
  state.userAccessManage;

export const selectUserAccessManage = createSelector(
  _selectUserAccessManage,
  ({ userData, rolesData, responsibilitiesData }) => ({
    userData,
    rolesData,
    responsibilitiesData,
  })
);

export const selectUserRolesForAppsMap = createSelector(
  _selectUserAccessManage,
  ({ rolesData: { data } }) => {
    return data.reduce<{
      [applicationTypeId: string]: RoleForCompanyData;
    }>(
      (prev, current) => ({
        ...prev,
        [current.applicationTypeId]: current,
      }),
      {}
    );
  }
);
