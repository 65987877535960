import { CheckableTag } from "@trace-one/react-components";
import { Row } from "antd";

import styles from "./CheckableTags.module.less";

export interface CheckableTagsProps<ValueType extends string = string> {
  value: ValueType[];
  onChange: (value: ValueType[]) => void;
  options: { value: ValueType; label: string; "data-test-id"?: string }[];
  asRadio?: boolean;
}

const CheckableTags = <ValueType extends string = string>({
  value: tags = [],
  onChange,
  options,
  asRadio,
}: CheckableTagsProps<ValueType>) => {
  return (
    <Row gutter={[0, 0]} className={styles.root}>
      {options.map(({ value, label, "data-test-id": dataTestId }) => {
        const checked = !!tags?.find(item => item === value);
        return (
          <CheckableTag
            key={value}
            value={label}
            checked={checked}
            onChange={() => {
              onChange(
                checked
                  ? tags.filter(item => item !== value)
                  : asRadio
                  ? [value]
                  : [...tags, value]
              );
            }}
            dataTestId={dataTestId ?? `tag-${value}`}
          />
        );
      })}
    </Row>
  );
};

export default CheckableTags;
