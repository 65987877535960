import { useIntl } from "react-intl";

import { RelationType } from "shared/constants";

export default function useRelationTypes() {
  const { formatMessage } = useIntl();

  const relationTypeLabels = {
    [RelationType.ANIMATOR]: formatMessage({
      id: "companyRelationList.relationType.animator",
    }),
    [RelationType.CONTRIBUTOR]: formatMessage({
      id: "companyRelationList.relationType.contributor",
    }),
  };

  const getRelationTypeOptions = (
    relationTypes?: RelationType[]
  ): { value: RelationType; label: string }[] => {
    if (relationTypes?.length > 0) {
      return relationTypes.map(type => ({
        value: type,
        label: relationTypeLabels[type],
      }));
    }

    return Object.entries(relationTypeLabels).map(([value, label]) => ({
      value: value as RelationType,
      label,
    }));
  };

  return {
    relationTypeLabels,
    getRelationTypeOptions,
  };
}
