import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { FilterProps, Picker } from "@trace-one/design-system";

import { SearchFiltersProps } from "components/SearchFilters";

import CheckableTags from "../../../../components/CheckableTags";
import FilterMultiSelection from "../../../../components/FilterMultiSelection";
import Select from "../../../../components/Select";
import {
  selectJobTitlesData,
  selectTeamMemberResponsibilitiesData,
} from "../../../../reduxStore/shared/selectors";
import { UserStatus } from "../../../../shared/constants";
import useAppTranslations from "../../../../shared/hooks/useAppTranslations";
// eslint-disable-next-line import/order
import { adjustIntercomButton } from "../../../../shared/utils/toggleIntercom";

import useUserStatuses from "../../hooks/useUserStatuses";

import { CompanyAdminUserFilter } from "./models";
import styles from "./UserSearch.module.less";

interface UserSearchProps {
  filterObj: CompanyAdminUserFilter;
  mergeFilters: (
    value:
      | Partial<CompanyAdminUserFilter>
      | ((prevState: CompanyAdminUserFilter) => CompanyAdminUserFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const UserSearch: React.FC<UserSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const showIntercom = useRef(true);

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  const { getUserStatusOptions } = useUserStatuses();

  const [applicationTypeIdsArr, setApplicationTypeIdsArr] = useState([]);

  const { appLabelOptions, appLabelMap } = useAppTranslations();

  const optionsToDisplay = appLabelOptions.filter(option => {
    return option["value"] !== "40";
  });

  const sortArray = (x, y) => x.label.localeCompare(y.label);
  const sortedOptionsToDisplay = optionsToDisplay.sort(sortArray);

  const teamMemberResponsibilitiesOptions = useSelector(
    selectTeamMemberResponsibilitiesData
  ).data.map(({ id, text }) => ({
    value: id,
    label: text,
  }));

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const jobTitlesMap = jobTitlesOptions.reduce<{
    [itemCode: string]: string;
  }>((prev, current) => ({ ...prev, [current.value]: current.label }), {});

  useEffect(() => {
    const {
      userStatuses,
      applicationTypeIds,
      userJobTitle,
      administratorFilterList,
      responsibilityId,
    } = filterObj;

    const appsTypes = applicationTypeIds
      ? appLabelOptions
          .filter(type => applicationTypeIds?.includes(type.value))
          .map(type => type.label)
      : [];

    const applicationTypeIdsArr = [];

    const defaultSelectedFilters = [
      userJobTitle && {
        filterId: "1",
        values: [jobTitlesMap[filterObj.userJobTitle]],
      },

      responsibilityId && {
        filterId: "2",
        values: [responsibilityId],
      },

      applicationTypeIds && {
        filterId: "3",
        values: appsTypes,
      },

      administratorFilterList && {
        filterId: "4",
        values: administratorFilterList.map(e => e.length),
      },

      userStatuses && {
        filterId: "5",
        values: userStatuses.map(e => e.length),
      },
    ].filter(i => !!i);

    if (filterObj.applicationTypeIds) {
      filterObj.applicationTypeIds.forEach(option => {
        applicationTypeIdsArr.push({
          value: option,
          label: appLabelMap[option],
          onCloseClick: () =>
            mergeFilters(prev => ({
              ...prev,
              applicationTypeIds: prev.applicationTypeIds.filter(
                appId => appId !== option
              ),
            })),
        });
      });
    }

    setSelectedFilters(defaultSelectedFilters);
    setApplicationTypeIdsArr(applicationTypeIdsArr);
  }, [filterObj]);

  // eslint-disable-next-line no-sparse-arrays
  const filters = [
    {
      title: formatMessage({ id: "general.jobTitle" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userJobTitle: undefined,
        });
      },
      elementToRender: (
        <Select
          value={filterObj.userJobTitle}
          onChange={userJobTitle => {
            mergeFilters({ userJobTitle });
          }}
          options={jobTitlesOptions}
          allowClear
          showSearch
          data-test-id="ad-companyAdmin-registered-users-filter-jobTitle"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "general.responsibilities" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          responsibilityId: undefined,
        });
      },
      elementToRender: (
        <Select
          value={filterObj.responsibilityId}
          onChange={responsibilityId => {
            mergeFilters({ responsibilityId });
          }}
          options={teamMemberResponsibilitiesOptions}
          allowClear
          showSearch
          data-test-id="ad-companyAdmin-registered-users-filter-responsabilities"
        />
      ),
      filterId: "2",
    },
    {
      title: formatMessage({ id: "general.applications" }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          applicationTypeIds: [],
        });
      },
      elementToRender: (
        <FilterMultiSelection
          dataTestId="ad-apps-selection"
          data-test-id="ad-companyAdmin-registered-users-filter-apps"
          options={sortedOptionsToDisplay}
          values={applicationTypeIdsArr}
          onChange={selectedValues => {
            mergeFilters({
              applicationTypeIds: selectedValues.length
                ? selectedValues?.map(e => e.value)
                : undefined,
            });
          }}
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.applications" })}
          showBadgeCount
          showSearchInput
          selectionType="multi"
          value={filterObj.applicationTypeIds ?? []}
          items={sortedOptionsToDisplay}
          onChange={({ value }) => {
            mergeFilters({
              applicationTypeIds: value.length ? value : undefined,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              applicationTypeIds: undefined,
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-companyAdmin-registered-users-filter-picker-apps"
        />
      ),
      filterId: "3",
    },

    {
      title: formatMessage({
        id: "general.administrator",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          administratorFilterList: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.administratorFilterList}
          onChange={administratorFilterList =>
            mergeFilters({
              administratorFilterList,
            })
          }
          options={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
          data-test-id="ad-companyAdmin-registered-users-filter-administrator"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.administrator",
          })}
          showBadgeCount
          selectionType="single"
          value={filterObj.administratorFilterList ?? []}
          items={[
            {
              value: "yes",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "no",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
          onChange={({ value }) => {
            mergeFilters({
              administratorFilterList: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              administratorFilterList: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-companyAdmin-registered-users-filter-picker-administrator"
        />
      ),
      filterId: "4",
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          userStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.userStatuses}
          onChange={userStatuses =>
            mergeFilters({
              userStatuses,
            })
          }
          options={getUserStatusOptions([
            UserStatus.ENABLED,
            UserStatus.DISABLED,
          ])}
          data-test-id="ad-companyAdmin-registered-users-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.userStatuses ?? []}
          items={getUserStatusOptions([
            UserStatus.ENABLED,
            UserStatus.DISABLED,
          ])}
          onChange={({ value }) => {
            mergeFilters({
              userStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              userStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-companyAdmin-registered-users-filter-picker-status"
        />
      ),
      filterId: "5",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "userList.toAdmin.searchPlaceholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default UserSearch;
