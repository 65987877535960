import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { Space } from "antd";
import useCompanyStatuses from "pages/Companies/hooks/useCompanyStatuses";

import StickyTabContainer from "components/StickyTabContainer";
import useCompanyActivityLabels from "shared/hooks/useCompanyActivityLabels";

import { CumdAPI } from "../../../../apis";
import { selectUserId } from "../../../../reduxStore/user/selectors";
import CompanyAdminGeneralInfo from "../../containers/CompanyAdminGeneralInfo";

const CompanyAdminDetailsPage = () => {
  const history = useHistory();
  const userId = useSelector(selectUserId);
  const { formatMessage } = useIntl();

  const { companyActivityLabels } = useCompanyActivityLabels();
  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();

  const [data, setData] = useState({ user: null, company: null });

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) return;

      try {
        const { data: userData } = await CumdAPI.getUserById(userId);
        setData(prev => ({ ...prev, user: userData }));

        const { data: companyData } = await CumdAPI.getCompanyById(
          userData.owningCompanyId
        );
        setData(prev => ({ ...prev, company: companyData }));
      } catch (error) {}
    };

    fetchData();
  }, [userId]);

  const tabList = [
    {
      label: formatMessage({ id: "general.generalInformation" }),
      content: <CompanyAdminGeneralInfo company={data?.company} />,
    },
  ];

  return (
    <StickyTabContainer
      title={
        <Space>
          <Tag
            label={companyStatusLabels[data?.company?.companyStatus]}
            color={companyStatusTagColors[data?.company?.companyStatus]}
            size="medium"
            mode="light"
          />
          <Tooltip title={data?.company?.companyDisplayName} placement="bottom">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                textTransform: "uppercase",
                marginLeft: "16px",
                maxWidth: "55ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data?.company?.companyDisplayName}
            </div>
          </Tooltip>
        </Space>
      }
      description={companyActivityLabels[data?.company?.companyActivityId]}
      onBack={() => {
        history.push("/users/list");
      }}
      tabs={tabList}
      companyId={data?.company?.companyId}
      companyStatus={data?.company?.companyStatus}
    />
  );
};

export default CompanyAdminDetailsPage;
