import { combineReducers, StateFromReducersMapObject } from "@reduxjs/toolkit";

import company from "./company/slice";
import companyChangeDunsForm from "./companyChangeDunsForm/slice";
import groupList from "./companyGroup/slice";
import companyList from "./companyList/slice";
import companyPendingList from "./companyPendingList/slice";
import companyRelationList from "./companyRelationList/slice";
import companySubscriptionForm from "./companySubscriptionForm/slice";
import companySubscriptionList from "./companySubscriptionList/slice";
import oidc from "./oidc/slice";
import pendingUser from "./pendingUser/slice";
import shared from "./shared/slice";
import user from "./user/slice";
import userAccessManage from "./userAccessManage/slice";
import userList from "./userList/slice";
import userPendingList from "./userPendingList/slice";

const rootReducer = {
  companyList,
  companyPendingList,
  companySubscriptionForm,
  companySubscriptionList,
  oidc,
  shared,
  user,
  userPendingList,
  userAccessManage,
  userList,
  companyRelationList,
  companyChangeDunsForm,
  pendingUser,
  company,
  groupList,
};

export type RootState = StateFromReducersMapObject<typeof rootReducer>;

export default combineReducers(rootReducer);
