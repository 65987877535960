import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Col, Typography } from "antd";

import Card from "components/Card";

import { CompanyForTraceoneAdminData } from "../../../../../models";

import styles from "./MainContactInfo.module.less";

interface MainContactInfoProps {
  company: CompanyForTraceoneAdminData;
}

const MainContactInfo: React.FC<MainContactInfoProps> = ({ company }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "general.isMainContact" }).toUpperCase()}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.name" })}
          </span>
          <div>
            {company?.mainContactUserName ? (
              <Typography.Link
                onClick={() => {
                  history.push(`/users/access/${company.mainContactUserId}`);
                }}
              >
                {company?.mainContactUserName}
              </Typography.Link>
            ) : (
              formatMessage({ id: "general.empty" })
            )}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.email" })}
          </span>
          <div>
            {company?.mainContactUserEmail
              ? company?.mainContactUserEmail
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.phone" })}
          </span>
          <div>
            {company?.mainContactUserPhone
              ? company?.mainContactUserPhone
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default MainContactInfo;
