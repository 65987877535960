import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { compose } from "@reduxjs/toolkit";

import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import ErrorPage from "../../../../components/ErrorPage";
import Spinner from "../../../../components/Spinner";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchCompany } from "../../../../reduxStore/companyChangeDunsForm/asyncActions";
import { selectCompanyChangeDunsForm } from "../../../../reduxStore/companyChangeDunsForm/selectors";
import CompanyChangeDunsForm from "../../containers/CompanyChangeDunsForm";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());
export const CompanyChangeDunsPage: React.FC<BaseHocProps> = ({
  isInitiated,
}) => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams<{ companyId?: string }>();

  const { companyData } = useSelector(selectCompanyChangeDunsForm);

  const [isLoading, setIsLoading] = useState(true);

  const refetchCompany = (companyId: string) => {
    return dispatch(fetchCompany({ companyId }));
  };

  useEffect(() => {
    Promise.all([refetchCompany(companyId)]).then(() => setIsLoading(false));
  }, [companyId]);

  if (companyData.hasError) {
    return <ErrorPage status={companyData.errorStatus} />;
  }

  if (isLoading /*|| !isInitiated*/) {
    return <Spinner underHeader />;
  }

  return <CompanyChangeDunsForm company={companyData.data} />;
};

export default enhance(CompanyChangeDunsPage);
