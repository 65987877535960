import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { compose, unwrapResult } from "@reduxjs/toolkit";
import { APPS_TO_DISPLAY } from "pages/Companies/constants";

import { useAppDispatch } from "reduxStore";
import {
  fetchCompany,
  fetchOffers,
  fetchSubscription,
} from "reduxStore/companySubscriptionForm/asyncActions";
import { selectCompanySubscriptionForm } from "reduxStore/companySubscriptionForm/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";
import { CompanyStatus } from "shared/constants";
import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import CompanySubscriptionForm from "../../containers/CompanySubscriptionForm";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());

export const CompanySubscriptionEditPage: React.FC<BaseHocProps> = ({
  isInitiated,
}) => {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams<{
    subscriptionId?: string;
  }>();

  const { companyData, offersData, subscriptionData } = useSelector(
    selectCompanySubscriptionForm
  );
  const [isLoading, setIsLoading] = useState(true);

  const refetchCompany = (companyId: string) => {
    return dispatch(fetchCompany({ companyId }));
  };

  const refetchOffers = (companyActivityId: number) => {
    return dispatch(
      fetchOffers({ applicationIds: APPS_TO_DISPLAY, companyActivityId })
    );
  };

  const refetchSubscription = () => {
    return dispatch(fetchSubscription({ subscriptionId }));
  };

  const refetchData = () => {
    return refetchSubscription()
      .then(unwrapResult)
      .then(({ subscription: { companyId } }) => refetchCompany(companyId))
      .then(unwrapResult)
      .then(({ company }) => refetchOffers(company.companyActivityId))
      .catch(() => {});
  };

  useEffect(() => {
    refetchData().then(() => {
      setIsLoading(false);
    });
  }, [subscriptionId]);

  if (
    subscriptionData.hasError ||
    companyData.hasError ||
    offersData.hasError
  ) {
    return (
      <ErrorPage
        status={
          subscriptionData.errorStatus ||
          companyData.errorStatus ||
          offersData.errorStatus
        }
      />
    );
  }

  if (isLoading || !isInitiated) {
    return <Spinner underHeader />;
  }

  if (companyData.data.companyStatus === CompanyStatus.ENABLED) {
    return (
      <CompanySubscriptionForm
        subscription={subscriptionData.data}
        company={companyData.data}
        disabledApps={[]}
        offersMap={offersData.data}
        appsToDisplay={[subscriptionData.data.applicationTypeId]}
        refetchData={refetchData}
      />
    );
  }

  return <ErrorPage status={403} />;
};

export default enhance(CompanySubscriptionEditPage);
