import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Button } from "@trace-one/design-system";
import { Col } from "antd";

import Card from "../../../../../components/Card";
import { CompanyForTraceoneAdminData } from "../../../../../models";

import styles from "./CurrentInfo.module.less";
import EditDunsName from "./EditDunsName";

interface CurrentInfoSectionProps {
  company: CompanyForTraceoneAdminData;
  companyType?: string;
  countryOptions?: any;
}

const CurrentInfoSection: React.FC<CurrentInfoSectionProps> = ({
  company,
  companyType,
  countryOptions,
}) => {
  const { formatMessage } = useIntl();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const country = countryOptions?.find(country => {
    return country.value === company.companyCountry;
  });

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({
          id: "general.currentInfo",
        })}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.companyDisplayName" })}
          </span>
          <div>{company.companyDisplayName}</div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.companyType" })}
          </span>
          <div>
            {company.companyActivityId
              ? companyType
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.infoWithBtn} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsName" })}
          </span>
          <div>
            {company.companyLegalName
              ? company.companyLegalName
              : formatMessage({ id: "general.empty" })}
          </div>
          {(company.companyDuns === null || company.companyDuns === "") && (
            <Button type="link" iconName="edit" onClick={openModal}>
              {formatMessage({ id: "general.edit" })}
            </Button>
          )}

          <EditDunsName
            company={company}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsNumber" })}
          </span>
          <div>
            {company.companyDuns
              ? company.companyDuns
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.address" })}
          </span>

          <div>{`${
            company.companyAddress1
              ? company.companyAddress1
              : formatMessage({ id: "general.empty" })
          },
           ${
             company.companyPostalCode
               ? company.companyPostalCode
               : formatMessage({ id: "general.empty" })
           },
           ${
             company.companyCity
               ? company.companyCity
               : formatMessage({ id: "general.empty" })
           }, ${
            company.companyCountry
              ? country?.name
              : formatMessage({ id: "general.empty" })
          }`}</div>
        </Col>
      </Card>
    </div>
  );
};

export default CurrentInfoSection;
