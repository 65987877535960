import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment/moment";

import Table from "../../../../components/Table";
import { useAppDispatch } from "../../../../reduxStore";
import { selectIsCompaniesLoading } from "../../../../reduxStore/companyList/selectors";
import { clearCompanies } from "../../../../reduxStore/companyList/slice";
import { fetchPendingCompanies } from "../../../../reduxStore/companyPendingList/asyncActions";
import {
  selectPendingCompanies,
  selectPendingCompaniesSkipAndTakeQueryStats,
} from "../../../../reduxStore/companyPendingList/selectors";
import { selectCompanyActivitiesData } from "../../../../reduxStore/shared/selectors";
import { CompanyStatus, showNewSearchForm } from "../../../../shared/constants";
import useSearchFilters from "../../../../shared/hooks/useSearchFilters";
import useTablePagination from "../../../../shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../../../shared/webStorage";
import CompanyTableHeader from "../CompanyList/CompanyTableHeader";
import { CompanyFilter } from "../CompanyList/models";

import CompanyPendingSearch from "./CompanyPendingSearch";
import useCompanyPendingTable from "./hooks/useCompanyPendingTable";
import OldCompanyPendingSearch from "./OldCompanyPendingSearch";

const PendingCompanyList = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState<string>("pendingCompanies");
  const pendingCompanies = useSelector(selectPendingCompanies);

  const pendingSkipAndTakeQueryStats = useSelector(
    selectPendingCompaniesSkipAndTakeQueryStats
  );
  const isCompaniesLoading = useSelector(selectIsCompaniesLoading);
  const isCompaniesActivitiesLoading = useSelector(
    selectCompanyActivitiesData
  ).isLoading;
  const isLoading = isCompaniesLoading || isCompaniesActivitiesLoading;

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats:
        show === "pendingCompanies" && pendingSkipAndTakeQueryStats,
      webStorage:
        show === "pendingCompanies" &&
        TablePaginationStorage.TO_ADMIN_PENDING_COMPANY_LIST,
    });

  let {
    searchText: pendingSearchText,
    setSearchText: pendingSetSearchText,
    mergeFilters: pendingMergeFilters,
    filterObj: pendingFilterObj,
    removeAllFiltersAndResetPageNumber:
      pendingRemoveAllFiltersAndResetPageNumber,
  } = useSearchFilters<CompanyFilter>(
    {
      applicationTypeIds: [],
      companyActivityId: undefined,
      companyStatuses: [CompanyStatus.PENDING],
      createdAt: undefined,
    },
    {
      clearedFilters: {
        applicationTypeIds: [],
        companyActivityId: undefined,
        companyStatuses: [],
        createdAt: undefined,
      },
      resetPageNumber,
      webStorage: SearchFiltersStorage.TO_ADMIN_PENDING_COMPANY_LIST,
    }
  );

  let filterObj = show === "pendingCompanies" && pendingFilterObj;
  const {
    companyActivityId,
    companyStatuses,
    companyLegalName,
    companyDuns,
    globalCompanyId,
    createdAt,
  } = filterObj;

  const refetchCompanies = useCallback(() => {
    const companyActivityIdNumber = Number(companyActivityId);

    const defaultPendingCompanyStatuses = [0, 1, 2];

    const _pendingCompanyStatuses = companyStatuses?.map(status => {
      if (status === "Pending") {
        return 0;
      } else if (status === "Rejected") {
        return 2;
      } else {
        return 1;
      }
    });

    show === "pendingCompanies" &&
      dispatch(
        fetchPendingCompanies({
          registrationStatuses:
            companyStatuses.find(item => item === "Pending") ||
            companyStatuses.find(item => item === "Rejected") ||
            companyStatuses.find(item => item === "Done")
              ? _pendingCompanyStatuses
              : defaultPendingCompanyStatuses,
          companyActivity: companyActivityId
            ? Number(companyActivityIdNumber)
            : undefined,
          companyLegalName,
          companyDuns,
          companySearchText: pendingSearchText,
          globalCompanyId,
          createdAt:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          skip: paginationQuery.skip,
          take: paginationQuery.take,
        })
      );
  }, [pendingSearchText, paginationQuery, show]);

  useEffect(() => {
    return () => {
      dispatch(clearCompanies(null));
    };
  }, []);

  useEffect(() => {
    refetchCompanies();
  }, [refetchCompanies]);

  const { pendingColumns, pendingCompaniesData } = useCompanyPendingTable({
    pendingCompanies,
    refetchCompanies,
  });

  return (
    <>
      {showNewSearchForm ? (
        <CompanyPendingSearch
          filterObj={filterObj}
          mergeFilters={pendingMergeFilters}
          initialSearchValue={pendingSearchText}
          onSearch={pendingSetSearchText}
          onClearFiltersClick={pendingRemoveAllFiltersAndResetPageNumber}
        />
      ) : (
        <OldCompanyPendingSearch
          filterObj={filterObj}
          mergeFilters={pendingMergeFilters}
          initialSearchValue={pendingSearchText}
          onSearch={pendingSetSearchText}
          onClearFiltersClick={pendingRemoveAllFiltersAndResetPageNumber}
        />
      )}

      <CompanyTableHeader
        showed={show}
        setChangeShow={setShow}
        totalCount={
          show === "pendingCompanies" && pendingSkipAndTakeQueryStats.totalCount
        }
        currentNumber={pendingSkipAndTakeQueryStats.currentCount ?? 0}
      />

      <Table
        columns={pendingColumns}
        dataSource={pendingCompaniesData}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={pendingSkipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />
    </>
  );
};

export default PendingCompanyList;
