import React from "react";

import { Title } from "@trace-one/react-components";
import { Typography } from "antd";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./FormSection.module.less";

export interface FormSectionProps
  extends Omit<React.HTMLProps<HTMLElement>, "title"> {
  title: React.ReactNode;
  description?: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  children,
  ...rest
}) => {
  return (
    <section {...rest} className={cn(styles.root, rest.className)}>
      <Title level={2} value={title} />
      {description && (
        <Typography.Paragraph>{description}</Typography.Paragraph>
      )}
      {children}
    </section>
  );
};

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default FormSection;
