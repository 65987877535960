import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { compose } from "@reduxjs/toolkit";
import { Container } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import { useAppDispatch } from "reduxStore";
import { fetchCompaniesForSearch } from "reduxStore/userList/asyncActions";

import Spinner from "components/Spinner";
import {
  BaseHocProps,
  withCompanyActivities,
  withJobTitles,
  withTeamMemberResponsibilities,
} from "shared/hocs";

import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import TraceoneAdminUserList from "../../containers/TraceoneAdminUserList";

import styles from "./TraceoneAdminUserListPage.module.less";

interface TraceoneAdminUserListPageProps extends BaseHocProps {}

const enhance = compose<React.FC<TraceoneAdminUserListPageProps>>(
  withJobTitles(),
  withCompanyActivities(),
  withTeamMemberResponsibilities({ includeParentItems: true })
);

export const TraceoneAdminUserListPage: React.FC<
  TraceoneAdminUserListPageProps
> = ({ isInitiated }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const userLanguageCode = useSelector(selectUserLanguageCode);

  useEffect(() => {
    dispatch(fetchCompaniesForSearch({}));
  }, []);

  return (
    <Container isPageWrapper>
      <div className={styles.titleWrapper} data-test-id={`${userLanguageCode}`}>
        <Title
          value={formatMessage({
            id: "general.registeredUsers",
          }).toUpperCase()}
          level={1}
        />
      </div>
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <TraceoneAdminUserList />
      </Spinner>
    </Container>
  );
};

export default enhance(TraceoneAdminUserListPage);
