import { Container } from "@trace-one/design-system";

import styles from "./StickyContainer.module.less";
import StickyHeader, { StickyHeaderProps } from "./StickyHeader";

export interface StickyContainerProps extends StickyHeaderProps {
  component?: React.ElementType;
  headerChildren?: React.ReactNode;
}

const StickyContainer: React.FC<StickyContainerProps> = ({
  children,
  component: Component = "div",
  headerChildren,
  ...rest
}) => {
  return (
    <Component className={styles.root}>
      <StickyHeader {...rest} children={headerChildren} />
      <Container className={styles.content}>{children}</Container>
    </Component>
  );
};

export default StickyContainer;
