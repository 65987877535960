import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Col, Typography } from "antd";

import Card from "../../../../../components/Card";

import styles from "./MainInformationCard.module.less";

interface MainInformationCardProps {
  userInfo: any;
  companyType: string;
  productCategories: string[];
}

const MainInformationCard: React.FC<MainInformationCardProps> = ({
  userInfo,
  companyType,
  productCategories,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "general.mainInformation" }).toUpperCase()}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.companyDisplayName" })}
          </span>
          <div>
            {userInfo.data?.companyLegalName
              ? userInfo.data.companyLegalName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.companyType" })}
          </span>
          <div>
            {companyType ? companyType : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.productCategories" })}
          </span>
          <div>
            {userInfo.data?.companyCategories?.length > 0
              ? productCategories
                  .map(category => category["categoryItemName"])
                  .join(", ")
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyList.companyGUID" })}
          </span>
          {userInfo.data?.registrationStatus === 1 ? (
            <Typography.Link
              onClick={() => {
                history.push(`/companies/${userInfo.data?.owningCompanyId}`);
              }}
            >
              {userInfo.data?.owningCompanyId
                ? userInfo.data?.owningCompanyId
                : formatMessage({ id: "general.empty" })}
            </Typography.Link>
          ) : (
            <div>
              {userInfo.data?.owningCompanyId
                ? userInfo.data?.owningCompanyId
                : formatMessage({ id: "general.empty" })}
            </div>
          )}
          {/*<div>*/}
          {/*  {userInfo.data?.owningCompanyId*/}
          {/*    ? userInfo.data?.owningCompanyId*/}
          {/*    : formatMessage({ id: "general.empty" })}*/}
          {/*</div>*/}
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.email" })}
          </span>
          <div>
            {userInfo.data?.companyEmail
              ? userInfo.data?.companyEmail
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.phone" })}
          </span>
          <div>
            {userInfo.data?.companyPhone
              ? userInfo.data?.companyPhone
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.fax" })}
          </span>
          <div>
            {userInfo.data?.companyFax
              ? userInfo.data?.companyFax
              : formatMessage({ id: "general.empty" })}{" "}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default MainInformationCard;
