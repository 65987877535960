import React from "react";
import { useIntl } from "react-intl";

import { Picker, Button } from "@trace-one/design-system";

import styles from "./CompanyTypeSelection.module.less";

interface CompanyTypeSelectionProps {
  setStepIndex: (step: number) => void;
  isCompanyCardSelected?: boolean;
  setIsCompanyCardSelected?: (boolean) => void;
  isTestCompanyCardSelected?: boolean;
  setIsTestCompanyCardSelected?: (boolean) => void;
}

const CompanyTypeSelection: React.FC<CompanyTypeSelectionProps> = ({
  setStepIndex,
  isCompanyCardSelected,
  setIsCompanyCardSelected,
  isTestCompanyCardSelected,
  setIsTestCompanyCardSelected,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.companyTypeSection}>
      <div className={styles.cardWrapper}>
        <Picker
          title={formatMessage({ id: "general.company" })}
          illustrationName="supplier"
          data-test-id="company-select-card"
          selected={isCompanyCardSelected}
          onClick={() => {
            setIsCompanyCardSelected(!isCompanyCardSelected);
            setIsTestCompanyCardSelected(false);
          }}
        />
        <div className={styles.divider} />
        <Picker
          title={formatMessage({ id: "general.testCompany" })}
          illustrationName="settings"
          data-test-id="test-company-select-card"
          selected={isTestCompanyCardSelected}
          onClick={() => {
            setIsTestCompanyCardSelected(!isTestCompanyCardSelected);
            setIsCompanyCardSelected(false);
          }}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            setStepIndex(1);
          }}
          disabled={!isTestCompanyCardSelected && !isCompanyCardSelected}
        >
          {formatMessage({ id: "general.next" })}
        </Button>
      </div>
    </div>
  );
};

export default CompanyTypeSelection;
