import React from "react";
import { useIntl } from "react-intl";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd/dist/models";
import { Datatable, Graphic, Badge } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";

import buildTableHandler from "../../../../../components/Table/utils/buildTableHandler";
import calculatePagination from "../../../../../components/Table/utils/calculatePagination";

import styles from "./RegisteredUsers.module.less";

interface RegisteredUsersProps {
  users: UserForUIAdmin[];
  setPaginationQuery?: any;
  skipAndTakeQueryStats?: any;
}

const RegisteredUsers: React.FC<RegisteredUsersProps> = ({
  users,
  setPaginationQuery,
  skipAndTakeQueryStats,
}) => {
  const { formatMessage } = useIntl();

  const tableColumns = [
    {
      title: formatMessage({ id: "general.name" }),
      dataIndex: "name",
    },
    {
      title: formatMessage({ id: "general.email" }),
      dataIndex: "email",
    },
  ];

  const usersData = users.map(user => ({
    key: user.userId,
    name: (
      <div className={styles.tableUserName}>
        {user.isAccountAdministrator && <Graphic name="admin" />}
        <div>
          {user.userFirstName} {user.userLastName}
          {user.isMainContact && (
            <Badge
              color="green-dark"
              placement="topRight"
              count={formatMessage({ id: "general.isMainContact" })}
            />
          )}
        </div>
      </div>
    ),
    email: user.userLogin,
  }));

  const skip = skipAndTakeQueryStats?.skip;
  const take = skipAndTakeQueryStats?.take;

  const pagination = calculatePagination({ skip, take, skipAndTakeQueryStats });

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage(
          { id: "userCreate.registeredUsers" },
          { total: skipAndTakeQueryStats.totalCount }
        )}
        collapsible
      >
        <Datatable.Plain
          dataSource={usersData}
          columns={tableColumns}
          pagination={pagination}
          onChange={buildTableHandler({ setPaginationQuery })}
        />
      </Card>
    </div>
  );
};

export default RegisteredUsers;
