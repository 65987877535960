import { memo } from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./TableHeader.module.less";

export interface HeaderProps extends React.HTMLProps<HTMLDivElement> {
  listingResult: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  children,
  listingResult,
  ...rest
}) => {
  return (
    <div {...rest} className={cn(styles.root, rest.className)}>
      <p className={styles.listingResult}>{listingResult}</p>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  listingResult: PropTypes.string.isRequired,
};
Header.displayName = "Table.Header";

export default memo(Header);
