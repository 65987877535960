import React from "react";

import { Company } from "@trace-one/api-clients.cumd/dist/models/company";

import CompanySelectList from "./CompanySelectList";
import CompanySelectNoResult from "./CompanySelectNoResult";
import CompanySelectPlaceholder from "./CompanySelectPlaceholder";

interface CompanySelectProps {
  companies: Company[] | null;
  selectedCompanyId: string;
  setSelectedCompanyId: (selectedCompanyId: string) => void;
  handleSubmit: () => void;
}

const CompanySelect: React.FC<CompanySelectProps> = ({
  companies,
  selectedCompanyId,
  setSelectedCompanyId,
  handleSubmit,
}) => {
  if (companies === null) {
    return <CompanySelectPlaceholder />;
  }

  if (companies.length === 0) {
    return <CompanySelectNoResult />;
  } else {
    return (
      <CompanySelectList
        companies={companies}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={setSelectedCompanyId}
        handleSubmit={handleSubmit}
      />
    );
  }
};

export default CompanySelect;
