import { OfferData } from "models";

import { OfferType } from "shared/constants";

function splitOffers(offers: OfferData[]) {
  let defaultOffer: OfferData;
  const addOnOffers: OfferData[] = [];

  offers.forEach(offer => {
    if (offer.offerType === OfferType.DEFAULT) {
      defaultOffer = offer;
    } else {
      addOnOffers.push(offer);
    }
  });
  return { defaultOffer, addOnOffers };
}

export default splitOffers;
