import AppIcon from "components/AppIcon";
import { ApplicationType, ModernizedApplicationType } from "shared/constants";

export interface AppIconsProps {
  applications: number[];
  excludedApps?: ApplicationType[];
  modernizedOnly?: boolean;
}

const AppIcons: React.FC<AppIconsProps> = ({
  applications,
  excludedApps,
  modernizedOnly,
}) => {
  let applicationList = applications?.filter(
    app => !excludedApps?.some(excludedApp => excludedApp === app)
  );

  if (modernizedOnly) {
    applicationList = applicationList?.filter(
      app => !!ModernizedApplicationType[app]
    );
  }

  return (
    <>
      {applicationList?.map(app => (
        <AppIcon key={app} type={app} spacing />
      ))}
    </>
  );
};

export default AppIcons;
