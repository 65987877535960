import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Stepper } from "@trace-one/design-system";
import { toaster } from "@trace-one/design-system";

import { CumdAPI } from "../../../../apis";
import StickyContainer from "../../../../components/StickyContainer";
import { CompanyForTraceoneAdminData } from "../../../../models";
import { useAppDispatch } from "../../../../reduxStore";
import {
  fetchCountries,
  fetchJobTitles,
  fetchLanguages,
} from "../../../../reduxStore/shared/asyncActions";
import {
  selectCountriesData,
  selectJobTitlesData,
  selectLanguagesData,
} from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { CompanyCountry } from "../../../../shared/constants";
import { ErrorCode } from "../../../../shared/errors";

import CompanyInformation from "./CompanyInformation/CompanyInformation";
import CompanyTypeSelection from "./CompanyTypeSelection/CompanyTypeSelection";
import styles from "./CreateCompany.module.less";
import TestCompanyInformation from "./TestCompanyInformation/TestCompanyInformation";
import TestUserInformation from "./TestUserInformation/TestUserInformation";
import UserInformation from "./UserInformation/UserInformation";

interface CreateCompanyProps {
  company: CompanyForTraceoneAdminData;
}

const CreateCompany: React.FC<CreateCompanyProps> = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const languageCode = useSelector(selectUserLanguageCode);
  const countries = useSelector(selectCountriesData);

  // STATE FOR COMPANY TYPE SELECTION
  const [isCompanyCardSelected, setIsCompanyCardSelected] =
    useState<boolean>(false);
  const [isTestCompanyCardSelected, setIsTestCompanyCardSelected] =
    useState<boolean>(false);

  // STATE FOR FORM ELEMENTS - COMPANY
  const [companyId, setCompanyId] = useState();
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");
  const [companyActivity, setCompanyActivity] = useState(null);
  const [category, setCategory] = useState([]);

  const [companyValue, setCompanyValue] = useState(null);
  const [companySearchValue, setCompanySearchValue] = useState<string>("");

  // STATE FOR FORM ELEMENTS - COMPANY TEST
  const [testCompanyId, setTestCompanyId] = useState();
  const [testCompanyName, setTestCompanyName] = useState("");
  const [testCountry, setTestCountry] = useState(null);
  const [countryDivision, setCountryDivision] = useState(null);
  const [testCity, setTestCity] = useState("");
  const [testPostalCode, setTestPostalCode] = useState("");
  const [testAddress, setTestAddress] = useState("");
  const [identifiers, setIdentifiers] = useState<
    { id: string; value: string }[]
  >([]);
  const [testCompanyActivity, setTestCompanyActivity] = useState(null);
  const [testCategory, setTestCategory] = useState([]);

  // STATE FOR FORM ELEMENTS - USER
  const [title, setTitle] = useState(null);
  const [jobTitle, setJobTitle] = useState<number>(undefined);
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [language, setLanguage] = useState<string>("en-US");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [confirmationEmail, setConfirmationEmail] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(null);
  const [emailErrorCode, setEmailErrorCode] = useState<string>(null);

  // STATE FOR FORM ELEMENTS - TEST USER
  const [testTitle, setTestTitle] = useState(null);
  const [testJobTitle, setTestJobTitle] = useState<number>(undefined);
  const [testLastName, setTestLastName] = useState<string>("");
  const [testFirstName, setTestFirstName] = useState<string>("");
  const [testLanguage, setTestLanguage] = useState<string>("en-US");
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>("");
  const [testEmail, setTestEmail] = useState<string>("");
  const [testConfirmationEmail, setTestConfirmationEmail] =
    useState<string>("");
  const [testEmailIsValid, setTestEmailIsValid] = useState<boolean>(null);
  const [testEmailErrorCode, setTestEmailErrorCode] = useState<string>(null);

  const countryOptions = countries.data.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const sortedCountriesOptions = countryOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const languageOptions = useSelector(selectLanguagesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
    dispatch(
      fetchJobTitles({
        languageCode,
      })
    );
    dispatch(
      fetchLanguages({
        languageCode,
      })
    );
  }, []);

  // STEPPER
  const stepsList = [
    {
      title: formatMessage({ id: "general.companyType" }),
      isCompleted: false,
      currentIsValid: true,
      onClick: () => {
        setStepIndex(0);
        setCountry(null);
        setCity("");
        setPostalCode("");
        setAddress("");
        setCompanyActivity(null);
        setCategory([]);
        setCompanyValue(null);
        setCompanySearchValue("");
        setTestCompanyName("");
        setTestCountry(null);
        setCountryDivision(null);
        setTestCity("");
        setTestPostalCode("");
        setTestAddress("");
        setIdentifiers([]);
        setTestCompanyActivity(null);
        setTestCategory([]);
      },
    },
    {
      title: formatMessage({ id: "general.companyInfo" }),
      isCompleted: false,
      currentIsValid: true,
      onClick: () => {
        setStepIndex(1);
      },
    },
    {
      title: formatMessage({ id: "general.userInfo" }),
      isCompleted: false,
      currentIsValid: true,
      onClick: () => {
        setStepIndex(2);
      },
    },
  ];

  const [stepIndex, setStepIndex] = useState(0);

  const validateEmail = email => {
    return CumdAPI.checkUserEmailAlreadyExists(email).then(({ data }) => {
      setEmailIsValid(data.isValid);
      setEmailErrorCode(data.errorCode);

      setTestEmailIsValid(data.isValid);
      setTestEmailErrorCode(data.errorCode);

      switch (data.errorCode) {
        case ErrorCode.EMAIL_ALREADY_EXISTS:
          toaster.open({
            message: formatMessage({
              id: "companyCreate.firstUserInfo.toast.title",
            }),
            description: formatMessage({
              id: "companyCreate.firstUserInfo.toast.description",
            }),
            type: "alert",
          });
          break;
        case ErrorCode.EMAIL_INVALID:
          toaster.open({
            message: formatMessage({
              id: "toast.error.invalid.email.title",
            }),
            description: formatMessage({
              id: "toast.error.invalid.email.description",
            }),
            type: "alert",
          });
          break;
      }

      return data.isValid;
    });
  };

  // SUBMIT AT THE END - CREATE COMPANY & USER
  const handleCreateUserAndCompany = async () => {
    const emailIsValid = await validateEmail(email);

    if (!emailIsValid) {
      return;
    }

    try {
      await CumdAPI.createUserAndCompany({
        globalCompanyId: companyId,
        companyCountry: country,
        companyState:
          country === CompanyCountry.CANADA ||
          country === CompanyCountry.UNITEDSTATES
            ? companyValue?.companyState
            : null,
        companyCity: companyValue.companyCity,
        companyPostalCode: companyValue.companyPostalCode,
        companyAddress: companyValue.companyAddress,
        companyDuns: companyValue?.companyDuns,
        companyDisplayName: companyValue?.companyName,
        companyLegalName: companyValue?.companyName,
        companyActivity: Number(companyActivity),
        companyCategories: category.map(item => ({
          categoryId: item.categoryId,
          categoryItemId: item.categoryItemId,
        })),
        companyIdentifiers: [
          {
            typeId: "c72de667-9229-43ef-bf82-368915a061f9",
            value: companyValue?.companySiret,
          },
          {
            typeId: "234277f6-0501-4148-bb74-935a08c055ac",
            value: companyValue?.companyVat,
          },
        ],
        userCivility: title,
        userJobTitle: Number(jobTitle),
        userLastName: lastName,
        userFirstName: firstName,
        userLanguagePreference: language,
        userPhoneNumber: phoneNumber,
        userEmail: email,
      });
    } catch (error) {
      toaster.open({
        message: formatMessage({
          id: "general.error",
        }),
        description: formatMessage({
          id: "companyCreate.firstUserInfo.toast.save.error",
        }),
        type: "alert",
      });
    }
  };

  // SUBMIT AT THE END - CREATE TEST COMPANY & USER
  const handleCreateTestUserAndCompany = async () => {
    const testEmailIsValid = await validateEmail(testEmail);

    if (!testEmailIsValid) {
      return;
    }

    try {
      await CumdAPI.createUserAndCompany({
        globalCompanyId: testCompanyId,
        companyCountry: testCountry,
        companyState: countryDivision,
        companyCity: testCity,
        companyPostalCode: testPostalCode,
        companyAddress: testAddress,
        // companyDuns: companyValue?.companyDuns,
        companyDisplayName: testCompanyName,
        companyLegalName: testCompanyName,
        companyActivity: Number(testCompanyActivity),
        companyCategories: testCategory.map(item => ({
          categoryId: item.categoryId,
          categoryItemId: item.categoryItemId,
        })),
        testCompany: true,
        companyIdentifiers: identifiers.map(item => ({
          typeId: item.id,
          value: item.value,
        })),
        userCivility: testTitle,
        userJobTitle: Number(testJobTitle),
        userLastName: testLastName,
        userFirstName: testFirstName,
        userLanguagePreference: testLanguage,
        userPhoneNumber: testPhoneNumber,
        userEmail: testEmail,
      });
    } catch (error) {
      toaster.open({
        message: formatMessage({
          id: "general.error",
        }),
        description: formatMessage({
          id: "companyCreate.firstUserInfo.toast.save.error",
        }),
        type: "alert",
      });
    }
  };

  return (
    <div className={styles.root}>
      <StickyContainer
        title={formatMessage({
          id: "general.createCompany",
        })}
        layoutMode="read"
        onBack={() => {
          history.push(`/companies/list`);
        }}
      >
        <div className={styles.innerContainer}>
          <div className={styles.stepper}>
            <Stepper steps={stepsList} stepIndex={stepIndex}></Stepper>
          </div>

          <div className={styles.sectionWrapper}>
            {stepIndex === 0 && (
              <CompanyTypeSelection
                setStepIndex={setStepIndex}
                isCompanyCardSelected={isCompanyCardSelected}
                setIsCompanyCardSelected={setIsCompanyCardSelected}
                isTestCompanyCardSelected={isTestCompanyCardSelected}
                setIsTestCompanyCardSelected={setIsTestCompanyCardSelected}
              />
            )}
            {stepIndex === 1 && isCompanyCardSelected && (
              <CompanyInformation
                setStepIndex={setStepIndex}
                isCompanyCardSelected={isCompanyCardSelected}
                setCompanyId={setCompanyId}
                countryOptions={sortedCountriesOptions}
                country={country}
                setCountry={setCountry}
                city={city}
                setCity={setCity}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                address={address}
                setAddress={setAddress}
                companyActivity={companyActivity}
                setCompanyActivity={setCompanyActivity}
                category={category}
                setCategory={setCategory}
                companyValue={companyValue}
                setCompanyValue={setCompanyValue}
                companySearchValue={companySearchValue}
                setCompanySearchValue={setCompanySearchValue}
              />
            )}

            {stepIndex === 1 && isTestCompanyCardSelected && (
              <TestCompanyInformation
                setStepIndex={setStepIndex}
                isTestCompanyCardSelected={isTestCompanyCardSelected}
                setTestCompanyId={setTestCompanyId}
                testCompanyName={testCompanyName}
                setTestCompanyName={setTestCompanyName}
                countryOptions={sortedCountriesOptions}
                testCountry={testCountry}
                setTestCountry={setTestCountry}
                countryDivision={countryDivision}
                setCountryDivision={setCountryDivision}
                testCity={testCity}
                setTestCity={setTestCity}
                testPostalCode={testPostalCode}
                setTestPostalCode={setTestPostalCode}
                testAddress={testAddress}
                setTestAddress={setTestAddress}
                identifiers={identifiers}
                setIdentifiers={setIdentifiers}
                testCompanyActivity={testCompanyActivity}
                setTestCompanyActivity={setTestCompanyActivity}
                testCategory={testCategory}
                setTestCategory={setTestCategory}
              />
            )}

            {stepIndex === 2 && isCompanyCardSelected && (
              <UserInformation
                companyId={companyId}
                companyValue={companyValue}
                jobTitlesOptions={jobTitlesOptions}
                languageOptions={languageOptions}
                setTitle={setTitle}
                title={title}
                setJobTitle={setJobTitle}
                jobTitle={jobTitle}
                setLastName={setLastName}
                lastName={lastName}
                setFirstName={setFirstName}
                firstName={firstName}
                setLanguage={setLanguage}
                language={language}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
                setEmail={setEmail}
                email={email}
                setConfirmationEmail={setConfirmationEmail}
                confirmationEmail={confirmationEmail}
                emailIsValid={emailIsValid}
                emailErrorCode={emailErrorCode}
                setIsValid={setEmailIsValid}
                handleCreateUserAndCompany={handleCreateUserAndCompany}
              />
            )}
            {stepIndex === 2 && isTestCompanyCardSelected && (
              <TestUserInformation
                testCompanyId={testCompanyId}
                testCompanyName={testCompanyName}
                jobTitlesOptions={jobTitlesOptions}
                languageOptions={languageOptions}
                setTestTitle={setTestTitle}
                testTitle={testTitle}
                setTestJobTitle={setTestJobTitle}
                testJobTitle={testJobTitle}
                setTestLastName={setTestLastName}
                testLastName={testLastName}
                setTestFirstName={setTestFirstName}
                testFirstName={testFirstName}
                setTestLanguage={setTestLanguage}
                testLanguage={testLanguage}
                testPhoneNumber={testPhoneNumber}
                setTestPhoneNumber={setTestPhoneNumber}
                setTestEmail={setTestEmail}
                testEmail={testEmail}
                setTestConfirmationEmail={setTestConfirmationEmail}
                testConfirmationEmail={testConfirmationEmail}
                testEmailIsValid={testEmailIsValid}
                testEmailErrorCode={testEmailErrorCode}
                setIsValid={setTestEmailIsValid}
                handleCreateTestUserAndCompany={handleCreateTestUserAndCompany}
              />
            )}
          </div>
        </div>
      </StickyContainer>
    </div>
  );
};

export default CreateCompany;
