import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Heading,
  Select,
  Input,
  Graphic,
  Tooltip,
} from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Divider, Form, Typography } from "antd";

import { CumdAPI } from "../../../../../apis";
import AsyncSearchSelect from "../../../../../components/AsyncSearchSelect";
import FoundedInfo from "../../CompanyChangeDunsForm/ChangeDuns/FoundedInfo";

import styles from "./AddDunsSection.module.less";

interface AddDunsSectionProps {
  userInfo: any;
  countryOptions?: object[];
  setSaveEnabled?: (boolean) => void;
  companyValue?: any;
  setCompanyValue?: (any) => void;
}

const AddDunsSection: React.FC<AddDunsSectionProps> = ({
  userInfo,
  countryOptions,
  setSaveEnabled,
  companyValue,
  setCompanyValue,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");

  const [companySearchValue, setCompanySearchValue] = useState<string>("");

  const getCompanyOptions = () => {
    return CumdAPI.getCompanyByDunsFilters({
      companyName: null,
      searchTerm: companySearchValue,
      companyCountry: country,
      companyCity: city,
      companyPostalCode: zipCode,
      companyAddress: address,
    }).then(({ data }) => {
      return data.map(company => {
        const isExistingCompany =
          company.companyId !== null &&
          company.companyId !== userInfo.owningCompanyId;

        let label;
        if (company.companyId !== null) {
          label = (
            <Tooltip
              text={
                company?.companyState !== null
                  ? `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
                  : `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`
              }
            >
              <div className={styles.labelWrapper}>
                <div>
                  {company?.companyState !== null ? (
                    <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`}</div>
                  ) : (
                    <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`}</div>
                  )}
                </div>
                {window.env.DISABLE_SIGNUP_FEATURE === "true" ? (
                  <span className={styles.icon}>
                    {company.companyId === userInfo.owningCompanyId ? (
                      <Graphic name="success" />
                    ) : (
                      <Graphic name="alert" />
                    )}
                  </span>
                ) : (
                  <span className={styles.icon}>
                    {company.companyId === userInfo.owningCompanyId ? (
                      <Graphic name="success" />
                    ) : (
                      <Graphic name="warning" />
                    )}
                  </span>
                )}
              </div>
            </Tooltip>
          );
        } else {
          label =
            company?.companyState !== null
              ? `${company.companyName} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
              : `${company.companyName} - ${company.companyPostalCode} - ${company.companyAddress}`;
        }

        return {
          value: company.companyDuns,
          label,
          isExistingCompany: isExistingCompany,
          ...company,
        };
      });
    });
  };

  useEffect(() => {
    window.env.DISABLE_SIGNUP_FEATURE === "true"
      ? setSaveEnabled(companyValue && !companyValue.isExistingCompany)
      : setSaveEnabled(companyValue);
  }, [companyValue]);

  const helpMessage =
    window.env.DISABLE_SIGNUP_FEATURE === "true" ? (
      <div className={styles.helpMessage}>
        <span>
          {formatMessage({
            id: "companyCreateDuns.changeDuns.selectCompanyName.errorText",
          })}
        </span>
        <br />
        <div>
          <span className={styles.helpMessageExtraText}>
            {formatMessage({
              id: "companyCreateDuns.changeDuns.selectCompanyName.errorAdditionalText",
            })}
          </span>
          <Typography.Link
            onClick={() => {
              history.push(`/companies/${companyValue.companyId}`);
            }}
          >
            {companyValue?.companyName}
          </Typography.Link>
        </div>
      </div>
    ) : (
      <div className={styles.helpMessageExistingCompany}>
        <span>
          {formatMessage({
            id: "companyAddDuns.validateIfExistedCompany",
          })}
        </span>
      </div>
    );

  const showFoundedInfo = () => {
    if (window.env.DISABLE_SIGNUP_FEATURE === "false") {
      if (companyValue !== null && companyValue !== undefined) {
        return (
          <>
            <Divider dashed />
            <FoundedInfo company={companyValue} />
          </>
        );
      }
    }
    if (window.env.DISABLE_SIGNUP_FEATURE === "true") {
      if (companyValue && !companyValue.isExistingCompany) {
        return (
          <>
            <Divider dashed />
            <FoundedInfo company={companyValue} />
          </>
        );
      }
    }
  };

  return (
    <div className={styles.root}>
      <Heading size="xxs">
        {formatMessage({
          id: "companyAddDuns.addDuns.title",
        })}
      </Heading>

      <div className={styles.fieldWrapper}>
        <Label title={formatMessage({ id: "general.country" })} required>
          <Form.Item
            name="country"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "general.error.mandatory.field",
                }),
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder={formatMessage({ id: "general.selectValue" })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={countryOptions}
              onChange={setCountry}
            />
          </Form.Item>
        </Label>
      </div>

      <div className={styles.innerFieldWrapper}>
        <div className={styles.smallInput}>
          <Label title={formatMessage({ id: "general.city" })}>
            <Form.Item name="city">
              <Input
                placeholder={formatMessage({
                  id: "companyCreateDuns.changeDuns.selectCity.placeholder",
                })}
                onChange={event => {
                  setCity(event.target.value);
                }}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.divider} />
        <div className={styles.smallInput}>
          <Label title={formatMessage({ id: "general.zipcode" })}>
            <Form.Item name="zipCode">
              <Input
                placeholder={formatMessage({
                  id: "companyCreateDuns.changeDuns.selectZipCode.placeholder",
                })}
                onChange={event => {
                  setZipCode(event.target.value);
                }}
              />
            </Form.Item>
          </Label>
        </div>
      </div>

      <div className={styles.fieldWrapper}>
        <Label title={formatMessage({ id: "general.address" })}>
          <Form.Item name="address">
            <Input
              placeholder={formatMessage({
                id: "companyCreateDuns.changeDuns.selectAddress.placeholder",
              })}
              onChange={event => {
                setAddress(event.target.value);
              }}
            />
          </Form.Item>
        </Label>
      </div>

      <div className={styles.fieldWrapper}>
        <Label
          title={formatMessage({
            id: "companyCreateDuns.changeDuns.selectCompanyName",
          })}
          required
        >
          <Form.Item
            name="companyName"
            validateStatus={companyValue?.isExistingCompany ? "error" : null}
            help={companyValue?.isExistingCompany ? helpMessage : null}
          >
            <AsyncSearchSelect
              disabled={!country}
              allowClear
              showSearch
              placeholder={formatMessage({
                id: "companyAddDuns.addDuns.selectCompanyName.placeholder",
              })}
              value={companyValue}
              searchValue={companySearchValue}
              onSearch={setCompanySearchValue}
              onChange={(value, option) => {
                setCompanyValue(option);
              }}
              onAsyncSearch={getCompanyOptions}
              minLengthToSearch={3}
              labelInValue
            />
          </Form.Item>
        </Label>
      </div>
      {showFoundedInfo()}
    </div>
  );
};

export default AddDunsSection;
