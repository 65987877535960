import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { Select } from "@trace-one/design-system";
import { Spinner, Empty } from "@trace-one/react-components";
import { SelectProps } from "antd";

import ShortMsg from "components/ShortMsg";
import useDebounce from "shared/hooks/useDebounce";
import useToast from "shared/hooks/useToast";
import { SelectOption } from "shared/typings";

export interface AsyncSearchSelectProps extends SelectProps<any> {
  minLengthToSearch?: number;
  defaultOptions?: SelectOption[];

  onAsyncSearch: ({
    searchValue,
  }: {
    searchValue: string;
  }) => Promise<SelectOption[]>;
}

// The reason why we don't use autocomplete?
// Because it's hopeless
const AsyncSearchSelect: React.FC<AsyncSearchSelectProps> = ({
  value,
  searchValue,
  minLengthToSearch,
  onSearch,
  onBlur,
  defaultOptions,
  onAsyncSearch,
  ...rest
}) => {
  const intl = useIntl();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const optionsRef = useRef<SelectOption[]>([]);

  const debouncedSearchValue = useDebounce(searchValue);

  const isMinLengthNumberProvided = Number.isInteger(minLengthToSearch);

  const hasEnoughTextLength = (text: string) => {
    return text?.trim()?.length >= minLengthToSearch;
  };

  useEffect(() => {
    // Race condition secure
    let mount = true;
    if (hasEnoughTextLength(debouncedSearchValue)) {
      onAsyncSearch({ searchValue: debouncedSearchValue })
        .then(options => {
          const isOptionsListArray = Array.isArray(options);
          if (!isOptionsListArray) {
            console.warn(
              "[AsyncSearchSelect] onAsyncSearch type ({ searchValue }) => Promise<{ label, value }[]>"
            );
          }
          if (mount && isOptionsListArray) {
            setOptions(options);
            optionsRef.current = options;
          }
          setLoading(false);
        })
        .catch(error => {
          if (mount) {
            toast.fetchError({ error });
            optionsRef.current = [];
          }
          setLoading(false);
        });
    }
    return () => {
      mount = false;
      optionsRef.current = [];
    };
  }, [debouncedSearchValue]);

  const asyncSearchSelectProps: Partial<AsyncSearchSelectProps> = {};

  const shouldDisplayDefaultOptionsNow =
    !searchValue && Array.isArray(defaultOptions);

  if (isMinLengthNumberProvided) {
    if (!shouldDisplayDefaultOptionsNow && !hasEnoughTextLength(searchValue)) {
      asyncSearchSelectProps.dropdownRender = () => (
        <ShortMsg.EnterAtLeast value={minLengthToSearch} />
      );
    }
  }

  return (
    <Select
      {...asyncSearchSelectProps}
      value={value ?? undefined}
      notFoundContent={
        loading ? (
          <Spinner />
        ) : (
          <Empty title={intl.formatMessage({ id: "general.noData" })} />
        )
      }
      searchValue={searchValue}
      showSearch
      size="large"
      allowClear
      autoClearSearchValue={false}
      placeholder={intl.formatMessage({ id: "general.selectValue" })}
      {...rest}
      onSearch={newSearchValue => {
        if (hasEnoughTextLength(newSearchValue)) {
          setLoading(true);
        }

        // Need to memorize ref of options if user type in fast value that will not trigger useEffect
        // Clearing options in cleanup will create vanish effect
        if (newSearchValue === debouncedSearchValue) {
          setOptions(optionsRef.current);
          setLoading(false);
        } else {
          setOptions([]);
        }

        onSearch && onSearch(newSearchValue);
      }}
      options={
        shouldDisplayDefaultOptionsNow ? defaultOptions ?? options : options
      }
      onBlur={
        /* istanbul ignore next */
        e => {
          // Clear loader when user on faze of loading will click outside Select
          setLoading(false);
          if (onBlur) {
            onBlur(e);
          }
        }
      }
      showArrow={false}
      filterOption={false}
    />
  );
};

export default AsyncSearchSelect;
