/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";

import { fetchCompany, fetchOffers, fetchSubscription } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "companySubscriptionForm",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(fetchSubscription.pending, (state, action) => {
        state.subscriptionData.hasError = false;
        state.subscriptionData.errorStatus = null;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.subscriptionData.data = action.payload.subscription;
      })
      .addCase(fetchSubscription.rejected, (state, action) => {
        state.subscriptionData.data = {};
        state.subscriptionData.hasError = true;
        state.subscriptionData.errorStatus = (action.payload as any).status;
      })

      .addCase(fetchCompany.pending, (state, action) => {
        state.companyData.isLoading = true;
        state.companyData.hasError = false;
        state.companyData.errorStatus = null;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.companyData.data = action.payload.company;
        state.companyData.disabledApps = action.payload.disabledApps;
        state.companyData.isLoading = false;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.companyData.data = null;
        state.companyData.disabledApps = [];
        state.companyData.isLoading = false;
        state.companyData.hasError = true;
        state.companyData.errorStatus = (action.payload as any).status;
      })

      .addCase(fetchOffers.pending, (state, action) => {
        state.offersData.isLoading = true;
        state.offersData.hasError = false;
        state.offersData.errorStatus = null;
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.offersData.data = action.payload;
        state.offersData.isLoading = false;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.offersData.data = {};
        state.offersData.isLoading = false;
        state.offersData.hasError = true;
        state.offersData.errorStatus = (action.payload as any).status;
      });
  },
});

export default slice.reducer;
