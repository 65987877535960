import { Col, ColProps, Row, RowProps } from "antd";
import cn from "classnames";

import { useFormLayout } from "components/FormLayout";

import styles from "./FormLabel.module.less";

export interface FormLabelProps {
  label?: React.ReactNode;
  wrapperFormRow?: RowProps;
  labelCol?: ColProps;
  inputCol?: ColProps;
  subLabel?: boolean;
  isRequired?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({
  children,
  label,
  wrapperFormRow,
  labelCol,
  inputCol,
  subLabel,
  isRequired,
}) => {
  const baseLayout = useFormLayout();
  return (
    <Row {...baseLayout.wrapperFormRow} {...wrapperFormRow}>
      <Col
        className={cn({ [styles.subLabel]: subLabel })}
        {...baseLayout.labelCol}
        {...labelCol}
      >
        {label} {isRequired && "*"}
      </Col>
      <Col {...baseLayout.inputCol} {...inputCol}>
        {children}
      </Col>
    </Row>
  );
};

export default FormLabel;
