import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

export const fetchPendingUser = createAsyncThunk(
  "pendingUser/fetchPendingUser",
  async ({ userId }: { userId: string }) => {
    const [pendingUser] = await Promise.all([
      CumdAPI.getPendingUserbyUserId(userId).then(data => data),
    ]);
    return { pendingUser };
  }
);
