import {
  CompanyForTraceoneAdminData,
  OfferData,
  SubscriptionData as SubscriptionApiData,
} from "models";

interface SubscriptionData extends SubscriptionApiData {
  comments?: SubscriptionApiData["comments"] & {
    authorName?: string;
  };
}

type State = {
  subscriptionData?: {
    data: SubscriptionData;
    hasError: boolean;
    errorStatus: number;
  };
  companyData: {
    data: CompanyForTraceoneAdminData;
    disabledApps: number[];
    isLoading: boolean;
    hasError: boolean;
    errorStatus: number;
  };
  offersData: {
    data: { [applicationTypeId: string]: OfferData[] };
    isLoading: boolean;
    hasError: boolean;
    errorStatus: number;
  };
};

export const initialState: State = {
  subscriptionData: {
    data: {},
    hasError: false,
    errorStatus: null,
  },
  companyData: {
    data: null,
    disabledApps: [],
    isLoading: true,
    hasError: false,
    errorStatus: null,
  },
  offersData: {
    data: {},
    isLoading: true,
    hasError: false,
    errorStatus: null,
  },
};
