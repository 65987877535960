import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { toaster, Alert } from "@trace-one/design-system";
import { Row, Col, Typography } from "antd";
import moment from "moment/moment";

import { CumdAPI, RlmdAPI } from "../../../../apis";
import { CompanyForTraceoneAdminData } from "../../../../models";
import { selectCompanyActivitesMap } from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import useToast from "../../../../shared/hooks/useToast";
import ManageSuspendModal from "../CompanyList/Modals/ManageSuspendModal";
import {
  RelationCloseCancel,
  RelationCloseSchedule,
} from "../CompanyList/models";

import AddressesCard from "./AddressCard";
import ApplicationsCard from "./ApplicationsCard";
import styles from "./CompanyGeneralInfo.module.less";
import DunsInformationCard from "./DunsInformationCard";
import MainContactInfo from "./MainContactInfo";
import MainInformationCard from "./MainInformationCard";

interface CompanyGeneralInfoProps {
  company: CompanyForTraceoneAdminData;
  currentCompany: CompanyForTraceoneAdminData;
  refetchData?: () => void;
  refetchCompanies?: () => void;
}

const CompanyGeneralInfo: React.FC<CompanyGeneralInfoProps> = ({
  company,
  currentCompany,
  refetchData,
  refetchCompanies,
}) => {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const [productCategories, setProductCategories] = useState([]);
  const [companyIdentifiers, setCompanyIdentifiers] = useState([]);

  const languageCode = useSelector(selectUserLanguageCode);
  const companyActivitiesMap = useSelector(selectCompanyActivitesMap);

  const [isManageSuspendModalOpen, setIsManageSuspendModalOpen] =
    useState(false);

  const fetchProductCategories = async () => {
    const productCategories = company?.companyCategories.map(
      category => category.categoryItemId
    );

    if (!productCategories || !productCategories.length) return;

    const { data } = await RlmdAPI.getCategoryItems(
      {
        idCollection: productCategories,
      },
      { languageCode }
    );

    setProductCategories(data);
  };

  const fetchCompanyIdentifiersType = async () => {
    const companyIdentifiers = company?.companyIdentifiers?.map(
      companyIdentifiers => companyIdentifiers.typeId
    );

    if (!companyIdentifiers || !companyIdentifiers.length) return;

    const { data } = await RlmdAPI.getReferenceListItemsByIdCollection(
      {
        idCollection: companyIdentifiers,
      },
      { languageCode }
    );

    setCompanyIdentifiers(data);
  };

  useEffect(() => {
    fetchProductCategories();

    if (!!company?.companyIdentifiers) {
      fetchCompanyIdentifiersType();
    }
  }, [company]);

  useEffect(() => {}, []);

  const bannerDescription = (
    <div className={styles.bannerDescription}>
      <div>
        {formatMessage({ id: "companyGeneralInfo.banner.description" })}
      </div>
      <div>
        <Typography.Link
          onClick={() => {
            setIsManageSuspendModalOpen(true);
          }}
        >
          {formatMessage({ id: "company.actions.manageSuspension" })}
        </Typography.Link>
      </div>
    </div>
  );

  const scheduledSuspension = currentCompany?.targetSuspendDate !== null;

  const suspendCompany = async () => {
    try {
      await CumdAPI.suspendComapnyStatusById(company.companyId);
      refetchCompanies();
      window.location.reload();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const scheduleSuspension = async ({
    companyIds,
    targetSuspendDate,
    action,
  }: {
    companyIds: string[];
    targetSuspendDate: string;
    action: string;
  }) => {
    try {
      await CumdAPI.updateCompanySuspension({
        companyIds,
        targetSuspendDate,
      });
      refetchCompanies();
      window.location.reload();

      if (action === RelationCloseSchedule) {
        toaster.open({
          message: formatMessage({
            id: "companySubscriptionForm.suspend.schedule.title",
          }),
          description: formatMessage(
            {
              id: "companySubscriptionForm.suspend.schedule.description",
            },
            {
              companyName: company.companyDisplayName,
              targetSuspendDate: moment(targetSuspendDate).format("DD/MM/YYYY"),
            }
          ),
          type: "confirmation",
        });
      } else if (action === RelationCloseCancel) {
        toaster.open({
          message: formatMessage({
            id: "companySubscriptionForm.suspend.cancel.title",
          }),
          description: formatMessage(
            {
              id: "companySubscriptionForm.suspend.cancel.description",
            },
            {
              companyName: company.companyDisplayName,
            }
          ),
          type: "confirmation",
        });
      } else return;
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <>
      {currentCompany?.targetSuspendDate !== null && (
        <div className={styles.bannerWrapper}>
          <Alert
            type="warning"
            message={formatMessage(
              {
                id: "companyGeneralInfo.banner.title",
              },
              {
                targetSuspendDate: moment(
                  currentCompany?.targetSuspendDate
                ).format("DD/MM/YYYY"),
              }
            )}
            description={bannerDescription}
            closable
            showIcon
            data-test-id="suspension-banner"
          />
        </div>
      )}
      <Row className={styles.root} gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <MainInformationCard
            company={company}
            companyType={companyActivitiesMap[company?.companyActivityId]?.text}
            productCategories={productCategories}
            companyIdentifiers={companyIdentifiers}
            refetchData={refetchData}
            refetchCompanies={refetchCompanies}
          />
          <MainContactInfo company={company} />
          <ApplicationsCard company={company} />
        </Col>
        <Col xs={24} lg={12}>
          <DunsInformationCard company={company} />
          <AddressesCard company={company} />
        </Col>
      </Row>
      <ManageSuspendModal
        visible={isManageSuspendModalOpen}
        setVisible={setIsManageSuspendModalOpen}
        selectedCompany={currentCompany}
        suspendCompany={suspendCompany}
        scheduleSuspension={scheduleSuspension}
        scheduledSuspension={scheduledSuspension}
      />
    </>
  );
};

export default CompanyGeneralInfo;
