import { UserManagerSettings } from "oidc-client";

const oidcConfig: UserManagerSettings = Object.freeze({
  authority: window.env.IDSV_URL,
  client_id: "traceone.internal.administration",
  redirect_uri: `${window.env.BASE_URL}/callback`,
  response_type: "code",
  scope: "openid t1_api t1_global_ids",
  post_logout_redirect_uri: window.env.BASE_URL,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.env.BASE_URL}/silent-refresh`,
  loadUserInfo: true,
});

export default oidcConfig;
