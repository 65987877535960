import React from "react";
import { useIntl } from "react-intl";

import { Modal } from "@trace-one/design-system";

import styles from "../../CompanyRelationList.module.less";

const BulkCancelClosureModal = ({
  visible,
  setVisible,
  cancelScheduledCloseRelations,
  relationIds,
}: {
  visible: boolean;
  setVisible: (boolean) => void;
  cancelScheduledCloseRelations: (relationIds) => void;
  companyName: string;
  relationIds: string[];
}) => {
  const { formatMessage } = useIntl();

  const closeModal = () => setVisible(false);

  return (
    <Modal.Simple
      title={formatMessage({
        id: "companyRelationList.actions.closeRelation.radioBtn.close.cancel",
      })}
      illustrationName="close-paper"
      open={visible}
      primaryButtonText={formatMessage({ id: "general.confirm" })}
      secondaryButtonText={formatMessage({ id: "general.cancel" })}
      onPrimaryButtonClick={() => {
        cancelScheduledCloseRelations(relationIds);
        closeModal();
      }}
      onSecondaryButtonClick={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <section className={styles.relationModal}>
        <div className={styles.description}>
          <p>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </p>
        </div>
      </section>
    </Modal.Simple>
  );
};

export default BulkCancelClosureModal;
