import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Modal, DatePicker } from "@trace-one/design-system";
import { Radio, RadioChangeEvent } from "antd";
import moment from "moment";

import {
  RelationCloseNow,
  RelationCloseSchedule,
} from "../../../CompanyList/models";
import styles from "../../CompanyRelationList.module.less";

const BulkCloseModal = ({
  visible,
  setVisible,
  disableRelations,
  scheduleClosures,
  companyName,
  relationIds,
}: {
  visible: boolean;
  setVisible: (boolean) => void;
  disableRelations?: ({ relationIds }) => void;
  scheduleClosures?: ({ relationIds, targetSuspendDate }) => void;
  companyName?: string;
  relationIds?: string[];
}) => {
  const { formatMessage } = useIntl();

  const [action, setAction] = useState<string>(null);
  const [targetSuspendDate, setTargetSuspendDate] = useState(null);

  const closeModal = () => {
    setVisible(false);
    setAction(null);
    setTargetSuspendDate(null);
  };

  return (
    <Modal.Simple
      title={formatMessage({
        id: "companyRelationList.actions.manageClosure.title",
      })}
      illustrationName="close-paper"
      open={visible}
      primaryButtonText={formatMessage({ id: "general.confirm" })}
      secondaryButtonText={formatMessage({ id: "general.cancel" })}
      onPrimaryButtonClick={() => {
        switch (action) {
          case RelationCloseNow:
            disableRelations({ relationIds: relationIds });
            break;
          case RelationCloseSchedule:
            scheduleClosures({
              relationIds: relationIds,
              targetSuspendDate: targetSuspendDate,
            });
            break;
        }

        closeModal();
      }}
      onSecondaryButtonClick={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
      primaryButtonProps={{
        disabled:
          action === null ||
          (action === RelationCloseSchedule && targetSuspendDate === null),
      }}
    >
      <section className={styles.relationModal}>
        <div className={styles.description}>
          <p>
            {formatMessage(
              {
                id: "companyRelationList.actions.closeRelation.bulk.description",
              },
              {
                number: relationIds.length.toString(),
                b: text => <b style={{ fontWeight: "bold" }}>{text}</b>,
                ownerCompanyName: companyName,
                br: <br />,
              }
            )}
          </p>
          <br />
          <p>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </p>
        </div>

        <div className={styles.radiosContainer}>
          <Radio.Group
            value={action}
            onChange={(e: RadioChangeEvent) => {
              setAction(e.target.value);
            }}
          >
            <Radio value={RelationCloseNow}>
              {formatMessage({
                id: "companyRelationList.actions.closeRelation.radioBtn.close.now",
              })}
            </Radio>
            <Radio value={RelationCloseSchedule}>
              {formatMessage({
                id: "companyRelationList.actions.closeRelation.radioBtn.close.schedule",
              })}
            </Radio>
            {action === RelationCloseSchedule && (
              <div className={styles.calendarInnerContainer}>
                <DatePicker
                  value={targetSuspendDate}
                  defaultValue={targetSuspendDate}
                  onChange={setTargetSuspendDate}
                  showToday={false}
                  disabledDate={current => {
                    return current && current <= moment().endOf("day");
                  }}
                />
              </div>
            )}
          </Radio.Group>
        </div>
      </section>
    </Modal.Simple>
  );
};

export default BulkCloseModal;
