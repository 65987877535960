import { createContext, useContext } from "react";

import { RowProps, ColProps } from "antd";

type FormLayoutType = {
  wrapperFormRow?: RowProps;
  labelCol?: ColProps;
  inputCol?: ColProps;
  sectionMargin?: string;
};

const FormLayoutContext = createContext<FormLayoutType>({
  wrapperFormRow: {
    align: "top",
    style: {
      marginTop: 20,
    },
  },
  labelCol: {
    xs: 24,
    md: 8,
    lg: 8,
    style: {
      minHeight: 42,
      display: "flex",
      alignItems: "center",
    },
  },
  inputCol: {
    xs: 24,
    md: 16,
    lg: 16,
  },
  sectionMargin: "38px 0",
});

export function useFormLayout() {
  const formLayoutConfigs = useContext(FormLayoutContext);
  return formLayoutConfigs;
}

const FormLayout: React.FC<FormLayoutType> = ({ children, ...rest }) => {
  return (
    <FormLayoutContext.Provider value={rest}>
      {children}
    </FormLayoutContext.Provider>
  );
};

export default FormLayout;
