import { useSelector } from "react-redux";

import { selectUserId } from "reduxStore/user/selectors";

import { StorageType } from "shared/webStorage/storage";

import saveFilters from "./saveFilters";

export default function useExternalFilters<T extends object>({
  paginationWebStorage,
  filterWebStorage,
}: {
  paginationWebStorage?: StorageType;
  filterWebStorage: StorageType;
}) {
  const userId = useSelector(selectUserId);

  const handleExternalFilterChange = (value: Partial<T>) => {
    paginationWebStorage?.remove();
    saveFilters(filterWebStorage, { filterObj: value, userId });
  };

  return { handleExternalFilterChange };
}
