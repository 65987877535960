import { useIntl } from "react-intl";

import { Select as DSSelect } from "@trace-one/design-system";

export interface SelectProps {
  value?: any;
  mode?: "multiple" | "tags";
  size?: "small" | "middle" | "large";
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  style?: object;
  showSearch?: boolean;
  onChange?: (newValue?: any, option?: object) => void;
  onSelect?: (value?: string) => void;
  onClear?: () => void;
  options?: {
    value: any;
    label: React.ReactNode;
  }[];
  error?: boolean;
  errorMessage?: React.ReactNode;
}

const Select: React.FC<SelectProps> = props => {
  const { value, onChange, options, ...rest } = props;

  const { formatMessage } = useIntl();

  const handleChange = (newValue, option) => {
    onChange(newValue, option);
  };

  return (
    <DSSelect
      notFoundMessage={formatMessage({ id: "general.noData" })}
      filterOption={(input, option) => {
        return (
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      placeholder={
        rest?.mode === "multiple"
          ? formatMessage({ id: "general.selectValues" })
          : formatMessage({ id: "general.selectValue" })
      }
      value={rest?.mode === "multiple" ? value ?? [] : value}
      onChange={handleChange}
      options={options?.map(({ value, label, ...rest }) => ({
        ...rest,
        value,
        // TODO To REMOVE <- stupid thing from shared library, we do it to make consistence with ant not with @react-components
        name: label,
      }))}
      {...rest}
    />
  );
};

export default Select;
