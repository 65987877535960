import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Table } from "@trace-one/react-components";

import { CumdAPI } from "apis";

import { selectUserLanguageCode } from "reduxStore/user/selectors";
import { selectUserAccessManage } from "reduxStore/userAccessManage/selectors";

import usePermissionsTable from "./hooks/usePermissionsTable";
import styles from "./Permissions.module.less";

interface PermissionsProps {
  selectedApp: number;
}

const Permissions: React.FC<PermissionsProps> = ({ selectedApp }) => {
  const rolesForApps = useSelector(selectUserAccessManage).rolesData.data;
  const userLanguadeCode = useSelector(selectUserLanguageCode);

  const [permissions, setPermissions] = useState<object[]>([]);
  const [roles, setRoles] = useState<{ id: string; name: string }[]>([]);

  const fetchRoles = (): void => {
    const data = rolesForApps
      .filter(item => item.applicationTypeId === selectedApp)
      .flatMap(item => item.roles)
      .map(item => ({ id: item.roleId, name: item.name }));

    setRoles(data);
  };

  const fetchPermissions = async () => {
    const { data } = await CumdAPI.getPermissionsByRole(
      {
        idCollection: roles.map(item => item.id),
      },
      {
        languageCode: userLanguadeCode,
      }
    );

    setPermissions(data);
  };

  useEffect(() => {
    fetchRoles();
  }, [selectedApp]);

  useEffect(() => {
    if (roles.length > 0) {
      fetchPermissions();
    }
  }, [roles]);

  const { columns, data } = usePermissionsTable(roles, permissions);
  const tableWidth = (columns.length - 1) * 250;

  return (
    <Table
      className={styles.root}
      columns={columns}
      dataSource={data}
      scroll={{ x: tableWidth }}
      pagination={false}
    />
  );
};

export default Permissions;
