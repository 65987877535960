import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Icon, Button } from "@trace-one/design-system";

import { FilteredSubscriptionsData } from "models";

import Select from "components/Select";
import Table from "components/Table";

import {
  Contract,
  ContractStatusActive,
  ContractStatusInactive,
} from "../models";

import styles from "./ContractInfo.module.less";
import useCompanyContractTable from "./hooks/useCompanyContractTable";

interface ContractInfoProps {
  companyId: string;
  applicationTypeId: number;
  contracts: Contract[];
  setContracts: (value: Contract[]) => void;
  subscriptionsSFM: FilteredSubscriptionsData[];
  disabled: boolean;
  isEdit: boolean;
}

const ContractInfo: React.FC<ContractInfoProps> = ({
  companyId,
  contracts,
  setContracts,
  subscriptionsSFM,
  disabled,
  isEdit,
}) => {
  const addContract = (contract: Contract) => {
    const newContracts = [...contracts, contract];

    setContracts(newContracts);
  };

  const removeContract = (ownerCompanyId: string) => {
    const newContracts = contracts.filter(
      contract => contract.ownerCompanyId !== ownerCompanyId
    );

    setContracts(newContracts);
  };

  const updateContractStatus = (ownerCompanyId: string, status: string) => {
    const newContracts = contracts.map(contract => {
      if (contract.ownerCompanyId === ownerCompanyId) {
        contract.status =
          contract.status === ContractStatusActive
            ? ContractStatusInactive
            : ContractStatusActive;
      }

      return contract;
    });

    setContracts(newContracts);
  };

  const { formatMessage } = useIntl();
  const { columns, data } = useCompanyContractTable(
    contracts,
    removeContract,
    updateContractStatus,
    disabled,
    isEdit
  );

  const [isSelectVisible, setIsSelectVisible] = useState<boolean>(false);

  const handleAddNew = () => {
    setIsSelectVisible(!isSelectVisible);
  };

  const handleCompanySelect = value => {
    const { companyDisplayName } = subscriptionsSFM.find(
      subscription => subscription.companyId === value
    );

    const newContract = {
      ownerCompanyName: companyDisplayName,
      ownerCompanyId: value,
      associatedCompanyId: companyId,
      status: "Active",
      deletable: true,
    };

    addContract(newContract);
    setIsSelectVisible(false);
  };

  return (
    <div className={styles.contractInfoWrapper}>
      <div className={styles.contractInfo}>
        <Table
          columns={columns}
          dataSource={data}
          hidePagination
          summary={() => {
            return (
              <Button
                onClick={handleAddNew}
                className={styles.addNewButton}
                type="link"
              >
                <div className={styles.addNewText}>
                  {formatMessage({
                    id: "companySubscriptionForm.contract.addNew",
                  })}
                </div>
                <Icon name="arrow-right" color="primary" />
              </Button>
            );
          }}
        />
      </div>
      {isSelectVisible && (
        <div className={styles.searchCompanyWrapper}>
          <Select
            placeholder={formatMessage({
              id: "companySubscriptionForm.contract.searchCompanySelect",
            })}
            onChange={handleCompanySelect}
            showSearch={true}
            data-test-id="contract-company-search"
            options={subscriptionsSFM.map(
              ({ companyId, companyDisplayName }) => ({
                value: companyId,
                label: companyDisplayName,
              })
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ContractInfo;
