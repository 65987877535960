import { useEffect, useState } from "react";

import { CumdAPI } from "apis";

import AsyncSearchSelect, {
  AsyncSearchSelectProps,
} from "components/AsyncSearchSelect";
import { SelectOption } from "shared/typings";

interface AsyncRelatedCompanySelectProps
  extends Omit<AsyncSearchSelectProps, "onAsyncSearch"> {
  label: string;
  onLabelChange: (label: string) => void;
  companyId: string;
}

const AsyncRelatedCompanySelect: React.FC<AsyncRelatedCompanySelectProps> = ({
  value,
  onChange,
  label,
  onLabelChange,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const getRelatedCompaniesOptions = ({
    searchValue,
  }: {
    searchValue?: string;
  }) => {
    return CumdAPI.getCompaniesByFiltersForToAdmin(
      {},
      {
        searchText: searchValue,
        isEnabled: true,
      }
    ).then(({ data }) => {
      return data.companies.map(({ companyId, companyDisplayName }) => ({
        value: companyId,
        label: companyDisplayName,
      }));
    });
  };

  const useDefaultOptionsCompany = () => {
    const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
      getRelatedCompaniesOptions({})
        .then(result => {
          setDefaultOptions(result);
        })
        .catch(() => {});
    }, []);

    return defaultOptions;
  };

  const defaultOptions = useDefaultOptionsCompany();

  useEffect(() => {
    if (value) {
      CumdAPI.getCompaniesByFiltersForToAdmin({ companyIds: [value] }).then(
        ({ data }) => {
          if (data.companies.length === 1) {
            const company = data.companies[0];
            onLabelChange(company?.companyDisplayName);
          }
        }
      );
    }
  }, []);

  return (
    <AsyncSearchSelect
      allowClear
      showSearch
      defaultOptions={defaultOptions}
      value={value ? { value, label } : undefined}
      searchValue={searchValue}
      onChange={(value, option) => {
        onChange(value?.value, option);
        onLabelChange(value?.label);
      }}
      onAsyncSearch={getRelatedCompaniesOptions}
      minLengthToSearch={3}
      labelInValue
      onSearch={setSearchValue}
    />
  );
};

export default AsyncRelatedCompanySelect;
