import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { DateRangePicker, FilterProps, Picker } from "@trace-one/design-system";
import moment from "moment/moment";

import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { CumdAPI } from "../../../../apis";
import AsyncPickerDropdown from "../../../../components/AsyncPickerDropdown";
import CheckableTags from "../../../../components/CheckableTags";
import { SearchFiltersProps } from "../../../../components/SearchFilters";
import Select from "../../../../components/Select";
import { CompanyForTraceoneAdminData } from "../../../../models";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { SelectOption } from "../../../../shared/typings";
import useRelationStatuses from "../../hooks/useRelationStatuses";
import useRelationTypes from "../../hooks/useRelationTypes";

import AsyncRelatedCompanySelect from "./components/RelatedCompanyAsyncSelect";
import { RelationFilter } from "./models";
import styles from "./RelationSearch.module.less";

interface RelationSearchProps {
  filterObj: RelationFilter;
  mergeFilters: (
    value:
      | Partial<RelationFilter>
      | ((prevState: RelationFilter) => RelationFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
  company: CompanyForTraceoneAdminData;
}

const RelationSearch: React.FC<RelationSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
  company,
}) => {
  const showIntercom = useRef(true);

  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  const getRelatedCompaniesOptions = ({
    searchValue,
  }: {
    searchValue?: string;
  }) => {
    return CumdAPI.getCompaniesByFiltersForToAdmin(
      {},
      {
        searchText: searchValue,
        isEnabled: true,
      }
    ).then(({ data }) => {
      return data.companies.map(({ companyId, companyDisplayName }) => ({
        value: companyId,
        label: companyDisplayName,
      }));
    });
  };

  const useDefaultOptionsCompany = () => {
    const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
      getRelatedCompaniesOptions({})
        .then(result => {
          setDefaultOptions(result);
        })
        .catch(() => {});
    }, []);

    return defaultOptions;
  };

  const defaultOptions = useDefaultOptionsCompany();

  const fetchRelationsOnSearch = searchText => {
    return getRelatedCompaniesOptions({ searchValue: searchText })
      .then(companies => {
        return companies;
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  };

  const { relationTypeLabels, getRelationTypeOptions } = useRelationTypes();

  const releationsTypes = getRelationTypeOptions();

  const { getRelationStatusOptions } = useRelationStatuses();

  const [relatedCompanyLabel, setRelatedCompanyLabel] = useState<string>("");

  useEffect(() => {
    const {
      relationType,
      relatedCompany,
      fromTargetSuspendDate,
      toTargetSuspendDate,
      relationStatuses,
    } = filterObj;

    const defaultSelectedFilters = [
      relationType && {
        filterId: "1",
        values: [relationTypeLabels[relationType]],
      },

      relatedCompany && {
        filterId: "2",
        values: [relatedCompany],
      },

      (fromTargetSuspendDate || toTargetSuspendDate) && {
        filterId: "3",
        values: [fromTargetSuspendDate + "-" + toTargetSuspendDate],
      },
      relationStatuses && {
        filterId: "4",
        values: relationStatuses.map(e => e.length),
      },
    ].filter(i => !!i);

    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: formatMessage({ id: "companyRelationList.relationType" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          relationType: undefined,
        });
      },
      elementToRender: (
        <Select
          value={filterObj.relationType}
          onChange={relationType => mergeFilters({ relationType })}
          options={getRelationTypeOptions()}
          allowClear
          showSearch
          data-test-id="ad-company-relation-filter-relationType"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "companyRelationList.relationType" })}
          showBadgeCount
          showSearchInput
          selectionType="single"
          value={filterObj.relationType ? [filterObj.relationType] : []}
          items={releationsTypes}
          onChange={({ selectedItem }) => {
            mergeFilters({
              relationType: selectedItem ? selectedItem.value : undefined,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              relationType: undefined,
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad--company-relation-filter-picker-relationType"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "companyRelationList.relatedCompany" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          relatedCompany: undefined,
        });
      },
      elementToRender: (
        <AsyncRelatedCompanySelect
          value={filterObj.relatedCompany}
          onChange={relatedCompany => {
            mergeFilters({ relatedCompany });
          }}
          label={relatedCompanyLabel}
          onLabelChange={label => {
            setRelatedCompanyLabel(label);
          }}
          companyId={company.companyId}
          data-test-id="ad-company-relation-filter-relatedCompany"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="ad-company-relation-filter-picker-relatedCompany"
          title={formatMessage({ id: "companyRelationList.relatedCompany" })}
          showBadgeCount
          defaultOptions={defaultOptions}
          value={filterObj.relatedCompany ? [filterObj.relatedCompany] : []}
          selectionType="single"
          onSearch={undefined}
          minLengthToSearch={3}
          onAsyncSearch={fetchRelationsOnSearch}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem }) => {
            mergeFilters({
              relatedCompany: selectedItem.value,
            });
            setRelatedCompanyLabel(selectedItem.label);
          }}
          clearSelection={() =>
            mergeFilters({
              relatedCompany: undefined,
            })
          }
        />
      ),
      filterId: "2",
    },

    {
      title: formatMessage({ id: "companyRelationList.closureDate" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          fromTargetSuspendDate: undefined,
          toTargetSuspendDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          value={[
            filterObj.fromTargetSuspendDate
              ? moment(filterObj.fromTargetSuspendDate)
              : undefined,
            filterObj.toTargetSuspendDate
              ? moment(filterObj.toTargetSuspendDate)
              : undefined,
          ]}
          defaultValue={[
            filterObj.fromTargetSuspendDate
              ? moment(filterObj.fromTargetSuspendDate)
              : undefined,
            filterObj.toTargetSuspendDate
              ? moment(filterObj.toTargetSuspendDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              fromTargetSuspendDate: dateRange?.[0]
                ? moment(dateRange[0]).toString()
                : undefined,
              toTargetSuspendDate: dateRange?.[1]
                ? moment(dateRange[1]).toString()
                : undefined,
            });
          }}
          locale={languageCode}
          data-test-id="ad-company-relation-filter-closureDate"
        />
      ),
      filterId: "3",
    },
    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          relationStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.relationStatuses}
          onChange={relationStatuses =>
            mergeFilters({
              relationStatuses,
            })
          }
          options={getRelationStatusOptions()}
          data-test-id="ad-company-relation-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.relationStatuses ?? []}
          items={getRelationStatusOptions()}
          onChange={({ value }) => {
            mergeFilters({
              relationStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              relationStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-company-relation-filter-picker-status"
        />
      ),
      filterId: "4",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "general.enterCompanyNameOrDuns",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default RelationSearch;
