import { useIntl } from "react-intl";

import { Checkbox } from "@trace-one/react-components";
import { ColumnsType } from "antd/lib/table";

import { PermissionsByRoleColProps } from "../../../../../models";
import styles from "../Permissions.module.less";

export default function usePermissionsTable(roles, permissions) {
  const { formatMessage } = useIntl();

  const columns: ColumnsType<PermissionsByRoleColProps> = [
    {
      title: formatMessage({
        id: "general.permissions",
      }),
      width: 250,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      className: styles.stickyCol,
    },
  ];

  roles.forEach(role => {
    columns.push({
      title: role.name,
      dataIndex: role.id,
      key: role.id,
      align: "center",
    });
  });

  const data = [];

  permissions.forEach(permission => {
    const row = {
      name: permission.name,
    };

    roles.forEach(role => {
      row["key"] = `${role.id}-${permission.permissionSetId}`;
      row[role.id] = (
        <Checkbox disabled value={permission.roleIds.includes(role.id)} />
      );
    });

    data.push(row);
  });

  return { columns, data };
}
