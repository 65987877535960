import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";

import { PmdAPI } from "../../../../apis";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchAdminCompany } from "../../../../reduxStore/company/asyncActions";
import { selectAdminCompanyById } from "../../../../reduxStore/company/selectors";
import { ErrorCode } from "../../../../shared/errors";
import useToast from "../../../../shared/hooks/useToast";
import CompanyProductSettings from "../../containers/CompanyProductSettings";

const CompanyProductSettingsPage = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { companyId } = useParams<{ companyId?: string }>();
  const company = useSelector(selectAdminCompanyById);

  const [companySettings, setCompanySettings] = useState<CompanySettings>({});
  const fetchCompanySettings = async () => {
    try {
      const companySettings = await PmdAPI.getCompanySettings(companyId);
      setCompanySettings(companySettings.data);
    } catch (error) {
      if (
        error.response?.data.errorCode === ErrorCode.COMPANY_SETTINGS_NOT_FOUND
      ) {
        return null;
      } else {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    fetchCompanySettings();
  }, []);

  useEffect(() => {
    Promise.all([refetchCompanies()]);
  }, [companyId]);

  const refetchCompanies = () => {
    return dispatch(fetchAdminCompany({ companyId }));
  };
  return (
    <div>
      <CompanyProductSettings
        company={company.data}
        companySettings={companySettings}
      />
    </div>
  );
};

export default CompanyProductSettingsPage;
