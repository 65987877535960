import { useEffect } from "react";
import { useSelector } from "react-redux";

import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchTeamMemberResponsibilities } from "reduxStore/shared/asyncActions";
import { selectTeamMemberResponsibilitiesData } from "reduxStore/shared/selectors";
import { clearTeamMemberResponsibilities } from "reduxStore/shared/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import { TeamMemberResponsibilityParentItem } from "shared/constants";
import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withTeamMemberResponsibilities =
  ({
    parentItemId,
    includeParentItems,
    checkIfRetailerOrSupplier,
  }: {
    parentItemId?: string;
    includeParentItems?: boolean;
    checkIfRetailerOrSupplier?: boolean;
  }) =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);
      const { isRetailer, isSupplier } = usePermissions();

      const { hasError, isLoading } = useSelector(
        selectTeamMemberResponsibilitiesData
      );

      useEffect(() => {
        return () => {
          dispatch(clearTeamMemberResponsibilities(null));
        };
      }, []);

      useEffect(() => {
        const params = {
          languageCode,
          parentItemId,
          includeParentItems,
        };
        if (checkIfRetailerOrSupplier) {
          if (isRetailer) {
            params.parentItemId = TeamMemberResponsibilityParentItem.RETAILER;
          } else if (isSupplier) {
            params.parentItemId = TeamMemberResponsibilityParentItem.SUPPLIER;
          }
        }
        dispatch(fetchTeamMemberResponsibilities(params));
      }, [languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: true,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(
      withTeamMemberResponsibilities,
      WrappedComponent
    );

    return Component;
  };

export default withTeamMemberResponsibilities;
