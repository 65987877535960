import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import {
  Select,
  InputNumber,
  Radio,
  Heading,
  toaster,
} from "@trace-one/design-system";
import { Form, Row } from "antd";
import { isEqual } from "lodash";

import { PmdAPI } from "../../../../apis";
import FormSection from "../../../../components/FormSection";
import StickyContainer from "../../../../components/StickyContainer";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchLanguages } from "../../../../reduxStore/shared/asyncActions";
import { selectLanguagesData } from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { ErrorCode } from "../../../../shared/errors";
import useToast from "../../../../shared/hooks/useToast";
import CompanyCard from "../CompanySubscriptionForm/CompanyCard";

import styles from "./CompanyProductSettings.module.less";

interface CompanyProductSettingsProps {
  company: any;
  companySettings?: CompanySettings;
}

const CompanyProductSettings: React.FC<CompanyProductSettingsProps> = ({
  company,
  companySettings,
}) => {
  const [form] = Form.useForm();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [isFormChanged, setIsFormChanged] = useState(false);

  const [allowGtinDuplicates, setAllowGtinDuplicates] = useState<boolean>(
    companySettings?.allowGtinDuplicates || false
  );
  const [validateGtin, setValidateGtin] = useState<boolean>(
    companySettings?.validateGtin || false
  );
  const [isProductCategoryMandatory, setIsProductCategoryMandatory] =
    useState<boolean>(companySettings?.isProductCategoryMandatory || null);
  const [
    minimumLevelProductCategoryRequired,
    setMinimumLevelProductCategoryRequired,
  ] = useState<number>(
    companySettings?.minimumLevelProductCategoryRequired || 1
  );
  const [isBrandMandatory, setIsBrandMandatory] = useState<boolean>(
    companySettings?.isBrandMandatory || null
  );
  const [isBrandTranslated, setIsBrandTranslated] = useState<boolean>(
    companySettings?.isBrandTranslated || null
  );
  const [isMultipackMandatory, setIsMultipackMandatory] = useState<boolean>(
    companySettings?.isMultipackMandatory || null
  );
  const [isCustomProductTypeMandatory, setIsCustomProductTypeMandatory] =
    useState<boolean>(companySettings?.isCustomProductTypeMandatory || null);

  const [isProductLanguageMandatory, setIsProductLanguageMandatory] =
    useState<boolean>(companySettings?.isProductLanguageMandatory || null);
  const [defaultLanguage, setDefaultLanguage] = useState<string>(
    companySettings?.defaultLanguage || null
  );
  const [availableProductLanguages, setAvailableProductLanguages] = useState<
    string[]
  >(companySettings?.availableProductLanguages || []);

  const [isManufacturedItemMandatory, setIsManufacturedItemMandatory] =
    useState<boolean>(companySettings?.isManufacturedItemNameMandatory || null);

  const [showErrorMsg, setShowErrorMsg] = useState<boolean>();

  const languageOptions = useSelector(selectLanguagesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const radioOptions = [
    { label: formatMessage({ id: "general.yes" }), value: true },
    { label: formatMessage({ id: "general.no" }), value: false },
  ];

  useEffect(() => {
    dispatch(
      fetchLanguages({
        languageCode,
      })
    );
  }, []);

  useEffect(() => {
    const getSettingOrDefault = (setting, defaultValue) =>
      typeof setting !== "undefined" ? setting : defaultValue;

    setAllowGtinDuplicates(
      getSettingOrDefault(companySettings?.allowGtinDuplicates, false)
    );
    setValidateGtin(getSettingOrDefault(companySettings?.validateGtin, false));
    setIsProductCategoryMandatory(
      getSettingOrDefault(companySettings?.isProductCategoryMandatory, null)
    );
    setMinimumLevelProductCategoryRequired(
      getSettingOrDefault(
        companySettings?.minimumLevelProductCategoryRequired,
        1
      )
    );
    setIsBrandMandatory(
      getSettingOrDefault(companySettings?.isBrandMandatory, null)
    );
    setIsBrandTranslated(
      getSettingOrDefault(companySettings?.isBrandTranslated, null)
    );
    setIsMultipackMandatory(
      getSettingOrDefault(companySettings?.isMultipackMandatory, null)
    );
    setIsCustomProductTypeMandatory(
      getSettingOrDefault(companySettings?.isCustomProductTypeMandatory, null)
    );
    setIsProductLanguageMandatory(
      getSettingOrDefault(companySettings?.isProductLanguageMandatory, null)
    );
    setDefaultLanguage(
      getSettingOrDefault(companySettings?.defaultLanguage, null)
    );

    setAvailableProductLanguages(
      companySettings?.availableProductLanguages || []
    );

    setIsManufacturedItemMandatory(
      getSettingOrDefault(
        companySettings?.isManufacturedItemNameMandatory,
        null
      )
    );

    form.setFieldsValue({
      allowGtinDuplicates: companySettings?.allowGtinDuplicates,
      validateGtin: companySettings?.validateGtin,
      isProductCategoryMandatory: companySettings?.isProductCategoryMandatory,
      minimumLevelProductCategoryRequired:
        companySettings?.minimumLevelProductCategoryRequired,
      isBrandMandatory: companySettings?.isBrandMandatory,
      isBrandTranslated: companySettings?.isBrandTranslated,
      isMultipackMandatory: companySettings?.isMultipackMandatory,
      isCustomProductTypeMandatory:
        companySettings?.isCustomProductTypeMandatory,
      isProductLanguageMandatory: companySettings?.isProductLanguageMandatory,
      defaultLanguage: companySettings?.defaultLanguage,
      availableProductLanguages:
        companySettings?.availableProductLanguages || [],
      isManufacturedItemNameMandatory:
        companySettings?.isManufacturedItemNameMandatory,
    });

    setIsFormChanged(false);
  }, [companySettings, form]);
  const allowTypeOnlyPositiveInt = e => {
    if (
      !/^[1-5]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      e.key !== "." &&
      e.key !== "Enter"
    ) {
      e.preventDefault();
    }
  };
  const createCompanySettings = async () => {
    try {
      const isValidLanguage =
        availableProductLanguages?.includes(defaultLanguage);

      if (!isValidLanguage) {
        form.setFields([
          {
            name: "availableProductLanguages",
            value: availableProductLanguages,
            errors: [
              formatMessage({ id: "productSettings.invalidLanguageError" }),
            ],
          },
          {
            name: "defaultLanguage",
            value: defaultLanguage,
            errors: [
              formatMessage({ id: "productSettings.invalidLanguageError" }),
            ],
          },
        ]);

        toaster.open({
          message: formatMessage({ id: "toast.alert" }),
          description: formatMessage({
            id: "general.could.not.be.saved",
          }),
          type: "alert",
        });

        return;
      }

      await PmdAPI.createCompanySettings({
        companyId: company?.companyId,
        validateGtin: validateGtin,
        allowGtinDuplicates: allowGtinDuplicates,
        defaultLanguage: defaultLanguage,
        isBrandMandatory: isBrandMandatory !== null && isBrandMandatory,
        isProductCategoryMandatory:
          isProductCategoryMandatory !== null && isProductCategoryMandatory,
        isMultipackMandatory:
          isMultipackMandatory !== null && isMultipackMandatory,
        isProductLanguageMandatory:
          isProductLanguageMandatory !== null && isProductLanguageMandatory,
        minimumLevelProductCategoryRequired:
          minimumLevelProductCategoryRequired,
        availableProductLanguages: availableProductLanguages,
        isBrandTranslated: isBrandTranslated !== null && isBrandTranslated,
        isCustomProductTypeMandatory: isCustomProductTypeMandatory,
        isManufacturedItemNameMandatory:
          isManufacturedItemMandatory !== null && isManufacturedItemMandatory,
      });

      toaster.open({
        message: formatMessage({ id: "toast.confirmation" }),
        description: formatMessage({
          id: "productSettings.toast.saveMsg",
        }),
        type: "confirmation",
      });

      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (
        error.response?.data.errorCode ===
        ErrorCode.INVALID_DEFAULT_LANGUAGE_CODE
      ) {
        setShowErrorMsg(true);
        toast.saveError({ error });
      } else {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    const isValidLanguage =
      availableProductLanguages?.includes(defaultLanguage);

    if (isValidLanguage) {
      form.setFields([
        {
          name: "availableProductLanguages",
          value: availableProductLanguages,
          errors: [],
        },
        {
          name: "defaultLanguage",
          value: defaultLanguage,
          errors: null,
        },
      ]);
    }
  }, [defaultLanguage, availableProductLanguages, form, formatMessage]);

  return (
    <div className={styles.root}>
      <Form
        name="basic"
        form={form}
        onFinish={createCompanySettings}
        onValuesChange={(_, allValues) => {
          const hasChanged = !isEqual(form.getFieldsValue(), {
            allowGtinDuplicates: companySettings?.allowGtinDuplicates,
            validateGtin: companySettings?.validateGtin,
            isProductCategoryMandatory:
              companySettings?.isProductCategoryMandatory,
            minimumLevelProductCategoryRequired:
              companySettings?.minimumLevelProductCategoryRequired,
            isBrandMandatory: companySettings?.isBrandMandatory,
            isBrandTranslated: companySettings?.isBrandTranslated,
            isMultipackMandatory: companySettings?.isMultipackMandatory,
            isCustomProductTypeMandatory:
              companySettings?.isCustomProductTypeMandatory,
            isProductLanguageMandatory:
              companySettings?.isProductLanguageMandatory,
            defaultLanguage: companySettings?.defaultLanguage,
            availableProductLanguages:
              companySettings?.availableProductLanguages || [],
            isManufacturedItemNameMandatory:
              companySettings?.isManufacturedItemNameMandatory,
          });
          setIsFormChanged(hasChanged);
        }}
      >
        <StickyContainer
          title={formatMessage({ id: "general.productSettings" })}
          layoutMode="edit"
          onCancel={() => {
            history.push(`/companies/${company.companyId}`);
          }}
          onBack={() => {
            history.push(`/companies/${company.companyId}`);
          }}
          saveEnabled={!isFormChanged}
        >
          <div className={styles.companyCardWrapper}>
            <CompanyCard company={company} />
          </div>

          <FormSection
            title={formatMessage({ id: "productSettings.title" })}
            description={formatMessage({ id: "productSettings.description" })}
          >
            <div className={styles.sectionWrapper}>
              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "general.gtin.ean.upc" })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="allowGtinDuplicates"
                    label={formatMessage({
                      id: "productSettings.allowDuplicate",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e => setAllowGtinDuplicates(e.target.value)}
                      defaultValue={allowGtinDuplicates}
                    />
                  </Form.Item>
                  <Form.Item
                    name="validateGtin"
                    label={formatMessage({
                      id: "productSettings.validateGtin",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e => setValidateGtin(e.target.value)}
                      defaultValue={validateGtin}
                    />
                  </Form.Item>
                </div>
              </Row>

              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({
                    id: "companyGeneralInfo.productCategories",
                  })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="isProductCategoryMandatory"
                    label={formatMessage({
                      id: "productSettings.isProductCategoryMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e =>
                        setIsProductCategoryMandatory(e.target.value)
                      }
                    />
                  </Form.Item>
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="minimumLevelProductCategoryRequired"
                      label={formatMessage({
                        id: "productSettings.minLevelsAllowed",
                      })}
                    >
                      <InputNumber
                        onChange={e =>
                          setMinimumLevelProductCategoryRequired(e)
                        }
                        defaultValue={1}
                        onKeyDown={allowTypeOnlyPositiveInt}
                        min={1}
                        max={5}
                        maxLength={1}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Row>
              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "productSettings.brand" })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="isBrandMandatory"
                    label={formatMessage({
                      id: "productSettings.isBrandMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e => setIsBrandMandatory(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="isBrandTranslated"
                    label={formatMessage({
                      id: "productSettings.isBrandTranslated",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e => setIsBrandTranslated(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </Row>
              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "productSettings.multiPack" })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="isMultipackMandatory"
                    label={formatMessage({
                      id: "productSettings.isMultiPackMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e => setIsMultipackMandatory(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </Row>
              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "productSettings.productType" })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="isCustomProductTypeMandatory"
                    label={formatMessage({
                      id: "productSettings.isProductTypeMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e =>
                        setIsCustomProductTypeMandatory(e.target.value)
                      }
                    />
                  </Form.Item>
                </div>
              </Row>
              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "general.productLanguage" })}
                </Heading>
                <div className={styles.itemWrapper}>
                  <Form.Item
                    name="isProductLanguageMandatory"
                    label={formatMessage({
                      id: "productSettings.isProductLanguageMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e =>
                        setIsProductLanguageMandatory(e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="defaultLanguage"
                    label={formatMessage({
                      id: "productSettings.defaultProductLanguage",
                    })}
                    help={
                      showErrorMsg
                        ? formatMessage({
                            id: "productSettings.invalidLanguageError",
                          })
                        : null
                    }
                    validateStatus={showErrorMsg ? "error" : undefined}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={formatMessage({
                        id: "general.selectValue",
                      })}
                      optionFilterProp="children"
                      options={languageOptions}
                      onChange={e => setDefaultLanguage(e)}
                      value={defaultLanguage}
                    />
                  </Form.Item>
                  <Form.Item
                    name="availableProductLanguages"
                    label={formatMessage({
                      id: "productSettings.allowedProductLanguage",
                    })}
                    help={
                      showErrorMsg
                        ? formatMessage({
                            id: "productSettings.invalidLanguageError",
                          })
                        : null
                    }
                    validateStatus={showErrorMsg ? "error" : undefined}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      placeholder={formatMessage({
                        id: "general.selectValue",
                      })}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={languageOptions}
                      onChange={e => setAvailableProductLanguages(e)}
                      value={availableProductLanguages}
                    />
                  </Form.Item>
                </div>
              </Row>

              <Row className={styles.section}>
                <Heading size="xs">
                  {formatMessage({ id: "productSettings.manufacturedItem" })}
                </Heading>
                <div className={styles.itemWrapperManufItem}>
                  <Form.Item
                    name="isManufacturedItemNameMandatory"
                    label={formatMessage({
                      id: "productSettings.isManufacturedItemNameMandatory",
                    })}
                  >
                    <Radio.Group
                      direction="horizontal"
                      options={radioOptions}
                      onChange={e =>
                        setIsManufacturedItemMandatory(e.target.value)
                      }
                    />
                  </Form.Item>
                </div>
              </Row>
            </div>
          </FormSection>
          <div className={styles.wrapper}></div>
        </StickyContainer>
      </Form>
    </div>
  );
};

export default CompanyProductSettings;
