const HeaderBackgroundSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="524"
      height="107"
      viewBox="0 0 524 107"
    >
      <defs>
        <clipPath>
          <rect
            width="524"
            height="107"
            transform="translate(842 71)"
            fill="#fff"
            stroke="#707070"
          />
        </clipPath>
      </defs>
      <g transform="translate(-842 -71)">
        <g>
          <g transform="translate(733 70)">
            <path
              d="M500,124.114H220s-26.667-41.577-64-47.173c-28.909-12.367-53.333-4.2-101.333-32.175S0,0,0,0H500Z"
              transform="translate(133)"
              fill="#efe2f4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HeaderBackgroundSvg;
