import React from "react";
import { useIntl } from "react-intl";

import Select from "components/Select";

import FormController, {
  FormControllerProps,
} from "../../../../../../../components/FormController";
import { SubscriptionData } from "../../../../../../../models";
import { ElectronicSignatureType } from "../../../../../../../shared/constants";
import { SubscriptionValues } from "../../../models";

import styles from "./SignatureTypeSelect.module.less";

interface SignatureTypeProps {
  subscription: SubscriptionData;
  name: FormControllerProps<SubscriptionValues>["name"];
  control: FormControllerProps<SubscriptionValues>["control"];
  rules?: object;
  "data-test-id": string;
}

const SignatureTypeSelect: React.FC<SignatureTypeProps> = ({
  control,
  rules,
}) => {
  const { formatMessage } = useIntl();

  const signatureTypeList = [
    { value: ElectronicSignatureType.NO, label: ElectronicSignatureType.NO },
    {
      value: ElectronicSignatureType.BASIC,
      label: ElectronicSignatureType.BASIC,
    },
  ];

  return (
    <FormController
      name="electronicSignatureTypes"
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.root}>
          <p style={{ fontSize: "16px", lineHeight: "22px" }}>
            {formatMessage(
              {
                id: "companySubscriptionForm.signatureType",
              },
              {
                b: text => <b className={styles.star}>{text}</b>,
              }
            )}
          </p>
          <Select
            mode="multiple"
            placeholder={formatMessage({
              id: "companySubscriptionForm.signatureType.placeholder",
            })}
            onChange={value => {
              if (value.length > 1) {
                return;
              }

              onChange(value);
            }}
            options={signatureTypeList?.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            value={value}
            data-test-id="signature-type-select"
            error={!!error}
            errorMessage={
              error?.type === "required"
                ? formatMessage({
                    id: "general.error.mandatory.field",
                  })
                : error?.message
            }
            style={
              error && {
                border: "#E91C40 solid 1px",
                borderRadius: "4px",
              }
            }
          />
        </div>
      )}
    />
  );
};

export default SignatureTypeSelect;
