import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";

import { CompanyPendingData } from "models";

import ActionDropdown from "components/ActionDropdown";
import { CompanyStatus } from "shared/constants";

import { CumdAPI } from "../../../../apis";
import usePermissions from "../../../../core/oidc/usePermissions";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import useToast from "../../../../shared/hooks/useToast";
import styles from "../CompanyList/CompanyListModel.module.less";

interface PendingCompanyActionProps {
  data: CompanyPendingData;
  status: string;
  refetchCompanies: () => void;
}

const PendingCompanyAction: React.FC<PendingCompanyActionProps> = ({
  data,
  status,
  refetchCompanies,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const toast = useToast();
  const languageCode = useSelector(selectUserLanguageCode);

  const handleTONRedirect = () => {
    const url = window.env.VALIDATE_COMPANY_URL.replace(
      "{global-company-id}",
      data.globalCompanyId
    );
    window.open(url, "_blank");
  };

  const handleToRedirectToPendingCompanyPage = () => {
    return history.push(`/companies/pending/${data.globalUserId}`);
  };

  const handleRedirectToPendingCompanyAddDunsPage = () => {
    history.push(`/companies/pending/${data.globalUserId}/addDuns`);
  };

  const delistPendingCompany = async () => {
    try {
      await CumdAPI.delistPendingCompany(data.globalCompanyId);
      refetchCompanies();
    } catch (error) {
      toast.fetchError({ error });
    }
  };

  const handleDelistModal = () => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          icon={"users"}
          confirmationModalTitle={formatMessage({
            id: "companyList.modal.pendingCompany.delist.title",
          })}
          onConfirm={delistPendingCompany}
        >
          <section className={styles.delistCompanyPendingModal}>
            <div className={styles.description}>
              <p>
                {formatMessage({
                  id: "companyList.modal.pendingCompany.delist.description.1",
                })}
              </p>
            </div>
            <p className={styles.confirmationQuestion}>
              {formatMessage({
                id: "general.action.confirmation.text",
              })}
            </p>
          </section>
        </ConfirmationModal>
      ),
    });
  };

  return (
    <>
      <ActionDropdown
        disabled={
          status === CompanyStatus.REJECTED ||
          status === CompanyStatus.VALIDATED
        }
        data-test-id="ad-pending-company-action"
        overlay={
          <Menu>
            {(hasRoleSuperAdmin || hasRoleAdministrator) && (
              <>
                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? handleTONRedirect
                      : handleToRedirectToPendingCompanyPage
                  }
                  // onClick={handleToRedirectToPendingCompanyPage}
                  // onClick={handleTONRedirect}
                  key="pending-company-validate"
                  disabled={!(data.companyDuns && data.companyLegalName)}
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.validate",
                  })}
                </Menu.Item>
                {/*<Menu.Item onClick={handleDelistModal} key="company-delist">*/}
                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? handleTONRedirect
                      : handleDelistModal
                  }
                  // onClick={handleTONRedirect}
                  // onClick={handleDelistModal}
                  key="company-delist"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.delist",
                  })}
                </Menu.Item>
                <Menu.Item
                  onClick={
                    window.env.DISABLE_SIGNUP_FEATURE === "true"
                      ? handleTONRedirect
                      : handleRedirectToPendingCompanyAddDunsPage
                  }
                  // onClick={handleTONRedirect}
                  // onClick={handleRedirectToPendingCompanyAddDunsPage}
                  key="add-company-duns"
                  disabled={
                    (data.companyDuns !== null && data.companyDuns !== "") ||
                    (data.companyLegalName === null &&
                      data.companyLegalName === "")
                  }
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.addDuns",
                  })}
                </Menu.Item>
              </>
            )}
          </Menu>
        }
      />
    </>
  );
};

export default PendingCompanyAction;
