import { createSlice } from "@reduxjs/toolkit";

import {
  fetchUserForManageAccess,
  fetchRolesForManageAccess,
  fetchResponsibilities,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "userAccessManage",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserForManageAccess.pending, (state, action) => {
        state.userData.isLoading = true;
        state.userData.hasError = false;
        state.userData.errorStatus = null;
      })
      .addCase(fetchUserForManageAccess.fulfilled, (state, action) => {
        state.userData.isLoading = false;
        state.userData.data = action.payload.user;
        state.userData.companyActivityGuid = action.payload.companyActivityGuid;
      })
      .addCase(fetchUserForManageAccess.rejected, (state, action) => {
        state.userData.isLoading = false;
        state.userData.data = null;
        state.userData.companyActivityGuid = null;
        state.userData.hasError = true;
        state.userData.errorStatus = (action.payload as any).status;
      })

      .addCase(fetchRolesForManageAccess.fulfilled, (state, action) => {
        state.rolesData.data = action.payload.roles;
      })
      .addCase(fetchRolesForManageAccess.rejected, (state, action) => {
        state.rolesData.data = [];
        state.rolesData.hasError = true;
        state.rolesData.errorStatus = (action.payload as any).status;
      })

      .addCase(fetchResponsibilities.pending, (state, action) => {
        state.responsibilitiesData.hasError = false;
        state.responsibilitiesData.errorStatus = null;
      })
      .addCase(fetchResponsibilities.fulfilled, (state, action) => {
        state.responsibilitiesData.data = action.payload;
      })
      .addCase(fetchResponsibilities.rejected, (state, action) => {
        state.responsibilitiesData.data = [];
        state.responsibilitiesData.hasError = true;
        state.responsibilitiesData.errorStatus = (action.payload as any).status;
      });
  },
});

export default slice.reducer;
