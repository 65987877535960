import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectGroupList = (state: RootState) => state.groupList;

export const selectGroupListData = createSelector(
  _selectGroupList,
  ({ groups }) => groups
);

export const selectGroupSkipAndTakeQueryStats = createSelector(
  _selectGroupList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
