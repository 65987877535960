import { createSlice } from "@reduxjs/toolkit";

import { fetchPendingUser } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "pendingUser",
  initialState,
  reducers: {
    updateUserLanguage: (state, { payload }) => {
      state.pendingUserData = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPendingUser.pending, (state, action) => {
        state.pendingUserData.isLoading = true;
      })
      .addCase(fetchPendingUser.fulfilled, (state, action) => {
        state.pendingUserData.isLoading = false;
        state.pendingUserData = {
          ...state.pendingUserData,
          ...action.payload.pendingUser,
        };
      })
      .addCase(fetchPendingUser.rejected, (state, action) => {
        state.pendingUserData.isLoading = false;
        state.pendingUserData.hasError = true;
      });
  },
});

export default slice.reducer;
