import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Col } from "antd";

import Card from "components/Card";

import { CompanyForTraceoneAdminData } from "../../../../../models";
import { useAppDispatch } from "../../../../../reduxStore";
import { fetchCountries } from "../../../../../reduxStore/shared/asyncActions";
import { selectCountriesData } from "../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../reduxStore/user/selectors";

import styles from "./AddressCard.module.less";

interface AddressesCardProps {
  company: CompanyForTraceoneAdminData;
}

const AddressesCard: React.FC<AddressesCardProps> = ({ company }) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const dispatch = useAppDispatch();

  const countries = useSelector(selectCountriesData);
  const countryOptions = countries.data?.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const country = countryOptions?.find(country => {
    return country.value === company?.companyCountry;
  });

  const sortedCountriesOptions = countryOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const deliverCountry = sortedCountriesOptions?.find(country => {
    return country.value === company?.companyDeliveryCountry;
  });

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "companyGeneralInfo.address.title" })}
        style={{
          clipPath: "inset(-10px -10px 0px -10px)",
          borderBottomRightRadius: "unset",
          borderBottomLeftRadius: "unset",
        }}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.address" })}
          </span>
          <div>
            {company?.companyAddress1
              ? company?.companyAddress1
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.addressDetails" })}
          </span>
          <div>
            {company?.companyAddress2
              ? company?.companyAddress2
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.zipcode" })}
          </span>
          <div>
            {company?.companyPostalCode
              ? company?.companyPostalCode
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.city" })}
          </span>
          <div>
            {company?.companyCity
              ? company?.companyCity
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.state" })}
          </span>
          <div>
            {company?.companyState
              ? company?.companyState
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.country" })}
          </span>
          <div>
            {company?.companyCountry
              ? country?.name
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>

      <div className={styles.divider} />

      <Card
        title={formatMessage({
          id: "companyGeneralInfo.deliveryAddress.title",
        })}
        style={{
          clipPath: "inset(0px -10px -10px -10px)",
          borderTopRightRadius: "unset",
          borderTopLeftRadius: "unset",
        }}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.address" })}
          </span>
          <div>
            {company?.companyDeliveryAddress1
              ? company?.companyDeliveryAddress1
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.addressDetails" })}
          </span>
          <div>
            {company?.companyDeliveryAddress2
              ? company?.companyDeliveryAddress2
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.zipcode" })}
          </span>
          <div>
            {company?.companyDeliveryPostalCode
              ? company?.companyDeliveryPostalCode
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.city" })}
          </span>
          <div>
            {company?.companyDeliveryCity
              ? company?.companyDeliveryCity
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.state" })}
          </span>
          <div>
            {company?.companyDeliveryState
              ? company?.companyDeliveryState
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.country" })}
          </span>
          <div>
            {company?.companyDeliveryCountry
              ? deliverCountry?.name
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default AddressesCard;
