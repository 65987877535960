import React from "react";

import cn from "classnames";

import styles from "../RelationCreate.module.less";

interface PanelProps {
  title: string;
  subTitle?: string;
  selected?: boolean;
  onSelect?: () => void;
}

const Panel: React.FC<PanelProps> = ({
  title,
  subTitle,
  selected,
  onSelect,
}) => {
  return (
    <div
      onClick={onSelect}
      className={cn(styles.panel, {
        [styles.panelActive]: selected,
      })}
    >
      <h4>{title}</h4>
      <p>{subTitle}</p>
    </div>
  );
};

export default Panel;
