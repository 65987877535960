import React from "react";
import { useIntl } from "react-intl";

import { Heading } from "@trace-one/design-system";

import styles from "./FirstUserInfoSection.module.less";

interface FirstUserInfoSectionProps {
  userInfo: any;
}

const FirstUserInfoSection: React.FC<FirstUserInfoSectionProps> = ({
  userInfo,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <Heading size="xxs">
        {formatMessage({
          id: "general.firstUserInfo",
        })}
      </Heading>

      <div className={styles.infos}>
        <div className={styles.info}>
          <span>{formatMessage({ id: "general.name" })}</span>
          <div>{`${userInfo?.userFirstName} ${userInfo?.userLastName}`}</div>
        </div>
        <div className={styles.info}>
          <span>{formatMessage({ id: "general.email" })}</span>
          <div>
            {userInfo?.userLogin !== null
              ? userInfo?.userLogin
              : formatMessage({ id: "general.empty" })}
          </div>
        </div>

        <div className={styles.info}>
          <span>{formatMessage({ id: "general.phone" })}</span>
          {userInfo?.userPhone !== null
            ? userInfo?.userPhone
            : formatMessage({ id: "general.empty" })}
        </div>
      </div>
    </div>
  );
};

export default FirstUserInfoSection;
