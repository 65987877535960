import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import {
  Picker,
  FilterProps,
  DateRangePicker,
  DatePicker,
} from "@trace-one/design-system";
import moment from "moment/moment";

import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import CheckableTags from "../../../../components/CheckableTags";
import FilterMultiSelection from "../../../../components/FilterMultiSelection";
import { SearchFiltersProps } from "../../../../components/SearchFilters";
import Select from "../../../../components/Select";
import {
  selectCompanyActivitesMap,
  selectCompanyActivitiesData,
} from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { CompanyStatus } from "../../../../shared/constants";
import useAppTranslations from "../../../../shared/hooks/useAppTranslations";
import useCompanyStatuses from "../../hooks/useCompanyStatuses";

import styles from "./CompanySearch.module.less";
import { CompanyFilter } from "./models";

interface CompanySearchProps {
  filterObj: CompanyFilter;
  mergeFilters: (
    value:
      | Partial<CompanyFilter>
      | ((prevState: CompanyFilter) => CompanyFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const CompanySearch: React.FC<CompanySearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const showIntercom = useRef(true);

  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  const [applicationTypeIdsArr, setApplicationTypeIdsArr] = useState([]);

  const companiesActivitiesMap = useSelector(selectCompanyActivitesMap);
  const companiesActivitiesOptions = useSelector(
    selectCompanyActivitiesData
  ).data.map(({ itemCode, text }) => ({ value: itemCode, label: text }));

  const { appLabelOptions, appLabelMap } = useAppTranslations();

  const optionsToDisplay = appLabelOptions.filter(option => {
    return option["value"] !== "40";
  });

  const sortArray = (x, y) => x.label.localeCompare(y.label);
  const sortedOptionsToDisplay = optionsToDisplay.sort(sortArray);

  const { getCompanyStatusOptions } = useCompanyStatuses();

  useEffect(() => {
    const {
      companyActivityId,
      applicationTypeIds,
      companyStatuses,
      createdAt,
      fromStatusUpdatedDate,
      toStatusUpdatedDate,
      fromTargetSuspendDate,
      toTargetSuspendDate,
    } = filterObj;

    const appsTypes = applicationTypeIds
      ? appLabelOptions
          .filter(type => applicationTypeIds?.includes(type.value))
          .map(type => type.label)
      : [];

    const applicationTypeIdsArr = [];

    const defaultSelectedFilters = [
      companyActivityId && {
        filterId: "1",
        values: [companiesActivitiesMap[filterObj.companyActivityId]?.text],
      },

      applicationTypeIds && {
        filterId: "2",
        values: appsTypes,
      },

      companyStatuses && {
        filterId: "3",
        values: companyStatuses.map(e => e.length),
      },

      createdAt && {
        filterId: "4",
        values: [createdAt],
      },

      (fromStatusUpdatedDate || toStatusUpdatedDate) && {
        filterId: "5",
        values: [fromStatusUpdatedDate + "-" + toStatusUpdatedDate],
      },

      (fromTargetSuspendDate || toTargetSuspendDate) && {
        filterId: "6",
        values: [fromTargetSuspendDate + "-" + toTargetSuspendDate],
      },
    ].filter(i => !!i);

    if (filterObj.applicationTypeIds) {
      filterObj.applicationTypeIds.forEach(option => {
        applicationTypeIdsArr.push({
          value: option,
          label: appLabelMap[option],
          onCloseClick: () =>
            mergeFilters(prev => ({
              ...prev,
              applicationTypeIds: prev.applicationTypeIds.filter(
                appId => appId !== option
              ),
            })),
        });
      });
    }

    setSelectedFilters(defaultSelectedFilters);
    setApplicationTypeIdsArr(applicationTypeIdsArr);
  }, [filterObj]);

  const filters = [
    {
      title: formatMessage({ id: "general.companyType" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          companyActivityId: undefined,
        });
      },
      elementToRender: (
        <Select
          value={filterObj.companyActivityId}
          onChange={companyActivityId => mergeFilters({ companyActivityId })}
          options={companiesActivitiesOptions}
          allowClear
          showSearch
          data-test-id="ad-registered-companies-filter-companyType"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.companyType" })}
          showBadgeCount
          showSearchInput
          selectionType="single"
          value={
            filterObj.companyActivityId ? [filterObj.companyActivityId] : []
          }
          items={companiesActivitiesOptions}
          onChange={({ value }) => {
            mergeFilters({
              companyActivityId: value.length && value,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              companyActivityId: undefined,
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-companies-filter-picker-companyType"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "general.applications" }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          applicationTypeIds: [],
        });
      },
      elementToRender: (
        <FilterMultiSelection
          dataTestId="ad-apps-selection"
          data-test-id="ad-registered-companies-filter-apps"
          options={sortedOptionsToDisplay}
          values={applicationTypeIdsArr}
          onChange={selectedValues => {
            mergeFilters({
              applicationTypeIds: selectedValues.length
                ? selectedValues.map(e => e.value)
                : undefined,
            });
          }}
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.applications" })}
          showBadgeCount
          showSearchInput
          selectionType="multi"
          value={filterObj.applicationTypeIds ?? []}
          items={sortedOptionsToDisplay}
          onChange={({ value }) => {
            mergeFilters({
              applicationTypeIds: value.length ? value : undefined,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              applicationTypeIds: undefined,
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-companies-filter-picker-apps"
        />
      ),
      filterId: "2",
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          companyStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.companyStatuses}
          onChange={companyStatuses =>
            mergeFilters({
              companyStatuses,
            })
          }
          options={getCompanyStatusOptions([
            CompanyStatus.ENABLED,
            CompanyStatus.DISABLED,
            CompanyStatus.SUSPENDED,
          ])}
          data-test-id="ad-registered-companies-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.companyStatuses ?? []}
          items={getCompanyStatusOptions([
            CompanyStatus.ENABLED,
            CompanyStatus.DISABLED,
            CompanyStatus.SUSPENDED,
          ])}
          onChange={({ value }) => {
            mergeFilters({
              companyStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              companyStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-registered-companies-filter-picker-status"
        />
      ),
      filterId: "3",
    },

    {
      title: formatMessage({ id: "general.createdSince" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          creationDate: undefined,
        });
      },
      elementToRender: (
        <DatePicker
          defaultValue={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          value={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          onChange={createdAt => {
            mergeFilters({
              createdAt: createdAt?.format("DD/MM/YYYY"),
            });
          }}
          showToday={false}
          style={{ width: "275px" }}
          data-test-id="ad-registered-companies-filter-createdSince"
        />
      ),
      filterId: "4",
    },

    {
      title: formatMessage({ id: "general.statusUpdatedOn" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          fromStatusUpdatedDate: undefined,
          toStatusUpdatedDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          value={[
            filterObj.fromStatusUpdatedDate
              ? moment(filterObj.fromStatusUpdatedDate)
              : undefined,
            filterObj.toStatusUpdatedDate
              ? moment(filterObj.toStatusUpdatedDate)
              : undefined,
          ]}
          defaultValue={[
            filterObj.fromStatusUpdatedDate
              ? moment(filterObj.fromStatusUpdatedDate)
              : undefined,
            filterObj.toStatusUpdatedDate
              ? moment(filterObj.toStatusUpdatedDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              fromStatusUpdatedDate: dateRange?.[0]
                ? moment(dateRange[0]).toString()
                : undefined,
              toStatusUpdatedDate: dateRange?.[1]
                ? moment(dateRange[1]).toString()
                : undefined,
            });
          }}
          locale={languageCode}
          disabledDate={current => {
            return current && current.valueOf() >= Date.now();
          }}
          data-test-id="ad-registered-companies-filter-statusUpdatedOn"
        />
      ),
      filterId: "5",
    },

    {
      title: formatMessage({ id: "companyList.filters.suspensionPlan" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          fromTargetSuspendDate: undefined,
          toTargetSuspendDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          value={[
            filterObj.fromTargetSuspendDate
              ? moment(filterObj.fromTargetSuspendDate)
              : undefined,
            filterObj.toTargetSuspendDate
              ? moment(filterObj.toTargetSuspendDate)
              : undefined,
          ]}
          defaultValue={[
            filterObj.fromTargetSuspendDate
              ? moment(filterObj.fromTargetSuspendDate)
              : undefined,
            filterObj.toTargetSuspendDate
              ? moment(filterObj.toTargetSuspendDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              fromTargetSuspendDate: dateRange?.[0]
                ? moment(dateRange[0]).toString()
                : undefined,
              toTargetSuspendDate: dateRange?.[1]
                ? moment(dateRange[1]).toString()
                : undefined,
            });
          }}
          locale={languageCode}
          data-test-id="ad-registered-companies-filter-suspensionPlan"
        />
      ),
      filterId: "6",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "general.enterCompanyNameOrDuns",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default CompanySearch;
