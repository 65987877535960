import React from "react";
import { useIntl } from "react-intl";

import { InputNumber as DSInputNumber } from "@trace-one/design-system";
import { SubscriptionValues } from "pages/Companies/containers/CompanySubscriptionForm/models";

import FormController, { FormControllerProps } from "components/FormController";

import styles from "./InputNumber.module.less";

interface InputNumberProps {
  label?: string;
  name: FormControllerProps<SubscriptionValues>["name"];
  control: FormControllerProps<SubscriptionValues>["control"];
  rules?: FormControllerProps<SubscriptionValues>["rules"];
  "data-test-id": string;
  placeholder?: string;
  setNumberOfTemplates?: (number) => void;
}

const InputNumber: React.FC<InputNumberProps> = ({
  label,
  name,
  control,
  rules,
  "data-test-id": dataTestId,
  placeholder,
  setNumberOfTemplates,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormController
      label={label}
      name={name}
      control={control}
      labelCol={{ lg: 12 }}
      inputCol={{ lg: 24 }}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.root}>
          <p style={{ fontSize: "16px", lineHeight: "22px" }}>
            {name === "maxUsers"
              ? formatMessage({
                  id: "companySubscriptionForm.numberOfUsers",
                })
              : formatMessage(
                  {
                    id: "companySubscriptionForm.templateNumber",
                  },
                  {
                    b: text => <b className={styles.star}>{text}</b>,
                  }
                )}
          </p>
          <DSInputNumber
            placeholder={placeholder}
            value={value}
            onChange={value => {
              const result = value ? Math.abs(Number.parseInt(value)) : value;
              onChange(result);

              if (typeof setNumberOfTemplates === "function") {
                setNumberOfTemplates(result);
              }
            }}
            onKeyDown={e => {
              if (["e", "E", "+", "-", "."].includes(e.key)) {
                e.preventDefault();
              }
            }}
            data-test-id={dataTestId}
            type="number"
            error={!!error}
            errorMessage={
              error?.type === "required" ||
              (error?.type === "min" && value === 0)
                ? formatMessage({
                    id: "general.error.mandatory.field",
                  })
                : error?.type === "min"
                ? formatMessage({
                    id: "companySubscriptionForm.templateNumber.error.message",
                  })
                : error?.message
            }
            style={error && { borderColor: "#E91C40" }}
          />
        </div>
      )}
    />
  );
};

export default InputNumber;
