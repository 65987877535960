import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import moment from "moment";

import { useAppDispatch } from "reduxStore";
import { selectTeamMemberResponsibilitiesData } from "reduxStore/shared/selectors";
import { fetchUsersForTraceoneAdmin } from "reduxStore/userList/asyncActions";
import {
  selectIsUsersLoading,
  selectUsers,
  selectUsersSkipAndTakeQueryStats,
} from "reduxStore/userList/selectors";
import { clearUsers } from "reduxStore/userList/slice";

import Table from "components/Table";
import { showNewSearchForm, UserStatus } from "shared/constants";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import { CumdAPI } from "../../../../apis";
import IconExpand from "../../../../components/IconExpand";
import useToast from "../../../../shared/hooks/useToast";

import RegisterUserInfoRows from "./components/RegisterUserInfoRows";
import useUserTable from "./hooks/useUserTable";
import { TraceoneAdminUserFilter } from "./models";
import OldUserSearch from "./OldUserSearch";
import styles from "./TraceoneAdminUserList.module.less";
import TraceoneAdminUserTableHeader from "./TraceoneAdminUserTableHeader";
import UserSearch from "./UserSearch";

const UserListForCompanyAdmin = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const [show, setShow] = useState<string>("registeredUsers");
  const users = useSelector(selectUsers);
  const skipAndTakeQueryStats = useSelector(selectUsersSkipAndTakeQueryStats);
  const isUserTableLoading = useSelector(selectIsUsersLoading);
  const isResponsibilitiesLoading = useSelector(
    selectTeamMemberResponsibilitiesData
  ).isLoading;
  const isLoading = isUserTableLoading || isResponsibilitiesLoading;
  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage:
        show === "registeredUsers" &&
        TablePaginationStorage.TO_ADMIN_REGISTERED_USER_LIST,
    });

  const {
    searchText: registeredSearchText,
    setSearchText: registeredSetSearchText,
    mergeFilters: registeredMergeFilters,
    filterObj: registeredFilterObj,
    removeAllFiltersAndResetPageNumber:
      registeredRemoveAllFiltersAndResetPageNumber,
    setFilterObj: registeredSetFilterObj,
    resetPageNumberAndRowKeys: registeredResetPageNumberAndRowKeys,
  } = useSearchFilters<TraceoneAdminUserFilter>(
    {
      owningCompanyId: undefined,
      userStatuses: [UserStatus.ENABLED],
      responsibilityId: undefined,
      applicationTypeIds: [],
      companyActivity: undefined,
      userJobTitle: undefined,
      administratorFilterList: [],
      createdAt: undefined,
      creationDate: undefined,
      lastSeenBefore: undefined,
      isMainContactFilterList: [],
      fromStatusUpdatedDate: undefined,
      toStatusUpdatedDate: undefined,
      userFirstName: undefined,
      userLastName: undefined,
      userLogin: undefined,
    },
    {
      resetPageNumber,
      clearedFilters: {
        owningCompanyId: undefined,
        userStatuses: [],
        responsibilityId: undefined,
        applicationTypeIds: [],
        companyActivity: undefined,
        userJobTitle: undefined,
        administratorFilterList: [],
        createdAt: undefined,
        creationDate: undefined,
        lastSeenBefore: undefined,
        isMainContactFilterList: [],
        fromStatusUpdatedDate: undefined,
        toStatusUpdatedDate: undefined,
        userFirstName: undefined,
        userLastName: undefined,
        userLogin: undefined,
      },
      webStorage: SearchFiltersStorage.TO_ADMIN_REGISTERED_USER_LIST,
    }
  );

  let filterObj = show === "registeredUsers" && registeredFilterObj;

  const {
    userStatuses,
    applicationTypeIds,
    responsibilityId,
    userJobTitle,
    administratorFilterList,
    userLockedFilter,
    owningCompanyId,
    createdAt,
    userLastLoggedInDate,
    isMainContactFilterList,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    userFirstName,
    userLastName,
    userLogin,
  } = filterObj;

  const refetchUsers = useCallback(() => {
    const _userStatuses = userStatuses.flatMap(status => {
      if (status === "DisabledAndSuspended") {
        return [UserStatus.DISABLED, UserStatus.SUSPENDED];
      } else {
        return status;
      }
    });

    show === "registeredUsers" &&
      dispatch(
        fetchUsersForTraceoneAdmin({
          owningCompanyId,
          userJobTitle: userJobTitle ? Number(userJobTitle) : undefined,
          userStatuses: _userStatuses,
          applicationTypeIds: applicationTypeIds?.map(applicationTypeId =>
            Number(applicationTypeId)
          ),
          isAccountAdministrator:
            administratorFilterList?.length === 1
              ? administratorFilterList[0] === "yes"
              : undefined,
          responsibilityId,
          searchText: registeredSearchText,
          isLocked:
            userLockedFilter?.length === 1
              ? userLockedFilter[0] === "yes"
              : undefined,
          creationDate:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          lastSeenBefore:
            userLastLoggedInDate &&
            moment(userLastLoggedInDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          isMainContact:
            isMainContactFilterList?.length === 1
              ? isMainContactFilterList[0] === "yes"
              : undefined,
          fromStatusUpdatedDate:
            fromStatusUpdatedDate &&
            moment(fromStatusUpdatedDate).format("YYYY-MM-DD"),
          toStatusUpdatedDate:
            toStatusUpdatedDate &&
            moment(toStatusUpdatedDate).format("YYYY-MM-DD"),
          userFirstName: userFirstName,
          userLastName: userLastName,
          userLogin: userLogin,
          skip: paginationQuery.skip,
          take: paginationQuery.take,
        })
      );
  }, [
    registeredSearchText,
    paginationQuery,
    userStatuses,
    applicationTypeIds,
    responsibilityId,
    userJobTitle,
    administratorFilterList,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    userFirstName,
    userLastName,
    userLogin,
    show,
    createdAt,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearUsers(null));
    };
  }, []);

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  const { columns, data } = useUserTable({
    users,
    refetchUsers,
  });

  const handleExport = async () => {
    const _userStatuses = userStatuses.flatMap(status => {
      if (status === "DisabledAndSuspended") {
        return [UserStatus.DISABLED, UserStatus.SUSPENDED];
      } else {
        return status;
      }
    });
    try {
      await CumdAPI.exportUsersInBulk(
        {
          userStatuses: _userStatuses,
          applicationTypeIds: applicationTypeIds.map(applicationTypeId =>
            Number(applicationTypeId)
          ),
        },
        {
          userSearch: registeredSearchText,
          owningCompanyId: owningCompanyId,
          isAccountAdministrator:
            administratorFilterList?.length === 1
              ? administratorFilterList[0] === "yes"
              : undefined,
          isLocked:
            userLockedFilter?.length === 1
              ? userLockedFilter[0] === "yes"
              : undefined,
          userJobTitle: userJobTitle ? Number(userJobTitle) : undefined,
          creationDate:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          lastSeenBefore:
            userLastLoggedInDate &&
            moment(userLastLoggedInDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          fromStatusUpdatedDate:
            fromStatusUpdatedDate &&
            moment(fromStatusUpdatedDate).format("YYYY-MM-DD"),
          toStatusUpdatedDate:
            toStatusUpdatedDate &&
            moment(toStatusUpdatedDate).format("YYYY-MM-DD"),
          isMainContact:
            isMainContactFilterList?.length === 1
              ? isMainContactFilterList[0] === "yes"
              : undefined,
          userFirstName: userFirstName && userFirstName,
          userLastName: userLastName && userLastName,
          userLogin: userLogin && userLogin,
        }
      );

      toast.success({
        description: formatMessage({ id: "general.export.message" }),
      });
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <div className={styles.root}>
      {showNewSearchForm ? (
        <UserSearch
          filterObj={filterObj}
          mergeFilters={registeredMergeFilters}
          initialSearchValue={registeredSearchText}
          onSearch={registeredSetSearchText}
          onClearFiltersClick={registeredRemoveAllFiltersAndResetPageNumber}
          setFilterObj={registeredSetFilterObj}
          resetPageNumberAndRowKeys={registeredResetPageNumberAndRowKeys}
        />
      ) : (
        <OldUserSearch
          filterObj={filterObj}
          mergeFilters={registeredMergeFilters}
          initialSearchValue={registeredSearchText}
          onSearch={registeredSetSearchText}
          onClearFiltersClick={registeredRemoveAllFiltersAndResetPageNumber}
          setFilterObj={registeredSetFilterObj}
          resetPageNumberAndRowKeys={registeredResetPageNumberAndRowKeys}
        />
      )}

      <TraceoneAdminUserTableHeader
        showed={show}
        setChangeShow={setShow}
        totalCount={skipAndTakeQueryStats.totalCount ?? 0}
        currentNumber={skipAndTakeQueryStats.currentCount ?? 0}
        handleExport={handleExport}
      />

      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandIcon: props => <IconExpand {...props} />,
          expandedRowRender: record => (
            <RegisterUserInfoRows record={record.rawData} />
          ),
        }}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />
    </div>
  );
};

export default UserListForCompanyAdmin;
