import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, TextArea, Icon } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";
import moment from "moment";

import { selectUserId, selectUserName } from "reduxStore/user/selectors";

import IconButton from "components/IconButton";

import { Comment } from "../models";

import styles from "./CommentsList.module.less";

interface CommentsListProps {
  comments: Comment[];
  setComments: (value: Comment[]) => void;
}

const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  setComments,
}) => {
  const { formatMessage } = useIntl();
  const [text, setText] = useState<string>("");

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);

  const handleAddComment = () => {
    const newComment: Comment = {
      value: text,
      authorId: userId,
      commentDate: moment().format(),
      authorName: userName,
    };

    setComments([...comments, newComment]);
    setText("");
    setIsDisabled(true);
  };

  const handleDeleteComment = ({ commentDate, value }) => {
    setComments(
      comments.filter(
        item => !(item.commentDate === commentDate && item.value === value)
      )
    );
  };

  return (
    <div className={styles.root}>
      {comments.map(comment => (
        <Card
          title={
            <>
              <span className={styles.userName}>{comment.authorName}</span>
              <span className={styles.date}>
                {` on ${moment(comment.commentDate).format("dd MMMM d YYYY")}`}
              </span>
            </>
          }
          className={styles.card}
          data-test-id="subscription-comment-card"
          extra={
            comment.authorId === userId && (
              <IconButton
                onClick={() =>
                  handleDeleteComment({
                    commentDate: comment.commentDate,
                    value: comment.value,
                  })
                }
                data-test-id="form-comment-delete"
                className={styles.removeBtn}
              >
                <Icon name="trash" useTOLibrary size={20} />
              </IconButton>
            )
          }
        >
          {comment.value}
        </Card>
      ))}
      <div>
        <TextArea
          className={styles.textarea}
          value={text}
          maxLength={1000}
          onChange={e => {
            setText(e.target.value);
            if (e.target.value !== "") {
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
          }}
        />
        <Button
          type="secondary"
          className={styles.addButton}
          disabled={isDisabled}
          onClick={handleAddComment}
        >
          {formatMessage({ id: "companySubscriptionForm.addComment" })}
        </Button>
      </div>
    </div>
  );
};

export default CommentsList;
