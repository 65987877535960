export const ContributionTypeAnimator = "Animator";
export const ContributionTypeContributor = "Contributor";

export const BusinessModelSFM = "SFM";
export const BusinessModelRFM = "RFM";
export const BusinessModelNA = "NA";

export const ContractStatusActive = "Active";
export const ContractStatusInactive = "Inactive";

export interface SubscriptionValues {
  offerIds: string[];
  maxUsers?: number;
  businessModel: string;
  templateListIds?: string[];
  maxNumberOfTemplates?: number;
  electronicSignatureTypes?: string[];
  nutriscoreCalculation?: boolean;
}

export interface Comment {
  value?: string;
  authorId?: string;
  authorName?: string;
  commentDate?: string;
}

export interface Contract {
  id?: string;
  ownerCompanyName?: string;
  ownerCompanyStatus?: string;
  ownerCompanyId?: string;
  ownerCompanySubscriptionActive?: boolean;
  associatedCompanyId?: string;
  status?: string;
  deletable?: boolean;
}
