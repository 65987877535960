import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Tag } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { selectCompanyActivitesMap } from "../../../../../reduxStore/shared/selectors";
import { UserStatus, CompanyActivity } from "../../../../../shared/constants";
import useUserStatuses from "../../../hooks/useUserStatuses";
import { TraceoneAdminUserPendingTable } from "../models";
import UserPendingAction from "../UserPendingAction";

export default function useUserPendingTable({
  pendingUsers,
  refetchUsers,
}: {
  pendingUsers: any;
  refetchUsers: () => void;
}) {
  const { formatMessage } = useIntl();

  const { userStatusLabels, userStatusTagColors } = useUserStatuses();
  const companiesActivitiesMap = useSelector(selectCompanyActivitesMap);

  const pendingColumns: ColumnsType<TraceoneAdminUserPendingTable> = [
    {
      title: formatMessage({
        id: "general.user.login",
      }),
      dataIndex: "userFullname",
      key: "userFullname",
      width: 200,
    },
    {
      title: formatMessage({
        id: "userList.toAdmin.userGuid",
      }),
      dataIndex: "userGuid",
      key: "globalUserId",
      width: 200,
    },
    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "registrationStatus",
      key: "registrationStatus",
      width: 140,
      render: (registrationStatus, record) => {
        let status = registrationStatus;
        if (record.rawData.registrationStatus === 0) {
          status = "Pending";
        }
        if (record.rawData.registrationStatus === 2) {
          status = "Rejected";
        }

        let title = "";
        if (
          (record.rawData.companyDuns &&
            record.rawData.companyDuns !== "" &&
            record.rawData.companyActivity !== CompanyActivity.RETAILER) ||
          (record.rawData.companyDuns &&
            record.rawData.companyDuns !== "" &&
            record.rawData.companyActivity === CompanyActivity.RETAILER &&
            record.rawData.isAttachedToExistingCompany === true)
        ) {
          title = formatMessage({
            id: "userList.action.userHasDunsAndNoRetailer",
          });
        }

        if (
          !record.rawData.companyDuns !== null &&
          !record.rawData.companyLegalName
        ) {
          title = formatMessage({
            id: "userList.action.userHasNoCompanies",
          });
        }

        return (
          <>
            {record.rawData.registrationStatus === 0 && (
              <Tooltip title={title} placement="right">
                <div style={{ display: "inline-block" }}>
                  <Tag
                    label={userStatusLabels[status]}
                    color={userStatusTagColors[status]}
                    size="medium"
                    mode="light"
                  />
                </div>
              </Tooltip>
            )}
            {record.rawData.registrationStatus === 2 && (
              <div>
                <Tag
                  label={userStatusLabels[status]}
                  color={userStatusTagColors[status]}
                  size="medium"
                  mode="light"
                />
              </div>
            )}
          </>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.createdOn",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
    },

    {
      title: formatMessage({
        id: "general.company",
      }),
      dataIndex: "companyLegalName",
      key: "companyLegalName",
      width: 150,
    },
    {
      title: formatMessage({
        id: "general.dunsNumber",
      }),
      dataIndex: "companyDuns",
      key: "companyDuns",
      width: 150,
    },

    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "action",
      key: "action",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (registrationStatus, record) => {
        let status = registrationStatus;
        if (record.rawData.registrationStatus === 0) {
          status = UserStatus.PENDING;
        }
        if (record.rawData.registrationStatus === 2) {
          status = UserStatus.REJECTED;
        }

        return (
          <UserPendingAction
            status={status}
            data={record.rawData}
            refetchUsers={refetchUsers}
          />
        );
      },
    },
  ];

  const pendingUserdata = pendingUsers?.map(user => {
    const result: TraceoneAdminUserPendingTable = {
      key: user.id,
      userFullname: (
        <div>
          <span>
            {[user.userFirstName, user.userLastName]
              .filter(item => !!item)
              .join(" ")}
          </span>
          <br />
          <span>{user.userEmail}</span>
        </div>
      ),
      companyDuns: user.companyDuns,
      userGuid: user.globalUserId,
      registrationStatus: user.registrationStatus,
      createdAt: moment(user.createdAt).format("DD/MM/YYYY"),
      companyLegalName: (
        <div>
          <span>{user.companyLegalName}</span>
          <br />
          <span>{companiesActivitiesMap[user.companyActivity]?.text}</span>
        </div>
      ),
      rawData: user,
    };
    return result;
  });

  return { pendingColumns, pendingUserdata };
}
