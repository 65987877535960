import { CompanyForTraceoneAdminData } from "models";

type State = {
  companyData: {
    data: CompanyForTraceoneAdminData;
    disabledApps: number[];
    isLoading: boolean;
    hasError: boolean;
    errorStatus: number;
  };
};

export const initialState: State = {
  companyData: {
    data: null,
    disabledApps: [],
    isLoading: true,
    hasError: false,
    errorStatus: null,
  },
};
