import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { toaster } from "@trace-one/design-system";
import { Form } from "antd";

import { CumdAPI } from "../../../../apis";
import StickyContainer from "../../../../components/StickyContainer";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchCountries } from "../../../../reduxStore/shared/asyncActions";
import { selectCountriesData } from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { IdentifiersItemId } from "../../../../shared/constants";
import useCompanyActivityLabels from "../../../../shared/hooks/useCompanyActivityLabels";
import useToast from "../../../../shared/hooks/useToast";

import AddDunsSection from "./AddDunsSection";
import CurrentInfoSection from "./CurrentInfoSection";
import FirstUserInfoSection from "./FirstUserInfoSection";
import styles from "./PendingCompanyAddDuns.module.less";

interface PendingCompanyAddDunsProps {
  userInfo: any;
}

const PendingCompanyAddDuns: React.FC<PendingCompanyAddDunsProps> = ({
  userInfo,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const { companyActivityLabels } = useCompanyActivityLabels();
  const languageCode = useSelector(selectUserLanguageCode);
  const countries = useSelector(selectCountriesData);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const [companyValue, setCompanyValue] = useState(null);

  const companyType = companyActivityLabels[userInfo?.companyActivity];

  const searchParams = new URLSearchParams(window.location.search);
  const pageName = searchParams.get("pageName");

  const countryOptions = countries.data?.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const sortedCountriesOptions = countryOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  const rejectPendingCompany = async () => {
    try {
      await CumdAPI.delistPendingUser(userInfo?.userId);

      toaster.open({
        description: formatMessage({ id: "companyAddDuns.reject.toast" }),
        type: "confirmation",
      });

      pageName && pageName === "user-pending"
        ? history.push(`/users/pending/list`)
        : history.push(`/companies/pending/list`);
    } catch (error) {
      toast.fetchError({ error });
    }
  };

  const identifiers = () => {
    if (
      companyValue?.companySiret !== null &&
      companyValue?.companyVat !== null
    ) {
      return [
        {
          typeId: IdentifiersItemId.SIRET_ITEM_ID,
          value: companyValue?.companySiret,
        },
        {
          typeId: IdentifiersItemId.VAT_ITEM_ID,
          value: companyValue?.companyVat,
        },
      ];
    } else if (companyValue?.companySiret !== null) {
      return [
        {
          typeId: IdentifiersItemId.SIRET_ITEM_ID,
          value: companyValue?.companySiret,
        },
      ];
    } else if (companyValue?.companyVat !== null) {
      return [
        {
          typeId: IdentifiersItemId.VAT_ITEM_ID,
          value: companyValue?.companyVat,
        },
      ];
    } else {
      return;
    }
  };
  const validatePendingCompany = async () => {
    try {
      await CumdAPI.createUserAndCompany({
        globalCompanyId: userInfo?.globalCompanyId,
        companyCountry: userInfo?.companyCountry,
        companyState: userInfo?.companyState,
        companyCity: userInfo?.companyCity,
        companyPostalCode: userInfo?.companyPostalCode,
        companyAddress: userInfo?.companyAddress,
        companyAddressComplement: userInfo?.applicationTypeId,
        companyPhone: userInfo?.companyPhone,
        companyFax: userInfo?.companyFax,
        companyEmail: userInfo?.companyEmail,
        companyDuns: companyValue?.companyDuns,
        companyDisplayName:
          userInfo?.companyDisplayName === null
            ? userInfo?.companyLegalName
            : userInfo?.companyDisplayName,
        companyLegalName: companyValue?.companyName,
        companyActivity: userInfo?.companyActivity,
        companyCategories: userInfo.companyCategories?.map(item => ({
          categoryId: item.categoryId,
          categoryItemId: item.categoryItemId,
        })),
        testCompany: false,
        companyIdentifiers: identifiers(),
        isRetailerCompany: true,
        globalUserId: userInfo?.userId,
        userCivility:
          userInfo?.userCivility === null ? "Mr" : userInfo?.userCivility,
        userJobTitle: Number(userInfo?.userJobTitle),
        userLastName: userInfo?.userLastName,
        userFirstName: userInfo?.userFirstName,
        userLanguagePreference: userInfo?.userLanguagePreference,
        userPhoneNumber: userInfo?.userPhoneNumber,
        userEmail: userInfo?.userLogin,
      });

      pageName && pageName === "user-pending"
        ? history.push(`/users/pending/list`)
        : history.push(`/companies/pending/list`);
    } catch (error) {
      console.log(error);
    }
  };

  const validatePendingUserIfCompanyExists = async () => {
    try {
      await CumdAPI.updeteUserAndCompanyPendingDetails(userInfo.userId, {
        userLanguagePreference: userInfo?.userLanguagePreference,
        userJobTitle: Number(userInfo?.userJobTitle),
        userPhoneNumber: userInfo?.userPhoneNumber,
        userCivility:
          userInfo?.userCivility === null ? "Mr" : userInfo?.userCivility,
        globalCompanyId: companyValue.companyId,
        companyLegalName: companyValue?.companyName,
        companyAddress: companyValue.companyAddress,
        companyAddressComplement: null,
        companyPostalCode: companyValue.companyPostalCode,
        companyCity: companyValue.companyCity,
        companyCountry: companyValue.companyCountry,
        companyDuns: companyValue.companyDuns,
        companyActivity: companyValue.companyActivityId,
        companyCategories: null,
        companyState: companyValue.companyState,
        companyPhone: null,
        companyEmail: null,
      });

      pageName && pageName === "user-pending"
        ? history.push(`/users/pending/list`)
        : history.push(`/companies/pending/list`);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const onValidateClick = () => {
    if (window.env.DISABLE_SIGNUP_FEATURE === "false") {
      if (companyValue.isExistingCompany) {
        validatePendingUserIfCompanyExists();
      } else {
        validatePendingCompany();
      }
    }
    if (window.env.DISABLE_SIGNUP_FEATURE === "true") {
      validatePendingCompany();
    }
  };

  return (
    <Form name="basic" form={form}>
      <StickyContainer
        title={formatMessage({
          id: "general.addDuns",
        })}
        layoutMode="edit"
        onBack={() => {
          pageName && pageName === "user-pending"
            ? history.push(`/users/pending/list`)
            : history.push(`/companies/pending/list`);
        }}
        isValidateAndRejectBtnsVersion
        showRejectionTooltip
        functionOnReject={rejectPendingCompany}
        showConfirmationTooltip
        functionOnValidate={onValidateClick}
        saveEnabled={!saveEnabled}
        isExistingCompany={companyValue?.isExistingCompany}
      >
        <div className={styles.root}>
          <div className={styles.leftPart}>
            <AddDunsSection
              userInfo={userInfo}
              countryOptions={sortedCountriesOptions}
              setSaveEnabled={setSaveEnabled}
              companyValue={companyValue}
              setCompanyValue={setCompanyValue}
            />
          </div>
          <div className={styles.rightPart}>
            <div>
              <CurrentInfoSection
                userInfo={userInfo}
                companyType={companyType}
              />
            </div>
            <div>
              <FirstUserInfoSection userInfo={userInfo} />
            </div>
          </div>
        </div>
      </StickyContainer>
    </Form>
  );
};

export default PendingCompanyAddDuns;
