import { useIntl } from "react-intl";

import { ContractStatus } from "shared/constants";

export default function useContractStatuses() {
  const { formatMessage } = useIntl();

  const contractStatusLabels = {
    [ContractStatus.ACTIVE]: formatMessage({ id: "general.active" }),
    [ContractStatus.INACTIVE]: formatMessage({
      id: "general.inactive",
    }),
  };

  const contractTagColors = {
    [ContractStatus.ACTIVE]: "green",
    [ContractStatus.INACTIVE]: "red" || "purple",
  };

  const getContractStatusOptions = (
    contractStatuses?: ContractStatus[]
  ): { value: ContractStatus; label: string }[] => {
    if (contractStatuses?.length > 0) {
      return contractStatuses.map(status => ({
        value: status,
        label: contractStatusLabels[status],
      }));
    }

    return Object.entries(contractStatusLabels).map(([value, label]) => ({
      value: value as ContractStatus,
      label,
    }));
  };

  return {
    contractStatusLabels,
    contractTagColors,
    getContractStatusOptions,
  };
}
