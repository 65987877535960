import { createAsyncThunk } from "@reduxjs/toolkit";

import { CatalogueAPI, RlmdAPI } from "apis";

export const fetchCompanyActivities = createAsyncThunk(
  "shared/fetchCompanyActivities",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: companyActivities } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Company_Activity",
        {
          languageCode,
        }
      );
    return companyActivities;
  }
);

export const fetchTeamMemberResponsibilities = createAsyncThunk(
  "shared/fetchTeamMemberResponsibilities",
  async ({
    languageCode,
    parentItemId,
    includeParentItems,
  }: {
    languageCode: string;
    parentItemId?: string;
    includeParentItems?: boolean;
  }) => {
    const { data: responsibilities } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Team_Member_Responsibility",
        {
          includeParentItems,
          parentItemId,
          languageCode,
        }
      );
    return responsibilities;
  }
);

export const fetchJobTitles = createAsyncThunk(
  "shared/fetchJobTitles",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: jobTitles } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("Job_Title", {
        languageCode,
      });
    return jobTitles;
  }
);

export const fetchCountries = createAsyncThunk(
  "shared/fetchCountries",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: countries } = await CatalogueAPI.fetchCountries();
    return countries;
  }
);

export const fetchLanguages = createAsyncThunk(
  "shared/fetchLanguages",
  async ({ languageCode }: { languageCode: string }) => {
    const { data: languages } =
      await RlmdAPI.getReferenceListItemsByReferenceListName("languages", {
        languageCode,
      });
    return languages;
  }
);
