import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCompanyActivities,
  fetchTeamMemberResponsibilities,
  fetchJobTitles,
  fetchCountries,
  fetchLanguages,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    clearCompanyActivities: (state, action) => {
      state.companyActivitiesData = initialState.companyActivitiesData;
    },
    clearTeamMemberResponsibilities: (state, action) => {
      state.teamMemberResponsibilitiesData =
        initialState.teamMemberResponsibilitiesData;
    },
    clearJobTitles: (state, action) => {
      state.jobTitlesData = initialState.jobTitlesData;
    },
    clearCountries: (state, action) => {
      state.countriesData = initialState.countriesData;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompanyActivities.pending, (state, action) => {
        state.companyActivitiesData.isInitiated =
          state.companyActivitiesData.isInitiated &&
          !state.companyActivitiesData.hasError;
        state.companyActivitiesData.isLoading = true;
        state.companyActivitiesData.hasError = false;
      })
      .addCase(fetchCompanyActivities.fulfilled, (state, action) => {
        state.companyActivitiesData.isInitiated = true;
        state.companyActivitiesData.isLoading = false;
        state.companyActivitiesData.data = action.payload;
      })
      .addCase(fetchCompanyActivities.rejected, (state, action) => {
        state.companyActivitiesData.isLoading = false;
        state.companyActivitiesData.data = [];
        state.companyActivitiesData.hasError = true;
      })

      .addCase(fetchTeamMemberResponsibilities.pending, (state, action) => {
        state.teamMemberResponsibilitiesData.isInitiated =
          state.teamMemberResponsibilitiesData.isInitiated &&
          !state.teamMemberResponsibilitiesData.hasError;
        state.teamMemberResponsibilitiesData.isLoading = true;
        state.teamMemberResponsibilitiesData.hasError = false;
      })
      .addCase(fetchTeamMemberResponsibilities.fulfilled, (state, action) => {
        state.teamMemberResponsibilitiesData.isInitiated = true;
        state.teamMemberResponsibilitiesData.isLoading = false;
        state.teamMemberResponsibilitiesData.data = action.payload;
      })
      .addCase(fetchTeamMemberResponsibilities.rejected, (state, action) => {
        state.teamMemberResponsibilitiesData.isLoading = false;
        state.teamMemberResponsibilitiesData.data = [];
        state.teamMemberResponsibilitiesData.hasError = true;
      })

      .addCase(fetchJobTitles.pending, (state, action) => {
        state.jobTitlesData.isInitiated =
          state.jobTitlesData.isInitiated && !state.jobTitlesData.hasError;
        state.jobTitlesData.isLoading = true;
        state.jobTitlesData.hasError = false;
      })
      .addCase(fetchJobTitles.fulfilled, (state, action) => {
        state.jobTitlesData.isInitiated = true;
        state.jobTitlesData.isLoading = false;
        state.jobTitlesData.data = action.payload;
      })
      .addCase(fetchJobTitles.rejected, (state, action) => {
        state.jobTitlesData.isLoading = false;
        state.jobTitlesData.data = [];
        state.jobTitlesData.hasError = true;
      })
      .addCase(fetchCountries.pending, (state, action) => {
        state.countriesData.isInitiated =
          state.countriesData.isInitiated && !state.countriesData.hasError;
        state.countriesData.isLoading = true;
        state.countriesData.hasError = false;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countriesData.isInitiated = true;
        state.countriesData.isLoading = false;
        state.countriesData.data = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countriesData.isLoading = false;
        state.countriesData.data = [];
        state.countriesData.hasError = true;
      })
      .addCase(fetchLanguages.pending, (state, action) => {
        state.languagesData.isLoading = true;
        state.languagesData.isInitiated =
          state.languagesData.isInitiated && !state.languagesData.hasError;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.languagesData.data = action.payload;
        state.languagesData.isInitiated = true;
        state.languagesData.isLoading = false;
        state.languagesData.hasError = false;
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.languagesData.data = [];
        state.languagesData.isLoading = false;
        state.languagesData.hasError = true;
      });
  },
});

export const {
  clearCompanyActivities,
  clearTeamMemberResponsibilities,
  clearJobTitles,
  clearCountries,
} = slice.actions;
export default slice.reducer;
