import Table from "components/Table";

interface ProductHeaderProps {
  listingResult?: string;
}

const SubscriptionTableHeader: React.FC<ProductHeaderProps> = ({
  listingResult,
}) => {
  return <Table.Header listingResult={listingResult} />;
};

export default SubscriptionTableHeader;
