import { createSlice } from "@reduxjs/toolkit";

import { fetchPendingCompanies } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "companyPendingList",
  initialState,
  reducers: {
    clearCompanies: (state, action) => {
      state.data = initialState.data;
      state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      state.isLoading = false;
      state.hasError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPendingCompanies.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchPendingCompanies.fulfilled, (state, action) => {
        state.data = action.payload.pendingCompanies;
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.isLoading = false;
      })
      .addCase(fetchPendingCompanies.rejected, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      });
  },
});

export const { clearCompanies } = slice.actions;
export default slice.reducer;
