/* istanbul ignore file */
import { useIntl } from "react-intl";

import usePermissions from "core/oidc/usePermissions";

interface HeaderRoute {
  path: string;
  text: string;
  subNav: HeaderRoute[];
  isVisible: boolean;
}

export default function useHeaderRoutes() {
  const { formatMessage } = useIntl();
  const { isCompanyAdmin, isTraceoneAdmin } = usePermissions();

  const homePath = "/";

  const headerRoutes: HeaderRoute[] = [
    {
      path: "/users/list",
      text: formatMessage({ id: "general.users" }),
      isVisible: isCompanyAdmin || isTraceoneAdmin,
      subNav: [],
    },
    {
      path: "/companies/list",
      text: formatMessage({ id: "general.companies" }),
      isVisible: isTraceoneAdmin,
      subNav: [],
    },
    {
      path: "/company-details",
      text: formatMessage({ id: "general.companies" }),
      isVisible: !isTraceoneAdmin,
      subNav: [],
    },
    {
      path: "/groups/list",
      text: formatMessage({ id: "general.group" }),
      isVisible: isTraceoneAdmin && window.env.COMPANY_GROUP === "true",
      subNav: [],
    },
  ];

  return { homePath, headerRoutes: headerRoutes.filter(r => r.isVisible) };
}
