import { useCallback, useEffect } from "react";

import { useReactOidc } from "@axa-fr/react-oidc-context";

import useLogout from "core/oidc/useLogout";

import { useAppDispatch } from "reduxStore";
import {
  setOidcAccessToken,
  setOidcPermissions,
  setOidcCompanyActivityToStore,
  setOidcGlobalUserId,
  setOidcIsTraceoneAdmin,
} from "reduxStore/oidc/slice";

export default function useOidcSetup() {
  const dispatch = useAppDispatch();
  const oidc = useReactOidc();
  const {
    oidcUser: {
      access_token,
      profile: {
        permissions,
        company_activity,
        global_user_id,
        is_company_traceone,
      },
    },
    events,
  } = oidc;
  const logout = useLogout();

  const saveOidcAccessTokenToStore = useCallback(
    ({ access_token }) => {
      dispatch(setOidcAccessToken(access_token));
    },
    [dispatch]
  );

  const saveOidcGlobalUserId = useCallback(
    ({ global_user_id }) => {
      dispatch(setOidcGlobalUserId(global_user_id));
    },
    [dispatch]
  );

  const saveOidcAllPermisionsToStore = useCallback(
    ({ permissions }) => {
      dispatch(setOidcPermissions(permissions));
    },
    [dispatch]
  );

  const saveOidcCompanyActivityToStore = useCallback(
    ({ company_activity }) => {
      dispatch(setOidcCompanyActivityToStore(company_activity));
    },
    [dispatch]
  );

  const saveOidcIsTraceoneAdmin = useCallback(
    ({ is_company_traceone }) => {
      dispatch(setOidcIsTraceoneAdmin(is_company_traceone === "True"));
    },
    [dispatch]
  );

  useEffect(() => {
    saveOidcAccessTokenToStore({ access_token });
    saveOidcAllPermisionsToStore({ permissions });
    saveOidcCompanyActivityToStore({ company_activity });
    saveOidcGlobalUserId({ global_user_id });
    saveOidcIsTraceoneAdmin({ is_company_traceone });
    // Access token will be renewed by subscription below
  }, []);

  useEffect(() => {
    if (!events) {
      return;
    }
    const logoutCallback = () => {
      logout();
    };

    events.addUserLoaded(saveOidcAccessTokenToStore);

    const saveOidcPermissionsToStoreCb = user =>
      saveOidcAllPermisionsToStore(user.profile);
    events.addUserLoaded(saveOidcPermissionsToStoreCb);

    events.addUserSignedOut(logoutCallback);

    return () => {
      events.removeUserLoaded(saveOidcAccessTokenToStore);
      events.removeUserLoaded(saveOidcPermissionsToStoreCb);
      events.removeUserSignedOut(logoutCallback);
    };
  }, [events, saveOidcAccessTokenToStore, saveOidcAllPermisionsToStore]);

  return oidc;
}
