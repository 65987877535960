import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import useUserStatuses from "pages/Users/hooks/useUserStatuses";

import {
  selectJobTitlesData,
  selectTeamMemberResponsibilitiesData,
  selectTeamMemberResponsibilitiesMap,
} from "reduxStore/shared/selectors";

import CheckableTags from "components/CheckableTags";
import SearchFilters, { SearchFiltersProps } from "components/SearchFilters";
import Select from "components/Select";
import { UserStatus } from "shared/constants";
import useAppTranslations from "shared/hooks/useAppTranslations";

import { CompanyAdminUserFilter } from "./models";

interface UserSearchProps {
  filterObj: CompanyAdminUserFilter;
  mergeFilters: (
    value:
      | Partial<CompanyAdminUserFilter>
      | ((prevState: CompanyAdminUserFilter) => CompanyAdminUserFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const OldUserSearch: React.FC<UserSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const { appLabelOptions, appLabelMap } = useAppTranslations();
  const { userStatusLabels, getUserStatusOptions } = useUserStatuses();

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );
  const jobTitlesMap = jobTitlesOptions.reduce<{
    [itemCode: string]: string;
  }>((prev, current) => ({ ...prev, [current.value]: current.label }), {});

  const teamMemberResponsibilitiesOptions = useSelector(
    selectTeamMemberResponsibilitiesData
  ).data.map(({ id, text }) => ({
    value: id,
    label: text,
  }));
  const teamMemberResponsibilitiesMap = useSelector(
    selectTeamMemberResponsibilitiesMap
  );

  const optionsToDisplay = appLabelOptions.filter(option => {
    return option["value"] !== "40";
  });

  const sortArray = (x, y) => x.label.localeCompare(y.label);
  const sortedOptionsToDisplay = optionsToDisplay.sort(sortArray);

  const getSelectedFilters = () => {
    const selectedFilters = [];
    if (filterObj.userJobTitle) {
      selectedFilters.push({
        id: filterObj.userJobTitle,
        value: jobTitlesMap[filterObj.userJobTitle],
        name: jobTitlesMap[filterObj.userJobTitle],
        onCloseClick: () => mergeFilters({ userJobTitle: undefined }),
      });
    }
    if (filterObj.responsibilityId) {
      selectedFilters.push({
        id: filterObj.responsibilityId,
        value: teamMemberResponsibilitiesMap[filterObj.responsibilityId]?.text,
        name: teamMemberResponsibilitiesMap[filterObj.responsibilityId]?.text,
        onCloseClick: () => mergeFilters({ responsibilityId: undefined }),
      });
    }

    if (filterObj.applicationTypeIds) {
      filterObj.applicationTypeIds.forEach(option => {
        selectedFilters.push({
          id: option,
          value: appLabelMap[option],
          name: appLabelMap[option],
          onCloseClick: () =>
            mergeFilters(prev => ({
              ...prev,
              applicationTypeIds: prev.applicationTypeIds.filter(
                appId => appId !== option
              ),
            })),
        });
      });
    }

    filterObj.administratorFilterList?.forEach(option => {
      const label =
        option === "yes"
          ? `${formatMessage({
              id: "general.administrator",
            })}: ${formatMessage({ id: "general.yes" })}`
          : `${formatMessage({
              id: "general.administrator",
            })}: ${formatMessage({ id: "general.no" })}`;

      selectedFilters.push({
        id: `isAdministrator:${option}`,
        value: label,
        name: label,
        onCloseClick: () =>
          mergeFilters(prev => ({
            ...prev,
            administratorFilterList: prev.administratorFilterList.filter(
              id => id !== option
            ),
          })),
      });
    });
    filterObj.userStatuses?.forEach(option => {
      selectedFilters.push({
        id: option,
        value: userStatusLabels[option],
        name: userStatusLabels[option],
        onCloseClick: () =>
          mergeFilters(prev => ({
            ...prev,
            userStatuses: prev.userStatuses.filter(
              userStatus => userStatus !== option
            ),
          })),
      });
    });

    return selectedFilters;
  };

  return (
    <SearchFilters
      onSearch={onSearch}
      searchPlaceholder={formatMessage({
        id: "userList.companyAdmin.searchPlaceholder",
      })}
      onClearFiltersClick={onClearFiltersClick}
      initialSearchValue={initialSearchValue}
      selectedFilters={getSelectedFilters()}
      filters={[
        {
          label: formatMessage({ id: "general.jobTitle" }),
          content: (
            <Select
              value={filterObj.userJobTitle}
              onChange={userJobTitle => mergeFilters({ userJobTitle })}
              options={jobTitlesOptions}
              allowClear
              showSearch
              data-test-id="ad-filter-jobTitle"
            />
          ),
        },
        {
          label: formatMessage({ id: "general.responsibilities" }),
          content: (
            <Select
              value={filterObj.responsibilityId}
              onChange={responsibilityId => mergeFilters({ responsibilityId })}
              options={teamMemberResponsibilitiesOptions}
              allowClear
              showSearch
              data-test-id="ad-filter-responsibilityId"
            />
          ),
        },
        {
          label: formatMessage({ id: "general.applications" }),
          content: (
            <Select
              mode="multiple"
              value={filterObj.applicationTypeIds}
              onChange={applicationTypeIds =>
                mergeFilters({ applicationTypeIds })
              }
              options={sortedOptionsToDisplay}
              allowClear
              showSearch
              data-test-id="ad-filter-applicationTypeId"
            />
          ),
        },
        {
          label: formatMessage({
            id: "general.administrator",
          }),
          content: (
            <CheckableTags
              value={filterObj.administratorFilterList}
              onChange={administratorFilterList =>
                mergeFilters({
                  administratorFilterList,
                })
              }
              options={[
                {
                  value: "yes",
                  label: formatMessage({ id: "general.yes" }),
                },
                {
                  value: "no",
                  label: formatMessage({ id: "general.no" }),
                },
              ]}
            />
          ),
        },
        {
          label: formatMessage({
            id: "general.status",
          }),
          content: (
            <CheckableTags
              value={filterObj.userStatuses}
              onChange={userStatuses =>
                mergeFilters({
                  userStatuses,
                })
              }
              options={getUserStatusOptions([
                UserStatus.ENABLED,
                UserStatus.DISABLED,
              ])}
            />
          ),
        },
      ]}
    />
  );
};

export default OldUserSearch;
