import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

const selectOidc = (state: RootState) => state.oidc;

export const selectOidcAccessToken = createSelector(
  selectOidc,
  oidc => oidc.accessToken
);
export const selectOidcUserPermissions = createSelector(
  selectOidc,
  oidc => oidc.permissions
);

export const selectOidcGlobalUserId = createSelector(
  selectOidc,
  oidc => oidc.globalUserId
);

export const selectCompanyActivity = createSelector(
  selectOidc,
  oidc => oidc.companyActivity
);

export const selectOidcIsTraceoneAdmin = createSelector(
  selectOidc,
  oidc => oidc.isTraceoneAdmin
);
