import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import { Button } from "@trace-one/design-system";

import { CompanyForTraceoneAdminData, SubscriptionData } from "models";

import Table from "components/Table";
import { CompanyStatus } from "shared/constants";

import usePermissions from "../../../../core/oidc/usePermissions";

import styles from "./CompanySubscriptionList.module.less";
import useCompanySubscriptionTable from "./hooks/useCompanySubscriptionTable";
import SubscriptionTableHeader from "./SubscriptionTableHeader";

interface CompanySubscriptionListProps {
  company: CompanyForTraceoneAdminData;
  companySubscriptions: SubscriptionData[];
  refetchData?: () => void;
  isLoading?: boolean;
}

const CompanySubscriptionList: React.FC<CompanySubscriptionListProps> = ({
  company,
  companySubscriptions,
  refetchData,
  isLoading,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { columns, data } = useCompanySubscriptionTable({
    companyId: company.companyId,
    companySubscriptions,
    refetchData,
  });

  return (
    <>
      <div className={styles.actions}>
        {(hasRoleSuperAdmin || hasRoleAdministrator) &&
          company.companyStatus === CompanyStatus.ENABLED && (
            <Button
              onClick={() => {
                history.push(
                  `/companies/${company.companyId}/subscriptions/create`
                );
              }}
              data-test-id="ad-subscription-create"
            >
              {formatMessage({
                id: "general.createSubscription",
              })}
            </Button>
          )}
      </div>

      <SubscriptionTableHeader
        listingResult={formatMessage(
          { id: "companySubscriptionList.tableHeader.listingResultNumber" },
          {
            current: companySubscriptions.length,
          }
        )}
      />

      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        hidePagination
      />
    </>
  );
};

export default CompanySubscriptionList;
