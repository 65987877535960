import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { UserForUIAdminData } from "models";

import { selectCompaniesMap } from "reduxStore/userList/selectors";

import AppIcons from "components/AppIcons";
import { UserStatus } from "shared/constants";

import usePermissions from "../../../../../core/oidc/usePermissions";
import { selectJobTitlesData } from "../../../../../reduxStore/shared/selectors";
import useUserStatuses from "../../../hooks/useUserStatuses";
import { TraceoneAdminUserTable } from "../models";
import UserAction from "../UserAction";

export default function useUserTable({
  users,
  refetchUsers,
}: {
  users: UserForUIAdminData[];
  refetchUsers: () => void;
}) {
  const { formatMessage } = useIntl();

  const { hasRoleSupportAdmin, hasRoleAdministrator, hasRoleSuperAdmin } =
    usePermissions();

  const history = useHistory();

  const { userStatusLabels, userStatusTagColors } = useUserStatuses();
  const companiesMap = useSelector(selectCompaniesMap);

  const columns: ColumnsType<TraceoneAdminUserTable> = [
    {
      title: formatMessage({
        id: "general.user.login",
      }),
      dataIndex: "userFullname",
      key: "userFullname",
      width: 200,
      ellipsis: true,
      render: (name, record) => {
        if (record.userStatus !== UserStatus.ENABLED) {
          return name;
        }

        return hasRoleSuperAdmin ||
          (record.rawData.isTraceOneCompany === false &&
            hasRoleAdministrator) ||
          (record.rawData.isTraceOneCompany === false &&
            hasRoleSupportAdmin) ? (
          <Typography.Link
            onClick={() => {
              history.push(`/users/access/${record.key}`);
            }}
          >
            {name}
          </Typography.Link>
        ) : (
          <Typography>{name}</Typography>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "userStatus",
      key: "userStatus",
      width: 140,
      render: (userStatus, record) => {
        let status = userStatus;
        if (record.rawData?.userLocked) status = UserStatus.LOCKED;
        if (record.rawData.isMainContact === true) {
          return (
            <Tag
              label={formatMessage({
                id: "general.isMainContact",
              })}
              color={userStatusTagColors[status]}
              size="medium"
              mode="light"
            />
          );
        } else {
          return (
            <Tag
              label={userStatusLabels[status]}
              color={userStatusTagColors[status]}
              size="medium"
              mode="light"
            />
          );
        }
      },
    },

    {
      title: formatMessage({
        id: "userList.table.administrator",
      }),
      dataIndex: "administrator",
      key: "administrator",
      width: 100,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.company",
      }),
      dataIndex: "companyDisplayName",
      key: "companyDisplayName",
      width: 180,
      ellipsis: true,
      render: (name, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/companies/${record.rawData.owningCompanyId}`);
            }}
          >
            {name}
          </Typography.Link>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.validatedOn",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
    },

    {
      title: formatMessage({
        id: "general.jobTitle",
      }),
      dataIndex: "userJobTitle",
      key: "userJobTitle",
      width: 150,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applications",
      key: "applications",
      width: 180,
      render: (applications: number[], record) => {
        return <AppIcons applications={applications} />;
      },
    },

    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <UserAction
          data={record.rawData}
          users={users}
          refetchUsers={refetchUsers}
          companyStatus={record.companyStatus}
        />
      ),
    },
  ];

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );
  const jobTitlesMap = jobTitlesOptions.reduce<{
    [itemCode: string]: string;
  }>((prev, current) => ({ ...prev, [current.value]: current.label }), {});

  const data = users?.map(user => {
    const result: TraceoneAdminUserTable = {
      key: user.userId,
      userFullname: (
        <div>
          <span>
            {[user.userFirstName, user.userLastName]
              .filter(item => !!item)
              .join(" ")}
          </span>
          <br />
          <span
            style={{
              color: "#929292",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {user.userLogin}
          </span>
        </div>
      ),
      userStatus: user.userStatus,
      userLogin: user.userLogin,
      companyDisplayName:
        companiesMap[user.owningCompanyId]?.companyDisplayName,
      companyStatus: companiesMap[user.owningCompanyId]?.companyStatus,
      userJobTitle: jobTitlesMap[user.userJobTitleId],
      administrator: formatMessage({
        id: user.isAccountAdministrator ? "general.yes" : "general.no",
      }),
      createdAt: moment(user.createdAt).format("DD/MM/YYYY"),
      applications: user.applicationTypeIds.filter(
        app => app !== 40 && user.applicationTypeIds
      ),
      rawData: user,
    };
    return result;
  });

  return { columns, data };
}
