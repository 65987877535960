import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Search, Checkbox } from "@trace-one/design-system";
import debounce from "lodash/debounce";

export interface option {
  label: string;
  value: string;
  "data-test-id"?: string;
}

interface FilterMultiSelectionProps {
  options: option[];
  onChange: (searchValue: option[]) => void;
  values: option[];
  dataTestId?: string;
}

const FilterMultiSelection: React.FC<FilterMultiSelectionProps> = ({
  options,
  onChange,
  values,
  dataTestId,
}) => {
  const { formatMessage } = useIntl();

  const isDirty = useRef(false);
  const [searchText, setSearchText] = useState("");

  const shouldIncludeSelectAll = searchText.length < 3 && !searchText.trim();

  const debouncedFilterItems = useRef(
    debounce(searchStr => {
      if (searchStr.length >= 3) {
        filterItems(searchStr);
      } else {
        setFilteredOptions(selectOptions);
        setSelectedValues(values.map(e => e.value));
      }
    }, 300)
  ).current;

  const handleSearchChange = e => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    debouncedFilterItems(newSearchText);
  };

  const handleChangeOption = e => {
    isDirty.current = true;
    const value = e.target.value;

    setSearchText("");
    setFilteredOptions(selectOptions);

    setSelectedValues(selectedOptions => {
      let updatedOptions = new Set(selectedOptions);

      if (value === "all") {
        if (updatedOptions.has("all")) {
          updatedOptions.clear();
        } else {
          options.forEach(option => updatedOptions.add(option.value));
        }
      } else {
        updatedOptions.delete("all"); // Ensure 'all' is removed when any other option is selected
        if (updatedOptions.has(value)) {
          updatedOptions.delete(value);
        } else {
          updatedOptions.add(value);
        }
      }

      return [...updatedOptions];
    });
  };

  const selectOptions = options.map(option => {
    return {
      ...option,
      onChange: handleChangeOption,
    };
  });

  const selectAllOption = {
    label: formatMessage({ id: "general.selectAll" }),
    value: "all",
    "data-test-id": "select-all-checkbox",
    onChange: e => {
      if (e.target.value === "all") {
        isDirty.current = true;
        if (filteredOptions.every(e => selectedValues.includes(e.value))) {
          setSelectedValues(values => {
            const filteredValues = filteredOptions.map(({ value }) => value);
            return values.filter(
              val => !filteredValues.includes(val) && val !== "all"
            );
          });
        } else {
          setSelectedValues(values => {
            const filteredValues = filteredOptions.map(({ value }) => value);
            return [
              ...values.filter(
                val => !filteredValues.includes(val) && val !== "all"
              ),
              ...filteredValues,
              "all",
            ];
          });
        }
      }
    },
  };

  const [filteredOptions, setFilteredOptions] = useState(selectOptions);
  const [selectedValues, setSelectedValues] = useState(
    values.map(e => e.value)
  );

  const filterItems = (searchStr: string) => {
    if (searchStr.length >= 3) {
      const filteredOptions = selectOptions.filter(x =>
        x.label.toLowerCase().includes(searchStr.toLowerCase())
      );
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(selectOptions);
    }
  };

  useEffect(() => {
    if (isDirty.current) {
      const selectedOptions = selectOptions.filter(option =>
        selectedValues.includes(option.value)
      );
      onChange(selectedOptions);
    }
  }, [selectedValues]);

  useEffect(() => {
    isDirty.current = false;
    setSelectedValues(values.map(e => e.value));
  }, [values]);

  useEffect(() => {
    if (
      filteredOptions.length &&
      filteredOptions.every(e => selectedValues.includes(e.value)) &&
      !selectedValues.includes("all")
    ) {
      setSelectedValues([...selectedValues, "all"]);
    }
  }, [selectedValues, filteredOptions]);

  return (
    <div>
      <Search
        data-test-id={dataTestId}
        onSearch={filterItems}
        onChange={handleSearchChange}
        value={searchText}
      ></Search>
      <div>
        <br />
      </div>
      <Checkbox.Group
        direction="vertical"
        value={selectedValues}
        options={
          shouldIncludeSelectAll
            ? [selectAllOption, ...filteredOptions]
            : filteredOptions
        }
      />
    </div>
  );
};

export default FilterMultiSelection;
