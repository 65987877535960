/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";

import { fetchAdminCompany } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAdminCompany.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorStatus = null;
      })
      .addCase(fetchAdminCompany.fulfilled, (state, action) => {
        state.data = action.payload.company;
        state.isLoading = false;
      })
      .addCase(fetchAdminCompany.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.hasError = true;
        state.errorStatus = (action.payload as any).status;
      });
  },
});

export default slice.reducer;
