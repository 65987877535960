import React, { useState } from "react";
import { useIntl } from "react-intl";

import { FormControllerProps } from "../../../../../components/FormController";
import { ApplicationType } from "../../../../../shared/constants";
import styles from "../CompanySupscriptionForm.module.less";
import { ContributionTypeAnimator, SubscriptionValues } from "../models";

import InputNumber from "./components/InputNumber";
import NutriscoreCalculation from "./components/NutriscoreCalculation";
import SignatureTypeSelect from "./components/SignatureTypeSelect";
import TemplateSelect from "./components/TemplateSelect";

interface LimitationsProps {
  subscription: any;
  control: FormControllerProps<SubscriptionValues>["control"];
  applicationTypeId: number;
  contributionType: string;
  defaultNumberOfTemplates: number;
  watch: (any?) => any;
  setNutriscoreCalculation: (boolean) => void;
  nutriscoreCalculation: boolean;
}

const Limitations: React.FC<LimitationsProps> = ({
  subscription,
  control,
  applicationTypeId,
  contributionType,
  defaultNumberOfTemplates,
  watch,
  setNutriscoreCalculation,
  nutriscoreCalculation,
}) => {
  const { formatMessage } = useIntl();

  const [numberOfTemplates, setNumberOfTemplates] = useState(
    defaultNumberOfTemplates
  );

  return (
    <div className={styles.formSectionWrapper}>
      <p>
        {formatMessage({
          id: "companySubscriptionForm.specifyLimitationsForApp",
        })}
      </p>
      <div className={styles.limitationsWrapper}>
        {contributionType === ContributionTypeAnimator &&
        applicationTypeId === ApplicationType.SPEC ? (
          <>
            <div className={styles.generalLimitation}>
              <InputNumber
                name="maxUsers"
                control={control}
                rules={{
                  max: {
                    value: 10000,
                    message: formatMessage({
                      id: "companySubscriptionForm.numberOfUsers.error",
                    }),
                  },
                }}
                data-test-id="ad-number-of-users"
                placeholder={formatMessage({
                  id: "companySubscriptionForm.numberOfUsers.placeholder",
                })}
              />

              <SignatureTypeSelect
                control={control}
                subscription={subscription}
                name="electronicSignatureTypes"
                data-test-id="signature-list"
                rules={{
                  required: formatMessage({
                    id: "general.error.mandatory.fields",
                  }),
                }}
              />
            </div>
            <div className={styles.templatesLimitation}>
              <InputNumber
                name="maxNumberOfTemplates"
                control={control}
                rules={{
                  required: formatMessage({
                    id: "general.error.mandatory.fields",
                  }),
                  min: {
                    value:
                      watch("templateListIds")?.length > 0
                        ? watch("templateListIds")?.length
                        : 1,
                    message: formatMessage({
                      id: "general.error.mandatory.fields",
                    }),
                  },
                }}
                data-test-id="ad-number-of-templates"
                setNumberOfTemplates={setNumberOfTemplates}
              />
              <TemplateSelect
                control={control}
                subscription={subscription}
                name="templateListIds"
                data-test-id="template-list"
                numberOfTemplates={numberOfTemplates}
                rules={{
                  required: formatMessage({
                    id: "general.error.mandatory.fields",
                  }),
                }}
              />
            </div>
            <NutriscoreCalculation
              control={control}
              subscription={subscription}
              name="nutriscoreCalculation"
              data-test-id="nutriscore-calculation"
              setNutriscoreCalculation={setNutriscoreCalculation}
              nutriscoreCalculation={nutriscoreCalculation}
            />
          </>
        ) : (
          <InputNumber
            name="maxUsers"
            control={control}
            rules={{
              max: {
                value: 10000,
                message: formatMessage({
                  id: "companySubscriptionForm.numberOfUsers.error",
                }),
              },
            }}
            data-test-id="ad-number-of-users"
            placeholder={formatMessage({
              id: "companySubscriptionForm.numberOfUsers.placeholder",
            })}
          />
        )}
      </div>
    </div>
  );
};

export default Limitations;
