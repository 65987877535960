import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { DatePicker } from "@trace-one/react-components";
import moment from "moment";
import useUserStatuses from "pages/Users/hooks/useUserStatuses";

import { CumdAPI } from "apis";

import CheckableTags from "components/CheckableTags";
import SearchFilters, { SearchFiltersProps } from "components/SearchFilters";
import Select from "components/Select";
import { UserStatus } from "shared/constants";

import { TraceoneAdminUserFilter } from "./models";

interface PendingUserSearchProps {
  filterObj: TraceoneAdminUserFilter;
  mergeFilters: (
    value:
      | Partial<TraceoneAdminUserFilter>
      | ((prevState: TraceoneAdminUserFilter) => TraceoneAdminUserFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
  setFilterObj: React.Dispatch<React.SetStateAction<TraceoneAdminUserFilter>>;
  resetPageNumberAndRowKeys: () => void;
}

const OldUserPendingSearch: React.FC<PendingUserSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();

  const { userStatusLabels } = useUserStatuses();
  const [companies, setCompanies] = useState([]);

  const getSelectedFilters = () => {
    const selectedFilters = [];
    if (filterObj.compnayLegalName) {
      selectedFilters.push({
        value: compnayLegalName,
        name: compnayLegalName,
        onCloseClick: () => mergeFilters({ compnayLegalName: undefined }),
      });
    }

    if (filterObj.createdAt) {
      selectedFilters.push({
        value: filterObj.createdAt,
        name: filterObj.createdAt,
        onCloseClick: () => mergeFilters({ createdAt: undefined }),
      });
    }

    filterObj.userStatuses?.forEach(option => {
      const label = userStatusLabels[option];

      selectedFilters.push({
        value: label,
        name: label,
        onCloseClick: () =>
          mergeFilters(prev => ({
            ...prev,
            userStatuses: prev.userStatuses.filter(
              userStatus => userStatus !== option
            ),
          })),
      });
    });

    return selectedFilters;
  };

  const { compnayLegalName } = filterObj;

  const getCompaniesOptions = compnayLegalName => {
    return CumdAPI.getCompaniesByFiltersForToAdmin(
      {},
      { searchText: compnayLegalName }
    ).then(({ data }) => {
      setCompanies(data.companies);
    });
  };

  useEffect(() => {
    getCompaniesOptions(compnayLegalName);
  }, [compnayLegalName]);

  const companyOptions = companies.map(itemCode => ({
    value: itemCode.companyDisplayName,
    label: itemCode.companyDisplayName,
  }));

  return (
    <SearchFilters
      onSearch={onSearch}
      searchPlaceholder={formatMessage({
        id: "userList.toAdmin.searchPlaceholder",
      })}
      onClearFiltersClick={onClearFiltersClick}
      initialSearchValue={initialSearchValue}
      selectedFilters={getSelectedFilters()}
      filters={[
        {
          label: formatMessage({ id: "general.company" }),
          content: (
            <Select
              value={filterObj.compnayLegalName}
              onChange={compnayLegalName => {
                mergeFilters({ compnayLegalName });
              }}
              options={companyOptions}
              allowClear
              showSearch
              data-test-id="ad-filter-compnayLegalName"
            />
          ),
        },

        {
          label: formatMessage({ id: "general.createdOn" }),
          content: (
            <DatePicker
              value={
                filterObj.createdAt
                  ? moment(filterObj.createdAt, "DD/MM/YYYY")
                  : undefined
              }
              onChange={createdAt => {
                mergeFilters({ createdAt: createdAt?.format("DD/MM/YYYY") });
              }}
            />
          ),
        },

        {
          label: formatMessage({
            id: "general.status",
          }),
          content: (
            <CheckableTags
              data-test-id="pending"
              value={filterObj.userStatuses}
              onChange={userStatuses =>
                mergeFilters({
                  userStatuses,
                })
              }
              options={[
                {
                  value: UserStatus.PENDING,
                  label: formatMessage({ id: "general.pending" }),
                },
                {
                  value: UserStatus.REJECTED,
                  label: formatMessage({ id: "general.rejected" }),
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default OldUserPendingSearch;
