import React from "react";

import { Graphic } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";

import { ApplicationType } from "shared/constants";

export const ApplicationIconNamesMapping = {
  [ApplicationType.QUALITY_PORTAL]: "QP",
  [ApplicationType.PACK_PORTAL]: "PP",
  [ApplicationType.PDM]: "PDM",
  [ApplicationType.PDM_NA]: "PDM_NA",
  [ApplicationType.PDM_FIND]: "PDM_MS",
  [ApplicationType.BUSINESS_INTELLIGENCE]: "BI",
  [ApplicationType.INSIGHT]: "Insight",
  [ApplicationType.TON]: "Network",
  [ApplicationType.PROJECT]: "Project",
  [ApplicationType.QMS]: "QMS",
  [ApplicationType.SPEC]: "Spec",
  [ApplicationType.RFX]: "RFX",
  [ApplicationType.MASTERDATA]: "MD",
  [ApplicationType.ADMINISTRATION]: "Admin",
};

export const ApplicationIconTooltipMapping = {
  [ApplicationType.SPEC]: "Specification",
  [ApplicationType.MASTERDATA]: "Master Data",
  [ApplicationType.INSIGHT]: "Insight",
  [ApplicationType.ADMINISTRATION]: "Administration",
  [ApplicationType.PACK_PORTAL]: "Pack Portal",
  [ApplicationType.QUALITY_PORTAL]: "Quality Portal",
  [ApplicationType.BUSINESS_INTELLIGENCE]: "Business Intelligence",
  [ApplicationType.PDM_NA]: "PLM NA",
  [ApplicationType.PDM_FIND]: "M&S FIND",
};

export interface AppIconProps {
  type?: number | string;
  size?: number;
  spacing?: boolean;
}

const AppIcon: React.FC<AppIconProps> = ({ type }) => {
  const appName = ApplicationIconNamesMapping[type];
  const appTooltip = ApplicationIconTooltipMapping[type] ?? appName;

  if (appName) {
    return (
      <Tooltip title={appTooltip}>
        <Graphic
          name={`app-logo-${appName}-full`}
          size="large"
          data-test-id={`logo-${appName}-full`}
          style={{ display: "inline-flex" }}
        />
      </Tooltip>
    );
  }

  return null;
};

export default AppIcon;
