type State = {
  accessToken?: string;
  permissions: string;
  companyActivity?: number;
  globalUserId?: string;
  isTraceoneAdmin?: boolean;
};

export const initialState: State = {
  accessToken: null,
  permissions: "",
  companyActivity: null,
  globalUserId: "",
  isTraceoneAdmin: false,
};
