import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button } from "@trace-one/design-system";

import usePermissions from "core/oidc/usePermissions";

import Switcher from "components/Switcher";

import styles from "./TraceoneAdminUserList.module.less";

interface TOAdminUsersTableHeaderProps {
  showed?: string;
  setChangeShow: any;
  totalCount?: number;
  currentNumber?: number;
  handleExport?: () => void;
}

const TraceoneAdminUserTableHeader: React.FC<TOAdminUsersTableHeaderProps> = ({
  totalCount,
  currentNumber,
  setChangeShow,
  showed,
  handleExport,
}) => {
  const { isTraceoneAdmin } = usePermissions();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleChange = (event: any) => {
    setChangeShow(event.target.value);
    showed === "pendingUsers"
      ? history.push(`/users/list`)
      : history.push(`/users/pending/list`);
  };

  return (
    <>
      <div className={styles.switch}>
        <div className={styles.userlistNumber}>
          <span className={styles.productsListNumberCount}>
            {formatMessage(
              { id: "userList.tableHeader.listingResultNumber" },
              {
                currentNumber: currentNumber,
                total: totalCount,
              }
            )}
          </span>
        </div>

        <div className={styles.buttonWrapper}>
          {isTraceoneAdmin && (
            <div className={styles.switchButton}>
              <Switcher
                onChange={handleChange}
                value={showed}
                options={[
                  {
                    value: "registeredUsers",
                    name: formatMessage({ id: "general.registeredUsers" }),
                    dataTestId: "registered-users-btn",
                  },
                  {
                    value: "pendingUsers",
                    name: formatMessage({ id: "general.pendingUser" }),
                    dataTestId: "pending-users-btn",
                  },
                ]}
              />
            </div>
          )}
          {showed === "registeredUsers" && (
            <Button
              className={styles.exportButton}
              type="secondary"
              onClick={handleExport}
              disabled={totalCount === 0 || null}
            >
              {formatMessage({ id: "general.export" })}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TraceoneAdminUserTableHeader;
