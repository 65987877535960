import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";

import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import { CumdAPI } from "../../../../apis";
import { UserStatus } from "../../../../shared/constants";
import useTablePagination from "../../../../shared/hooks/useTablePagination";
import { TablePaginationStorage } from "../../../../shared/webStorage";
import CreateUser from "../../containers/CreateUser";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());

export const CreateUserPage: React.FC<BaseHocProps> = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  const [users, setUsers] = useState([]);
  const [skipAndTakeQueryStats, setSkipAndTakeQueryStats] = useState<object>({
    currentCount: undefined,
    totalCount: undefined,
  });

  const { paginationQuery, setPaginationQuery } = useTablePagination({
    skipAndTakeQueryStats,
    webStorage: TablePaginationStorage.CREATE_USER_PAGE_USER_LIST,
  });

  const [company, setCompany] = useState<any>();

  const fetchCompany = async companyId => {
    try {
      const { data: company } = await CumdAPI.getCompanyById(companyId);
      setCompany(company);
    } catch (error) {}
  };

  const refetchCompany = () => {
    fetchCompany(companyId);
  };
  const getUsers = () => {
    return CumdAPI.getUsersByFiltersForUIAdmin(
      {
        userStatuses: [UserStatus.ENABLED],
      },
      {
        owningCompanyId: companyId,
        skip: paginationQuery.skip,
        take: paginationQuery.take,
      }
    ).then(({ data }) => {
      setUsers(data.users);
      setSkipAndTakeQueryStats(data.skipAndTakeQueryStats);
    });
  };

  useEffect(() => {
    refetchCompany();
    getUsers();
  }, [companyId, paginationQuery]);

  return (
    <CreateUser
      company={company}
      users={users}
      setPaginationQuery={setPaginationQuery}
      skipAndTakeQueryStats={skipAndTakeQueryStats}
    />
  );
};

export default enhance(CreateUserPage);
