import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd";
import { Tooltip, TagV1 as Tag, toaster } from "@trace-one/design-system";
import { v4 } from "uuid";

import { CumdAPI } from "apis";
import { ReferenceListItemData } from "models";

import { selectUserRolesForAppsMap } from "reduxStore/userAccessManage/selectors";

import StickyContainer from "components/StickyContainer";
import useResetForm from "shared/hooks/useResetForm";
import useToast from "shared/hooks/useToast";
import getFirstErrorMessage from "shared/utils/getFirstFormErrorMessage";

import { ErrorCode } from "../../../../shared/errors";
import useUserStatuses from "../../hooks/useUserStatuses";

import BottomPart from "./BottomPart";
import LeftPart from "./LeftPart";
import { UserAccessManageValues } from "./models";
import RightPart from "./RightPart";
import styles from "./UserAccessManage.module.less";
import getDefaultValues from "./utils/getDefaultValues";
import getSubmitData from "./utils/getSubmitData";

const UserAccessManagePage: React.FC<{
  refetchUser: Function;
  user: UserForUIAdmin;
  teamMemberResponsibilities: ReferenceListItemData[];
}> = ({ refetchUser, user, teamMemberResponsibilities }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const rolesMap = useSelector(selectUserRolesForAppsMap);
  const defaultValues = getDefaultValues({ user, rolesMap });
  const { handleSubmit, control, formState, reset } =
    useForm<UserAccessManageValues>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues,
    });
  const resetFormIdRef = useResetForm({ reset, defaultValues });

  const [selectedApp, setSelectedApp] = useState<number>(null);

  const { userStatusLabels, userStatusTagColors } = useUserStatuses();

  const headerTitle = (
    <>
      <Tag
        label={
          user.isMainContact === true
            ? formatMessage({
                id: "general.isMainContact",
              })
            : userStatusLabels[user.userStatus]
        }
        color={userStatusTagColors[user.userStatus]}
        size="medium"
        mode="light"
      />
      <Tooltip
        placement="bottom"
        text={`${user.userFirstName} ${user.userLastName}`}
        showFullText
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            textTransform: "uppercase",
            marginLeft: "16px",
            maxWidth: "55ch",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {user.userFirstName} {user.userLastName}
        </div>
      </Tooltip>
    </>
  );

  const onSubmit = async (values: UserAccessManageValues) => {
    const data = getSubmitData({ values });

    try {
      await CumdAPI.updateUserByAdmin(user.userId, data);
      await refetchUser();
      toast.saveSuccess();
      resetFormIdRef.current = v4();
    } catch (error) {
      error.response.data.errorCode === ErrorCode.REACHED_MAX_NUMBER_OF_USERS
        ? toaster.open({
            message: formatMessage({
              id: "toast.alert",
            }),
            description: formatMessage({
              id: "toast.error.max.number.users.reached",
            }),
            type: "alert",
          })
        : toast.saveError({ error });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, errors => {
        const firstErrorMessage = getFirstErrorMessage(errors);
        toast.error({
          checkErrorResponse: false,
          description: firstErrorMessage,
        });
      })}
    >
      <StickyContainer
        title={headerTitle}
        description={formatMessage({ id: "userAccessManage.description" })}
        isSubmitting={formState.isSubmitting}
        layoutMode="edit"
        onCancel={() => {
          history.push("/users/list");
        }}
      >
        <div className={styles.partsWrapper}>
          <div className={styles.top}>
            <LeftPart user={user} />
            <RightPart
              user={user}
              control={control}
              teamMemberResponsibilities={teamMemberResponsibilities}
              selectedApp={selectedApp}
              setSelectedApp={setSelectedApp}
            />
          </div>
          <div className={styles.bottom}>
            <BottomPart
              user={user}
              control={control}
              selectedApp={selectedApp}
              setSelectedApp={setSelectedApp}
            />
          </div>
        </div>
      </StickyContainer>
    </form>
  );
};

export default UserAccessManagePage;
