import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchJobTitles } from "reduxStore/shared/asyncActions";
import { selectJobTitlesData } from "reduxStore/shared/selectors";
import { clearJobTitles } from "reduxStore/shared/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withJobTitles =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);

      const { hasError, isLoading } = useSelector(selectJobTitlesData);

      useEffect(() => {
        return () => {
          dispatch(clearJobTitles(null));
        };
      }, []);

      useEffect(() => {
        dispatch(
          fetchJobTitles({
            languageCode,
          })
        );
      }, [languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: true,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withJobTitles, WrappedComponent);

    return Component;
  };

export default withJobTitles;
