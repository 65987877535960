import { createSlice } from "@reduxjs/toolkit";

import {
  fetchUsersForTraceoneAdmin,
  fetchUsersForCompanyAdmin,
} from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    clearUsers: (state, action) => {
      state.data = initialState.data;
      state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      state.companiesMap = initialState.companiesMap;
      state.isLoading = false;
      state.hasError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsersForTraceoneAdmin.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchUsersForTraceoneAdmin.fulfilled, (state, action) => {
        state.data = action.payload.users;
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.companiesMap = action.payload.companiesMap;
        state.isLoading = false;
      })
      .addCase(fetchUsersForTraceoneAdmin.rejected, (state, action) => {
        state.data = [];
        state.companiesMap = {};
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      })

      .addCase(fetchUsersForCompanyAdmin.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchUsersForCompanyAdmin.fulfilled, (state, action) => {
        state.data = action.payload.users;
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.isLoading = false;
      })
      .addCase(fetchUsersForCompanyAdmin.rejected, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      });
  },
});

export const { clearUsers } = slice.actions;
export default slice.reducer;
