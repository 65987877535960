import { useIntl } from "react-intl";

import { CompanyActivity } from "shared/constants";

export default function useCompanyActivityLabels() {
  const { formatMessage } = useIntl();

  const companyActivityLabels = {
    [CompanyActivity.RETAILER]: formatMessage({ id: "general.retailer" }),
    [CompanyActivity.SUPPLIER]: formatMessage({ id: "general.supplier" }),
  };

  return {
    companyActivityLabels,
  };
}
