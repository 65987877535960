import { useEffect, useRef } from "react";
import { UnpackNestedValue, UseFormReset, DeepPartial } from "react-hook-form";

function useResetForm<T>({
  reset,
  defaultValues,
}: {
  reset: UseFormReset<T>;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
}) {
  const resetFormIdRef = useRef("");

  useEffect(() => {
    if (resetFormIdRef.current) {
      // @ts-ignore
      reset(defaultValues);
    }
  }, [resetFormIdRef.current]);

  return resetFormIdRef;
}

export default useResetForm;
