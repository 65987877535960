import { GroupData } from "models";

type State = {
  groups: GroupData[];
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  isLoading: boolean;
  hasError: boolean;
  errorStatus: number;
};

export const initialState: State = {
  groups: [],
  skipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  isLoading: true,
  hasError: false,
  errorStatus: null,
};
