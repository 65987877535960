import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button } from "@trace-one/design-system";

import Switcher from "components/Switcher";

import styles from "../../../Users/containers/TraceoneAdminUserList/TraceoneAdminUserList.module.less";

interface CompanyHeaderProps {
  showed?: string;
  setChangeShow: any;
  totalCount?: number;
  currentNumber?: number;
  handleExport?: () => void;
}

const CompanyTableHeader: React.FC<CompanyHeaderProps> = ({
  totalCount,
  currentNumber,
  setChangeShow,
  showed,
  handleExport,
}) => {
  const { formatMessage } = useIntl();

  const history = useHistory();

  const handleChange = (event: any) => {
    setChangeShow(event.target.value);
    showed === "pendingCompanies"
      ? history.push(`/companies/list`)
      : history.push(`/companies/pending/list`);
  };

  return (
    <>
      <div className={styles.switch}>
        <div className={styles.userlistNumber}>
          <span className={styles.productsListNumberCount}>
            {formatMessage(
              { id: "companyList.tableHeader.listingResultNumberText" },
              {
                currentNumber: currentNumber,
                total: totalCount,
              }
            )}
          </span>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.switchButton}>
            <Switcher
              onChange={handleChange}
              value={showed}
              options={[
                {
                  value: "registeredCompanies",
                  name: formatMessage({ id: "general.registeredCompanies" }),
                  dataTestId: "registered-companies-btn",
                },
                {
                  value: "pendingCompanies",
                  name: formatMessage({ id: "general.pendingCompanies" }),
                  dataTestId: "pending-companies-btn",
                },
              ]}
            />
          </div>
          {showed === "registeredCompanies" && (
            <Button
              className={styles.exportButton}
              type="secondary"
              onClick={handleExport}
              disabled={totalCount === 0 || null}
            >
              {formatMessage({ id: "general.export" })}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyTableHeader;
