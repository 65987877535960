import React from "react";
import { useIntl } from "react-intl";

// import { LoadingOutlined } from "@ant-design/icons";
import { Table as AntdTable, Empty, TableProps as AntdTableProps } from "antd";
import cn from "classnames";

import Header from "./Header";
import useResizableColumns from "./hooks/useResizableColumns";
import styles from "./Table.module.less";
import buildSelectAllHandler from "./utils/buildSelectAllHandler";
import buildSelectHandler from "./utils/buildSelectHandler";
import buildTableHandler from "./utils/buildTableHandler";
import calculatePagination from "./utils/calculatePagination";

// export interface TableProps<RecordType>
//   extends Omit<AntdTableProps<RecordType>, "loading"> {
//   skip?: number;
//   take?: number;
//   skipAndTakeQueryStats?: {
//     currentCount?: number;
//     totalCount?: number;
//   };
//   loading?: boolean;
//   setPaginationQuery?: ({ skip, take }: { skip: number; take: number }) => void;
//   setSelectedRowKeys?: (keys: string[]) => void;
//   hidePagination?: boolean;
// }

interface BaseTableProps<RecordType>
  extends Omit<AntdTableProps<RecordType>, "loading"> {
  loading?: boolean;
  selectedRowKeys?: string[];
  setSelectedRowKeys?: (keys: string[]) => void;
}

interface PaginatedTableProps<RecordType> extends BaseTableProps<RecordType> {
  skip: number;
  take: number;
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  setPaginationQuery: ({ skip, take }: { skip: number; take: number }) => void;
  hidePagination?: false;
}

interface NonPaginatedTableProps<RecordType>
  extends BaseTableProps<RecordType> {
  hidePagination: true;
}

export type TableProps<RecordType> =
  | NonPaginatedTableProps<RecordType>
  | PaginatedTableProps<RecordType>;

const TableInternal = <RecordType extends { key: string | number }>({
  columns,
  loading = false,
  setSelectedRowKeys,
  ...rest
}: TableProps<RecordType>): JSX.Element => {
  const { formatMessage } = useIntl();
  const { resizableColumns, components } = useResizableColumns(columns);

  let pagination = {};
  let onChange = rest.onChange;
  if (!("hidePagination" in rest)) {
    const { skip, take, skipAndTakeQueryStats, setPaginationQuery } = rest;
    pagination = calculatePagination({ skip, take, skipAndTakeQueryStats });
    onChange = buildTableHandler({ setPaginationQuery });
  }

  const paginationLocale = {
    items_per_page: formatMessage({ id: "general.page" }),
    page: formatMessage({ id: "general.page" }),
  };

  pagination = {
    ...pagination,
    locale: paginationLocale,
  };
  return (
    <AntdTable
      columns={resizableColumns}
      components={components}
      // loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
      locale={{
        emptyText: (
          <Empty description={formatMessage({ id: "general.noData" })} />
        ),
      }}
      onChange={onChange}
      scroll={{ x: "100%" }}
      tableLayout="fixed"
      {...rest}
      className={cn(styles.root, rest.className)}
      rowSelection={
        setSelectedRowKeys
          ? {
              type: "checkbox",
              onSelect: buildSelectHandler({ setSelectedRowKeys }),
              onSelectAll: buildSelectAllHandler({ setSelectedRowKeys }),
              ...rest.rowSelection,
            }
          : rest.rowSelection
      }
      pagination={
        rest.hidePagination ? false : { ...pagination, ...rest.pagination }
      }
    />
  );
};

type InternalTableType = typeof TableInternal;
interface TableInterface extends InternalTableType {
  Header: typeof Header;
}

const Table = TableInternal as TableInterface;
Table.Header = Header;

export default Table;
