import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { compose, unwrapResult } from "@reduxjs/toolkit";
import { APPS_TO_DISPLAY } from "pages/Companies/constants";

import { useAppDispatch } from "reduxStore";
import {
  fetchCompany,
  fetchOffers,
} from "reduxStore/companySubscriptionForm/asyncActions";
import { selectCompanySubscriptionForm } from "reduxStore/companySubscriptionForm/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";
import { CompanyStatus } from "shared/constants";
import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import CompanySubscriptionForm from "../../containers/CompanySubscriptionForm";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());
export const CompanySubscriptionCreatePage: React.FC<BaseHocProps> = ({
  isInitiated,
}) => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams<{ companyId?: string }>();

  const { companyData, offersData } = useSelector(
    selectCompanySubscriptionForm
  );
  const [isLoading, setIsLoading] = useState(true);

  const refetchCompany = () => {
    return dispatch(fetchCompany({ companyId }));
  };

  const refetchOffers = (companyActivityId: number) => {
    return dispatch(
      fetchOffers({ applicationIds: APPS_TO_DISPLAY, companyActivityId })
    );
  };

  const refetchData = () => {
    return refetchCompany()
      .then(unwrapResult)
      .then(({ company }) => refetchOffers(company.companyActivityId))
      .catch(() => {});
  };

  useEffect(() => {
    refetchData().then(() => {
      setIsLoading(false);
    });
  }, [companyId]);

  if (companyData.hasError || offersData.hasError) {
    return (
      <ErrorPage status={companyData.errorStatus || offersData.errorStatus} />
    );
  }

  if (isLoading || !isInitiated) {
    return <Spinner underHeader />;
  }

  if (companyData.data.companyStatus === CompanyStatus.ENABLED) {
    return (
      <CompanySubscriptionForm
        company={companyData.data}
        disabledApps={companyData.disabledApps}
        offersMap={offersData.data}
        appsToDisplay={APPS_TO_DISPLAY}
        refetchData={refetchData}
      />
    );
  }

  return <ErrorPage status={403} />;
};

export default enhance(CompanySubscriptionCreatePage);
