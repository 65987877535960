import React from "react";

import styles from "./RelationCreate.module.less";

interface ModalTitleProps {
  content?: React.ReactNode;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ content }) => {
  return (
    <>
      <div className={styles.modalTitle}>{content}</div>
    </>
  );
};

export default ModalTitle;
