import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectPendingUserList = (state: RootState) =>
  state.userPendingList;

export const selectPendingUsers = createSelector(
  _selectPendingUserList,
  ({ data }) => data.pendingUsers
);

export const selectUsersSkipAndTakeQueryStats = createSelector(
  _selectPendingUserList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
export const selectIsUsersLoading = createSelector(
  _selectPendingUserList,
  ({ isLoading }) => isLoading
);
