import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Icon, Tooltip } from "@trace-one/design-system";
import {
  Container,
  usePreviousLocation,
  Button,
} from "@trace-one/react-components";
import { Space, Typography } from "antd";
import cn from "classnames";
import { renderToStaticMarkup } from "react-dom/server";

import IconButton from "components/IconButton";

import HeaderBackgroundSvg from "./HeaderBackgroundSvg";
import styles from "./StickyHeader.module.less";

export interface StickyHeaderProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  hideButtons?: boolean;
  isSubmitting?: boolean;
  layoutMode: "edit" | "read";
  onCancel?: () => void;
  onBack?: () => void;
  saveEnabled?: boolean;
  isValidateAndRejectBtnsVersion?: boolean;
  showRejectionTooltip?: React.ReactNode;
  functionOnReject?: any;
  showConfirmationTooltip?: React.ReactNode;
  functionOnValidate?: any;
  isExistingCompany?: boolean;
  // backgroundMode?: "light" | "white";
}

const StickyHeader: React.FC<StickyHeaderProps> = ({
  children,
  title,
  description,
  hideButtons,
  isSubmitting,
  layoutMode,
  onCancel,
  onBack,
  saveEnabled,
  isValidateAndRejectBtnsVersion,
  showRejectionTooltip,
  functionOnReject,
  showConfirmationTooltip,
  functionOnValidate,
  isExistingCompany,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const previousLocation = usePreviousLocation();
  const [rejectTooltipVisible, setRejectTooltipVisible] = useState(false);
  const [validateTooltipVisible, setValidateTooltipVisible] = useState(false);

  if (children) {
    return (
      <header className={styles.root}>
        <Container className={styles.container}>{children}</Container>
      </header>
    );
  }

  const encodedHeaderBackgroundSvg = encodeURIComponent(
    renderToStaticMarkup(<HeaderBackgroundSvg />)
  );

  return (
    <header
      className={styles.root}
      style={{
        backgroundImage: `url("data:image/svg+xml,${encodedHeaderBackgroundSvg}")`,
      }}
    >
      <Container className={styles.container}>
        <section>
          <div className={styles.titleWrapper}>
            {(onBack || previousLocation) && (
              <IconButton
                data-test-id="ad-back-btn"
                onClick={() => {
                  if (onBack) {
                    onBack();
                  } else {
                    history.goBack();
                  }
                }}
                className={styles.backBtn}
              >
                <Icon
                  name="long-arrow-left"
                  color="primary"
                  size="large"
                  useTOLibrary
                />
              </IconButton>
            )}
            {typeof title === "string" ? <h2>{title}</h2> : title}
          </div>
          {!!description && (
            <Typography.Paragraph
              className={cn(styles.description, {
                [styles.descriptionReadLayoutMode]: layoutMode === "read",
                [styles.descriptionMarginLeft]:
                  layoutMode === "read" && !!previousLocation,
              })}
            >
              {description}
            </Typography.Paragraph>
          )}
        </section>
        {!hideButtons && layoutMode !== "read" && (
          <Space>
            {showRejectionTooltip ? (
              <Tooltip
                onOpenChange={() =>
                  setRejectTooltipVisible(!rejectTooltipVisible)
                }
                placement="top"
                text={formatMessage({ id: "companyAddDuns.reject.tooltip" })}
                actions={[
                  {
                    text: formatMessage({ id: "general.cancel" }),
                    onClick: event => {
                      event.target.blur();
                      setRejectTooltipVisible(false);
                    },
                  },
                  {
                    text: formatMessage({ id: "general.confirm" }),
                    onClick: () => {
                      functionOnReject();
                      setRejectTooltipVisible(false);
                    },
                  },
                ]}
                visible={rejectTooltipVisible}
              >
                <Button
                  type="secondary"
                  onClick={onCancel}
                  data-test-id="ad-cancel-btn"
                  className={styles.cancelBtn}
                  disabled={isSubmitting}
                >
                  {isValidateAndRejectBtnsVersion
                    ? formatMessage({ id: "general.reject" })
                    : formatMessage({ id: "general.cancel" })}
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="secondary"
                onClick={onCancel}
                data-test-id="ad-cancel-btn"
                className={styles.cancelBtn}
                disabled={isSubmitting}
              >
                {isValidateAndRejectBtnsVersion
                  ? formatMessage({ id: "general.reject" })
                  : formatMessage({ id: "general.cancel" })}
              </Button>
            )}

            {showConfirmationTooltip ? (
              <Tooltip
                onOpenChange={() =>
                  setValidateTooltipVisible(!validateTooltipVisible)
                }
                placement="top"
                text={
                  isExistingCompany
                    ? formatMessage({
                        id: "companyAddDuns.validateIfExistedCompany",
                      })
                    : formatMessage({ id: "companyAddDuns.validate.tooltip" })
                }
                actions={[
                  {
                    text: formatMessage({ id: "general.cancel" }),
                    onClick: event => {
                      event.target.blur();
                      setValidateTooltipVisible(false);
                    },
                  },
                  {
                    text: formatMessage({ id: "general.confirm" }),
                    onClick: () => {
                      functionOnValidate();
                      setValidateTooltipVisible(false);
                    },
                  },
                ]}
                visible={validateTooltipVisible}
              >
                <Button
                  htmlType="submit"
                  data-test-id="ad-submit-btn"
                  loading={isSubmitting}
                  disabled={saveEnabled}
                >
                  {isValidateAndRejectBtnsVersion
                    ? formatMessage({ id: "general.validate" })
                    : formatMessage({ id: "general.save" })}
                </Button>
              </Tooltip>
            ) : (
              <Button
                htmlType="submit"
                data-test-id="ad-submit-btn"
                loading={isSubmitting}
                disabled={saveEnabled}
              >
                {isValidateAndRejectBtnsVersion
                  ? formatMessage({ id: "general.validate" })
                  : formatMessage({ id: "general.save" })}
              </Button>
            )}
            {/*<Button*/}
            {/*  htmlType="submit"*/}
            {/*  data-test-id="ad-submit-btn"*/}
            {/*  loading={isSubmitting}*/}
            {/*  disabled={saveEnabled}*/}
            {/*>*/}
            {/*  {isValidateAndRejectBtnsVersion*/}
            {/*    ? formatMessage({ id: "general.validate" })*/}
            {/*    : formatMessage({ id: "general.save" })}*/}
            {/*</Button>*/}
          </Space>
        )}
      </Container>
    </header>
  );
};

export default StickyHeader;
