import React from "react";
import { useIntl } from "react-intl";

import moment from "moment";

import { CompanyForTraceoneAdminData } from "models";

import styles from "./CompanyInfoRows.module.less";

interface ExpandedRowsProps {
  record: CompanyForTraceoneAdminData;
}

const CompanyInfoRows: React.FC<ExpandedRowsProps> = ({ record }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.expandedWrapper}>
      <ul className={styles.expandedList}>
        <li>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "companyList.companyGUID",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.externalCode",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.validationDate",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.creationDate",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.statusUpdatedOn",
            })}
          </span>
        </li>
        <li key={record.companyId}>
          <span className={styles.tableContent}>{record.companyId}</span>
          <span className={styles.tableContent}>{record.seExternalCode}</span>
          <span className={styles.tableContent}>
            {record.createdAt && moment(record.createdAt).format("DD/MM/YYYY")}
          </span>
          <span className={styles.tableContent}>
            {record.createdAt && moment(record.createdAt).format("DD/MM/YYYY")}
          </span>
          <span className={styles.tableContent}>
            {record.lastStatusUpdatedDate &&
              moment(record.createdAt).format("DD/MM/YYYY")}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default CompanyInfoRows;
