import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { TagV1 as Tag, Icon } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { Space } from "antd";
import useCompanyStatuses from "pages/Companies/hooks/useCompanyStatuses";

import { useAppDispatch } from "reduxStore";
import { selectCompanyRelationsData } from "reduxStore/companyRelationList/selectors";
import { fetchCompanySubscriptions } from "reduxStore/companySubscriptionList/asyncActions";
import { selectCompanySubscriptionsData } from "reduxStore/companySubscriptionList/selectors";

import ErrorPage from "components/ErrorPage";
import StickyTabContainer from "components/StickyTabContainer";
import useCompanyActivityLabels from "shared/hooks/useCompanyActivityLabels";
import { useExternalFilters } from "shared/hooks/useSearchFilters";

import Spinner from "../../../../components/Spinner";
import usePermissions from "../../../../core/oidc/usePermissions";
import { fetchAdminCompany } from "../../../../reduxStore/company/asyncActions";
import { selectAdminCompanyById } from "../../../../reduxStore/company/selectors";
import { fetchRelatedCompanies } from "../../../../reduxStore/companyRelationList/asyncActions";
import { fetchCompanyActivities } from "../../../../reduxStore/shared/asyncActions";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { CompanyStatus } from "../../../../shared/constants";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../../../shared/webStorage";
import { TraceoneAdminUserFilter } from "../../../Users/containers/TraceoneAdminUserList/models";
import CompanyGeneralInfo from "../../containers/CompanyGeneralInfo";
import CompanyRelationList from "../../containers/CompanyRelationList";
import CompanySubscriptionList from "../../containers/CompanySubscriptionList";

const CompanyDetailsPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isInitiated, setIsInitiated] = useState(true);
  const { companyId } = useParams<{ companyId?: string }>();
  const { companyActivityLabels } = useCompanyActivityLabels();
  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();
  const { company, companySubscriptions, errorStatus, hasError, isLoading } =
    useSelector(selectCompanySubscriptionsData);

  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();

  const adminCompany = useSelector(selectAdminCompanyById);

  const { relations } = useSelector(selectCompanyRelationsData);
  const languageCode = useSelector(selectUserLanguageCode);

  const { formatMessage } = useIntl();

  const { handleExternalFilterChange } =
    useExternalFilters<TraceoneAdminUserFilter>({
      paginationWebStorage:
        TablePaginationStorage.TO_ADMIN_REGISTERED_USER_LIST,
      filterWebStorage: SearchFiltersStorage.TO_ADMIN_REGISTERED_USER_LIST,
    });

  const refetchCompanySubscriptions = () => {
    return dispatch(fetchCompanySubscriptions({ companyId }));
  };

  const refetchRelatedCompanies = () => {
    return dispatch(fetchRelatedCompanies({ companyId }));
  };

  const refetchCompanies = () => {
    return dispatch(fetchAdminCompany({ companyId }));
  };

  useEffect(() => {
    Promise.all([
      refetchCompanySubscriptions(),
      refetchRelatedCompanies(),
      refetchCompanies(),
    ]).then(() => setIsInitiated(true));

    dispatch(fetchCompanyActivities({ languageCode }));
  }, [companyId]);

  if (hasError) {
    return <ErrorPage status={errorStatus} />;
  }

  if (!isInitiated || isLoading) {
    return <Spinner underHeader />;
  }

  const usersTab = (
    <div
      onClick={() => {
        const owningCompanyId = company?.companyId;
        handleExternalFilterChange({
          owningCompanyId,
          userStatuses: [],
        });
        history.push(`/users/list`);
      }}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {formatMessage({
        id: "general.users",
      })}
      <Icon name="link" size="medium" />
    </div>
  );

  const productSettingsTab = (
    <div
      onClick={() => {
        history.push(`/companies/${company.companyId}/productSettings`);
      }}
    >
      {formatMessage({
        id: "general.productSettings",
      })}
    </div>
  );

  const tabList = [
    {
      label: formatMessage({ id: "general.generalInformation" }),
      content: (
        <CompanyGeneralInfo
          company={adminCompany.data}
          currentCompany={company}
          refetchData={refetchCompanySubscriptions}
          refetchCompanies={refetchCompanies}
        />
      ),
    },
    {
      label: formatMessage({ id: "general.subscriptions" }),
      content: (
        <CompanySubscriptionList
          company={company}
          companySubscriptions={companySubscriptions}
          refetchData={() => {
            refetchCompanySubscriptions();
          }}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: formatMessage({ id: "general.relations" }),
      content: (
        <CompanyRelationList
          company={company}
          companyRelations={relations}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: usersTab,
    },
    ((company?.companyActivityId === 1 &&
      company?.applicationTypeIds.includes(100) &&
      company?.companyStatus === CompanyStatus.ENABLED &&
      hasRoleAdministrator) ||
      (company?.companyActivityId === 1 &&
        company?.applicationTypeIds.includes(100) &&
        company?.companyStatus === CompanyStatus.ENABLED &&
        hasRoleSuperAdmin)) && {
      label: productSettingsTab,
    },
  ];

  return (
    <StickyTabContainer
      title={
        <Space>
          <Tag
            label={companyStatusLabels[company.companyStatus]}
            color={companyStatusTagColors[company.companyStatus]}
            size="medium"
            mode="light"
          />
          <Tooltip title={company.companyDisplayName} placement="bottom">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                textTransform: "uppercase",
                marginLeft: "16px",
                maxWidth: "55ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {company.companyDisplayName}
            </div>
          </Tooltip>
        </Space>
      }
      description={companyActivityLabels[company.companyActivityId]}
      onBack={() => {
        history.push("/companies/list");
      }}
      tabs={tabList}
      companyId={companyId}
      companyStatus={company.companyStatus}
    />
  );
};

export default CompanyDetailsPage;
