import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { toaster } from "@trace-one/design-system";
import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";

import { CumdAPI } from "apis";
import { UserForUIAdminData } from "models";

import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { UserStatus, UserActionOptions } from "shared/constants";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import useToast from "shared/hooks/useToast";

import MainContactModal from "../../../../components/MainContactModal";
import usePermissions from "../../../../core/oidc/usePermissions";

import styles from "./TraceoneAdminUserList.module.less";

interface UserActionProps {
  data: UserForUIAdminData;
  users: UserForUIAdminData[];
  refetchUsers?: () => void;
  companyStatus?: string;
}

const UserAction: React.FC<UserActionProps> = ({
  data,
  users,
  refetchUsers,
  companyStatus,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator, hasRoleSupportAdmin } =
    usePermissions();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const languageCode = useSelector(selectUserLanguageCode);
  const toast = useToast();
  const isLocked = data.userLocked;
  const SUSPEND = UserActionOptions.SUSPEND;
  const DELIST = UserActionOptions.DELIST;

  const [isMainContactModalVisible, setIsMainContactModalVisible] =
    useState<boolean>(false);
  const [previousMainContact, setPreviousMainContact] = useState<any>();
  const handleUpdateMainContact = async () => {
    try {
      await CumdAPI.updateUserMainContact(data?.userId);

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "toast.confirmation.savingDescription",
        }),
        type: "confirmation",
      });

      setIsMainContactModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  useEffect(() => {
    const mainContactUser = users?.find(user => user.isMainContact);

    if (mainContactUser) {
      setPreviousMainContact(mainContactUser);
    }
  }, [users]);

  const handleSuspendedUser = async () => {
    try {
      await CumdAPI.activeUserStatusById(data.userId);
      refetchUsers();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const handleModalConfirm = async (type: string) => {
    if (type === SUSPEND) {
      try {
        await CumdAPI.suspendUserStatusById(data.userId);
        refetchUsers();
      } catch (error) {
        if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_TO,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.TO",
            }),
          });
        } else if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_COMPANY_ADMIN,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.company.admin",
            }),
          });
        } else if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_MAIN_CONTACT,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.main.contact",
            }),
          });
        } else {
          toast.saveError({ error });
        }
      }
    }
    if (type === DELIST) {
      try {
        await CumdAPI.delistUserStatusById(data.userId);
        refetchUsers();
      } catch (error) {
        if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_TO,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.TO",
            }),
          });
        } else if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_COMPANY_ADMIN,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.company.admin",
            }),
          });
        } else if (
          hasErrorOccurred({
            error,
            errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_MAIN_CONTACT,
          })
        ) {
          toast.error({
            error,
            description: formatMessage({
              id: "toast.error.cannot.deactivate.suspend.main.contact",
            }),
          });
        } else {
          toast.saveError({ error });
        }
      }
    }
  };

  const handleUserStatusLock = async () => {
    try {
      await CumdAPI.setUserStatusLock(data.userId);
      refetchUsers();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const handleConfirmationModal = (type: string) => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          icon={"users"}
          onConfirm={() => handleModalConfirm(type)}
          confirmationModalTitle={
            type === SUSPEND
              ? formatMessage({ id: "userActionModel.suspend.title" })
              : formatMessage({
                  id: "userActionModel.delist.title",
                })
          }
        >
          {type === SUSPEND ? (
            <section className={styles.userStatusModal}>
              <div className={styles.description}>
                <p>
                  {formatMessage(
                    { id: "userActionModel.suspend.description" },
                    {
                      firstName: data.userFirstName,
                      lastName: data.userLastName,
                    }
                  )}
                </p>
              </div>
              <p className={styles.confirmationQuestion}>
                {formatMessage({
                  id: "general.action.confirmation.text",
                })}
              </p>
            </section>
          ) : (
            <section className={styles.userStatusModal}>
              <div className={styles.description}>
                <p>
                  {formatMessage(
                    {
                      id: "userActionModel.delist.description",
                    },
                    {
                      firstName: data.userFirstName,
                      lastName: data.userLastName,
                    }
                  )}
                </p>
              </div>
              {formatMessage({
                id: "general.action.confirmation.text",
              })}
            </section>
          )}
        </ConfirmationModal>
      ),
    });
  };

  const handleMasquerade = () => {
    const { userId } = data;
    if (window.env.UI_ADMIN_USERS_LIST_URL && userId) {
      const url = `${window.env.IDSV_URL}/account/impersonate/${userId}?returnUrl=${window.env.UI_ADMIN_MASQUERADE_URL}`;

      window.open(url, "_self");
    }
  };

  const allowMasquerade =
    hasRoleSuperAdmin ||
    (hasRoleSupportAdmin && data.isTraceOneCompany === false) ||
    (hasRoleAdministrator && data.isTraceOneCompany === false);

  const allowManageUserAccess =
    hasRoleSuperAdmin ||
    (hasRoleSupportAdmin && data.isTraceOneCompany === false) ||
    (hasRoleAdministrator && data.isTraceOneCompany === false);

  const allowSuspend =
    hasRoleSuperAdmin ||
    (hasRoleSupportAdmin && data.isTraceOneCompany === false) ||
    hasRoleAdministrator;

  const allowAuthorize =
    hasRoleSuperAdmin ||
    (hasRoleSupportAdmin && data.isTraceOneCompany === false) ||
    (hasRoleAdministrator && data.isTraceOneCompany === false);

  const allowUnlock =
    hasRoleSuperAdmin ||
    (hasRoleSupportAdmin && data.isTraceOneCompany === false) ||
    hasRoleAdministrator;

  const allowDelist = hasRoleSuperAdmin || hasRoleAdministrator;

  return (
    <>
      {
        <ActionDropdown
          data-test-id="ad-user-action"
          overlay={
            <Menu>
              {allowMasquerade &&
                data.userLocked === false &&
                data.userStatus === UserStatus.ENABLED && (
                  <Menu.Item
                    onClick={handleMasquerade}
                    key="masquerade"
                    data-test-id="masquerade"
                    style={{ fontSize: "16px", lineHeight: "24px" }}
                  >
                    {formatMessage({ id: "general.masquerade" })}
                  </Menu.Item>
                )}

              {data.userStatus === UserStatus.ENABLED && (
                <>
                  {allowManageUserAccess && (
                    <Menu.Item
                      onClick={() => {
                        history.push(`/users/access/${data.userId}`);
                      }}
                      key="user-manage-access"
                      data-test-id="ad-manage-access"
                      disabled={isLocked}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "userList.action.editUser",
                      })}
                    </Menu.Item>
                  )}

                  {data?.isMainContact === false &&
                    data.userStatus === UserStatus.ENABLED && (
                      <Menu.Item
                        onClick={() => {
                          setIsMainContactModalVisible(true);
                        }}
                        disabled={isLocked}
                        key="update-main-contact"
                        data-test-id="update-main-contact"
                        style={{ fontSize: "16px", lineHeight: "24px" }}
                      >
                        {formatMessage({ id: "general.defineMainContact" })}
                      </Menu.Item>
                    )}

                  {allowSuspend && data.isMainContact !== true && (
                    <Menu.Item
                      onClick={() => handleConfirmationModal(SUSPEND)}
                      key="suspend-user"
                      data-test-id="suspend-user"
                      disabled={isLocked}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "general.suspend",
                      })}
                    </Menu.Item>
                  )}
                </>
              )}

              {allowDelist && data.isMainContact !== true && (
                <Menu.Item
                  onClick={() => handleConfirmationModal(DELIST)}
                  key="delist-user"
                  data-test-id="delist-user"
                  disabled={isLocked}
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.delist",
                  })}
                </Menu.Item>
              )}

              {(data?.userStatus === UserStatus.SUSPENDED ||
                data?.userStatus === UserStatus.DISABLED) && (
                <>
                  {allowAuthorize && companyStatus === UserStatus.ENABLED && (
                    <Menu.Item
                      onClick={handleSuspendedUser}
                      key="authorize-user"
                      data-test-id="authorize-user"
                      disabled={isLocked}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "general.authorize",
                      })}
                    </Menu.Item>
                  )}
                </>
              )}

              {allowUnlock && isLocked && (
                <Menu.Item
                  onClick={handleUserStatusLock}
                  key="unlock-user"
                  data-test-id="unlock-user"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "general.unlocked",
                  })}
                </Menu.Item>
              )}
            </Menu>
          }
        />
      }

      <MainContactModal
        isModalVisible={isMainContactModalVisible}
        setIsModalVisible={setIsMainContactModalVisible}
        userFirstName={data?.userFirstName}
        userLastName={data?.userLastName}
        previousMainContact={previousMainContact}
        updateMainContact={handleUpdateMainContact}
      />
    </>
  );
};

export default UserAction;
