import { UpdateUserByAdmin } from "@trace-one/api-clients.cumd";

import { UserAccessManageValues } from "./../models";

function getSubmitData({
  values,
}: {
  values: UserAccessManageValues;
}): UpdateUserByAdmin {
  const applications: string[] = [];
  const roles: string[] = [];

  if (values.applicationRoles) {
    Object.entries(values.applicationRoles).forEach(
      ([applicationTypeId, rolesForApp]) => {
        if (rolesForApp?.length > 0) {
          applications.push(applicationTypeId);
          roles.push(...rolesForApp);
        }
      }
    );
  }

  return {
    isAccountAdministrator: values.isAccountAdministrator,
    responsibilities: values.responsibilities,
    applications,
    roles,
  };
}

export default getSubmitData;
