import { useState, useRef } from "react";

import { CumdAPI } from "apis";
import { GroupData } from "models";

export default function useGroupDetails() {
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);
  const detailsInfoRef = useRef<GroupData | null>(null);

  const fetchGroupDetails = async (id: string) => {
    setIsDetailsLoading(true);
    try {
      const { data } = await CumdAPI.getCompanyGroupById(id);
      detailsInfoRef.current = data;
      setIsDetailsLoading(false);
      setIsInitiated(true);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    fetchGroupDetails,
    isDetailsLoading,
    isInitiated,
    groupDetails: detailsInfoRef.current,
  };
}
