import { Switch } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import GroupDetailsPage from "./pages/GroupDetailsPage";
import GroupListPage from "./pages/GroupListPage";

const GroupRouting = () => {
  const { isTraceoneAdmin } = usePermissions();

  return (
    <Switch>
      <AuthorizedRoute path="/groups/list" canActive={isTraceoneAdmin} exact>
        <GroupListPage />
      </AuthorizedRoute>
      <AuthorizedRoute
        path="/groups/details/:groupId"
        canActive={isTraceoneAdmin}
        exact
      >
        <GroupDetailsPage />
      </AuthorizedRoute>

      <RouteIfNotFound />
    </Switch>
  );
};

export default GroupRouting;
