import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { Button } from "@trace-one/design-system";
import { Container } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import Spinner from "components/Spinner";
import {
  BaseHocProps,
  withJobTitles,
  withTeamMemberResponsibilities,
} from "shared/hocs";

import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { selectUsers } from "../../../../reduxStore/userList/selectors";
import CompanyAdminUserList from "../../containers/CompanyAdminUserList";

import styles from "./CompanyAdminUserListPage.module.less";

interface CompanyAdminUserListPageProps extends BaseHocProps {}

const enhance = compose<React.FC<CompanyAdminUserListPageProps>>(
  withJobTitles(),
  withTeamMemberResponsibilities({ checkIfRetailerOrSupplier: true })
);

export const CompanyAdminUserListPage: React.FC<
  CompanyAdminUserListPageProps
> = ({ isInitiated }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const userLanguageCode = useSelector(selectUserLanguageCode);

  const [companyId, setCompanyId] = useState<string>();

  const users = useSelector(selectUsers);

  useEffect(() => {
    users.filter(id => setCompanyId(id.owningCompanyId));
  }, [users]);

  return (
    <Container isPageWrapper>
      <div className={styles.titleWrapper} data-test-id={`${userLanguageCode}`}>
        <Title
          value={formatMessage({
            id: "general.registeredUsers",
          }).toUpperCase()}
          level={1}
        />
        <Button
          onClick={() => {
            history.push(`/users/create-user/${companyId}`);
          }}
          data-test-id="ad-create-company-admin-user"
        >
          {formatMessage({ id: "general.createUser" })}
        </Button>
      </div>
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <CompanyAdminUserList />
      </Spinner>
    </Container>
  );
};

export default enhance(CompanyAdminUserListPage);
