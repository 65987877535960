import React from "react";
import { useIntl } from "react-intl";

import { Col } from "antd";

import Card from "../../../../../components/Card";

import styles from "./UserInfoCard.module.less";

interface UserInfoCardProps {
  userInfo: any;
  userJobTitle: string;
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({
  userInfo,
  userJobTitle,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({
          id: "general.firstUserInfo",
        }).toUpperCase()}
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.firstName" })}
          </span>
          <div>
            {userInfo.data?.userFirstName
              ? userInfo.data?.userFirstName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.lastName" })}
          </span>
          <div>
            {userInfo.data?.userLastName
              ? userInfo.data?.userLastName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.email" })}
          </span>
          <div>
            {userInfo.data?.userLogin
              ? userInfo.data?.userLogin
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.phone" })}
          </span>
          <div>
            {userInfo.data?.userPhone
              ? userInfo.data?.userPhone
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.jobTitle" })}
          </span>
          <div>
            {userJobTitle
              ? userJobTitle
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default UserInfoCard;
