/* istanbul ignore file */
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectAdminCompanyById = (state: RootState) => state.company;

export const selectAdminCompanyById = createSelector(
  _selectAdminCompanyById,
  state => state
);
