import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Select from "components/Select";

import { RlmdAPI } from "../../../../../../../apis";
import FormController, {
  FormControllerProps,
} from "../../../../../../../components/FormController";
import { SubscriptionData } from "../../../../../../../models";
import { selectUserLanguageCode } from "../../../../../../../reduxStore/user/selectors";
import { SubscriptionValues } from "../../../models";

import styles from "./TemplateSelect.module.less";

interface TemplateSelectProps {
  subscription: SubscriptionData;
  name: FormControllerProps<SubscriptionValues>["name"];
  control: FormControllerProps<SubscriptionValues>["control"];
  rules?: object;
  "data-test-id": string;
  numberOfTemplates: number;
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({
  control,
  numberOfTemplates,
  rules,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateList, setSelectedTemplateList] = useState([]);

  const fetchTemplateList = async () => {
    const { data } = await RlmdAPI.getReferenceListItemsByReferenceListName(
      "TemplateType",
      {
        languageCode,
      }
    );
    const list = data.map(item => ({ value: item.id, label: item.text }));
    setTemplateList(list);
  };

  useEffect(() => {
    fetchTemplateList();
  }, []);

  return (
    <FormController
      name="templateListIds"
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.root}>
          <p style={{ fontSize: "16px", lineHeight: "22px" }}>
            {formatMessage(
              {
                id: "companySubscriptionForm.templateList",
              },
              {
                b: text => <b className={styles.star}>{text}</b>,
              }
            )}
          </p>
          <Select
            mode="multiple"
            placeholder={formatMessage({
              id: "companySubscriptionForm.templateList.placeholder",
            })}
            onChange={value => {
              if (
                value.length > numberOfTemplates &&
                selectedTemplateList.length > 0 &&
                selectedTemplateList.length < value.length
              ) {
                return;
              }

              setSelectedTemplateList(value);
              onChange(value);
            }}
            options={templateList?.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            value={value}
            data-test-id="template-select"
            error={!!error}
            errorMessage={
              error?.type === "required"
                ? formatMessage({
                    id: "general.error.mandatory.field",
                  })
                : error?.message
            }
            style={
              error && { border: "#E91C40 solid 1px", borderRadius: "4px" }
            }
          />
        </div>
      )}
    />
  );
};

export default TemplateSelect;
