import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Tooltip } from "@trace-one/react-components";
import { Row, Col } from "antd";
import OfferCardButton from "pages/Companies/containers/CompanySubscriptionForm/AppCardSelect/components/OfferCardButton";
import splitOffers from "pages/Companies/containers/CompanySubscriptionForm/AppCardSelect/utils/splitOffers";

import { OfferData } from "models";

import Select from "components/Select";
import { ApplicationType } from "shared/constants";
import useAppTranslations from "shared/hooks/useAppTranslations";

import styles from "./AppCardSelect.module.less";
import Placeholder from "./components/Placeholder";

interface AppCardSelectProps {
  initialSelectedAppId?: number;
  isEdit: boolean;
  appsToDisplay: ApplicationType[];
  offersMap: { [applicationTypeId: string]: OfferData[] };
  disabledApps: number[];
  selectedOfferIds: string[];
  onSelectedOfferIdsChange: (value: string[]) => void;
  setContributionType: (value: string) => void;
  setApplicationTypeId: (value: number) => void;
}

const AppCardSelect: React.FC<AppCardSelectProps> = ({
  initialSelectedAppId,
  isEdit,
  appsToDisplay,
  offersMap,
  disabledApps,
  selectedOfferIds,
  onSelectedOfferIdsChange,
  setContributionType,
  setApplicationTypeId,
}) => {
  const { formatMessage } = useIntl();
  const { appLabelMap } = useAppTranslations();
  const [selectedAppId, setSelectedAppId] =
    useState<number>(initialSelectedAppId);

  const handleAppSelect = (applicationTypeId: number) => {
    const offers = offersMap[applicationTypeId];

    if (offers?.length > 0) {
      const { defaultOffer } = splitOffers(offers);
      onSelectedOfferIdsChange([defaultOffer.offerId]);
      setContributionType(defaultOffer.offerContributionType);
    } else {
      onSelectedOfferIdsChange([]);
      setContributionType(null);
    }

    setSelectedAppId(applicationTypeId);
    setApplicationTypeId(applicationTypeId);
  };

  const isOfferSelected = (offer: OfferData) =>
    selectedOfferIds.includes(offer?.offerId);

  const renderOffers = (selectedAppId: number) => {
    const offers = offersMap[selectedAppId];

    const { defaultOffer, addOnOffers } = splitOffers(offers);

    return (
      <>
        <Row gutter={[40, 50]} className={styles.offersList}>
          <Col xs={24} md={12} xl={8} xxl={6}>
            <Tooltip
              title={formatMessage({
                id: "companySubscriptionForm.defaultOffer.tooltip",
              })}
              placement="rightTop"
              overlayStyle={{ maxWidth: "280px" }}
            >
              <OfferCardButton
                offer={defaultOffer}
                applicationTypeId={selectedAppId}
                selected={isOfferSelected(defaultOffer)}
                disabled
                data-test-id="ad-offer-default"
              />
            </Tooltip>
          </Col>
        </Row>
        {defaultOffer && addOnOffers?.length > 0 && (
          <h2>
            {formatMessage({ id: "companySubscriptionForm.selectAddOnOffers" })}
          </h2>
        )}

        <Row gutter={[40, 50]} className={styles.offersList}>
          {addOnOffers.map(offer => {
            const selected = isOfferSelected(offer);
            return (
              <Col key={offer.offerId}>
                <OfferCardButton
                  offer={offer}
                  applicationTypeId={selectedAppId}
                  onClick={() => {
                    const newSelectedOfferIds = selected
                      ? selectedOfferIds.filter(id => id !== offer.offerId)
                      : [...selectedOfferIds, offer.offerId];
                    onSelectedOfferIdsChange(newSelectedOfferIds);
                  }}
                  selected={selected}
                  data-test-id={`ad-offer-${offer.offerId}`}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  return (
    <section>
      <div className={styles.wrapper}>
        <h2>
          {formatMessage({ id: "companySubscriptionForm.selectAnApplication" })}
        </h2>
        <Select
          placeholder={formatMessage({ id: "general.selectApplication" })}
          onChange={handleAppSelect}
          options={appsToDisplay.map(app => ({
            value: app,
            label: appLabelMap[app],
            disabled: disabledApps.includes(app),
          }))}
          value={selectedAppId || undefined}
          disabled={isEdit}
          data-test-id="app-select"
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.offersContent}>
          {selectedAppId ? (
            offersMap[selectedAppId]?.length > 0 ? (
              renderOffers(selectedAppId)
            ) : (
              <Placeholder isOfferListEmpty />
            )
          ) : (
            <Placeholder isOfferListEmpty={false} />
          )}
        </div>
      </div>
    </section>
  );
};

export default AppCardSelect;
