import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from "react-hook-form";

import FormLabel, { FormLabelProps } from "components/FormLabel";

export interface FormControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends ControllerProps<TFieldValues, TName>,
    FormLabelProps {}

const FormController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  wrapperFormRow,
  labelCol,
  inputCol,
  subLabel,
  ...rest
}: FormControllerProps<TFieldValues, TName>) => {
  const ControllerComp = <Controller {...rest} />;

  if (label) {
    const formLabelProps = {
      isRequired: !!rest.rules?.required,
      label,
      wrapperFormRow,
      labelCol,
      inputCol,
      subLabel,
    };

    return <FormLabel {...formLabelProps}>{ControllerComp}</FormLabel>;
  }

  return ControllerComp;
};

export default FormController;
