import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useReactOidc } from "@axa-fr/react-oidc-context";

import {
  selectCompanyActivity,
  selectOidcUserPermissions,
} from "reduxStore/oidc/selectors";

import { CompanyActivity } from "shared/constants";

export const PERMISSIONS = Object.freeze({
  brandsWrite: "brands.write",
  categoryListsRead: "categoryLists.read",
  categoryListsImport: "categoryLists.import",
  categoryListsWrite: "categoryLists.write",
  categoryListsDelete: "categoryLists.delete",
  companyprivateWrite: "companyprivate.write",
  companyRelationsWrite: "companyRelations.write",
  companiesRead: "companies.read",
  dunsRead: "duns.read",
  masterdataMyAssociatedSites: "masterdata.myAssociatedSites",
  masterdataMyBrands: "masterdata.myBrands",
  masterdataMyCategories: "masterdata.myCategories",
  masterdataMyProducts: "masterdata.myProducts",
  masterdataMySites: "masterdata.mySites",
  masterdataMySuppliers: "masterdata.mySuppliers",
  productsWrite: "products.write",
  productsRead: "products.read",
  productsUpdate: "products.update",
  productsImport: "products.import",
  sitesWrite: "sites.write",
  sitesImport: "sites.import",
  sitesRead: "sites.read",
  sitesDelete: "sites.delete",
  siteRelationsWrite: "siteRelations.write",
});

type PermissionObject = Record<keyof typeof PERMISSIONS, boolean>;

export function getPermissionsObject(permissions = "") {
  const permissionsList = permissions?.split(",") || [];

  const hasPermission = permissionType =>
    permissionsList.includes(permissionType);

  return Object.fromEntries(
    Object.keys(PERMISSIONS).map(key => [key, hasPermission(PERMISSIONS[key])])
  ) as PermissionObject;
}

export default function usePermissions() {
  const permissions = useSelector(selectOidcUserPermissions);
  const companyActivity = useSelector(selectCompanyActivity);

  const {
    oidcUser: {
      profile: {
        global_user_is_admin,
        is_company_traceone,
        accessToAdministration,
        roles,
        isMasquerade,
      },
    },
  } = useReactOidc();

  const permissionsObject = useMemo(
    () => getPermissionsObject(permissions),
    [permissions]
  );

  return {
    ...permissionsObject,
    hasAccessToAdministration: accessToAdministration === "True",
    isCompanyAdmin: global_user_is_admin === "True",
    isTraceoneAdmin: is_company_traceone === "True",
    isRetailer: companyActivity === CompanyActivity.RETAILER,
    isSupplier: companyActivity === CompanyActivity.SUPPLIER,
    hasRoleSuperAdmin: roles?.includes("7ff48f46-7b59-494d-9717-fa0581773a7b"),
    hasRoleAdministrator: roles?.includes(
      "bff3dbb3-32d7-4ac3-ab1b-3221ea59d53b"
    ),
    hasRoleSupportAdmin: roles?.includes(
      "d52d51df-fe02-41be-a1c1-90c8ad732e2e"
    ),
    isMasquerade: isMasquerade === "True",
  };
}
