import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { compose } from "@reduxjs/toolkit";
import { Alert } from "@trace-one/design-system";

import { RlmdAPI } from "../../../../apis";
import { selectJobTitlesData } from "../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { BaseHocProps, withJobTitles } from "../../../../shared/hocs";

import AddressCard from "./AddressCard";
import DunsInformationCard from "./DunsInformationCard";
import MainInformationCard from "./MainInformationCard";
import styles from "./PendingCompanyGeneralInfo.module.less";
import UserInfoCard from "./UserInfoCard";

interface PendingCompanyGeneralInfoProps extends BaseHocProps {
  userInfo: any;
  companyType: string;
}

const enhance = compose<React.FC<PendingCompanyGeneralInfoProps>>(
  withJobTitles()
);

const PendingCompanyGeneralInfo: React.FC<PendingCompanyGeneralInfoProps> = ({
  userInfo,
  companyType,
}) => {
  const [productCategories, setProductCategories] = useState([]);
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const userJobTitle = jobTitlesOptions.find(
    jobTitlesOption =>
      Number(jobTitlesOption.value) === userInfo.data?.userJobTitleId
  )?.label;

  const fetchProductCategories = async () => {
    if (userInfo.data?.companyCategories !== null) {
      const productCategories = userInfo.data?.companyCategories?.map(
        category => category.categoryItemId
      );
      const { data } = await RlmdAPI.getCategoryItems(
        {
          idCollection: productCategories,
        },
        { languageCode }
      );

      setProductCategories(data);
    }
    return;
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  return (
    <>
      {window.env.DISABLE_SIGNUP_FEATURE === "false" &&
        userInfo.data.registrationStatus === 0 &&
        (userInfo.data?.companyDuns !== "" ||
          userInfo.data?.companyDuns !== null) && (
          <div className={styles.bannerWrapper}>
            <Alert
              type="info"
              message={formatMessage({
                id: "companyGeneralInfo.pending.banner.title",
              })}
              description={formatMessage({
                id: "companyGeneralInfo.pending.banner.description",
              })}
              closable
              showIcon
              data-test-id="suspension-banner"
            />
          </div>
        )}

      <div className={styles.root}>
        <div className={styles.leftPart}>
          <MainInformationCard
            userInfo={userInfo}
            companyType={companyType}
            productCategories={productCategories}
          />
          <UserInfoCard userInfo={userInfo} userJobTitle={userJobTitle} />
        </div>
        <div className={styles.rightPart}>
          <DunsInformationCard userInfo={userInfo} />
          <AddressCard userInfo={userInfo} />
        </div>
      </div>
    </>
  );
};

export default enhance(PendingCompanyGeneralInfo);
