import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button } from "@trace-one/design-system";
import { Col } from "antd";

import { UserData } from "models";

import Card from "components/Card";

import usePermissions from "../../../../../core/oidc/usePermissions";
import { selectUserId } from "../../../../../reduxStore/user/selectors";

import EditUserInfo from "./components/EditUserInfo";
import styles from "./LeftPart.module.less";
interface UserCardProps {
  user: UserData;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const { formatMessage } = useIntl();

  const {
    hasRoleSuperAdmin,
    hasRoleAdministrator,
    hasRoleSupportAdmin,
    isCompanyAdmin,
    isTraceoneAdmin,
  } = usePermissions();

  const canEdit =
    (isTraceoneAdmin &&
      (hasRoleSupportAdmin || hasRoleAdministrator || hasRoleSuperAdmin)) ||
    isCompanyAdmin;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const userId = useSelector(selectUserId);

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) return;
    };

    fetchData();
  }, [userId]);

  return (
    <div className={styles.userCard}>
      <Card
        title={formatMessage({ id: "general.userInfo" }).toUpperCase()}
        extra={
          canEdit && (
            <>
              <Button
                type="link"
                iconName="edit"
                onClick={openModal}
                data-test-id="manage-user-edit-user-button"
              >
                {formatMessage({ id: "general.edit" })}
              </Button>

              <EditUserInfo
                user={user}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            </>
          )
        }
      >
        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-title"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.title" })}
          </span>
          <div>
            {user.userCivility
              ? user.userCivility
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-firstName"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.firstName" })}
          </span>
          <div>
            {user.userFirstName
              ? user.userFirstName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-lastName"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.lastName" })}
          </span>
          <div>
            {user.userLastName
              ? user.userLastName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-companyName"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.companyName" })}
          </span>
          <div>
            {user.companyDisplayName
              ? user.companyDisplayName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-jobTitle"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.jobTitle" })}
          </span>
          <div>
            {user.userJobTitle
              ? user.userJobTitle
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-notificationEmail"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.notificationEmail" })}
          </span>
          <div>
            {user.userNotificationEmail
              ? user.userNotificationEmail
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="manage-user-user-info-phone"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.phone" })}
          </span>
          <div>
            {user.userPhone
              ? user.userPhone
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default UserCard;
