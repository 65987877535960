/* istanbul ignore file */
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCompanyChangeDunsForm = (state: RootState) =>
  state.companyChangeDunsForm;

export const selectCompanyChangeDunsForm = createSelector(
  _selectCompanyChangeDunsForm,
  state => state
);
