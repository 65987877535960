import { useState } from "react";
import { useIntl } from "react-intl";

import { Company } from "@trace-one/api-clients.cumd/dist/models/company";
import { Icon } from "@trace-one/design-system";
import { Modal, ModalProps } from "antd";

import { CumdAPI } from "apis";
import { CompanyForTraceoneAdminData } from "models";

import { RelationType } from "shared/constants";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import useToast from "shared/hooks/useToast";

import CompanySearch from "./CompanySearch";
import CompanySelect from "./CompanySelect";
import ModalTitle from "./ModalTitle";
import styles from "./RelationCreate.module.less";

interface RelationCreateProps extends ModalProps {
  company?: CompanyForTraceoneAdminData | null;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  refetchRelations: () => void;
}

const RelationCreate: React.FC<RelationCreateProps> = ({
  company,
  isModalVisible,
  setIsModalVisible,
  refetchRelations,
}) => {
  const { formatMessage } = useIntl();

  const toast = useToast();

  const [relationType, setRelationType] = useState<RelationType>(null);
  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null
  );

  const closeModal = () => {
    setIsModalVisible(false);
    setRelationType(null);
    setCompanies(null);
    setSelectedCompanyId(null);
  };

  const handleSubmit = async () => {
    let ownerCompanyId;
    let associatedCompanyId;

    if (relationType === RelationType.ANIMATOR) {
      ownerCompanyId = company.companyId;
      associatedCompanyId = selectedCompanyId;
    }
    if (relationType === RelationType.CONTRIBUTOR) {
      ownerCompanyId = selectedCompanyId;
      associatedCompanyId = company.companyId;
    }

    try {
      await CumdAPI.createRelation(ownerCompanyId, associatedCompanyId);
      closeModal();
      refetchRelations();
    } catch (error) {
      if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.COMPANY_RELATION_ALREADY_EXISTS,
        })
      ) {
        toast.error({
          error,
          description: formatMessage({
            id: "companyRelationList.modal.error.relationAlreadyExists",
          }),
        });
      } else {
        toast.error({ error });
      }
    }
  };

  const closeIcon = (
    <Icon className="ant-modal-close" name="close" data-test-id="close" />
  );

  const modalTitle = (
    <ModalTitle
      content={
        <>
          <h2>{formatMessage({ id: "companyRelationList.createRelation" })}</h2>
          <p>
            {formatMessage(
              { id: "companyRelationList.modal.mainSubTitle" },
              { companyName: company?.companyDisplayName }
            )}
          </p>
        </>
      }
    />
  );

  return (
    <Modal
      width={1400}
      closeIcon={closeIcon}
      destroyOnClose
      maskClosable={false}
      footer={null}
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      className={styles.mainRoot}
    >
      <div className={styles.root}>
        <div className={styles.modalLeftSide}>
          {modalTitle}
          <CompanySearch
            company={company}
            setRelationType={setRelationType}
            setCompanies={setCompanies}
          />
        </div>
        <div className={styles.modalRightSide}>
          <CompanySelect
            companies={companies}
            selectedCompanyId={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompanyId}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RelationCreate;
