import { useIntl } from "react-intl";

import { RelationStatus } from "shared/constants";

export default function useRelationStatuses() {
  const { formatMessage } = useIntl();

  const relationStatusLabels = {
    [RelationStatus.ACTIVE]: formatMessage({ id: "general.active" }),
    [RelationStatus.INACTIVE]: formatMessage({
      id: "general.inactive",
    }),
  };

  const relationTagColors = {
    [RelationStatus.ACTIVE]: "green",
    [RelationStatus.INACTIVE]: "red",
  };

  const getRelationStatusOptions = (
    relationStatuses?: RelationStatus[]
  ): { value: RelationStatus; label: string }[] => {
    if (relationStatuses?.length > 0) {
      return relationStatuses.map(status => ({
        value: status,
        label: relationStatusLabels[status],
      }));
    }

    return Object.entries(relationStatusLabels).map(([value, label]) => ({
      value: value as RelationStatus,
      label,
    }));
  };

  return {
    relationStatusLabels,
    relationTagColors,
    getRelationStatusOptions,
  };
}
