import { Switch } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import CompanyAdminUserListPage from "./pages/CompanyAdminUserListPage";
import { CreateUserPage } from "./pages/CreateUserPage";
import TraceoneAdminPendingUserListPage from "./pages/TraceoneAdminPendingUserListPage";
import TraceoneAdminUserListPage from "./pages/TraceoneAdminUserListPage";
import UserAccessManagePage from "./pages/UserAccessManagePage";

const UsersRouting: React.FC = () => {
  const { isCompanyAdmin, isTraceoneAdmin } = usePermissions();

  return (
    <Switch>
      <AuthorizedRoute
        path="/users/list"
        canActive={isCompanyAdmin || isTraceoneAdmin}
        exact
      >
        {isTraceoneAdmin ? (
          <TraceoneAdminUserListPage />
        ) : (
          <CompanyAdminUserListPage />
        )}
      </AuthorizedRoute>

      {isTraceoneAdmin && (
        <AuthorizedRoute
          path="/users/pending/list"
          canActive={isCompanyAdmin || isTraceoneAdmin}
          exact
        >
          <TraceoneAdminPendingUserListPage />
        </AuthorizedRoute>
      )}

      <AuthorizedRoute
        path="/users/access/:userId"
        canActive={isCompanyAdmin || isTraceoneAdmin}
        exact
      >
        <UserAccessManagePage />
      </AuthorizedRoute>

      <AuthorizedRoute
        path="/users/create-user/:companyId"
        canActive={isCompanyAdmin || isTraceoneAdmin}
        exact
      >
        <CreateUserPage />
      </AuthorizedRoute>

      <RouteIfNotFound />
    </Switch>
  );
};

export default UsersRouting;
