import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Filter } from "@trace-one/business-components";
import { Picker, FilterProps, DatePicker } from "@trace-one/design-system";
import moment from "moment";

import { SearchFiltersProps } from "components/SearchFilters";

import CheckableTags from "../../../../components/CheckableTags";
import { CompanyStatus } from "../../../../shared/constants";
import { adjustIntercomButton } from "../../../../shared/utils/toggleIntercom";
import useCompanyStatuses from "../../hooks/useCompanyStatuses";

import styles from "./CompanyPendingSearch.module.less";
import { CompanyFilter } from "./models";

interface PendingCompanySearchProps {
  filterObj: CompanyFilter;
  mergeFilters: (
    value:
      | Partial<CompanyFilter>
      | ((prevState: CompanyFilter) => CompanyFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const CompanyPendingSearch: React.FC<PendingCompanySearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const showIntercom = useRef(true);

  const { getCompanyStatusOptions } = useCompanyStatuses();

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  useEffect(() => {
    const { companyStatuses, createdAt } = filterObj;

    const defaultSelectedFilters = [
      createdAt && {
        filterId: "1",
        values: [createdAt],
      },
      companyStatuses && {
        filterId: "2",
        values: companyStatuses.map(e => e.length),
      },
    ].filter(i => !!i);

    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: formatMessage({ id: "general.createdSince" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          createdAt: undefined,
        });
      },

      elementToRender: (
        <DatePicker
          defaultValue={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          value={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          onChange={createdAt => {
            mergeFilters({
              createdAt: createdAt?.format("DD/MM/YYYY"),
            });
          }}
          showToday={false}
          style={{ width: "275px" }}
          data-test-id="ad-pending-companies-filter-createdSince"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          companyStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.companyStatuses}
          onChange={companyStatuses =>
            mergeFilters({
              companyStatuses,
            })
          }
          options={getCompanyStatusOptions([
            CompanyStatus.PENDING,
            CompanyStatus.REJECTED,
            CompanyStatus.VALIDATED,
          ])}
          data-test-id="ad-pending-companies-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.companyStatuses ?? []}
          items={getCompanyStatusOptions([
            CompanyStatus.PENDING,
            CompanyStatus.REJECTED,
            CompanyStatus.VALIDATED,
          ])}
          onChange={({ value }) => {
            mergeFilters({
              companyStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              companyStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-pending-companies-filter-picker-status"
        />
      ),
      filterId: "2",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "general.enterCompanyNameOrDuns",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default CompanyPendingSearch;
