import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { TagV1 as Tag } from "@trace-one/design-system";
import { toaster } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { Space } from "antd";

import { useAppDispatch } from "reduxStore";

import { CumdAPI } from "../../../../apis";
import Spinner from "../../../../components/Spinner";
import StickyContainer from "../../../../components/StickyContainer";
import { fetchPendingUser } from "../../../../reduxStore/pendingUser/asyncActions";
import { selectPendingUserInfo } from "../../../../reduxStore/pendingUser/selectors";
import useCompanyActivityLabels from "../../../../shared/hooks/useCompanyActivityLabels";
import useToast from "../../../../shared/hooks/useToast";
import PendingCompanyGeneralInfo from "../../containers/PendingCompanyGeneralInfo";
import useCompanyStatuses from "../../hooks/useCompanyStatuses";

const PendingCompanyDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const toast = useToast();
  const { formatMessage } = useIntl();

  const { userId } = useParams<{ userId?: string }>();
  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();
  const { companyActivityLabels } = useCompanyActivityLabels();

  const userInfo = useSelector(selectPendingUserInfo);

  const searchParams = new URLSearchParams(window.location.search);
  const pageName = searchParams.get("pageName");

  const refetchPendingUser = () => {
    return dispatch(fetchPendingUser({ userId }));
  };

  const companyType = companyActivityLabels[userInfo.data?.companyActivity];

  useEffect(() => {
    Promise.all([refetchPendingUser()]).then(() => setIsLoading(false));
  }, [userId]);

  let status;
  if (userInfo.data?.registrationStatus === 0) {
    status = "Pending";
  }
  if (userInfo.data?.registrationStatus === 2) {
    status = "Rejected";
  }
  if (userInfo.data?.registrationStatus === 1) {
    status = "Done";
  }

  if (isLoading) {
    return <Spinner underHeader />;
  }

  const validatePendingCompany = async () => {
    try {
      await CumdAPI.createUserAndCompany({
        globalCompanyId: userInfo.data?.globalCompanyId,
        companyCountry: userInfo.data?.companyCountry,
        companyState: userInfo.data?.companyState,
        companyCity: userInfo.data?.companyCity,
        companyPostalCode: userInfo.data?.companyPostalCode,
        companyAddress: userInfo.data?.companyAddress,
        companyAddressComplement: companyType,
        companyPhone: userInfo.data?.companyPhone,
        companyFax: userInfo.data?.companyFax,
        companyEmail: userInfo.data?.companyEmail,
        companyDuns: userInfo.data?.companyDuns,
        companyDisplayName:
          userInfo.data?.companyDisplayName === null
            ? userInfo.data?.companyLegalName
            : userInfo.data?.companyDisplayName,
        companyLegalName: userInfo.data?.companyLegalName,
        companyActivity: userInfo.data?.companyActivity,
        companyCategories: userInfo.data.companyCategories?.map(item => ({
          categoryId: item.categoryId,
          categoryItemId: item.categoryItemId,
        })),
        testCompany: false,
        isRetailerCompany: true,
        globalUserId: userInfo.data?.userId,
        userCivility:
          userInfo.data?.userCivility === null
            ? "Mr"
            : userInfo.data?.userCivility,
        userJobTitle: Number(userInfo.data?.userJobTitle),
        userLastName: userInfo.data?.userLastName,
        userFirstName: userInfo.data?.userFirstName,
        userLanguagePreference: userInfo.data?.userLanguagePreference,
        userPhoneNumber: userInfo.data?.userPhoneNumber,
        userEmail: userInfo.data?.userLogin,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const disabledValidateBtn =
    userInfo.data.registrationStatus === 0 &&
    (userInfo.data?.companyDuns === "" || userInfo.data?.companyDuns === null);

  const rejectPendingCompany = async () => {
    try {
      await CumdAPI.delistPendingUser(userInfo.data?.userId);

      toaster.open({
        description: formatMessage({ id: "companyAddDuns.reject.toast" }),
        type: "confirmation",
      });

      pageName && pageName === "user-pending"
        ? history.push(`/users/pending/list`)
        : history.push(`/companies/pending/list`);
    } catch (error) {
      toast.fetchError({ error });
    }
  };

  const handleCompanyOrUserListRedirect = () => {
    validatePendingCompany();
    pageName && pageName === "user-pending"
      ? history.push(`/users/pending/list`)
      : history.push(`/companies/pending/list`);
  };

  return (
    <StickyContainer
      title={
        <Space>
          <Tag
            label={companyStatusLabels[status]}
            color={companyStatusTagColors[status]}
            size="medium"
            mode="light"
          />
          <Tooltip title={userInfo.data?.companyLegalName} placement="bottom">
            <h2>{userInfo.data?.companyLegalName} </h2>
          </Tooltip>
        </Space>
      }
      onBack={() => {
        pageName && pageName === "user-pending"
          ? history.push(`/users/pending/list`)
          : history.push(`/companies/pending/list`);
      }}
      layoutMode={
        window.env.DISABLE_SIGNUP_FEATURE === "false" &&
        userInfo.data.registrationStatus === 0 &&
        (userInfo.data?.companyDuns !== "" ||
          userInfo.data?.companyDuns !== null)
          ? "edit"
          : "read"
      }
      isValidateAndRejectBtnsVersion
      showRejectionTooltip
      functionOnReject={rejectPendingCompany}
      showConfirmationTooltip
      functionOnValidate={handleCompanyOrUserListRedirect}
      saveEnabled={disabledValidateBtn}
    >
      <PendingCompanyGeneralInfo
        userInfo={userInfo}
        companyType={companyType}
      />
    </StickyContainer>
  );
};

export default PendingCompanyDetailsPage;
