import React from "react";
import { useIntl } from "react-intl";

import { Radio } from "@trace-one/design-system";

import FormController, {
  FormControllerProps,
} from "../../../../../../../components/FormController";
import { SubscriptionData } from "../../../../../../../models";
import { SubscriptionValues } from "../../../models";
import styles from "../NutriscoreCalculation/NutriscoreCalculation.module.less";
interface NutriscoreCalculationProps {
  subscription: SubscriptionData;
  name: FormControllerProps<SubscriptionValues>["name"];
  control: FormControllerProps<SubscriptionValues>["control"];
  rules?: object;
  "data-test-id": string;
  setNutriscoreCalculation: (boolean) => void;
  nutriscoreCalculation: boolean;
}

const NutriscoreCalculation: React.FC<NutriscoreCalculationProps> = ({
  control,
  rules,
  setNutriscoreCalculation,
  nutriscoreCalculation,
}) => {
  const { formatMessage } = useIntl();

  const radioOptions = [
    { label: formatMessage({ id: "general.yes" }), value: true },
    { label: formatMessage({ id: "general.no" }), value: false },
  ];

  const onNutriscoreCalculationChange = e => {
    setNutriscoreCalculation(e.target.value);
  };

  return (
    <FormController
      name="nutriscoreCalculation"
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.root}>
          <p style={{ fontSize: "16px", lineHeight: "22px" }}>
            {formatMessage(
              {
                id: "companySubscriptionForm.nutriscoreCalculation",
              },
              {
                b: text => <b className={styles.star}>{text}</b>,
              }
            )}
          </p>
          <div>
            <Radio.Group
              direction="horizontal"
              options={radioOptions}
              value={nutriscoreCalculation}
              onChange={onNutriscoreCalculationChange}
            />
          </div>
        </div>
      )}
    />
  );
};

export default NutriscoreCalculation;
