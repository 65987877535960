import React from "react";

import { Card as BaseCard } from "@trace-one/react-components";
import {
  Col,
  Row,
  CardProps as BaseCardProps,
  ColProps,
  Typography,
} from "antd";

import styles from "./Card.module.less";

export type CardColumn = {
  title: string;
  content: React.ReactNode;
  dataTestId?: string;
} & ColProps;

export interface CardProps extends BaseCardProps {
  title?: React.ReactNode;
  columns?: CardColumn[];
  columnProps?: ColProps;
}

const Card: React.FC<CardProps> = ({
  title,
  columns,
  columnProps,
  ...rest
}) => {
  let Title = title;

  const hasColumns = Array.isArray(columns);
  if (hasColumns) {
    Title = (
      <Row className={styles.header}>
        {columns.map(
          ({ title, content, dataTestId, ...itemColumnProps }, index) => (
            <Col
              key={index}
              style={{ display: "flex", flexDirection: "column", padding: 5 }}
              xs={12}
              xl={6}
              {...columnProps}
              {...itemColumnProps}
            >
              <span className={styles.fieldName}>{title}</span>

              <div data-test-id={dataTestId}>
                {typeof content === "string" || typeof content === "number" ? (
                  <Typography.Text className={styles.fieldData}>
                    {content}
                  </Typography.Text>
                ) : (
                  content
                )}
              </div>
            </Col>
          )
        )}
      </Row>
    );
  }

  return (
    <BaseCard
      {...rest}
      title={Title}
      style={
        hasColumns
          ? { overflow: "hidden", borderRadius: "4px", ...rest.style }
          : rest.style
      }
    />
  );
};

export default Card;
