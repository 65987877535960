import { RelationData } from "models";

type State = {
  relations: RelationData[];
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  relatedCompaniesMap: { [relatedCompanyId: string]: string };
  isLoading: boolean;
  hasError: boolean;
  errorStatus: number;
};

export const initialState: State = {
  relations: [],
  skipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  relatedCompaniesMap: {},
  isLoading: true,
  hasError: false,
  errorStatus: null,
};
