/* istanbul ignore file */
import React from "react";

import { Icon } from "@trace-one/react-components";
import { Dropdown, DropDownProps } from "antd";
import cn from "classnames";
import PropTypes from "prop-types";

import IconButton from "components/IconButton";

import styles from "./ActionDropdown.module.less";

export interface ActionDropdownProps extends DropDownProps {
  "data-test-id"?: string;
  size?: number;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
  overlay,
  "data-test-id": dataTestId,
  size = 20,
  ...rest
}) => {
  return (
    <Dropdown
      overlay={overlay}
      overlayClassName={styles.overlay}
      trigger={["click"]}
      {...rest}
    >
      <IconButton
        className={styles.root}
        disabled={rest.disabled}
        data-test-id={dataTestId}
      >
        <div className={styles.iconWrapper}>
          <Icon
            name="action-dots"
            className={cn({
              [styles.iconDisabled]: rest.disabled,
            })}
            size={size}
          />
        </div>
      </IconButton>
    </Dropdown>
  );
};

ActionDropdown.propTypes = {
  overlay: PropTypes.element,
};

export default ActionDropdown;
