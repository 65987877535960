import React from "react";

import { UserForUIAdminData } from "models";

import styles from "./LeftPart.module.less";
import UserCard from "./UserCard";

interface LeftPartProps {
  user: UserForUIAdminData;
}

const LeftPart: React.FC<LeftPartProps> = ({ user }) => {
  return (
    <div className={styles.root}>
      <UserCard user={user} />
    </div>
  );
};

export default LeftPart;
