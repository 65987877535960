type state = {
  data: any;
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };

  isLoading: boolean;
  hasError: boolean;
};

export const initialState: state = {
  data: [],
  skipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },

  isLoading: false,
  hasError: false,
};
