import React from "react";
import { useIntl } from "react-intl";

import { Illustration } from "@trace-one/design-system";

import styles from "./PermissionsPlaceholder.module.less";

const PermissionsPlaceholder = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <Illustration name="no-data" color="grey" />

      <span className={styles.text}>
        {formatMessage(
          {
            id: "userAccessManage.permissions.no.data",
          },
          { br: <br /> }
        )}
      </span>
    </div>
  );
};

export default PermissionsPlaceholder;
