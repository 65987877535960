import { UserPendingData } from "models";

type State = {
  pendingUserData: {
    data: UserPendingData;
    isLoading: boolean;
    hasError: boolean;
    errorStatus: number;
  };
};

export const initialState: State = {
  pendingUserData: {
    data: null,
    isLoading: true,
    hasError: false,
    errorStatus: null,
  },
};
