import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";

import { RlmdAPI } from "../../../../apis";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import useCompanyActivityLabels from "../../../../shared/hooks/useCompanyActivityLabels";
import AddressesCard from "../CompanyGeneralInfo/AddressCard";
import ApplicationsCard from "../CompanyGeneralInfo/ApplicationsCard";
import styles from "../CompanyGeneralInfo/CompanyGeneralInfo.module.less";
import DunsInformationCard from "../CompanyGeneralInfo/DunsInformationCard";
import MainContactInfo from "../CompanyGeneralInfo/MainContactInfo";
import MainInformationCard from "../CompanyGeneralInfo/MainInformationCard";

interface CompanyGeneralInfoProps {
  company: any;
}

const CompanyGeneralInfo: React.FC<CompanyGeneralInfoProps> = ({ company }) => {
  const [productCategories, setProductCategories] = useState([]);
  const [companyIdentifiers, setCompanyIdentifiers] = useState([]);

  const languageCode = useSelector(selectUserLanguageCode);
  const { companyActivityLabels } = useCompanyActivityLabels();

  const fetchProductCategories = async () => {
    const productCategories = company?.companyCategories.map(
      category => category.categoryItemId
    );

    if (!productCategories || !productCategories.length) return;

    const { data } = await RlmdAPI.getCategoryItems(
      {
        idCollection: productCategories,
      },
      { languageCode }
    );

    setProductCategories(data);
  };

  const fetchCompanyIdentifiersType = async () => {
    const companyIdentifiers = company?.companyIdentifiers?.map(
      companyIdentifiers => companyIdentifiers.typeId
    );

    if (!companyIdentifiers || !companyIdentifiers.length) return;

    const { data } = await RlmdAPI.getReferenceListItemsByIdCollection(
      {
        idCollection: companyIdentifiers,
      },
      { languageCode }
    );

    setCompanyIdentifiers(data);
  };

  useEffect(() => {
    fetchProductCategories();

    if (!!company?.companyIdentifiers) {
      fetchCompanyIdentifiersType();
    }
  }, [company]);

  useEffect(() => {}, []);

  return (
    <>
      <Row className={styles.root} gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <MainInformationCard
            company={company}
            companyType={companyActivityLabels[company?.companyActivityId]}
            productCategories={productCategories}
            companyIdentifiers={companyIdentifiers}
          />
          <MainContactInfo company={company} />
          <ApplicationsCard company={company} />
        </Col>
        <Col xs={24} lg={12}>
          <DunsInformationCard company={company} />
          <AddressesCard company={company} />
        </Col>
      </Row>
    </>
  );
};

export default CompanyGeneralInfo;
