import React, { useState } from "react";

import { Container } from "@trace-one/design-system";

import styles from "./StickyTabContainer.module.less";
import StickyTabHeader from "./StickyTabHeader";

export interface StickyTabContainerProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  onBack: () => void;
  tabs: {
    label: React.ReactNode;
    content?: React.ReactNode;
  }[];
  companyId?: string;
  companyStatus?: string;
}

const StickyTabContainer: React.FC<StickyTabContainerProps> = ({
  title,
  description,
  onBack,
  tabs,
  companyId,
  companyStatus,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (selectedTab: string) =>
    setSelectedTab(parseInt(selectedTab));

  return (
    <div className={styles.root}>
      <StickyTabHeader
        title={title}
        description={description}
        onBack={onBack}
        tabs={tabs}
        onTabChange={onTabChange}
        companyId={companyId}
        companyStatus={companyStatus}
      />
      <Container className={styles.content}>
        {tabs[selectedTab].content}
      </Container>
    </div>
  );
};

export default StickyTabContainer;
