import React from "react";
import { useIntl } from "react-intl";

import { Icon } from "@trace-one/design-system";
import { Checkbox, Tooltip } from "@trace-one/react-components";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";

import { CompanyStatus } from "../../../../../../shared/constants";
import { Contract, ContractStatusActive } from "../../models";
import styles from "../ContractInfo.module.less";

export interface CompanyContractTableColumn {
  id: string;
  key: string;
  contributorWithId?: string;
  contributorWith?: React.ReactNode;
  contractActive?: React.ReactNode;
  deletable?: boolean;
  ownerCompanySubscriptionActive?: boolean;
  ownerCompanyStatus?: string;
  remove?: React.ReactNode;
}

export default function useCompanyContractTable(
  contracts: Contract[],
  removeContract: (ownerCompanyId: string) => void,
  updateContract: (ownerCompanyId: string, status: string) => void,
  disabled,
  isEdit
) {
  const { formatMessage } = useIntl();

  const columns: ColumnsType<CompanyContractTableColumn> = [
    {
      title: formatMessage({
        id: "companySubscriptionForm.contract.contributorWith",
      }),
      dataIndex: "contributorWith",
      key: "contributorWith",
      render: (value, record) => {
        const companySuspendedOrInactive =
          disabled &&
          record.deletable === false &&
          (record.ownerCompanyStatus === CompanyStatus.SUSPENDED ||
            record.ownerCompanyStatus === CompanyStatus.DISABLED);

        return (
          <Tooltip
            title={
              companySuspendedOrInactive &&
              formatMessage({
                id: "companySubscriptionForm.contract.tooltip.companySuspendedOrInactive",
              })
            }
            placement="right"
          >
            <span
              className={
                companySuspendedOrInactive
                  ? styles.disabledName
                  : styles.enabledName
              }
            >
              {record.contributorWith}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: formatMessage({
        id: "companySubscriptionForm.contract.contractActive",
      }),
      dataIndex: "contractActive",
      key: "contractActive",
      render: (value, record) => {
        const ownerInactiveSubscription =
          disabled &&
          record.deletable === false &&
          record.ownerCompanySubscriptionActive === false &&
          record.ownerCompanyStatus === CompanyStatus.ENABLED;

        return (
          <Tooltip
            title={
              ownerInactiveSubscription &&
              formatMessage({
                id: "companySubscriptionForm.contract.tooltip.ownerInactiveSubscription",
              })
            }
            placement="right"
          >
            <Checkbox
              onChange={value => {
                updateContract(record.contributorWithId, value);
              }}
              value={value === ContractStatusActive}
              disabled={isEdit && !record.ownerCompanySubscriptionActive}
            />
          </Tooltip>
        );
      },
    },
    {
      title: formatMessage({
        id: "companySubscriptionForm.contract.remove",
      }),
      dataIndex: "remove",
      key: "remove",
      render: (value, record) => {
        const notDeletable = disabled && record.deletable === false;

        const notDeletableAndActive =
          disabled &&
          record.deletable === false &&
          record.ownerCompanyStatus === CompanyStatus.ENABLED &&
          record.ownerCompanySubscriptionActive === true;

        return (
          <Tooltip
            title={
              notDeletableAndActive &&
              formatMessage({
                id: "companySubscriptionForm.contract.tooltip.notDeletable",
              })
            }
            placement="right"
          >
            <Button
              disabled={notDeletable}
              type="link"
              icon={
                <Icon
                  name="trash"
                  style={{
                    width: "25px",
                    height: "25px",
                    color: notDeletable ? "#9f9f9f" : "#661e75",
                  }}
                />
              }
              onClick={() => removeContract(record.contributorWithId)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const data = contracts.map((contract, index) => ({
    id: contract.id,
    key: index.toString(),
    contributorWithId: contract.ownerCompanyId,
    contributorWith: contract.ownerCompanyName,
    contractActive: contract.status,
    deletable: contract.deletable,
    ownerCompanySubscriptionActive: contract.ownerCompanySubscriptionActive,
    ownerCompanyStatus: contract.ownerCompanyStatus,
    remove: "bin icon",
  }));

  return { columns, data };
}
