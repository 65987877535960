import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tag } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { CompanyPendingData } from "../../../../../models";
import { selectCompanyActivitesMap } from "../../../../../reduxStore/shared/selectors";
import { CompanyStatus } from "../../../../../shared/constants";
import useCompanyStatuses from "../../../hooks/useCompanyStatuses";
import { CompanyPendingTable } from "../models";
import PendingCompanyAction from "../PendingCompanyActions";

export default function useCompanyPendingTable({
  pendingCompanies,
  refetchCompanies,
}: {
  pendingCompanies: CompanyPendingData[];
  refetchCompanies: () => void;
}) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();
  const companiesActivitiesMap = useSelector(selectCompanyActivitesMap);

  const pendingColumns: ColumnsType<CompanyPendingTable> = [
    {
      title: formatMessage({
        id: "general.company",
      }),
      dataIndex: "companyFullName",
      key: "companyFullName",
      width: 200,
      ellipsis: true,
      render: (companyDisplayName, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/companies/pending/${record.rawData.globalUserId}`);
            }}
          >
            {companyDisplayName}
          </Typography.Link>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.dunsNumber",
      }),
      dataIndex: "dunsNumber",
      key: "dunsNumber",
      width: 200,
    },

    {
      title: formatMessage({
        id: "general.dunsName",
      }),
      dataIndex: "companyDuns",
      key: "companyDuns",
      width: 200,
    },

    {
      title: formatMessage({
        id: "general.creationDate",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "registrationStatus",
      key: "registrationStatus",
      width: 140,
      render: (registrationStatus, record) => {
        let status = registrationStatus;
        if (record.rawData.registrationStatus === 0) {
          status = "Pending";
        }
        if (record.rawData.registrationStatus === 2) {
          status = "Rejected";
        }
        if (record.rawData.registrationStatus === 1) {
          status = "Done";
        }

        return (
          <>
            {record.rawData.registrationStatus === 0 && (
              <div>
                <Tag
                  label={companyStatusLabels[status]}
                  color={companyStatusTagColors[status]}
                  size="medium"
                  mode="light"
                />
              </div>
            )}
            {record.rawData.registrationStatus === 2 && (
              <div>
                <Tag
                  label={companyStatusLabels[status]}
                  color={companyStatusTagColors[status]}
                  size="medium"
                  mode="light"
                />
              </div>
            )}
            {record.rawData.registrationStatus === 1 && (
              <div>
                <Tag
                  label={companyStatusLabels[status]}
                  color={companyStatusTagColors[status]}
                  size="medium"
                  mode="light"
                />
              </div>
            )}
          </>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "action",
      key: "action",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (registrationStatus, record) => {
        let status = registrationStatus;
        if (record.rawData.registrationStatus === 0) {
          status = CompanyStatus.PENDING;
        }
        if (record.rawData.registrationStatus === 2) {
          status = CompanyStatus.REJECTED;
        }
        if (record.rawData.registrationStatus === 1) {
          status = CompanyStatus.VALIDATED;
        }

        return (
          <PendingCompanyAction
            data={record.rawData}
            status={status}
            refetchCompanies={refetchCompanies}
          />
        );
      },
    },
  ];

  const pendingCompaniesData = pendingCompanies?.map((company, index) => {
    const result: CompanyPendingTable = {
      key: `company-${index}`,
      companyFullName: (
        <div>
          <span style={{ color: "#661e75" }}>
            {[company.companyLegalName].filter(item => !!item).join(" ")}
          </span>
          <br />
          <span style={{ color: "#929292" }}>
            {companiesActivitiesMap[company.companyActivity]?.text}
          </span>
        </div>
      ),
      companyDuns: company.companyLegalName,
      dunsNumber: company.companyDuns,
      createdAt: moment(company.createdAt).format("DD/MM/YYYY"),
      registrationStatus: company.registrationStatus.toString(),
      rawData: company,
    };
    return result;
  });

  return { pendingColumns, pendingCompaniesData };
}
