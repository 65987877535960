import { createSlice } from "@reduxjs/toolkit";

import { fetchPendingUsersForTraceoneAdmin } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "userPendingList",
  initialState,
  reducers: {
    clearUsers: (state, action) => {
      state.data = initialState.data;
      state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      state.isLoading = false;
      state.hasError = false;
      state.data = [];
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchPendingUsersForTraceoneAdmin.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchPendingUsersForTraceoneAdmin.fulfilled, (state, action) => {
        state.skipAndTakeQueryStats = action.payload.skipAndTakeQueryStats;
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPendingUsersForTraceoneAdmin.rejected, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.hasError = true;
        state.skipAndTakeQueryStats = initialState.skipAndTakeQueryStats;
      });
  },
});

export const { clearUsers } = slice.actions;
export default slice.reducer;
