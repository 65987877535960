import { useIntl } from "react-intl";

import { UserStatus } from "shared/constants";

// +----------------------+--------------------------+---------------------+--+--+
// | Status               | Display to company admin | Display to TO admin |  |  |
// +======================+==========================+=====================+==+==+
// | Enabled              | Active                   | Active              |  |  |
// +----------------------+--------------------------+---------------------+--+--+
// | Disabled             | Suspended                | Suspended           |  |  |
// +----------------------+--------------------------+---------------------+--+--+
// | Suspended            | N/A                      | Suspended           |  |  |
// +----------------------+--------------------------+---------------------+--+--+
// | Deactivated/Inactive | N/A                      | Inactive            |  |  |
// +----------------------+--------------------------+---------------------+--+--+
export default function useUserStatuses() {
  const { formatMessage } = useIntl();

  const userStatusLabels = {
    [UserStatus.ENABLED]: formatMessage({ id: "general.active" }),
    [UserStatus.DISABLED]: formatMessage({ id: "general.suspended" }),
    [UserStatus.SUSPENDED]: formatMessage({ id: "general.suspended" }),
    [UserStatus.DEACTIVATED]: formatMessage({ id: "general.inactive" }),
    [UserStatus.LOCKED]: formatMessage({ id: "general.locked" }),
    [UserStatus.PENDING]: formatMessage({ id: "general.pending" }),
    [UserStatus.REJECTED]: formatMessage({ id: "general.rejected" }),
  };

  const userStatusTagColors = {
    [UserStatus.ENABLED]: "green",
    [UserStatus.DISABLED]: "orange",
    [UserStatus.SUSPENDED]: "orange",
    [UserStatus.DEACTIVATED]: "red",
    [UserStatus.PENDING]: "blue",
    [UserStatus.REJECTED]: "red",
    [UserStatus.LOCKED]: "blue",
  };
  const getUserStatusOptions = (
    userStatuses?: UserStatus[]
  ): { value: UserStatus; label: string }[] => {
    if (userStatuses?.length > 0) {
      return userStatuses.map(status => ({
        value: status,
        label: userStatusLabels[status],
      }));
    }

    return Object.values(userStatusLabels).map(([value, label]) => ({
      value: value as UserStatus,
      label,
    }));
  };

  return { userStatusLabels, userStatusTagColors, getUserStatusOptions };
}
