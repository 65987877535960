import React from "react";
import { useIntl } from "react-intl";

import { Illustration } from "@trace-one/design-system";
import { Empty } from "antd";

import styles from "./Placeholder.module.less";

const Placeholder: React.FC<{ isOfferListEmpty: boolean }> = ({
  isOfferListEmpty,
}) => {
  const { formatMessage } = useIntl();

  const message = isOfferListEmpty
    ? formatMessage({ id: "companySubscriptionForm.noOffers" })
    : formatMessage(
        {
          id: "companySubscriptionForm.noApplicationSelected",
        },
        {
          span: text => <span>{text}</span>,
        }
      );

  return (
    <div className={styles.empty}>
      <Empty
        description={<p className={styles.description}>{message}</p>}
        image={
          <Illustration
            name="no-data"
            size={120}
            color="currentColor"
            style={{ marginLeft: "45%" }}
          />
        }
      />
    </div>
  );
};

export default Placeholder;
