export default function calculatePagination({
  skip,
  take,
  skipAndTakeQueryStats,
}) {
  return {
    showSizeChanger: true,
    pageSize: take,
    total: skipAndTakeQueryStats.totalCount ?? 0,
    current: Math.floor(skip / take) + 1,
  };
}
