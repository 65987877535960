import React from "react";
import { useIntl } from "react-intl";

import { Illustration } from "@trace-one/design-system";

import ModalTitle from "../ModalTitle";
import styles from "../RelationCreate.module.less";

const CompanySelectPlaceholder = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ModalTitle
        content={
          <h3>
            {formatMessage({
              id: "companyRelationList.modal.companySelect.title",
            })}
          </h3>
        }
      />
      <div className={styles.companySelectPlaceholder}>
        <Illustration name="no-data" color="currentColor" />
        <span>
          {formatMessage(
            {
              id: "companyRelationList.modal.companySelect.placeholderText",
            },
            {
              b: text => <b>{text}</b>,
            }
          )}
        </span>
      </div>
    </>
  );
};

export default CompanySelectPlaceholder;
