import cn from "classnames";

import styles from "./CardButton.module.less";

export interface CardButtonProps extends React.HTMLProps<HTMLDivElement> {
  applicationTypeId?: number;
  selected?: boolean;
}

const CardButton: React.FC<CardButtonProps> = ({
  children,
  applicationTypeId,
  selected,
  onClick,
  disabled,
  ...rest
}) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      data-app-id={applicationTypeId}
      role="button"
      {...rest}
      className={cn(
        styles.root,
        {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
        },
        rest.className
      )}
    >
      {children}
    </div>
  );
};

export default CardButton;
