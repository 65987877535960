import React from "react";
import { useIntl } from "react-intl";

import { Col } from "antd";

import Card from "../../../../../components/Card";

import styles from "./AddressCard.module.less";

interface AddressCardProps {
  userInfo: any;
}

const AddressCard: React.FC<AddressCardProps> = ({ userInfo }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <Card title={formatMessage({ id: "companyGeneralInfo.address.title" })}>
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.address" })}
          </span>
          <div>
            {userInfo.data?.companyAddress
              ? userInfo.data?.companyAddress
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.addressDetails" })}
          </span>
          <div>
            {userInfo.data?.companyAddressComplement
              ? userInfo.data?.companyAddressComplement
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.zipcode" })}
          </span>
          <div>
            {userInfo.data?.companyPostalCode
              ? userInfo.data?.companyPostalCode
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.city" })}
          </span>
          <div>
            {userInfo.data?.companyCity
              ? userInfo.data?.companyCity
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.state" })}
          </span>
          <div>
            {userInfo.data?.companyState
              ? userInfo.data?.companyState
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.country" })}
          </span>
          <div>
            {userInfo.data?.companyCountry
              ? userInfo.data?.companyCountry
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default AddressCard;
