import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCompanyRelationList = (state: RootState) =>
  state.companyRelationList;

export const selectCompanyRelationsData = createSelector(
  _selectCompanyRelationList,
  state => state
);

export const selectRelationsSkipAndTakeQueryStats = createSelector(
  _selectCompanyRelationList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);

export const selectRelatedCompaniesMap = createSelector(
  _selectCompanyRelationList,
  ({ relatedCompaniesMap }) => relatedCompaniesMap
);
