import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectPendingCompanyList = (state: RootState) =>
  state.companyPendingList;

export const selectPendingCompanies = createSelector(
  _selectPendingCompanyList,
  ({ data }) => data
);

export const selectPendingCompaniesSkipAndTakeQueryStats = createSelector(
  _selectPendingCompanyList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
export const selectIsPendingCompaniesLoading = createSelector(
  _selectPendingCompanyList,
  ({ isLoading }) => isLoading
);
