import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Contract } from "@trace-one/api-clients.cumd";
import { TagV1 as Tag } from "@trace-one/design-system";
import { Tooltip } from "@trace-one/react-components";
import { Typography } from "antd";

import { ApplicationIconNamesMapping } from "components/AppIcon";
import { formatDate } from "shared/utils/dateUtils";

import {
  ContractStatus,
  SubscriptionStatus,
} from "../../../../../../shared/constants";
import useContractStatuses from "../../../../hooks/useContractStatuses";

import styles from "./ExpandedRows.module.less";

interface ExpandedRowsProps {
  contracts: Contract[];
}

const ExpandedRows: React.FC<ExpandedRowsProps> = ({ contracts }) => {
  const { contractStatusLabels, contractTagColors } = useContractStatuses();

  const history = useHistory();
  const { formatMessage } = useIntl();

  return contracts.length !== 0 ? (
    <div className={styles.expandedWrapper}>
      {contracts.map((contract, index) => (
        <>
          <ul className={styles.expandedList}>
            <li>
              <span className={styles.tableHead}>
                {formatMessage({
                  id: "general.subscription",
                })}
              </span>
              <span className={styles.tableHead}>
                {formatMessage({
                  id: "general.applicationName",
                })}
              </span>
              <span className={styles.tableHead}>
                {formatMessage({
                  id: "general.contractStatus",
                })}
              </span>
              <span className={styles.tableHead}>
                {formatMessage({
                  id: "general.creationDate",
                })}
              </span>
              <span className={styles.tableHead}>
                {formatMessage({
                  id: "general.lastModifiedDate",
                })}
              </span>
            </li>

            <li key={contract.subscriptionId}>
              {contract.subscriptionStatus === SubscriptionStatus.INACTIVE ? (
                <Tooltip
                  title={formatMessage({
                    id: "companyRelationList.contracts.tooltip.text",
                  })}
                  placement="bottom"
                  overlayStyle={{ maxWidth: "280px" }}
                >
                  <span className={styles.disabled}>
                    <p>{contract.subscriptionId}</p>
                  </span>
                </Tooltip>
              ) : (
                <span>
                  <Typography.Link
                    onClick={() => {
                      history.push(
                        `/companies/subscriptions/${contract.subscriptionId}/edit`
                      );
                    }}
                  >
                    {contract.subscriptionId}
                  </Typography.Link>
                </span>
              )}
              <span
                className={
                  contract.subscriptionStatus === SubscriptionStatus.INACTIVE &&
                  styles.disabled
                }
              >
                {ApplicationIconNamesMapping[contract.applicationTypeId]}
              </span>
              <span>
                <div className={styles.tagWrapper}>
                  {contract.subscriptionStatus ===
                    SubscriptionStatus.INACTIVE &&
                    contract.status === ContractStatus.INACTIVE && (
                      <Tag
                        label={contractStatusLabels[contract.status]}
                        color={(contractTagColors[contract.status] = "default")}
                        size="small"
                        mode="light"
                      />
                    )}

                  {contract.subscriptionStatus === SubscriptionStatus.ACTIVE &&
                    contract.status === ContractStatus.INACTIVE && (
                      <Tag
                        label={contractStatusLabels[contract.status]}
                        color={(contractTagColors[contract.status] = "error")}
                        size="small"
                        mode="light"
                      />
                    )}

                  {contract.subscriptionStatus === SubscriptionStatus.ACTIVE &&
                    contract.status === ContractStatus.ACTIVE && (
                      <Tag
                        label={contractStatusLabels[contract.status]}
                        color={(contractTagColors[contract.status] = "success")}
                        size="small"
                        mode="light"
                      />
                    )}
                </div>
              </span>
              <span
                className={
                  contract.subscriptionStatus === SubscriptionStatus.INACTIVE &&
                  styles.disabled
                }
              >
                {formatDate(contract.createdAt)}
              </span>
              <span
                className={
                  contract.subscriptionStatus === SubscriptionStatus.INACTIVE &&
                  styles.disabled
                }
              >
                {formatDate(contract.modifiedAt)}
              </span>
            </li>
          </ul>
          {index < contracts.length - 1 && <div className={styles.divider} />}
        </>
      ))}
    </div>
  ) : (
    <div className={styles.noContracts}>
      {formatMessage({
        id: "companyRelationList.contracts.empty",
      })}
    </div>
  );
};

export default ExpandedRows;
