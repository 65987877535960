import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Button } from "@trace-one/design-system";
import { Col } from "antd";

import Card from "components/Card";
import { IdentifiersType } from "shared/constants";

import usePermissions from "../../../../../core/oidc/usePermissions";
import { CompanyForTraceoneAdminData } from "../../../../../models";

import EditCompanyInfo from "./EditCompanyInfo";
import styles from "./MainInformationCard.module.less";

interface MainInformationCardProps {
  company: CompanyForTraceoneAdminData;
  companyType: string;
  productCategories: string[];
  companyIdentifiers: object[];
  refetchData?: () => void;
  refetchCompanies?: () => void;
}

const MainInformationCard: React.FC<MainInformationCardProps> = ({
  company,
  companyType,
  productCategories,
  companyIdentifiers,
  refetchData,
  refetchCompanies,
}) => {
  const { hasRoleAdministrator, hasRoleSuperAdmin, isTraceoneAdmin } =
    usePermissions();

  const { formatMessage } = useIntl();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "general.mainInformation" }).toUpperCase()}
        extra={
          (hasRoleSuperAdmin || hasRoleAdministrator) &&
          isTraceoneAdmin && (
            <>
              <Button type="link" iconName="edit" onClick={openModal}>
                {formatMessage({ id: "general.edit" })}
              </Button>
              <EditCompanyInfo
                company={company}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                companyType={companyType}
                refetchData={refetchData}
                refetchCompanies={refetchCompanies}
              />
            </>
          )
        }
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.companyDisplayName" })}
          </span>
          <div>
            {company?.companyDisplayName
              ? company?.companyDisplayName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.companyType" })}
          </span>
          <div>
            {company?.companyActivityId
              ? companyType
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "companyGeneralInfo.productCategories" })}
          </span>
          <div>
            {company?.companyCategories?.length > 0
              ? productCategories
                  .map(category => category["categoryItemName"])
                  .join(", ")
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.externalCode" })}
          </span>
          <div>
            {company?.seExternalCode
              ? company?.seExternalCode
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        {company?.companyEmail && (
          <Col className={styles.info} style={{ padding: "0" }}>
            <span className={styles.infoTitle}>
              {formatMessage({ id: "general.email" })}
            </span>
            <div>{company?.companyEmail}</div>
          </Col>
        )}

        {company?.companyPhone && (
          <Col className={styles.info} style={{ padding: "0" }}>
            <span className={styles.infoTitle}>
              {formatMessage({ id: "general.phone" })}
            </span>
            <div>{company?.companyPhone}</div>
          </Col>
        )}

        {company?.companyFax && (
          <Col className={styles.info} style={{ padding: "0" }}>
            <span className={styles.infoTitle}>
              {formatMessage({ id: "companyGeneralInfo.fax" })}
            </span>
            <div>{company?.companyFax}</div>
          </Col>
        )}

        {companyIdentifiers?.map(identifier => {
          if (Object.values(IdentifiersType).includes(identifier["itemCode"])) {
            return (
              <Col className={styles.info} style={{ padding: "0" }}>
                <span className={styles.infoTitle}>
                  {formatMessage({
                    id: `companyGeneralInfo.companyIdentifier${identifier["itemCode"]}`,
                  })}
                </span>
                <div>
                  {
                    company?.companyIdentifiers?.find(
                      companyIdentifier =>
                        companyIdentifier.typeId === identifier["id"]
                    ).value
                  }
                </div>
              </Col>
            );
          }
          return null;
        })}
      </Card>
    </div>
  );
};

export default MainInformationCard;
