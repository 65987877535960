import React from "react";

import { Spinner as BaseSpinner, Container } from "@trace-one/react-components";
import { SpinProps } from "antd";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./Spinner.module.less";

export interface SpinnerProps extends SpinProps {
  underHeader?: boolean;
  hideChildrenVisibilityOnLoading?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  children,
  underHeader,
  hideChildrenVisibilityOnLoading,
  ...rest
}) => {
  if (underHeader) {
    return (
      <Container isPageWrapper>
        <div className={styles.root}>
          <BaseSpinner {...rest}>{children}</BaseSpinner>
        </div>
      </Container>
    );
  }

  if (hideChildrenVisibilityOnLoading) {
    return (
      <BaseSpinner
        {...rest}
        wrapperClassName={cn(
          {
            [styles.hiddenVisibility]: rest.spinning,
          },
          rest.wrapperClassName
        )}
      >
        {children}
      </BaseSpinner>
    );
  }

  return (
    <div className={styles.root}>
      <BaseSpinner {...rest}>{children}</BaseSpinner>
    </div>
  );
};

Spinner.propTypes = {
  underHeader: PropTypes.bool,
  hideChildrenVisibilityOnLoading: PropTypes.bool,
  spinning: PropTypes.bool,
};

export default Spinner;
