import React from "react";
import { useIntl } from "react-intl";

import { Icon, Tooltip, TagV1 as Tag } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import useRelationStatuses from "pages/Companies/hooks/useRelationStatuses";

import { RelationData } from "models";

import { RelationStatus } from "shared/constants";
import { formatDate } from "shared/utils/dateUtils";

import styles from "../CompanyRelationList.module.less";
import { CompanyRelationTableColumn } from "../models";
import RelationActions from "../RelationActions";

export default function useCompanyRelationTable({
  companyId,
  companyRelations,
  refetchRelations,
  closeExpandedRows,
  clearSelectedRowKeys,
  company,
}: {
  companyId: string;
  companyRelations: RelationData[];
  refetchRelations: () => void;
  closeExpandedRows: () => void;
  clearSelectedRowKeys: () => void;
  company?: object;
}) {
  const { formatMessage } = useIntl();

  const { relationStatusLabels, relationTagColors } = useRelationStatuses();

  const columns: ColumnsType<CompanyRelationTableColumn> = [
    {
      title: formatMessage({
        id: "companyRelationList.relationId",
      }),
      dataIndex: "key",
      key: "key",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "companyRelationList.relationType",
      }),
      dataIndex: "relationType",
      key: "relationType",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "companyRelationList.relatedCompany",
      }),
      dataIndex: "relatedCompany",
      key: "relatedCompany",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.creationDate",
      }),
      dataIndex: "creationDate",
      key: "creationDate",
      width: 140,
      ellipsis: true,
      render: creationDate => formatDate(creationDate),
    },
    {
      title: formatMessage({
        id: "general.lastModifiedDate",
      }),
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      width: 140,
      ellipsis: true,
      render: lastModifiedDate => formatDate(lastModifiedDate),
    },
    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (status: string, record) => {
        return (
          <div className={styles.tagsWrapper}>
            <Tag
              label={relationStatusLabels[status]}
              color={relationTagColors[status]}
              size="medium"
              mode="light"
            />
            {record.targetSuspendDate !== null &&
              record.status !== RelationStatus.INACTIVE && (
                <Tooltip
                  text={formatMessage(
                    { id: "companyRelationList.clock.icon.tooltip" },
                    {
                      targetSuspendDate: moment(
                        record.targetSuspendDate
                      ).format("DD/MM/YYYY"),
                    }
                  )}
                >
                  <div className={styles.tagWithTooltip}>
                    <Tag
                      label={<Icon name="pending-suspension" color="orange" />}
                      color="orange"
                      mode="light"
                    />
                  </div>
                </Tooltip>
              )}
          </div>
        );
      },
    },
    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <RelationActions
          selectedRelation={record.rawData}
          relations={companyRelations}
          refetchRelations={refetchRelations}
          closeExpandedRows={closeExpandedRows}
          clearSelectedRowKeys={clearSelectedRowKeys}
          company={company}
        />
      ),
    },
  ];

  const data = companyRelations.map(relation => {
    let relatedCompanyName = "";
    let relationType = "";

    if (companyId === relation.ownerCompanyId) {
      relatedCompanyName = relation.associatedCompanyName;
      relationType = "Work as animator with";
    } else if (companyId === relation.associatedWithCompanyId) {
      relatedCompanyName = relation.ownerCompanyName;
      relationType = "Work as contributor with";
    }

    const result: CompanyRelationTableColumn = {
      key: relation.id,
      relationType: relationType,
      relatedCompany: relatedCompanyName,
      creationDate: relation.createdAt,
      lastModifiedDate: relation.modifiedAt,
      status: relation.enabled
        ? RelationStatus.ACTIVE
        : RelationStatus.INACTIVE,
      rawData: relation,
      targetSuspendDate: relation.targetSuspendDate,
    };

    return result;
  });

  return { columns, data };
}
