import React from "react";

import AddFirstUser from "./components/AddFirstUser";
// import AddMoreUsers from "./components/AddMoreUsers";
import styles from "./TestUserInformation.module.less";

interface TestUserInformationProps {
  testCompanyId?: string;
  testCompanyName?: string;
  jobTitlesOptions?: object[];
  languageOptions?: object[];
  setTestTitle?: (string) => void;
  testTitle?: string;
  // eslint-disable-next-line no-empty-pattern
  setTestJobTitle?: ({}) => void;
  testJobTitle?: number;
  setTestLastName?: (string) => void;
  testLastName?: string;
  setTestFirstName?: (string) => void;
  testFirstName?: string;
  // eslint-disable-next-line no-empty-pattern
  setTestLanguage?: ({}) => void;
  testLanguage?: string;
  testPhoneNumber?: string;
  setTestPhoneNumber?: (string) => void;
  setTestEmail?: (string) => void;
  testEmail?: string;
  setTestConfirmationEmail?: (string) => void;
  testConfirmationEmail?: string;
  testEmailIsValid?: boolean;
  testEmailErrorCode?: string;
  setIsValid?: (boolean) => void;
  handleCreateTestUserAndCompany?: any;
  checkIfEmailExists?: any;
}

const TestUserInformation: React.FC<TestUserInformationProps> = ({
  testCompanyId,
  testCompanyName,
  jobTitlesOptions,
  languageOptions,
  setTestTitle,
  testTitle,
  setTestJobTitle,
  testJobTitle,
  setTestLastName,
  testLastName,
  setTestFirstName,
  testFirstName,
  setTestLanguage,
  testLanguage,
  testPhoneNumber,
  setTestPhoneNumber,
  setTestEmail,
  testEmail,
  setTestConfirmationEmail,
  testConfirmationEmail,
  testEmailIsValid,
  testEmailErrorCode,
  setIsValid,
  handleCreateTestUserAndCompany,
}) => {
  return (
    <div className={styles.userInfoSection}>
      <AddFirstUser
        testCompanyId={testCompanyId}
        testCompanyName={testCompanyName}
        jobTitlesOptions={jobTitlesOptions}
        languageOptions={languageOptions}
        setTestTitle={setTestTitle}
        testTitle={testTitle}
        setTestJobTitle={setTestJobTitle}
        testJobTitle={testJobTitle}
        setTestLastName={setTestLastName}
        testLastName={testLastName}
        setTestFirstName={setTestFirstName}
        testFirstName={testFirstName}
        setTestLanguage={setTestLanguage}
        testLanguage={testLanguage}
        testPhoneNumber={testPhoneNumber}
        setTestPhoneNumber={setTestPhoneNumber}
        setTestEmail={setTestEmail}
        testEmail={testEmail}
        setTestConfirmationEmail={setTestConfirmationEmail}
        testConfirmationEmail={testConfirmationEmail}
        testEmailIsValid={testEmailIsValid}
        testEmailErrorCode={testEmailErrorCode}
        setIsValid={setIsValid}
        handleCreateTestUserAndCompany={handleCreateTestUserAndCompany}
      />
    </div>
  );
};

export default TestUserInformation;
