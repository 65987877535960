function getFirstErrorMessage(errors: any) {
  const firstError = Object.values(errors)[0] as any;
  let message = "";
  if ("type" in firstError) {
    if (typeof firstError?.message === "string") {
      message = firstError?.message;
    }
  } else {
    message = getFirstErrorMessage(firstError);
  }
  return message;
}

export default getFirstErrorMessage;
