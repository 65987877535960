import { SubscriptionData, CompanyForTraceoneAdminData } from "models";

type State = {
  companySubscriptions: SubscriptionData[];
  company: CompanyForTraceoneAdminData;
  isLoading: boolean;
  hasError: boolean;
  errorStatus: number;
};

export const initialState: State = {
  companySubscriptions: [],
  company: null,
  isLoading: true,
  hasError: false,
  errorStatus: null,
};
