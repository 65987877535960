import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCompanyList = (state: RootState) => state.companyList;

export const selectCompanies = createSelector(
  _selectCompanyList,
  ({ data }) => data
);
export const selectCompaniesSkipAndTakeQueryStats = createSelector(
  _selectCompanyList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
export const selectIsCompaniesLoading = createSelector(
  _selectCompanyList,
  ({ isLoading }) => isLoading
);
