import React from "react";
import { useIntl } from "react-intl";

import moment from "moment";

import { UserForUIAdminData } from "models";

import styles from "./RegisterUserInfoRows.module.less";

interface ExpandedRowsProps {
  record: UserForUIAdminData;
}

const RegisterUserInfoRows: React.FC<ExpandedRowsProps> = ({ record }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.expandedWrapper}>
      <ul className={styles.expandedList}>
        <li>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "userList.toAdmin.userGuid",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.lastSeen",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.creationDate",
            })}
          </span>
          <span className={styles.tableHead}>
            {formatMessage({
              id: "general.statusUpdatedDate",
            })}
          </span>
        </li>

        <li key={record.userId}>
          <span className={styles.tableContent}>{record.userId}</span>
          <span className={styles.tableContent}>
            {record.userLastLoggedInDate
              ? moment(record.userLastLoggedInDate).format("DD/MM/YYYY HH:mm")
              : formatMessage({ id: "general.empty" })}
          </span>
          <span className={styles.tableContent}>
            {record.createdAt
              ? moment(record.createdAt).format("DD/MM/YYYY")
              : formatMessage({ id: "general.empty" })}
          </span>
          <span className={styles.tableContent}>
            {record.lastStatusUpdatedDate
              ? moment(record.lastStatusUpdatedDate).format("DD/MM/YYYY")
              : formatMessage({ id: "general.empty" })}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default RegisterUserInfoRows;
