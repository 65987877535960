import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Select, Input, Graphic, Tooltip } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Divider, Form, Typography } from "antd";

import { CumdAPI } from "../../../../../apis";
import AsyncSearchSelect from "../../../../../components/AsyncSearchSelect";
import Card from "../../../../../components/Card";
import { CompanyForTraceoneAdminData } from "../../../../../models";

import styles from "./ChangeDuns.module.less";
import FoundedInfo from "./FoundedInfo";

interface ChangeDunsSectionProps {
  company: CompanyForTraceoneAdminData;
  countryOptions?: object[];
  setSaveEnabled?: (boolean) => void;
  setCompanyDuns?: (object) => void;
}

const ChangeDunsSection: React.FC<ChangeDunsSectionProps> = ({
  company: currentCompany,
  countryOptions,
  setSaveEnabled,
  setCompanyDuns,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [address, setAddress] = useState("");

  const [companyValue, setCompanyValue] = useState(null);
  const [companySearchValue, setCompanySearchValue] = useState<string>("");

  const getCompanyOptions = () => {
    return CumdAPI.getCompanyByDunsFilters({
      companyName: null,
      searchTerm: companySearchValue,
      companyCountry: country,
      companyCity: city,
      companyPostalCode: zipcode,
      companyAddress: address,
    }).then(({ data }) => {
      return data.map(company => {
        const updateNotAllowed =
          company.companyId !== null &&
          company.companyId !== currentCompany.companyId;

        let label;
        if (company.companyId !== null) {
          label = (
            <Tooltip
              text={
                company?.companyState !== null
                  ? `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
                  : `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`
              }
            >
              <div className={styles.labelWrapper}>
                <div>
                  {company?.companyState !== null ? (
                    <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`}</div>
                  ) : (
                    <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`}</div>
                  )}
                </div>
                <span className={styles.icon}>
                  {company.companyId === currentCompany.companyId ? (
                    <Graphic name="success" />
                  ) : (
                    <Graphic name="alert" />
                  )}
                </span>
              </div>
            </Tooltip>
          );
        } else {
          label =
            company?.companyState !== null
              ? `${company.companyName} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
              : `${company.companyName} - ${company.companyPostalCode} - ${company.companyAddress}`;
        }

        return {
          value: company.companyDuns,
          label,
          updateNotAllowed: updateNotAllowed,
          ...company,
        };
      });
    });
  };

  useEffect(() => {
    setSaveEnabled(companyValue && !companyValue.updateNotAllowed);
    setCompanyDuns(companyValue);
  }, [companyValue]);

  const helpMessage = (
    <div className={styles.helpMessage}>
      <span>
        {formatMessage({
          id: "companyCreateDuns.changeDuns.selectCompanyName.errorText",
        })}
      </span>
      <br />
      <div>
        <span className={styles.helpMessageExtraText}>
          {formatMessage({
            id: "companyCreateDuns.changeDuns.selectCompanyName.errorAdditionalText",
          })}
        </span>
        <Typography.Link
          onClick={() => {
            history.push(`/companies/${companyValue.companyId}`);
          }}
        >
          {" "}
          {companyValue?.companyName}
        </Typography.Link>
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({
          id: "companyCreateDuns.title",
        })}
      >
        <div>
          <Label title={formatMessage({ id: "general.country" })} required>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder={formatMessage({ id: "general.selectValue" })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                options={countryOptions}
                onChange={setCountry}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.innerWrapper}>
          <div className={styles.smallInput}>
            <Label title={formatMessage({ id: "general.city" })}>
              <Form.Item name="city">
                <Input
                  placeholder={formatMessage({
                    id: "companyCreateDuns.changeDuns.selectCity.placeholder",
                  })}
                  onChange={event => {
                    setCity(event.target.value);
                  }}
                />
              </Form.Item>
            </Label>
          </div>
          <div className={styles.divider} />
          <div className={styles.smallInput}>
            <Label title={formatMessage({ id: "general.zipcode" })}>
              <Form.Item name="zipCode">
                <Input
                  placeholder={formatMessage({
                    id: "companyCreateDuns.changeDuns.selectZipCode.placeholder",
                  })}
                  onChange={event => {
                    setZipcode(event.target.value);
                  }}
                />
              </Form.Item>
            </Label>
          </div>
        </div>

        <div>
          <Label title={formatMessage({ id: "general.address" })}>
            <Form.Item name="address">
              <Input
                placeholder={formatMessage({
                  id: "companyCreateDuns.changeDuns.selectAddress.placeholder",
                })}
                onChange={event => {
                  setAddress(event.target.value);
                }}
              />
            </Form.Item>
          </Label>
        </div>

        <div>
          <Label
            title={formatMessage({
              id: "companyCreateDuns.changeDuns.selectCompanyName",
            })}
            required
          >
            <Form.Item
              name="companyName"
              validateStatus={companyValue?.updateNotAllowed ? "error" : null}
              help={companyValue?.updateNotAllowed ? helpMessage : null}
            >
              <AsyncSearchSelect
                disabled={!country}
                allowClear
                showSearch
                placeholder={formatMessage({
                  id: "general.enterCompanyName",
                })}
                value={companyValue}
                searchValue={companySearchValue}
                onSearch={setCompanySearchValue}
                onChange={(value, option) => {
                  setCompanyValue(option);
                }}
                onAsyncSearch={getCompanyOptions}
                minLengthToSearch={3}
                labelInValue
              />
            </Form.Item>
          </Label>
        </div>

        {companyValue && !companyValue.updateNotAllowed && (
          <>
            <Divider dashed />
            <FoundedInfo company={companyValue} />
          </>
        )}
      </Card>
    </div>
  );
};

export default ChangeDunsSection;
