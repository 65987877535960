import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { compose } from "@reduxjs/toolkit";

import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import ErrorPage from "../../../../components/ErrorPage";
import Spinner from "../../../../components/Spinner";
import { useAppDispatch } from "../../../../reduxStore";
import { fetchPendingUser } from "../../../../reduxStore/pendingUser/asyncActions";
import { selectPendingUserInfo } from "../../../../reduxStore/pendingUser/selectors";
import PendingCompanyAddDuns from "../../containers/PendingCompanyAddDuns";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());
export const PendingCompanyAddDunsPage: React.FC<BaseHocProps> = ({
  isInitiated,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { userId } = useParams<{ userId?: string }>();

  const userInfo = useSelector(selectPendingUserInfo);

  const refetchPendingUser = () => {
    return dispatch(fetchPendingUser({ userId }));
  };

  useEffect(() => {
    Promise.all([refetchPendingUser()]).then(() => setIsLoading(false));
  }, [userId]);

  if (isLoading) {
    return <Spinner underHeader />;
  }

  if (userInfo.hasError) {
    return <ErrorPage status={userInfo.errorStatus} />;
  }

  return <PendingCompanyAddDuns userInfo={userInfo.data} />;
};

export default enhance(PendingCompanyAddDunsPage);
