import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import CompaniesRouting from "pages/Companies/routing";
import UsersRouting from "pages/Users/routing";

import usePermissions from "core/oidc/usePermissions";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import MasqueradeRoute from "./components/MasqueradeRoute";
import CompanyAdminDetailsPage from "./pages/Companies/pages/CompanyAdminDetailsPage";
import Download from "./pages/Download";
import GroupRouting from "./pages/Groups/routing";

const AppRouting: React.FC = () => {
  const { hasAccessToAdministration } = usePermissions();

  return (
    <Switch>
      <AuthorizedRoute
        path="/users"
        component={UsersRouting}
        canActive={hasAccessToAdministration}
      />
      <AuthorizedRoute
        path="/companies"
        component={CompaniesRouting}
        canActive={hasAccessToAdministration}
      />
      <AuthorizedRoute
        path="/company-details"
        component={CompanyAdminDetailsPage}
        canActive={hasAccessToAdministration}
      />
      <AuthorizedRoute
        path="/download"
        canActive={hasAccessToAdministration}
        component={Download}
        exact
      />
      {window.env.COMPANY_GROUP === "true" && (
        <AuthorizedRoute
          path="/groups"
          canActive={hasAccessToAdministration}
          component={GroupRouting}
        />
      )}
      {/* TODO move permission check to pages */}
      {/* <AuthorizedRoute
        path="/roles/list"
        canActive={isCompanyAdmin}
        exact
      ></AuthorizedRoute> */}
      <Route path="/masquerade" component={MasqueradeRoute} exact />
      <Redirect from="/" to="/users/list" exact />
      <Redirect from="/home" to="/users/list" exact />
      <RouteIfNotFound />
    </Switch>
  );
};

export default AppRouting;
