import React from "react";
import { useIntl } from "react-intl";

import StickyContainer from "../../../../components/StickyContainer";
import { CompanyForTraceoneAdminData } from "../../../../models";
import styles from "../CreateUser/CreateUser.module.less";

import CreateUserForm from "./CreateUserForm";
import RegisteredUsers from "./RegisteredUsers";

interface CreateUserProps {
  company: CompanyForTraceoneAdminData;
  users: any;
  setPaginationQuery?: any;
  skipAndTakeQueryStats?: any;
}

const CreateUser: React.FC<CreateUserProps> = ({
  company,
  users,
  setPaginationQuery,
  skipAndTakeQueryStats,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <StickyContainer
        title={
          company &&
          formatMessage(
            {
              id: "userCreate.title",
            },
            { name: company?.companyDisplayName }
          )
        }
        layoutMode="read"
      >
        <div className={styles.innerContainer}>
          <div className={styles.sectionWrapper}>
            <RegisteredUsers
              users={users}
              setPaginationQuery={setPaginationQuery}
              skipAndTakeQueryStats={skipAndTakeQueryStats}
            />
          </div>
          <div className={styles.sectionWrapper}>
            <CreateUserForm company={company} users={users} />
          </div>
        </div>
      </StickyContainer>
    </div>
  );
};

export default CreateUser;
