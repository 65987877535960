import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import { FilteredSubscription } from "@trace-one/api-clients.cumd";
import { toaster } from "@trace-one/design-system";

import { CumdAPI } from "apis";
import {
  CompanyForTraceoneAdminData,
  OfferData,
  SubscriptionData,
} from "models";

import FormController from "components/FormController";
import FormSection from "components/FormSection";
import StickyContainer from "components/StickyContainer";
import { ApplicationType, SubscriptionStatus } from "shared/constants";
import useToast from "shared/hooks/useToast";
import getFirstErrorMessage from "shared/utils/getFirstFormErrorMessage";

import { ErrorCode } from "../../../../shared/errors";

import AppCardSelect from "./AppCardSelect";
import splitOffers from "./AppCardSelect/utils/splitOffers";
import BusinessModel from "./BusinessModel";
import CommentsList from "./CommentsList";
import CompanyCard from "./CompanyCard";
import styles from "./CompanySupscriptionForm.module.less";
import ContractInfo from "./ContractInfo";
import Limitations from "./Limitations";
import {
  BusinessModelNA,
  BusinessModelSFM,
  Contract,
  ContributionTypeAnimator,
  ContributionTypeContributor,
  SubscriptionValues,
} from "./models";
import { Comment } from "./models";

interface CompanySubscriptionFormProps {
  company: CompanyForTraceoneAdminData;
  subscription?: SubscriptionData;
  disabledApps?: number[];
  offersMap: { [applicationTypeId: string]: OfferData[] };
  appsToDisplay: ApplicationType[];
  refetchData: () => Promise<any>;
}

const CompanySupscriptionForm: React.FC<CompanySubscriptionFormProps> = ({
  company,
  subscription,
  disabledApps = [],
  offersMap,
  appsToDisplay,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const toast = useToast();

  const [contributionType, setContributionType] = useState<string>(null);

  const isEdit = !!subscription?.subscriptionId;
  const showNutriscoreValue = () => {
    if (isEdit && subscription.nutriscoreCalculation !== null) {
      return subscription.nutriscoreCalculation;
    } else {
      return false;
    }
  };

  const [nutriscoreCalculation, setNutriscoreCalculation] = useState<boolean>(
    isEdit ? showNutriscoreValue() : false
  );

  const defaultValues: SubscriptionValues = isEdit
    ? {
        offerIds: subscription.offers.map(({ offerId }) => offerId),
        maxUsers: subscription.maxUsers || undefined,
        maxNumberOfTemplates: subscription.maxNumberOfTemplates || null,
        businessModel: subscription.businessModel,
        templateListIds: subscription.templateListIds || [],
        electronicSignatureTypes: subscription.electronicSignatureTypes || [],
      }
    : {
        offerIds: [],
        businessModel: undefined,
        maxNumberOfTemplates: 1,
      };

  useEffect(() => {
    if (isEdit) {
      const { defaultOffer } = splitOffers(subscription.offers);
      setContributionType(defaultOffer.offerContributionType);
    }
  });

  const { handleSubmit, control, formState, watch } =
    useForm<SubscriptionValues>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues,
    });

  const [comments, setComments] = useState<Comment[]>(
    isEdit ? subscription.comments : []
  );

  const [contracts, setContracts] = useState<Contract[]>(
    isEdit
      ? subscription.contracts.map(contract => ({
          ...contract,
          deletable: false,
        }))
      : []
  );

  const [applicationTypeId, setApplicationTypeId] = useState<number>(
    isEdit ? subscription.applicationTypeId : null
  );

  const [subscriptionsSFM, setSubscriptionsSFM] = useState<
    FilteredSubscription[]
  >([]);

  const onCreate = async (values: SubscriptionValues) => {
    if (
      contributionType === ContributionTypeContributor ||
      applicationTypeId !== ApplicationType.SPEC
    ) {
      values.businessModel = BusinessModelNA;
      values.maxNumberOfTemplates = null;
    }

    try {
      await CumdAPI.createSubscription({
        ...values,
        companyId: company.companyId,
        comments,
        contracts,
        nutriscoreCalculation:
          applicationTypeId === ApplicationType.SPEC
            ? nutriscoreCalculation
            : null,
      });
      toast.saveSuccess();
      history.push(`/companies/${company.companyId}`);
    } catch (error) {
      error.response.data.errorCode === ErrorCode.REACHED_MAX_NUMBER_OF_USERS
        ? toaster.open({
            message: formatMessage({
              id: "toast.alert",
            }),
            description: formatMessage({
              id: "toast.error.max.number.users.subscription.reached",
            }),
            type: "alert",
          })
        : toast.saveError({ error });
    }
  };

  const onEdit = async (values: SubscriptionValues) => {
    try {
      const data = {
        ...subscription,
        ...values,
        comments,
        contracts,
        nutriscoreCalculation:
          applicationTypeId === ApplicationType.SPEC
            ? nutriscoreCalculation
            : null,
      };
      await CumdAPI.updateSubscription(subscription.subscriptionId, data);
      setContracts(
        contracts.map(contract => ({ ...contract, deletable: false }))
      );
      toast.saveSuccess();
    } catch (error) {
      error.response.data.errorCode === ErrorCode.REACHED_MAX_NUMBER_OF_USERS
        ? toaster.open({
            message: formatMessage({
              id: "toast.alert",
            }),
            description: formatMessage({
              id: "toast.error.max.number.users.subscription.reached",
            }),
            type: "alert",
          })
        : toast.saveError({ error });
    }
  };

  const fetchContracts = async () => {
    const ownerCompanyIds = subscription.contracts.map(
      contract => contract.ownerCompanyId
    );

    let companies = [];

    try {
      let { data } = await CumdAPI.getCompaniesByFiltersForToAdmin({
        companyIds: ownerCompanyIds,
      });
      companies = data.companies;
    } catch (error) {
      toast.saveError({ error });
    }

    const newContracts = await Promise.all(
      subscription.contracts.map(async contract => {
        const ownerCompanyName = companies.find(
          company => company.companyId === contract.ownerCompanyId
        )?.companyDisplayName;

        const ownerCompanyStatus = companies.find(
          company => company.companyStatus
        )?.companyStatus;

        let ownerCompanySubscriptionActive = null;

        try {
          const { data } = await CumdAPI.getSubscriptionsByCompanyId(
            contract.ownerCompanyId
          );

          ownerCompanySubscriptionActive = data.some(
            subscription =>
              subscription.applicationTypeId === applicationTypeId &&
              subscription.status === SubscriptionStatus.ACTIVE
          );
        } catch (error) {
          toast.saveError({ error });
        }

        return {
          ...contract,
          ownerCompanyName,
          ownerCompanyStatus,
          ownerCompanySubscriptionActive,
          deletable: false,
        };
      })
    );

    setContracts(newContracts);
  };

  const fetchSubscriptionsSFM = async (applicationTypeId: number) => {
    if (applicationTypeId) {
      let subscriptions = [];

      try {
        let { data } = await CumdAPI.getSubscriptionByFilters({
          applicationTypeId,
          businessModel: BusinessModelSFM,
        });
        subscriptions = data.subscriptions;
      } catch (error) {
        toast.saveError({ error });
      }

      if (subscriptions?.length === 0) {
        setSubscriptionsSFM(subscriptions);
      }

      let ownerCompanyIds = [];
      contracts.forEach(contract =>
        ownerCompanyIds.push(contract.ownerCompanyId)
      );

      if (isEdit) {
        subscription.contracts.forEach(contract =>
          ownerCompanyIds.push(contract.ownerCompanyId)
        );
      }

      subscriptions = subscriptions.filter(
        subscription => !ownerCompanyIds.includes(subscription.companyId)
      );

      setSubscriptionsSFM(subscriptions);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchContracts();
    }

    fetchSubscriptionsSFM(applicationTypeId);
  }, [applicationTypeId]);

  useEffect(() => {
    fetchSubscriptionsSFM(applicationTypeId);
  }, [contracts]);

  return (
    <form
      onSubmit={handleSubmit(isEdit ? onEdit : onCreate, errors => {
        const firstErrorMessage = getFirstErrorMessage(errors);
        toast.error({
          checkErrorResponse: false,
          description: firstErrorMessage,
        });
      })}
    >
      <StickyContainer
        title={
          isEdit
            ? `${formatMessage({
                id: "general.subscription",
              })} ${subscription.functionalId}`
            : formatMessage({ id: "general.createSubscription" })
        }
        layoutMode="edit"
        isSubmitting={formState.isSubmitting}
        onCancel={() => {
          history.push(`/companies/${company.companyId}`);
        }}
      >
        <div className={styles.companyCardWrapper}>
          <CompanyCard company={company} />
        </div>

        <FormSection
          title={
            isEdit
              ? formatMessage({
                  id: "companySubscriptionForm.editSubscription",
                })
              : formatMessage({
                  id: "general.createSubscription",
                })
          }
          description={
            isEdit
              ? `${formatMessage({
                  id: "companySubscriptionForm.editSubscription.description",
                })} ${company.companyDisplayName}`
              : `${formatMessage({
                  id: "companySubscriptionForm.createNewSubscription.description",
                })} ${company.companyDisplayName}`
          }
        >
          <div className={styles.formSectionWrapper}>
            <FormController
              name="offerIds"
              control={control}
              rules={{
                validate: offerIds => {
                  if (!(offerIds?.length > 0)) {
                    return formatMessage({
                      id: "companySubscriptionForm.noOffersEmpty",
                    });
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppCardSelect
                  initialSelectedAppId={
                    isEdit ? subscription.applicationTypeId : null
                  }
                  isEdit={isEdit}
                  appsToDisplay={appsToDisplay}
                  offersMap={offersMap}
                  disabledApps={disabledApps}
                  selectedOfferIds={value}
                  onSelectedOfferIdsChange={onChange}
                  setContributionType={setContributionType}
                  setApplicationTypeId={setApplicationTypeId}
                />
              )}
            />
          </div>
        </FormSection>

        {contributionType === ContributionTypeAnimator &&
          applicationTypeId === ApplicationType.SPEC && (
            <FormSection
              title={formatMessage(
                {
                  id: "companySubscriptionForm.businessModel",
                },
                {
                  b: text => <b style={{ color: "red" }}>{text}</b>,
                }
              )}
            >
              <FormController
                name="businessModel"
                control={control}
                rules={{
                  validate: businessModel => {
                    if (!businessModel) {
                      return formatMessage({
                        id: "companySubscriptionForm.businessModelEmpty",
                      });
                    }
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <BusinessModel
                    onChange={onChange}
                    value={value}
                    disabled={isEdit}
                  />
                )}
              />
            </FormSection>
          )}

        {contributionType === ContributionTypeContributor &&
          applicationTypeId === ApplicationType.SPEC && (
            <FormSection
              title={formatMessage({
                id: "companySubscriptionForm.contract",
              })}
            >
              <ContractInfo
                companyId={company.companyId}
                applicationTypeId={applicationTypeId}
                contracts={contracts}
                setContracts={setContracts}
                subscriptionsSFM={subscriptionsSFM}
                disabled={isEdit}
                isEdit={isEdit}
              />
            </FormSection>
          )}

        <FormSection
          title={formatMessage({
            id: "companySubscriptionForm.specifyLimitations",
          })}
          description={formatMessage({
            id: "companySubscriptionForm.specifyLimitations.description",
          })}
        >
          <Limitations
            subscription={subscription}
            control={control}
            applicationTypeId={applicationTypeId}
            contributionType={contributionType}
            defaultNumberOfTemplates={defaultValues.maxNumberOfTemplates}
            watch={watch}
            setNutriscoreCalculation={setNutriscoreCalculation}
            nutriscoreCalculation={nutriscoreCalculation}
          />
        </FormSection>

        <FormSection
          title={formatMessage({
            id: "companySubscriptionForm.comments",
          })}
        >
          <CommentsList comments={comments} setComments={setComments} />
        </FormSection>
      </StickyContainer>
    </form>
  );
};

export default CompanySupscriptionForm;
