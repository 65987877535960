import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment/moment";

import Table from "../../../../components/Table";
import { useAppDispatch } from "../../../../reduxStore";
import { selectTeamMemberResponsibilitiesData } from "../../../../reduxStore/shared/selectors";
import {
  selectIsUsersLoading,
  selectUsersSkipAndTakeQueryStats,
} from "../../../../reduxStore/userList/selectors";
import { clearUsers } from "../../../../reduxStore/userList/slice";
import { fetchPendingUsersForTraceoneAdmin } from "../../../../reduxStore/userPendingList/asyncActions";
import { selectPendingUsers } from "../../../../reduxStore/userPendingList/selectors";
import { showNewSearchForm, UserStatus } from "../../../../shared/constants";
import useSearchFilters from "../../../../shared/hooks/useSearchFilters";
import useTablePagination from "../../../../shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../../../shared/webStorage";
import { TraceoneAdminUserFilter } from "../TraceoneAdminUserList/models";
import TraceoneAdminUserTableHeader from "../TraceoneAdminUserList/TraceoneAdminUserTableHeader";

import useUserPendingTable from "./hooks/useUserPendingTable";
import OldUserPendingSearch from "./OldUserPendingSearch";
import PendingUserSearch from "./PendingUserSearch";
import styles from "./TraceoneAdminPendingUserList.module.less";

const TraceoneAdminPendingUserList = () => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<string>("pendingUsers");
  const pendingUsers = useSelector(selectPendingUsers);
  const skipAndTakeQueryStats = useSelector(selectUsersSkipAndTakeQueryStats);
  const isUserTableLoading = useSelector(selectIsUsersLoading);
  const isResponsibilitiesLoading = useSelector(
    selectTeamMemberResponsibilitiesData
  ).isLoading;

  const isLoading = isUserTableLoading || isResponsibilitiesLoading;
  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage:
        show === "pendingUsers" &&
        TablePaginationStorage.TO_ADMIN_PENDING_USER_LIST,
    });

  const {
    searchText: pendingSearchText,
    setSearchText: pendingSetSearchText,
    mergeFilters: pendingMergeFilters,
    filterObj: pendingFilterObj,
    removeAllFiltersAndResetPageNumber:
      pendingRemoveAllFiltersAndResetPageNumber,
    setFilterObj: pendingSetFilterObj,
    resetPageNumberAndRowKeys: pendingResetPageNumberAndRowKeys,
  } = useSearchFilters<TraceoneAdminUserFilter>(
    {
      owningCompanyId: undefined,
      userStatuses: [UserStatus.PENDING],
      responsibilityId: undefined,
      applicationTypeIds: [],
      companyActivity: undefined,
      userJobTitle: undefined,
      administratorFilterList: [],
      createdAt: undefined,
      isMainContactFilterList: [],
      userFirstName: undefined,
      userLastName: undefined,
      userEmail: undefined,
    },
    {
      resetPageNumber,
      clearedFilters: {
        owningCompanyId: undefined,
        userStatuses: [],
        responsibilityId: undefined,
        applicationTypeIds: [],
        companyActivity: undefined,
        userJobTitle: undefined,
        administratorFilterList: [],
        createdAt: undefined,
        isMainContactFilterList: [],
        userFirstName: undefined,
        userLastName: undefined,
        userEmail: undefined,
      },
      webStorage: SearchFiltersStorage.TO_ADMIN_PENDING_USER_LIST,
    }
  );

  let filterObj = show === "pendingUsers" && pendingFilterObj;

  const {
    userStatuses,
    compnayLegalName,
    createdAt,
    userFirstName,
    userLastName,
    userEmail,
  } = filterObj;

  const refetchUsers = useCallback(() => {
    const defaultPendingUserStatuses = [0, 2];
    const _pendinguserStatuses = userStatuses.map(status => {
      if (status === "Pending") {
        return 0;
      } else {
        return 2;
      }
    });

    show === "pendingUsers" &&
      dispatch(
        fetchPendingUsersForTraceoneAdmin({
          skip: paginationQuery.skip,
          take: paginationQuery.take,
          registrationStatuses:
            userStatuses.find(ele => ele === "Pending") ||
            userStatuses.find(ele => ele === "Rejected")
              ? _pendinguserStatuses
              : defaultPendingUserStatuses,
          SearchText: pendingSearchText,
          CompanyLegalName: compnayLegalName,
          createdAt:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          UserFirstName: userFirstName,
          UserLastName: userLastName,
          UserEmail: userEmail,
        })
      );
  }, [
    pendingSearchText,
    paginationQuery,
    userStatuses,
    show,
    createdAt,
    userFirstName,
    userLastName,
    userEmail,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearUsers(null));
    };
  }, []);

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  const { pendingColumns, pendingUserdata } = useUserPendingTable({
    pendingUsers,
    refetchUsers,
  });

  return (
    <div className={styles.root}>
      {showNewSearchForm ? (
        <PendingUserSearch
          filterObj={filterObj}
          mergeFilters={pendingMergeFilters}
          initialSearchValue={pendingSearchText}
          onSearch={pendingSetSearchText}
          onClearFiltersClick={pendingRemoveAllFiltersAndResetPageNumber}
          setFilterObj={pendingSetFilterObj}
          resetPageNumberAndRowKeys={pendingResetPageNumberAndRowKeys}
        />
      ) : (
        <OldUserPendingSearch
          filterObj={filterObj}
          mergeFilters={pendingMergeFilters}
          initialSearchValue={pendingSearchText}
          onSearch={pendingSetSearchText}
          onClearFiltersClick={pendingRemoveAllFiltersAndResetPageNumber}
          setFilterObj={pendingSetFilterObj}
          resetPageNumberAndRowKeys={pendingResetPageNumberAndRowKeys}
        />
      )}

      <TraceoneAdminUserTableHeader
        showed={show}
        setChangeShow={setShow}
        totalCount={skipAndTakeQueryStats.totalCount ?? 0}
        currentNumber={skipAndTakeQueryStats.currentCount ?? 0}
      />

      <Table
        columns={pendingColumns}
        dataSource={pendingUserdata}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />
    </div>
  );
};

export default TraceoneAdminPendingUserList;
