import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectPendingUser = (state: RootState) => state.pendingUser;

export const selectPendingUserInfo = createSelector(
  _selectPendingUser,
  ({ pendingUserData }) => pendingUserData
);

// export const selectIsUsersLoading = createSelector(
//   _selectPendingUser,
//   ({ isLoading }) => isLoading
// );
