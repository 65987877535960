import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

import { selectOidcIsTraceoneAdmin } from "../oidc/selectors";

/* istanbul ignore next */
export const _selectUser = (state: RootState) => state.user;

export const selectIsUserApplicationsLoading = createSelector(
  _selectUser,
  ({ isApplicationsLoading }) => isApplicationsLoading
);

export const selecthasApplicationsError = createSelector(
  _selectUser,
  ({ hasApplicationsError }) => hasApplicationsError
);

export const selectUserApplications = createSelector(
  _selectUser,
  ({ applications }) => applications
);

export const selectUserApplicationsCompanies = createSelector(
  _selectUser,
  ({ applicationsCompanies }) => applicationsCompanies
);

export const selecthasUserDetailsError = createSelector(
  _selectUser,
  ({ hasUserDetailsError }) => hasUserDetailsError
);

export const selectIsUserDetailsLoading = createSelector(
  _selectUser,
  ({ isUserDetailsLoading }) => isUserDetailsLoading
);
const selectUserDetails = createSelector(_selectUser, ({ details }) => details);

export const selectUserId = createSelector(
  selectUserDetails,
  ({ userId }) => userId
);
export const selectUserLogin = createSelector(
  selectUserDetails,
  ({ userLogin }) => userLogin
);

/**
 * TO admin use only ENGLISH
 */
export const selectUserLanguageCode = createSelector(
  [selectUserDetails, selectOidcIsTraceoneAdmin],
  ({ userLanguagePreference }) => {
    return userLanguagePreference;
  }
);

export const selectUserOwningCompanyId = createSelector(
  selectUserDetails,
  ({ owningCompanyId }) => owningCompanyId
);
export const selectUserPhotoUrl = createSelector(
  selectUserDetails,
  ({ userPhotoUrl }) => userPhotoUrl
);
export const selectUserName = createSelector(
  selectUserDetails,
  ({ userFirstName, userLastName }) =>
    [userFirstName, userLastName].filter(item => item).join(" ")
);
export const selectUserJobTitle = createSelector(
  selectUserDetails,
  ({ userJobTitle }) => userJobTitle
);
export const selectUserCompanyActivityNameInEng = createSelector(
  _selectUser,
  ({ companyActivityNameInEn }) => companyActivityNameInEn
);
export const selectUserOwningCompanyName = createSelector(
  _selectUser,
  ({ details, applicationsCompanies }) => {
    return applicationsCompanies.find(
      c => c.companyId === details.owningCompanyId
    )?.companyDisplayName;
  }
);
