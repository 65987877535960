import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { toaster } from "@trace-one/design-system";
import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";
import moment from "moment";

import { CumdAPI } from "apis";
import { CompanyForTraceoneAdminData } from "models";

import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { CompanyStatus, UserActionOptions } from "shared/constants";
import useToast from "shared/hooks/useToast";

import usePermissions from "../../../../core/oidc/usePermissions";
import {
  RelationCloseCancel,
  RelationCloseSchedule,
} from "../CompanyList/models";
import RelationCreate from "../CompanyRelationList/RelationCreate";

import styles from "./CompanyListModel.module.less";
import ManageSuspendModal from "./Modals/ManageSuspendModal";

interface CompanyActionProps {
  data: CompanyForTraceoneAdminData;
  refetchCompanies: () => void;
  scheduledSuspension: boolean;
}

const CompanyAction: React.FC<CompanyActionProps> = ({
  data,
  refetchCompanies,
  scheduledSuspension,
}) => {
  const { hasRoleAdministrator, hasRoleSuperAdmin } = usePermissions();
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const history = useHistory();
  const toast = useToast();
  const DELIST = UserActionOptions.DELIST;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isManageSuspendModalOpen, setIsManageSuspendModalOpen] =
    useState(false);

  const handleModalConfirm = async (type: string) => {
    if (type === DELIST) {
      try {
        await CumdAPI.delistCompanyStatusById(data.companyId);
        refetchCompanies();
      } catch (error) {
        toast.saveError({ error });
      }
    }
  };

  const setUserSatusActive = async () => {
    try {
      await CumdAPI.activeComapnyStatusById(data.companyId);
      refetchCompanies();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const handleConfirmationModal = (type: string) => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          icon={"users"}
          onConfirm={() => handleModalConfirm(type)}
          confirmationModalTitle={formatMessage({
            id: "companySubscriptionForm.delist.title",
          })}
        >
          <section className={styles.userStatusModal}>
            <div className={styles.description}>
              <p>
                {formatMessage(
                  {
                    id: "companySubscriptionForm.delist.description",
                  },
                  {
                    CompanyDisplayName: data.companyDisplayName,
                  }
                )}
              </p>
            </div>
            {formatMessage({
              id: "general.action.confirmation.text",
            })}
          </section>
        </ConfirmationModal>
      ),
    });
  };

  const suspendCompany = async () => {
    try {
      await CumdAPI.suspendComapnyStatusById(data.companyId);
      refetchCompanies();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const scheduleSuspension = async ({
    companyIds,
    targetSuspendDate,
    action,
  }: {
    companyIds: string[];
    targetSuspendDate: string;
    action: string;
  }) => {
    try {
      await CumdAPI.updateCompanySuspension({
        companyIds,
        targetSuspendDate,
      });
      refetchCompanies();
      if (action === RelationCloseSchedule) {
        toaster.open({
          message: formatMessage({
            id: "companySubscriptionForm.suspend.schedule.title",
          }),
          description: formatMessage(
            {
              id: "companySubscriptionForm.suspend.schedule.description",
            },
            {
              companyName: data.companyDisplayName,
              targetSuspendDate: moment(targetSuspendDate).format("DD/MM/YYYY"),
            }
          ),
          type: "confirmation",
        });
      } else if (action === RelationCloseCancel) {
        toaster.open({
          message: formatMessage({
            id: "companySubscriptionForm.suspend.cancel.title",
          }),
          description: formatMessage(
            {
              id: "companySubscriptionForm.suspend.cancel.description",
            },
            {
              companyName: data.companyDisplayName,
            }
          ),
          type: "confirmation",
        });
      } else return;
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <>
      {data.companyStatus !== CompanyStatus.DISABLED && (
        <>
          <ActionDropdown
            data-test-id="ad-company-action"
            overlay={
              <Menu>
                {(hasRoleSuperAdmin || hasRoleAdministrator) && (
                  <>
                    <Menu.Item
                      key="company-create-relation"
                      data-test-id="ad-create-relation"
                      onClick={() => setIsModalVisible(true)}
                      disabled={data.companyStatus === CompanyStatus.SUSPENDED}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "companyRelationList.createRelation",
                      })}
                    </Menu.Item>

                    <Menu.Item
                      key="company-create-subscription"
                      data-test-id="ad-create-subscription"
                      disabled={data.companyStatus === CompanyStatus.SUSPENDED}
                      onClick={() => {
                        history.push(
                          `/companies/${data.companyId}/subscriptions/create`
                        );
                      }}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "general.createSubscription",
                      })}
                    </Menu.Item>

                    <Menu.Item
                      key="company-create-user"
                      data-test-id="ad-create-user"
                      disabled={data.companyStatus === CompanyStatus.SUSPENDED}
                      onClick={() => {
                        history.push(`/users/create-user/${data.companyId}`);
                      }}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "general.createUser",
                      })}
                    </Menu.Item>

                    {data.companyStatus === CompanyStatus.SUSPENDED ? (
                      <Menu.Item
                        key="company-create-authorize"
                        data-test-id="ad-create-authorize"
                        onClick={() => setUserSatusActive()}
                        style={{ fontSize: "16px", lineHeight: "24px" }}
                      >
                        {formatMessage({
                          id: "general.authorize",
                        })}
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        key="company-create-suspend"
                        data-test-id="ad-create-suspend"
                        onClick={() => setIsManageSuspendModalOpen(true)}
                        style={{ fontSize: "16px", lineHeight: "24px" }}
                      >
                        {formatMessage({
                          id: scheduledSuspension
                            ? "company.actions.manageSuspension"
                            : "general.suspend",
                        })}
                      </Menu.Item>
                    )}

                    <Menu.Item
                      key="company-create-delist"
                      data-test-id="ad-create-delist"
                      onClick={() => handleConfirmationModal(DELIST)}
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {formatMessage({
                        id: "general.delist",
                      })}
                    </Menu.Item>
                  </>
                )}
              </Menu>
            }
          />
          <RelationCreate
            company={data}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            refetchRelations={refetchCompanies}
          />
          <ManageSuspendModal
            visible={isManageSuspendModalOpen}
            setVisible={setIsManageSuspendModalOpen}
            selectedCompany={data}
            suspendCompany={suspendCompany}
            scheduleSuspension={scheduleSuspension}
            scheduledSuspension={scheduledSuspension}
          />
        </>
      )}
    </>
  );
};

export default CompanyAction;
