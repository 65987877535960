import { CompanyForTraceoneAdminData } from "models";

import { ApplicationType, CompanyStatus } from "shared/constants";

export const RelationCloseNow = "now";
export const RelationCloseSchedule = "schedule";
export const RelationCloseCancel = "cancel";

export interface CompanyFilter {
  companyActivityId?: string;
  applicationTypeIds?: string[];
  companyStatuses?: CompanyStatus[];
  companyLegalName?: string;
  companyDuns?: string;
  companySearchText?: string;
  globalCompanyId?: string;
  createdAt?: string;
  creationDate?: string;
  fromStatusUpdatedDate?: string;
  toStatusUpdatedDate?: string;
  fromTargetSuspendDate?: string;
  toTargetSuspendDate?: string;
}

export interface CompanyTableColumn {
  key: string;
  companyDisplayName?: React.ReactNode;
  companyDuns?: string;
  companyLegalName?: string;
  companyType?: string;
  applications?: ApplicationType[];
  companyStatus?: string;
  userCount?: number;
  lastStatusUpdatedDate?: string;
  rawData: CompanyForTraceoneAdminData;
}
