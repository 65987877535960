import { useIntl } from "react-intl";

import { Illustration, Tag } from "@trace-one/design-system";
import { Divider } from "antd";
import cn from "classnames";

import { OfferData } from "models";

// import Icon from "components/Icon";

import { OfferType } from "shared/constants";

import CardButton, { CardButtonProps } from "../CardButton";

import styles from "./OfferCardButton.module.less";

interface OfferCardButtonProps extends CardButtonProps {
  offer: OfferData;
}

const OfferCardButton: React.FC<OfferCardButtonProps> = ({
  offer,
  selected,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { offerName, offerDescription, offerType, offerContributionType } =
    offer;

  const renderOfferTypeTag = () => {
    if (offerType === OfferType.DEFAULT) {
      return (
        <Tag
          label={formatMessage({ id: "companySubscriptionForm.defaultOffer" })}
          mode="light"
          size="medium"
          color="purple"
          data-offer-type={OfferType.DEFAULT}
        />
      );
    } else if (offerType === OfferType.ADD_ON) {
      return (
        <Tag
          label={formatMessage({ id: "companySubscriptionForm.addOnOffer" })}
          mode="light"
          size="medium"
          color="green"
          data-offer-type={OfferType.ADD_ON}
        />
      );
    }
  };

  const renderContributorTypeTag = () => {
    return (
      <Tag
        label={offerContributionType}
        mode="light"
        size="medium"
        color="blue"
        data-offer-type={offerContributionType}
      />
    );
  };

  return offerType === OfferType.DEFAULT ? (
    <CardButton
      selected={selected}
      {...rest}
      className={cn(styles.root, styles.default, {
        [styles.selected]: selected,
      })}
    >
      <div className={styles.header}>
        <div className={styles.offerNameWrapper}>
          <h3>{offerName}</h3>
        </div>
        <div className={styles.offerTagsWrapper}>
          <div>{renderContributorTypeTag()}</div>
          <div>{renderOfferTypeTag()}</div>
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className={styles.body}>{offerDescription}</div>
    </CardButton>
  ) : (
    <CardButton
      selected={selected}
      {...rest}
      className={cn(styles.root, styles.addOnn, {
        [styles.selected]: selected,
      })}
    >
      <div className={styles.header}>
        <div>
          <Illustration name="add-ons" color="currentColor" />
        </div>
        <div className={styles.offerNameWrapper}>
          <h3>{offerName}</h3>
        </div>
        <div className={styles.offerTagsWrapper}>
          <div>{renderContributorTypeTag()}</div>
          <div>{renderOfferTypeTag()}</div>
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className={styles.body}>{offerDescription}</div>
    </CardButton>
  );
};

export default OfferCardButton;
