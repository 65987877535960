import CatalogueDataApi from "@trace-one/api-clients.catalogue";
import CompanyUserMasterDataApi from "@trace-one/api-clients.cumd";
import ProductMasterDataApi from "@trace-one/api-clients.pmd";
import ReferenceListMasterDataApi from "@trace-one/api-clients.rlmd";
import SiteMasterData from "@trace-one/api-clients.smd";

import AXIOS_INSTANCES from "./axiosInstances";

export const CumdAPI = new CompanyUserMasterDataApi(AXIOS_INSTANCES.CUMD);
export const PmdAPI = new ProductMasterDataApi(AXIOS_INSTANCES.PMD);
export const RlmdAPI = new ReferenceListMasterDataApi(AXIOS_INSTANCES.RLMD);
export const SmdAPI = new SiteMasterData(AXIOS_INSTANCES.SMD);
export const CatalogueAPI = new CatalogueDataApi(AXIOS_INSTANCES.CATALOGUE);
