import React from "react";

import { Icon } from "@trace-one/react-components";

import styles from "./IconExpand.module.less";

interface IconExpandProps {
  expandable: boolean;
  expanded: boolean;
  record: object;
  onExpand: (record, e) => void;
}

const IconExpand: React.FC<IconExpandProps> = ({
  expandable,
  expanded,
  record,
  onExpand,
}) => {
  const onClick = e => {
    onExpand(record, e);
  };

  const iconProps = {
    size: 18,
    name: expanded ? "arrow-down" : "arrow-right",
    ...(expandable && { onClick }),
    className: expanded ? styles.arrowDown : styles.arrowRight,
  };

  return <Icon {...iconProps} data-test-id="expand-icon-svg" />;
};

export default IconExpand;
