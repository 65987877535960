import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Filter } from "@trace-one/business-components";
import {
  DatePicker,
  FilterProps,
  Picker,
  Input,
} from "@trace-one/design-system";
import moment from "moment";

import { SearchFiltersProps } from "components/SearchFilters";

import CheckableTags from "../../../../components/CheckableTags";
import { UserStatus } from "../../../../shared/constants";
import { adjustIntercomButton } from "../../../../shared/utils/toggleIntercom";

import { TraceoneAdminUserFilter } from "./models";
import styles from "./PendingUserSearch.module.less";

interface PendingUserSearchProps {
  filterObj: TraceoneAdminUserFilter;
  mergeFilters: (
    value:
      | Partial<TraceoneAdminUserFilter>
      | ((prevState: TraceoneAdminUserFilter) => TraceoneAdminUserFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
  setFilterObj: React.Dispatch<React.SetStateAction<TraceoneAdminUserFilter>>;
  resetPageNumberAndRowKeys: () => void;
}

const PendingUserSearch: React.FC<PendingUserSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();
  const showIntercom = useRef(true);

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  useEffect(() => {
    const { userStatuses, createdAt, userFirstName, userLastName, userEmail } =
      filterObj;

    const defaultSelectedFilters = [
      userFirstName && {
        filterId: "1",
        values: [userFirstName],
      },

      userLastName && {
        filterId: "2",
        values: [userLastName],
      },

      userEmail && {
        filterId: "3",
        values: [userEmail],
      },

      createdAt && {
        filterId: "4",
        values: [createdAt],
      },

      userStatuses && {
        filterId: "5",
        values: userStatuses.map(e => e.length),
      },
    ].filter(i => !!i);

    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: formatMessage({ id: "general.firstName" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userFirstName: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          value={filterObj.userFirstName}
          onChange={e => mergeFilters({ userFirstName: e.target.value })}
          allowClear
          showSearch
          data-test-id="ad-pending-users-filter-firstName"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "general.lastName" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userLastName: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          onChange={e => mergeFilters({ userLastName: e.target.value })}
          value={filterObj.userLastName}
          allowClear
          showSearch
          data-test-id="ad-pending-users-filter-lastName"
        />
      ),
      filterId: "2",
    },

    {
      title: formatMessage({ id: "general.user.login" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          userEmail: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          onChange={e => mergeFilters({ userEmail: e.target.value })}
          value={filterObj.userEmail}
          allowClear
          showSearch
          data-test-id="ad-pending-users-filter-userLogin"
        />
      ),
      filterId: "3",
    },

    {
      title: formatMessage({ id: "general.createdOn" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          creationDate: undefined,
        });
      },
      elementToRender: (
        <DatePicker
          value={
            filterObj.createdAt
              ? moment(filterObj.createdAt, "DD/MM/YYYY")
              : undefined
          }
          onChange={createdAt => {
            mergeFilters({
              createdAt: createdAt?.format("DD/MM/YYYY"),
            });
          }}
          showToday={false}
          style={{ width: "275px" }}
          data-test-id="ad-pending-users-filter-createdOn"
        />
      ),
      filterId: "4",
    },
    {
      title: formatMessage({
        id: "general.status",
      }),
      controlType: "multi-selection",
      clearSelection: () => {
        mergeFilters({
          userStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.userStatuses}
          onChange={userStatuses =>
            mergeFilters({
              userStatuses,
            })
          }
          options={[
            {
              value: UserStatus.PENDING,
              label: formatMessage({ id: "general.pending" }),
            },
            {
              value: UserStatus.REJECTED,
              label: formatMessage({ id: "general.rejected" }),
            },
          ]}
          data-test-id="ad-pending-users-filter-status"
        />
      ),
      quickFilter: (
        <Picker.Filter
          title={formatMessage({
            id: "general.status",
          })}
          showBadgeCount
          selectionType="multi"
          value={filterObj.userStatuses ?? []}
          items={[
            {
              value: UserStatus.PENDING,
              label: formatMessage({ id: "general.pending" }),
            },
            {
              value: UserStatus.REJECTED,
              label: formatMessage({ id: "general.rejected" }),
            },
          ]}
          onChange={({ value }) => {
            mergeFilters({
              userStatuses: value.length ? value : [],
            });
          }}
          clearSelection={() =>
            mergeFilters({
              userStatuses: [],
            })
          }
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          data-test-id="ad-pending-users-filter-picker-status"
        />
      ),
      filterId: "5",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "userList.toAdmin.searchPlaceholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default PendingUserSearch;
