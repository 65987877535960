import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TagV1 as Tag, Tooltip, Icon } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import useCompanyStatuses from "pages/Companies/hooks/useCompanyStatuses";
import { TraceoneAdminUserFilter } from "pages/Users/containers/TraceoneAdminUserList/models";

import { CompanyForTraceoneAdminData } from "models";

import { selectCompanyActivitesMap } from "reduxStore/shared/selectors";

import AppIcons from "components/AppIcons";
import { CompanyStatus } from "shared/constants";
import { useExternalFilters } from "shared/hooks/useSearchFilters";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import CompanyAction from "../CompanyAction";
import styles from "../CompanyListModel.module.less";
import { CompanyTableColumn } from "../models";

export default function useCompanyTable({
  companies,
  refetchCompanies,
}: {
  companies: CompanyForTraceoneAdminData[];
  refetchCompanies: () => void;
}) {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { handleExternalFilterChange } =
    useExternalFilters<TraceoneAdminUserFilter>({
      paginationWebStorage:
        TablePaginationStorage.TO_ADMIN_REGISTERED_USER_LIST,
      filterWebStorage: SearchFiltersStorage.TO_ADMIN_REGISTERED_USER_LIST,
    });

  const companiesActivitiesMap = useSelector(selectCompanyActivitesMap);
  const { companyStatusLabels, companyStatusTagColors } = useCompanyStatuses();

  const columns: ColumnsType<CompanyTableColumn> = [
    {
      title: formatMessage({
        id: "companyList.companyDisplayName",
      }),
      dataIndex: "companyDisplayName",
      key: "companyDisplayName",
      width: 200,
      ellipsis: true,
      render: (companyDisplayName, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/companies/${record.key}`);
            }}
          >
            {companyDisplayName}
          </Typography.Link>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.dunsNumber",
      }),
      dataIndex: "companyDuns",
      key: "companyDuns",
      width: 200,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.dunsName",
      }),
      dataIndex: "companyLegalName",
      key: "companyLegalName",
      width: 200,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "companyStatus",
      key: "companyStatus",
      width: 140,
      render: (status, record) => {
        return (
          <div className={styles.tagsWrapper}>
            <Tag
              label={companyStatusLabels[status]}
              color={companyStatusTagColors[status]}
              size="medium"
              mode="light"
            />

            {!!record.rawData.targetSuspendDate &&
              status !== CompanyStatus.SUSPENDED && (
                <Tooltip
                  text={formatMessage(
                    { id: "company.clock.icon.tooltip" },
                    {
                      targetSuspendDate: moment(
                        record.rawData.targetSuspendDate
                      ).format("DD/MM/YYYY"),
                    }
                  )}
                >
                  <div className={styles.tagWithTooltip}>
                    <Tag
                      label={<Icon name="pending-suspension" color="orange" />}
                      color="orange"
                      mode="light"
                    />
                  </div>
                </Tooltip>
              )}
          </div>
        );
      },
    },

    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applications",
      key: "applications",
      width: 200,
      render: (applications: number[], record) => {
        return <AppIcons applications={applications} />;
      },
    },

    {
      title: formatMessage({
        id: "general.users",
      }),
      dataIndex: "userCount",
      key: "userCount",
      width: 140,
      ellipsis: true,
      render: (value, record) => {
        const suffix = formatMessage({
          id: "general.users",
        });

        if (value > 0) {
          return (
            <Typography.Link
              onClick={() => {
                const owningCompanyId = record.rawData.companyId;
                handleExternalFilterChange({
                  owningCompanyId,
                  userStatuses: [],
                });
                history.push(`/users/list`);
              }}
              underline
            >
              {`${value} ${suffix}`}
            </Typography.Link>
          );
        } else {
          return `${value} ${suffix}`;
        }
      },
    },

    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <CompanyAction
          data={record.rawData}
          refetchCompanies={refetchCompanies}
          scheduledSuspension={
            !!record.rawData.targetSuspendDate &&
            record.rawData.companyStatus !== CompanyStatus.SUSPENDED
          }
        />
      ),
    },
  ];

  const data = companies.map(company => {
    const result: CompanyTableColumn = {
      key: company.companyId,
      companyDisplayName: (
        <div>
          <span>{company.companyDisplayName}</span>
          <br />
          <span
            style={{ color: "#929292", fontSize: "14px", lineHeight: "20px" }}
          >
            {companiesActivitiesMap[company.companyActivityId]?.text}
          </span>
        </div>
      ),
      companyDuns: company.companyDuns,
      companyLegalName: company.companyLegalName,
      companyStatus: company.companyStatus,
      applications: company.applicationTypeIds.filter(
        app => app !== 40 && company.applicationTypeIds
      ),
      userCount: company.userCount,
      rawData: company,
    };
    return result;
  });
  return { columns, data };
}
