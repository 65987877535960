import React, { useState } from "react";
import { useIntl } from "react-intl";

import { toaster } from "@trace-one/design-system";
import { Menu } from "antd";
import moment from "moment";

import ActionDropdown from "components/ActionDropdown";

import { CumdAPI } from "../../../../apis";
import usePermissions from "../../../../core/oidc/usePermissions";
import { RelationData } from "../../../../models";
import { CompanyStatus } from "../../../../shared/constants";
import useToast from "../../../../shared/hooks/useToast";
import {
  RelationCloseCancel,
  RelationCloseSchedule,
} from "../CompanyList/models";

import CloseModal from "./components/Modals/CloseModal";
import ManageClosureModal from "./components/Modals/ManageClosureModal";
import ReactivateModal from "./components/Modals/ReactivateModal";

interface RelationActionsProps {
  selectedRelation: RelationData;
  relations: RelationData[];
  refetchRelations: () => void;
  closeExpandedRows: () => void;
  clearSelectedRowKeys: () => void;
  company?: object;
}

const RelationActions: React.FC<RelationActionsProps> = ({
  selectedRelation,
  relations,
  refetchRelations,
  closeExpandedRows,
  clearSelectedRowKeys,
  company,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);

  const [isManageClosureModalOpen, setIsManageClosureModalOpen] =
    useState(false);

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  const reactivateRelation = async () => {
    try {
      const relation = relations.find(
        relation => relation.id === selectedRelation.id
      );

      await CumdAPI.updateRelation(selectedRelation.ownerCompanyId, {
        companyIdType: "global_company_id",
        relations: [
          {
            associatedWithCompanyId: relation.associatedWithCompanyId,
            enabled: true,
          },
        ],
      });
      refetchRelations();
      closeExpandedRows();
      clearSelectedRowKeys();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const disableRelation = async () => {
    try {
      await CumdAPI.disableRelation(selectedRelation.id);
      refetchRelations();
      closeExpandedRows();
      clearSelectedRowKeys();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const scheduleClosure = async ({
    relationIds,
    targetSuspendDate,
    action,
  }: {
    relationIds: string[];
    targetSuspendDate: string;
    action: string;
  }) => {
    try {
      await CumdAPI.updateRelationsSuspension({
        relationIds,
        targetSuspendDate,
      });
      refetchRelations();
      closeExpandedRows();
      clearSelectedRowKeys();

      if (action === RelationCloseSchedule) {
        toaster.open({
          message: formatMessage({
            id: "companyRelationList.actions.manageClosure.schedule.toaster.title",
          }),
          description: formatMessage(
            {
              id: "companyRelationList.actions.manageClosure.schedule.toaster.description",
            },
            {
              ownerCompanyName: selectedRelation.ownerCompanyName,
              associatedCompanyName: selectedRelation.associatedCompanyName,
              targetSuspendDate: moment(targetSuspendDate).format("DD/MM/YYYY"),
            }
          ),
          type: "confirmation",
        });
      } else if (action === RelationCloseCancel) {
        toaster.open({
          message: formatMessage({
            id: "companyRelationList.actions.manageClosure.cancel.toaster.title",
          }),
          description: formatMessage(
            {
              id: "companyRelationList.actions.manageClosure.cancel.toaster.description",
            },
            {
              ownerCompanyName: selectedRelation.ownerCompanyName,
              associatedCompanyName: selectedRelation.associatedCompanyName,
            }
          ),
          type: "confirmation",
        });
      } else return;
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <>
      <ActionDropdown
        data-test-id="ad-user-action"
        overlay={
          <Menu>
            {(hasRoleSuperAdmin || hasRoleAdministrator) &&
              selectedRelation.enabled &&
              selectedRelation.targetSuspendDate === null && (
                <Menu.Item
                  onClick={() => setIsCloseModalOpen(true)}
                  key="close-relation"
                  data-test-id="close-relation"
                >
                  {formatMessage({
                    id: "companyRelationList.actions.closeRelation",
                  })}
                </Menu.Item>
              )}
            {(hasRoleSuperAdmin || hasRoleAdministrator) &&
              selectedRelation.enabled === false &&
              company["companyStatus"] === CompanyStatus.ENABLED && (
                <Menu.Item
                  onClick={() => setIsReactivateModalOpen(true)}
                  key="reactivate-relation"
                  data-test-id="reactivate-relation"
                >
                  {formatMessage({
                    id: "general.reactivate",
                  })}
                </Menu.Item>
              )}
            {(hasRoleSuperAdmin || hasRoleAdministrator) &&
              selectedRelation.enabled &&
              selectedRelation.targetSuspendDate !== null && (
                <Menu.Item
                  onClick={() => setIsManageClosureModalOpen(true)}
                  key="manage-closure"
                  data-test-id="manage-closure"
                >
                  {formatMessage({
                    id: "companyRelationList.actions.manageClosure",
                  })}
                </Menu.Item>
              )}
          </Menu>
        }
      />
      <ManageClosureModal
        visible={isManageClosureModalOpen}
        setVisible={setIsManageClosureModalOpen}
        selectedRelation={selectedRelation}
        disableRelation={disableRelation}
        scheduleClosure={scheduleClosure}
      />
      <ReactivateModal
        visible={isReactivateModalOpen}
        setVisible={setIsReactivateModalOpen}
        selectedRelation={selectedRelation}
        reactivateRelation={reactivateRelation}
      />
      <CloseModal
        visible={isCloseModalOpen}
        setVisible={setIsCloseModalOpen}
        selectedRelation={selectedRelation}
        disableRelation={disableRelation}
        scheduleClosure={scheduleClosure}
      />
    </>
  );
};

export default RelationActions;
