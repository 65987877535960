import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectCompanySubscriptionList = (state: RootState) =>
  state.companySubscriptionList;

export const selectCompanySubscriptionsData = createSelector(
  _selectCompanySubscriptionList,
  state => state
);
