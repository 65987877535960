import Table from "components/Table";

interface ProductHeaderProps {
  listingResult?: React.ReactNode;
}

const RelationTableHeader: React.FC<ProductHeaderProps> = ({
  listingResult,
}) => {
  return <Table.Header listingResult={listingResult} />;
};

export default RelationTableHeader;
