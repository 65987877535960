import { CompanyData, UserForUIAdminData } from "models";

type State = {
  data: UserForUIAdminData[];
  skipAndTakeQueryStats: {
    currentCount?: number;
    totalCount?: number;
  };
  companiesMap: { [companyId: string]: CompanyData };
  isLoading: boolean;
  hasError: boolean;
};

export const initialState: State = {
  data: [],
  skipAndTakeQueryStats: {
    currentCount: undefined,
    totalCount: undefined,
  },
  companiesMap: {},
  isLoading: false,
  hasError: false,
};
