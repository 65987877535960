import { useState } from "react";
import { useIntl } from "react-intl";

import { toaster } from "@trace-one/design-system";
import { Menu } from "antd";

import ActionDropdown from "components/ActionDropdown";

import { CumdAPI } from "../../../../../../apis";

import MainCompanyModal from "./components/MainCompanyModal";
import RemoveFromGroupModal from "./components/RemoveFromGroupModal";

interface GroupActionsProps {
  data?: any;
  companyGroupId?: string;
  numberOfActiveCompanies?: number;
}

const GroupsActions: React.FC<GroupActionsProps> = ({
  data,
  companyGroupId,
  numberOfActiveCompanies,
}) => {
  const { formatMessage } = useIntl();
  const [isMainCompanyModalVisible, setIsMainCompanyModalVisible] =
    useState<boolean>(false);

  const [isRemoveFromGroupModalVisible, setIsRemoveFromGroupModalVisible] =
    useState<boolean>(false);
  const handleUpdateMainCompany = async () => {
    try {
      await CumdAPI.updateMainCompanyInGroup(companyGroupId, {
        companyId: data?.key,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        type: "confirmation",
      });

      setIsMainCompanyModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  const handleRemoveFromGroup = async () => {
    try {
      await CumdAPI.removeCompanyFromGroup(companyGroupId, {
        companyId: data?.key,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        type: "confirmation",
      });

      setIsRemoveFromGroupModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  return (
    <>
      <ActionDropdown
        data-test-id="ad-group-action"
        overlay={
          <Menu>
            <>
              <Menu.Item
                key="define-as-main-company"
                data-test-id="ad-define-as-main-company"
                onClick={() => setIsMainCompanyModalVisible(true)}
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                {formatMessage({
                  id: "groupDetailsPage.defineAsMainCompany",
                })}
              </Menu.Item>

              {numberOfActiveCompanies > 2 && (
                <Menu.Item
                  key="remove-from-group"
                  data-test-id="ad-remove-from-group"
                  onClick={() => setIsRemoveFromGroupModalVisible(true)}
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  {formatMessage({
                    id: "groupDetailsPage.removeFromGroup",
                  })}
                </Menu.Item>
              )}
            </>
          </Menu>
        }
      />

      <MainCompanyModal
        isModalVisible={isMainCompanyModalVisible}
        setIsModalVisible={setIsMainCompanyModalVisible}
        companyDisplayName={data?.companyName}
        handleUpdateMainCompany={handleUpdateMainCompany}
      />

      <RemoveFromGroupModal
        isModalVisible={isRemoveFromGroupModalVisible}
        setIsModalVisible={setIsRemoveFromGroupModalVisible}
        companyDisplayName={data?.companyName}
        handleRemoveFromGroup={handleRemoveFromGroup}
      />
    </>
  );
};

export default GroupsActions;
