import { createSelector } from "@reduxjs/toolkit";

import { ReferenceListItemData } from "models";

import { RootState } from "reduxStore";

export const _selectShared = (state: RootState) => state.shared;

export const selectCompanyActivitiesData = createSelector(
  _selectShared,
  ({ companyActivitiesData }) => companyActivitiesData
);
export const selectCompanyActivitesMap = createSelector(
  selectCompanyActivitiesData,
  ({ data }) =>
    data.reduce<{ [itemCode: string]: ReferenceListItemData }>(
      (prev, current) => ({ ...prev, [current.itemCode]: current }),
      {}
    )
);

export const selectTeamMemberResponsibilitiesData = createSelector(
  _selectShared,
  ({ teamMemberResponsibilitiesData }) => teamMemberResponsibilitiesData
);
export const selectTeamMemberResponsibilitiesMap = createSelector(
  selectTeamMemberResponsibilitiesData,
  ({ data }) =>
    data.reduce<{ [refId: string]: ReferenceListItemData }>(
      (prev, current) => ({ ...prev, [current.id]: current }),
      {}
    )
);
export const selectJobTitlesData = createSelector(
  _selectShared,
  ({ jobTitlesData }) => jobTitlesData
);

export const selectCountriesData = createSelector(
  _selectShared,
  ({ countriesData }) => countriesData
);

export const selectLanguagesData = createSelector(
  _selectShared,
  ({ languagesData }) => languagesData
);
