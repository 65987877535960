import { useIntl } from "react-intl";

import { SubscriptionStatus } from "shared/constants";

export default function useSubscriptionStatuses() {
  const { formatMessage } = useIntl();

  const subscriptionStatusLabels = {
    [SubscriptionStatus.ACTIVE]: formatMessage({ id: "general.active" }),
    [SubscriptionStatus.INACTIVE]: formatMessage({
      id: "general.inactive",
    }),
  };

  const subscriptionTagColors = {
    [SubscriptionStatus.ACTIVE]: "success",
    [SubscriptionStatus.INACTIVE]: "error",
  };

  return {
    subscriptionStatusLabels,
    subscriptionTagColors,
  };
}
