import { useIntl } from "react-intl";

import { Container } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import GroupList from "../../containers/GroupList";

import styles from "./GroupListPage.module.less";
export const GroupListPage: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Container isPageWrapper>
      <div className={styles.titleWrapper}>
        <Title
          value={formatMessage({
            id: "groupList.title",
          }).toUpperCase()}
          level={1}
        />
      </div>
      <GroupList />
    </Container>
  );
};

export default GroupListPage;
