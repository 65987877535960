import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";

import { useAppDispatch } from "reduxStore";
import { selectCompanyActivitiesData } from "reduxStore/shared/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";
import {
  fetchRolesForManageAccess,
  fetchUserForManageAccess,
  fetchResponsibilities,
} from "reduxStore/userAccessManage/asyncActions";
import { selectUserAccessManage } from "reduxStore/userAccessManage/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";

import UserAccessManage from "../../containers/UserAccessManage";

export const UserAccessManagePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: string }>();
  const languageCode = useSelector(selectUserLanguageCode);

  const companyActivities = useSelector(selectCompanyActivitiesData).data;
  const companyActivitiesRef = useRef(companyActivities);
  companyActivitiesRef.current = companyActivities;

  const { userData, rolesData, responsibilitiesData } = useSelector(
    selectUserAccessManage
  );
  const [isInitiated, setIsInitiated] = useState(false);

  const refetchUser = () => {
    return dispatch(fetchUserForManageAccess({ userId }));
  };

  const refetchRoles = ({ companyId }: { companyId: string }) => {
    return dispatch(
      fetchRolesForManageAccess({
        companyId,
        languageCode,
      })
    );
  };

  const refetchResponsibilities = ({
    companyActivityGuid,
    companyId,
  }: {
    companyActivityGuid: string;
    companyId: string;
  }) => {
    return dispatch(
      fetchResponsibilities({
        parentItemId: companyActivityGuid,
        languageCode,
        companyId,
      })
    );
  };

  useEffect(() => {
    setIsInitiated(false);
    refetchUser()
      .then(unwrapResult)
      .then(({ user: { owningCompanyId }, companyActivityGuid }) =>
        Promise.all([
          refetchRoles({ companyId: owningCompanyId }),
          refetchResponsibilities({
            companyActivityGuid,
            companyId: owningCompanyId,
          }),
        ])
      )
      .finally(() => {
        setIsInitiated(true);
      });
  }, [userId]);

  useEffect(() => {
    if (isInitiated) {
      return;
    }
    const companyId = userData.data?.owningCompanyId;
    const companyActivityGuid = userData.companyActivityGuid;

    if (companyId) {
      refetchRoles({ companyId });
    }
    if (companyActivityGuid) {
      refetchResponsibilities({
        companyActivityGuid,
        companyId,
      });
    }
  }, [languageCode]);

  if (
    userData.hasError ||
    rolesData.hasError ||
    responsibilitiesData.hasError
  ) {
    return (
      <ErrorPage
        status={
          userData.errorStatus ||
          rolesData.errorStatus ||
          responsibilitiesData.errorStatus
        }
      />
    );
  }

  if (!isInitiated) {
    return <Spinner underHeader />;
  }

  return (
    <UserAccessManage
      refetchUser={refetchUser}
      user={userData.data}
      teamMemberResponsibilities={responsibilitiesData.data}
    />
  );
};

export default UserAccessManagePage;
