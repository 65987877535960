import { CompanyForTraceoneAdminData } from "models";

type State = {
  data: CompanyForTraceoneAdminData;
  isLoading: boolean;
  hasError: boolean;
  errorStatus: number;
};

export const initialState: State = {
  data: null,
  isLoading: true,
  hasError: false,
  errorStatus: null,
};
